import axios from 'axios'

const BASE_URL = '/companies'

export const getCompanyInfoService = async ({ companyId }) =>
  axios.get(`${BASE_URL}/${companyId}`).then(({ data }) => data)

export const getUserByCompanyService = async ({ companyId }) =>
  axios.get(`${BASE_URL}/${companyId}/users`).then(({ data }) => data)

export const isAllowedCreateUserServive = async () =>
  axios.get(`${BASE_URL}/allowed_create_user`).then(({ data }) => data)

export const selectPlanCompanyService = async ({ companyId, period, plan_package_id }) =>
  axios.patch(`${BASE_URL}/${companyId}/package`, { period, plan_package_id }).then(({ data }) => data)

export const updateCompanyService = async ({ companyId, body }) =>
  axios.patch(`${BASE_URL}/${companyId}`, body).then(({ data }) => data)

export const updateInitConfigCompanyService = async ({ body }) =>
  axios.patch(`${BASE_URL}/init_config`, body).then(({ data }) => data)
