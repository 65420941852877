import React from 'react'
import { countDecimalsAndAdd0 } from 'Utils/helperFunctions'
import { ROLE_ADMIN_NAME, ROLE_DEVELOPER_NAME } from 'constant/roles'
import useSessionStore from 'Context/useSessionStore'
import Avatar from 'react-avatar'
import { GET_COLOR_BY_THEME } from 'constant/colors'

const StepUsersTable = ({ users, nextStep }) => {
  const { user: userContext } = useSessionStore((state) => ({ user: state.user }))

  let totalHours = 0
  let totalPrice = 0

  return (
    <div className="card p-4">
      <div className="table-responsive projects-card">
        <table className="table align-middle mb-0 payroll_table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Payment Type</th>
              <th scope="col" className="text-end">
                Hours
              </th>
              {[ROLE_ADMIN_NAME, ROLE_DEVELOPER_NAME].includes(userContext.role) && (
                <th scope="col" className="text-end">
                  Payable amount
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              const hoursType = Object.keys(user?.service_details).filter(
                (item) => user?.service_details[item].total_hours > 0
              )

              return (
                <React.Fragment key={user.id}>
                  {hoursType.map((hourType) => {
                    const hours = user?.service_details?.[hourType]?.billable_hours || 0
                    const price =
                      hourType === 'hourly_cost'
                        ? user?.service_details?.[hourType]?.billable_cost || 0
                        : user?.cost_rate?.cost_rate || 0

                    totalHours += hours
                    totalPrice += price

                    return (
                      <tr key={hourType} className="hover">
                        <td className="text-no-wrap pointer d-flex align-items-center" onClick={() => nextStep({ userId: user.id })}>
                          <Avatar
                            name={user.full_name}
                            src={user?.logo || null}
                            className="avatarImage me-2"
                            color={GET_COLOR_BY_THEME({ theme: userContext.theme }).AVATAR}
                            fgColor={GET_COLOR_BY_THEME({ theme: userContext.theme }).AVATAR_TEXT}
                            round={true}
                            size="38px"
                            textSizeRatio={3}
                          />

                          {user.full_name}
                        </td>
                        <td className="text-no-wrap">
                          {hourType === 'hourly_cost'
                            ? (
                            <span className="badge text-bg-quaternaly">Hourly rate</span>
                              )
                            : (
                            <span className="badge text-bg-primary">Monthly payment</span>
                              )}
                        </td>
                        <td className="text-end">{countDecimalsAndAdd0({ number: hours })}h</td>
                        {[ROLE_ADMIN_NAME, ROLE_DEVELOPER_NAME].includes(userContext.role) && (
                          <td className="text-end">
                            ${countDecimalsAndAdd0({ number: price })} {hourType === 'hourly_cost' ? '' : '/month'}
                          </td>
                        )}
                      </tr>
                    )
                  })}
                </React.Fragment>
              )
            })}

            <br />
            <tr style={{ borderTop: '1px solid rgba(54, 58, 57, 0.25)' }}>
              <td style={{ border: 'none' }} className="text-no-wrap text-normal fw-bold">
                Total
              </td>
              <td style={{ border: 'none' }} />
              <td style={{ border: 'none' }} className="text-end text-normal fw-bold">
                {countDecimalsAndAdd0({ number: totalHours })}h
              </td>
              {[ROLE_ADMIN_NAME, ROLE_DEVELOPER_NAME].includes(userContext.role) && (
                <td style={{ border: 'none' }} className="text-end text-normal fw-bold">
                  ${countDecimalsAndAdd0({ number: totalPrice })}
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default StepUsersTable
