import LoadingButton from './LoadingButton'

const ButtonsModal = ({ onClose, loading, formControl }) => {
  return (
    <div className="d-flex gap-3">
      <LoadingButton type="button" className="btn btn-secondary" onClick={onClose}>
        Close
      </LoadingButton>
      <LoadingButton type="submit" form={formControl} className="btn btn-primary" disabled={loading}>
        Save
      </LoadingButton>
    </div>
  )
}

export default ButtonsModal
