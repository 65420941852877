import { ArrowsIn, ArrowsOut } from '@phosphor-icons/react'
import ContextColumnTimeEntry from 'Context/ColumnsTimeEntryContext'
import ContextColumnTimeSheet from 'Context/ColumnsTimeSheetContext'
import useSessionStore from 'Context/useSessionStore'
import FilterAllDropdown from 'Organisms/FilterAllDropdown'
import { Loading } from 'Organisms/Loading'
import { ModalFilters } from 'Organisms/ModalFilters'
import { ROLE_DEVELOPER_NAME } from 'constant/roles'
import { INITIAL_STATE_TIMEENTRY, INITIAL_STATE_TIMESHEET } from 'constant/tableColumns'
import { useContext, useState } from 'react'

const ModalFiltersTimeSheetMobile = ({
  onFilter,
  defaultSelectProject,
  defaultSelectUsers,
  defaultSelectTasks,
  defaultSelectStatus
}) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const { loadingColumnsTimeSheetContext, timeSheetColunm, handleChangeActiveSwitch } =
    useContext(ContextColumnTimeSheet)
  const { loadingColumnsTimeEntryContext, timeEntryColunm, handleChangeActiveSwitchTimeEntry } =
    useContext(ContextColumnTimeEntry)

  const [filter, setFilter] = useState([])
  const [clear, setClear] = useState(true)
  const [isCondensed, setIsCondensed] = useState(true)

  const handleChangeFilter = ({ filter }) => setFilter(filter)

  const handleSendFilter = () => {
    onFilter && onFilter({ condensed: isCondensed, filterP: filter })
  }

  const handleClearFilter = () => {
    setClear(!clear)
    setFilter([])
    onFilter && onFilter({ filter: [] })
  }

  const handleChangeTypeTable = ({ condensed }) => setIsCondensed(condensed)

  return (
    <div className="my-4">
      {(loadingColumnsTimeSheetContext || loadingColumnsTimeEntryContext) && <Loading />}

      <ModalFilters onFilter={handleSendFilter} onClear={handleClearFilter}>
        <div className="modalFilterPayrollMobile">
          <FilterAllDropdown
            onChangeFilter={handleChangeFilter}
            hiddenUsers={user.role === ROLE_DEVELOPER_NAME}
            hiddenSprint
            hiddenClear
            clearOut={clear}
            colorBtn="secondary"
            defaultSelectProject={defaultSelectProject}
            defaultSelectUsers={defaultSelectUsers}
            defaultSelectTasks={defaultSelectTasks}
            defaultSelectStatus={defaultSelectStatus}
          />

          <div className="btn-group my-4" role="group">
            <button
              type="button"
              className={`btn btn-secondary ${isCondensed ? 'active' : ''}`}
              title="Condense"
              onClick={() => handleChangeTypeTable({ condensed: true })}>
              <ArrowsIn size={17} weight="bold" />
            </button>
            <button
              type="button"
              className={`btn btn-secondary ${isCondensed ? '' : 'active'}`}
              title="Expand"
              onClick={() => handleChangeTypeTable({ condensed: false })}>
              <ArrowsOut size={17} weight="bold" />
            </button>
          </div>
          {isCondensed
            ? (
            <div className="modalFilterUserMobile_optionRole">
              {timeSheetColunm.map((column) => (
                <p
                  key={column.id}
                  className={`${
                    column.checked ? 'role_active' : ''
                  } btn btn-secondary modalFilterUserMobile_options m-0`}
                  onClick={() => handleChangeActiveSwitch({ item: column, active: !column.checked })}>
                  {INITIAL_STATE_TIMESHEET.find((item) => item.name === column.name).text}
                </p>
              ))}
            </div>
              )
            : (
            <div className="modalFilterUserMobile_optionRole">
              {timeEntryColunm.map((column) => (
                <p
                  key={column.id}
                  className={`${
                    column.checked ? 'role_active' : ''
                  } btn btn-secondary modalFilterUserMobile_options m-0`}
                  onClick={() => handleChangeActiveSwitchTimeEntry({ item: column, active: !column.checked })}>
                  {INITIAL_STATE_TIMEENTRY.find((item) => item.name === column.name).text}
                </p>
              ))}
            </div>
              )}
        </div>
      </ModalFilters>
    </div>
  )
}

export default ModalFiltersTimeSheetMobile
