import { useEffect, useState } from 'react'
import { Mixpanel } from 'Utils/Mixpanel'
import CardPricing from './Components/CardPricing'
import FAQsPricing from './Components/FAQsPricing'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { DASHBAORD_ROUTER, SIGNUP_ROUTER } from 'constant/router'
import useSessionStore from 'Context/useSessionStore'
import { useCompany, usePayment } from 'Hooks'
import { Loading } from 'Organisms/Loading'
import { getMe } from 'Services'
import { CURRENT_USER_STORAGE } from 'Utils/const/localstorage'
import { ModalSuccess } from 'Organisms/ModalSuccess'
import { GET_LIST_PLAN_PACKAGES, OPTIONS_PRINCING } from 'Utils/const/packages'
import { Helmet } from 'react-helmet'
import { trackEvent } from 'Utils/GoogleAnalytics'

const PricingPage = () => {
  const { user, updateUser } = useSessionStore((state) => ({ user: state.user, updateUser: state.updateUser }))

  const [searchParams] = useSearchParams()

  const checkout = searchParams.get('checkout')

  const navigate = useNavigate()

  const { selectPlanCompanyHook } = useCompany()
  const { getPaymentConnectHook } = usePayment()

  const [selectedPeriod, setSelectedPeriod] = useState(OPTIONS_PRINCING.annually.name)
  const [loading, setLoading] = useState(false)
  const [loadingValidate, setLoadingValidate] = useState(false)
  const [message, setMessage] = useState({ open: false })

  const PLANS = GET_LIST_PLAN_PACKAGES({ period: selectedPeriod, hiddenCustom: !!user })

  const choosePlan = async ({ plan }) => {
    if (!user) {
      await trackEvent({
        category: 'Pricing Page',
        action: 'Choose Plan',
        label: plan
      })

      Mixpanel.track('Pricing Page Interaction', {
        event_type: 'Plan Clicked',
        plan_name: 'Starter',
        plan_price: 0,
        billing_cycle: selectedPeriod
      })

      return navigate(SIGNUP_ROUTER)
    }

    if (plan === PLANS.FREE.plan) {
      Mixpanel.track('Pricing Page Interaction', {
        event_type: 'Plan Clicked',
        plan_name: 'Starter',
        plan_price: 0,
        billing_cycle: selectedPeriod,
        timestamp: new Date().toISOString()
      })
      return selectPlanCompany({ plan_id: PLANS.FREE.id, period: selectedPeriod })
    }

    if (plan === PLANS.PROFESSIONAL.plan) {
      Mixpanel.track('Pricing Page Interaction', {
        event_type: 'Plan Clicked',
        plan_name: 'Professional',
        plan_price: selectedPeriod === OPTIONS_PRINCING.annually.name ? OPTIONS_PRINCING.annually.price : OPTIONS_PRINCING.monthly.price,
        billing_cycle: selectedPeriod,
        timestamp: new Date().toISOString()
      })
      const res = await getPaymentConnectHook({ period: selectedPeriod })

      if (!res.success) {
        return setMessage({
          open: true,
          error: true,
          message: 'An error has occurred'
        })
      }

      window.location.href = res.data.url
    }
  }

  const selectPlanCompany = async ({ plan_id, period }) => {
    try {
      setLoading(true)
      const res = await selectPlanCompanyHook({
        companyId: user.company_id,
        period,
        plan_package_id: plan_id
      })

      if (!res.success) {
        setLoading(false)
        return console.error('error')
      }

      await updateUser({
        ...user,
        company_is_active: true
      })
      navigate(DASHBAORD_ROUTER)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      if (checkout) {
        setLoadingValidate(true)
        setTimeout(async () => {
          try {
            const { data } = await getMe()
            localStorage.setItem(CURRENT_USER_STORAGE, JSON.stringify({ ...user, ...data }))
            await updateUser({ ...user, ...data })

            if (data.company_is_active) {
              return navigate(DASHBAORD_ROUTER)
            } else {
              setLoadingValidate(false)
            }
          } catch (e) {
            console.error(e)
            setLoadingValidate(false)
          }
        }, 3000)
      }
    })()
  }, [])

  if (loadingValidate) return <Loading />

  return (
    <>
      <Helmet>
        <title>Plans & Pricing</title>
        <meta
          name="description"
          content="Time and task Tracking. Free trial available. Three pricing plans available."
        />
        <meta
          name="keywords"
          content="time tracker, time tracking, remote employees, productivity, software, remote work"
        />
        <meta name="author" content="Pulse by Canned Head" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Plans & Pricing" />
        <meta
          property="og:description"
          content="Time and task Tracking. Free trial available. Three pricing plans available."
        />
        <meta property="og:image" content="https://example.com/image.jpg" />
        <meta property="og:url" content="https://example.com/time-tracking-software" />
        <meta property="og:type" content="website" />
      </Helmet>

      {loading && <Loading />}
      <div className={`landing_img_text_bg ${user ? 'pb-5' : ''}`}>
        <div className="containers pricing pt-4">
          <div className="row justify-content-center py-5">
            { !user
              ? <h1 className="col-12 text-center">Pricing</h1>
              : <h1 className="col-12 text-center">Choose a plan</h1>
            }
          </div>
          <div className="row justify-content-center mb-4">
            <div className="col-auto">
              <div className="btn-group">
                <button
                  type="button"
                  onClick={() => setSelectedPeriod(OPTIONS_PRINCING.monthly.name)}
                  className={`btn btn-secondary ${selectedPeriod === OPTIONS_PRINCING.monthly.name ? 'active2' : ''}`}
                  aria-current="page">
                  Monthly billing
                </button>
                <button
                  type="button"
                  onClick={() => setSelectedPeriod(OPTIONS_PRINCING.annually.name)}
                  className={`btn btn-secondary ${selectedPeriod === OPTIONS_PRINCING.annually.name ? 'active2' : ''}`}>
                  Yearly billing <span className="badge text-bg-primary">up to -20%</span>
                </button>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            {Object.values(PLANS).map((plan) => (
              <CardPricing
                key={plan.plan}
                title={plan?.title || ''}
                mode={plan?.mode || ''}
                creditCardRequired={plan?.creditCardRequired}
                description={plan?.description || ''}
                btnChoose={plan?.btnChoose || ''}
                including={plan?.including || []}
                onChoosePlan={() => choosePlan({ plan: plan?.plan || '' })}
                period={plan?.period || ''}
                btnColor={plan?.btnColor || ''}
              />
            ))}
          </div>
          { !user && <FAQsPricing /> }
        </div>
      </div>

      {message.open && (
        <ModalSuccess
          visible={true}
          setVisible={() => setMessage({ open: false, message: '', error: false })}
          title={message.message}
          identifier="modalPayment"
          error={message.error}
        />
      )}
    </>
  )
}

export default PricingPage
