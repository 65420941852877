import { useDevops } from 'Hooks'
import { Loading } from 'Organisms/Loading'
import { useEffect } from 'react'
import { FIELD_CONNECT_AZURE } from './ModalConnectAzure'

const FieldMappingAzureIntegration = ({ register, project, projectName, organization }) => {
  const SELECTS_FIELD_INTEGRATION_AZURE = [
    {
      name: 'Estimate',
      field: FIELD_CONNECT_AZURE.FIELD_ESTIMATE
    },
    {
      name: 'Sprint',
      field: FIELD_CONNECT_AZURE.FIELD_SPRINT
    },
    {
      name: 'Story Points',
      field: FIELD_CONNECT_AZURE.FIELD_STORY_POINTS
    }
  ]

  const { listFieldAzureHook, loadingDevopsHook, getFieldAzureIntegrationHook } = useDevops()

  useEffect(() => {
    ;(async () => {
      await getFieldAzureIntegrationHook({ projectId: project.id, projectName, organization })
    })()
  }, [])

  return (
    <>
      {loadingDevopsHook && <Loading />}

      {SELECTS_FIELD_INTEGRATION_AZURE.map((field) => (
        <div className="row" key={field.field}>
          <label className="col-12 col-form-label" htmlFor="inputProject">
            {field.name}
          </label>
          <div className="col-12">
            <select
              {...register(field.field)}
              className='form-control form-select'
              id="inputProject">
              <option value="">Select a Field</option>

              {listFieldAzureHook.map((option) => (
                <option key={option.referenceName} value={option.referenceName}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      ))}
    </>
  )
}

export default FieldMappingAzureIntegration
