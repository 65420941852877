import axios from 'axios'

const BASE_URL_USER = '/payrolls'

// GET
export const getAllPayrollService = async ({ query }) =>
  axios.get(`${BASE_URL_USER}/submissions${query && `?${query}`}`).then(({ data }) => data)

// POST
export const updateApprovedHoursService = async ({ body }) =>
  axios.post(`${BASE_URL_USER}/submit/closed`, body).then(({ data }) => data)

export const updateReOpenHoursService = async ({ body }) =>
  axios.post(`${BASE_URL_USER}/submit/open`, body).then(({ data }) => data)

export const updateRejectedHoursService = async ({ body }) =>
  axios.post(`${BASE_URL_USER}/submit/reject`, body).then(({ data }) => data)
