import { Desktop, PencilSimple, Trash, User } from '@phosphor-icons/react'
import useSessionStore from 'Context/useSessionStore'
import { useModal, useUser } from 'Hooks'
import { Loading } from 'Organisms/Loading'
import { useState } from 'react'

const AvatarModalSettings = ({ onClose, edit, onRemove }) => {
  const { user, updateUser } = useSessionStore((state) => ({ user: state.user, updateUser: state.updateUser }))
  const { Modal, hide } = useModal({ identifier: 'modalSuccess', visible: true, setVisible: onClose })
  const { loadingUserHook, uploadUserAvatarHook } = useUser()

  const [avatar, setAvatar] = useState(edit?.logo ?? '')
  const [isChange, setIsChange] = useState(false)
  const [isEdit, setIsEdit] = useState(!!edit?.logo)
  const [contentFile, setContentFile] = useState(null)

  const handleChangeAvatar = (e) => {
    const imageFile = e.target.files[0]

    if (!imageFile.type.includes('image/')) return

    const imageUrl = URL.createObjectURL(imageFile)
    setAvatar(imageUrl)
    setContentFile(imageFile)
    isEdit && setIsEdit(false)
    isChange && setIsChange(false)
  }

  const handleRemove = () => {
    hide()
    onRemove && onRemove()
  }

  const handleSaveAvatar = async () => {
    const name = `users/${user.id}/logo/profile.png`

    const res = await uploadUserAvatarHook({ file: contentFile, name, user })

    if (!res.success) return

    updateUser({
      ...user,
      logo: res.data.logo
    })

    hide()
  }

  return (
    <Modal center>
      <>
        {loadingUserHook && <Loading />}
        <div className="modal-content modalSuccess p-5" style={{ maxWidth: 400 }}>
          <button type="button" className="btn-close btnCloseModal" onClick={hide} aria-label="Close" />

          {((!avatar && !isEdit) || isChange) && (
            <>
              <h3 className="fs-5 text-center">Add profile picture</h3>

              <div className="avatarModalSettings_form">
                <label htmlFor="uploadAvatar">
                  <div className="avatarModalSettings_drag">
                    <div className="avatarModalSettings_avatar">
                      {avatar ? <img src={avatar} /> : <User size={40} color="white" />}
                    </div>

                    <p>Drag photo here</p>
                    <p>- or -</p>
                    <input
                      type="file"
                      className="avatarModalSettings_avatar_input"
                      id="uploadAvatar"
                      onChange={handleChangeAvatar}
                      accept=".png, .jpg, .jpeg"
                    />
                  </div>

                  <div className="avatarModalSettings_upload">
                    <Desktop />
                    <p>Upload from computer</p>
                  </div>
                </label>
              </div>
            </>
          )}

          {avatar && !isEdit && (
            <>
              <h3 className="fs-5 text-center">Profile Picture</h3>

              <div className="avatarModalSettings_form">
                <div className="avatarModalSettings_drag">
                  <p>A picture helps people to recognize you</p>
                  <div className="avatarModalSettings_avatar">
                    <img src={avatar} />
                  </div>
                </div>

                <div className="row justify-content-center pt-4">
                  <div className="col-auto">
                    <button type="button" id="btnModalCancel" className="btn btn-secondary mr-2" onClick={() => hide()}>
                      Cancel
                    </button>
                  </div>
                  <div className="col-auto">
                    <button id="btnModalConfirm" type="button" onClick={handleSaveAvatar} className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}

          {isEdit && !isChange && (
            <>
              <h3 className="fs-5 text-center">Change profile picture</h3>

              <div className="avatarModalSettings_form">
                <div className="avatarModalSettings_drag">
                  <p>A picture helps people to recognize you</p>
                  <div className="avatarModalSettings_avatar">
                    <img src={avatar} />
                  </div>
                </div>

                <div className="row justify-content-center pt-4">
                  <div className="col-auto">
                    <button
                      type="button"
                      id="btnModalCancel"
                      className="btn btn-secondary mr-2"
                      onClick={() => setIsChange(true)}>
                      <PencilSimple /> <span>Change</span>
                    </button>
                  </div>
                  <div className="col-auto">
                    <button
                      id="btnModalConfirm"
                      type="button"
                      onClick={() => handleRemove(true)}
                      className="btn btn-primary">
                      <Trash />
                      <span>Remove</span>
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    </Modal>
  )
}

export default AvatarModalSettings
