import { useCallback, useEffect, useState } from 'react'
import { TrashSimple, WarningOctagon } from '@phosphor-icons/react'
import { useFormContext, useWatch } from 'react-hook-form'
import dayjs from 'dayjs'

// Components
import SelectWithHint from 'Organisms/SelectWithHint'

// Hooks
import { useDebounce, useProject } from 'Hooks'

// Const
import { COLOR_BLUE_MATE, COLOR_RED } from 'constant/colors'
import { Loading } from 'Organisms/Loading'

const AssignedProjects = ({ userId, error, projectsAssigned }) => {
  // State
  const [loadingInternal, setLoadingInternal] = useState(false)
  const [search, setSearch] = useState('')
  const { control, setValue } = useFormContext()
  const assignProject = useWatch({
    control,
    name: 'assignProject',
    defaultValue: (projectsAssigned || []).map((item) => item.project)
  })

  // Hooks
  const { projectsHook, loadingProjectHook, getProjectsHook, deleteUserToProjectHook } = useProject()

  // Handlers
  useEffect(() => {
    ;(async () => {
      await getProjectsHook()
    })()
  }, [])

  const filterDebounce = useCallback(
    useDebounce(async ({ search }) => {
      setLoadingInternal(true)
      await getProjectsHook({ search })
      setLoadingInternal(false)
    }, 1000),
    []
  )

  const hanldeChangeSearch = (searchParam) => {
    setSearch(searchParam)
    filterDebounce({ search: searchParam })
  }

  const handleChangeSelectUser = (value) => {
    const project = projectsHook.find((item) => item.id === value.value)

    if (assignProject.some((projectP) => projectP.id === project.id)) return

    setValue('assignProject', [...assignProject, { ...project, new: true }])
  }

  const handleRemove = async ({ project }) => {
    const updateList = assignProject.filter((item) => item.id !== project.id)

    if (!project.new) await deleteUserToProjectHook({ userId, idProject: project.id })

    setValue('assignProject', updateList)
  }

  const formatSelect = ({ list }) => {
    return list.map((item) => ({
      value: item.id,
      label: `[${item?.code || ''}] ${item?.name || ''}`
    }))
  }

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-11 d-grid">
          {loadingProjectHook && !loadingInternal && <Loading />}

          <div className="row">
            <h4>Assigned Projects</h4>
            <p>You can only track time to projects you’re assigned.</p>
          </div>

          {error && (
            <div className="alert alert-danger" role="alert">
              <div className="row">
                <div className="col-auto">
                  <WarningOctagon size={24} color={COLOR_RED} weight="bold" />
                </div>
                <div className="col">
                  <p className="mb-0 me-3">{error}</p>
                </div>
              </div>
            </div>
          )}

          <SelectWithHint
            loading={loadingInternal}
            value={search}
            id="asignedProject"
            placeholder="Search project"
            formatSelect={formatSelect({ list: projectsHook })}
            handleInputChange={hanldeChangeSearch}
            handleChange={handleChangeSelectUser}
            noOptionsMessage="User not found"
            loadingMessage="Search"
          />

          <div className="table-responsive mt-4">
            <table className="table align-middle">
              <thead>
                <tr>
                  <th scope="col">Project name</th>
                  <th scope="col">Date assigned</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {assignProject.map((project) => (
                  <tr key={project.id}>
                    <td>
                      <p className="mb-0 text-no-wrap">{`[${project.code}] ${project.name}`}</p>
                    </td>
                    <td className='text-no-wrap'>{dayjs().format('MMM DD, YYYY')}</td>
                    <td className="text-end">
                      <button
                        type="button"
                        className="btn btn-secondary rounded"
                        onClick={() => handleRemove({ project })}>
                        <TrashSimple size={18} color={COLOR_BLUE_MATE} weight="duotone" />{' '}
                        <span className="d-none d-sm-inline">Delete</span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default AssignedProjects
