import React from 'react'
import { countDecimalsAndAdd0, mapStates } from 'Utils/helperFunctions'
import { getMyHoursTimeSheet, setBackgroundColorTimeSheet } from '../utils/helpersTimeSheet'
import { STATUS_COLOR_TICKET_DANGER } from 'constant/StatusTicket'
import { ProgressBarTimeSheet } from 'Organisms/ProgressBarTimeSheet'
import {
  TABLE_ITEM_BILLABLE_TIMESHEET,
  TABLE_ITEM_ESTIMATE_TIMESHEET,
  TABLE_ITEM_MY_WORK_TIMESHEET,
  TABLE_ITEM_STATE_TIMESHEET,
  TABLE_ITEM_STORY_POINTS_TIMESHEET,
  TABLE_ITEM_TAKS_TIMESHEET,
  TABLE_ITEM_TITLE_TIMESHEET,
  TABLE_ITEM_WARRANTY_WORK_TIMESHEET
} from 'constant/tableColumns'
import useSessionStore from 'Context/useSessionStore'
import { TextTruncate } from 'Organisms/TextTruncate'
import { WarrantyTag } from 'Organisms/WarrantyTag'

const TimesheetTableItemCondensed = ({ item, columns, onOpenDetail }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const estimate = item?.estimate || 0
  const completedWork = item?.completed_work || 0
  const warrantyWork = item?.warranty_work || 0
  const billableRate = completedWork - warrantyWork > 0 ? completedWork - warrantyWork : 0

  const COLOR_STATUS = setBackgroundColorTimeSheet({
    completedWork: billableRate,
    estimate,
    warrantyWork,
    status: item.state
  })

  return (
    <tr>
      {columns.map((column) => {
        const { name, checked } = column

        if (!checked) return <React.Fragment key={name} />

        return (
          <React.Fragment key={name}>
            {name === TABLE_ITEM_TITLE_TIMESHEET && (
              <td style={{ maxWidth: 900, width: '100%' }}>
                <TextTruncate
                  text={item.title.trim()}
                  classTooltip={user.theme ? 'custom-tooltip-dark' : 'custom-tooltip-ligth'}
                  maxCharacter={84}
                  classText={COLOR_STATUS === STATUS_COLOR_TICKET_DANGER ? 'text-danger' : ''}>
                  <span className='underline-hover pointer' onClick={onOpenDetail}>{item.title.trim()}{' '}</span>
                  {warrantyWork > 0 && <WarrantyTag />}
                </TextTruncate>
              </td>
            )}

            {name === TABLE_ITEM_STATE_TIMESHEET && (
              <td>
                <span className={`badge ${estimate ? `text-bg-${COLOR_STATUS}` : ''}`}>
                  {item?.state ? mapStates(item.state) : '-'}
                </span>
              </td>
            )}

            {name === TABLE_ITEM_TAKS_TIMESHEET && <td className="text-no-wrap">{item?.task || '-'}</td>}

            {name === TABLE_ITEM_STORY_POINTS_TIMESHEET && <td>{item.story_points || '-'}</td>}

            {name === TABLE_ITEM_ESTIMATE_TIMESHEET && <td>{estimate || '-'}</td>}

            {name === TABLE_ITEM_BILLABLE_TIMESHEET && (
              <td className={`${COLOR_STATUS === STATUS_COLOR_TICKET_DANGER ? 'text-danger' : ''}`}>
                {countDecimalsAndAdd0({ number: billableRate })}
              </td>
            )}

            {name === TABLE_ITEM_WARRANTY_WORK_TIMESHEET && <td>{countDecimalsAndAdd0({ number: warrantyWork })}</td>}

            {name === TABLE_ITEM_MY_WORK_TIMESHEET && (
              <td className={`${COLOR_STATUS === STATUS_COLOR_TICKET_DANGER ? 'text-danger' : ''}`}>
                {countDecimalsAndAdd0({ number: item.my_work })}
              </td>
            )}
          </React.Fragment>
        )
      })}
      <td>
        <ProgressBarTimeSheet
          warrantyWork={warrantyWork}
          estimate={estimate}
          billableRateGroup={billableRate}
          status={item?.state || ''}
          completedWork={billableRate}
          item={item}
        />
      </td>
      <td>
        <span className="col-auto">
          {getMyHoursTimeSheet({ completedWork: billableRate, estimate, warranty: warrantyWork })}
        </span>
      </td>
    </tr>
  )
}

export default TimesheetTableItemCondensed
