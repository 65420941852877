import { useModal } from 'Hooks'
import { useState } from 'react'

const ModalConfirmOrCancel = ({
  setVisible,
  onConfirm,
  title,
  header,
  style = 'primary',
  btnConfirm = 'Confirm',
  btnCancel = 'Cancel',
  visible = true,
  icon,
  includeNote,
  identifier = 'modalConfirmOrCancel',
  center
}) => {
  const { Modal, hide } = useModal({ identifier, visible, setVisible })
  const [note, setNote] = useState('')

  return (
    <Modal center={center}>
      <div className="modal-content modalSuccess p-5" style={{ maxWidth: 400 }}>
        {icon && <div className="row justify-content-center">{icon}</div>}
        <div className="row justify-content-center pt-4">
          {header && <h4 className='text-center'>{header}</h4>}
          <p className="text-center m-0">{title}</p>
        </div>
        {includeNote && (
          <div className="p-4">
            <label className="form-label">Notes</label>
            <textarea
              className="form-control"
              placeholder="Notes"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
        )}
        <div className={`row justify-content-center ${!includeNote ? 'pt-4' : ''}`}>
          <div className="col-auto">
            <button type="button" id='btnModalCancel' className="btn btn-secondary mr-2" onClick={() => hide()}>
              {btnCancel}
            </button>
          </div>
          <div className="col-auto">
            <button
              id='btnModalConfirm'
              type="submit"
              onClick={() => {
                onConfirm({ note })
                hide()
              }}
              className={`btn btn-${style}`}>
              {btnConfirm}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalConfirmOrCancel
