/* import DateRangePicker from '@wojtekmaj/react-daterange-picker'
 */
const Forms = () => {
  return (
    <section className="col-12">
      <h2 className="mt-5">Forms</h2>
      <hr />
      <div className="row mt-4 mb-5">
        <div className="col-12 col-md-2">
          <h4>Inputs</h4>
        </div>
        <div className="col-12 col-md-3">
          <label className="form-label">Text input</label>
          <input type="text" className="form-control" placeholder="placeholder" />
        </div>
        <div className="col-12 col-md-3">
          <label className="form-label">Password input</label>
          <input type="password" className="form-control" placeholder="Secret password" />
        </div>
        <div className="col-12 col-md-3">
          <label className="form-label">Disabled input</label>
          <input
            className="form-control"
            type="text"
            placeholder="Disabled input"
            aria-label="Disabled"
            disabled
          ></input>
        </div>
      </div>
      <div className="row mt-4 mb-5">
        <div className="col-12 col-md-2">
          <h4>Date picker</h4>
        </div>
        <div className="col-12 col-md-3">
          <label className="form-label">Date Range Picker</label>
          {/* <DateRangePicker onChange={onChange} value={value} /> */}
        </div>
      </div>
    </section>
  )
}

export default Forms
