import SelectWithHint from 'Organisms/SelectWithHint'

const StepCompanySize = ({ data, setData }) => {
  const OPTIONS_COMPANY_SIZE_STEP = [
    {
      value: 'Only Me',
      label: 'Only Me'
    },
    {
      value: '2 - 10',
      label: '2 - 10'
    },
    {
      value: '11 - 50',
      label: '11 - 50'
    },
    {
      value: '51 - 100',
      label: '51 - 100'
    },
    {
      value: '101 - 200',
      label: '101 - 200'
    },
    {
      value: '201 - 500',
      label: '201 - 500'
    },
    {
      value: '501 - 1000',
      label: '501 - 1000'
    },
    {
      value: '1001 - 5000',
      label: '1001 - 5000'
    },
    {
      value: '5001 - 10000',
      label: '5001 - 10000'
    },
    {
      value: '10000+',
      label: '10000+'
    }
  ]

  return (
    <div>
      <h4>Company size</h4>
      <p>What size is your organization?</p>

      <SelectWithHint
        value={data?.company_size ? OPTIONS_COMPANY_SIZE_STEP.find((item) => item.value === data.company_size) : null}
        id="selectCompanySize"
        placeholder="Select..."
        formatSelect={OPTIONS_COMPANY_SIZE_STEP}
        handleChange={({ value }) => setData({ ...data, company_size: value })}
      />
    </div>
  )
}

export default StepCompanySize
