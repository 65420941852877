import { useState } from 'react'

import { createClientService, getClients } from 'Services'

export default function useClient () {
  const [loadingClientHook, setLoadingClientHook] = useState(false)
  const [listClients, setListClients] = useState([])

  const getClientsHook = async () => {
    try {
      setLoadingClientHook(true)
      const data = await getClients()
      setListClients(data?.data || [])
      return data?.data || []
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingClientHook(false)
    }
  }

  const createClientHook = async ({ nameClient }) => {
    try {
      setLoadingClientHook(true)
      const create = await createClientService({ nameClient })
      return {
        success: true,
        data: create.data
      }
    } catch (e) {
      console.error(e)
      return {
        success: false,
        message: e.message
      }
    } finally {
      setLoadingClientHook(false)
    }
  }

  return {
    loadingClientHook,
    listClients,
    getClientsHook,
    createClientHook
  }
}
