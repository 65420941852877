import { useModal, usePayment } from 'Hooks'
import { Plus } from '@phosphor-icons/react'
import { PaymentCard } from './Components'
import { useState } from 'react'
import { Loading } from 'Organisms/Loading'
import { ModalSuccess } from 'Organisms/ModalSuccess'

const ChangePaymentMethodModal = ({ onClose, data, defaultCard, onOpenNewCreditCard, onRefresh }) => {
  const { Modal, hide } = useModal({ identifier: 'manageSeatsModal', visible: true, setVisible: onClose })
  const [selectDefaultCard, setSelectDefaultCard] = useState(defaultCard)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({ open: false })

  const { changeDefaultPaymentMethodHook } = usePayment()

  const handleSubmit = async () => {
    try {
      if (defaultCard === selectDefaultCard) return

      setLoading(true)

      const res = await changeDefaultPaymentMethodHook({ payment_method_id: selectDefaultCard })

      if (!res.success) throw new Error()

      setMessage({
        open: true,
        message: 'Your default card was changed successfully.'
      })
    } catch {
      setMessage({
        open: true,
        error: true,
        message: 'An error occurred when changing your default card.'
      })
    } finally {
      setLoading(false)
    }
  }

  const handleCloseModalMessage = async () => {
    if (!message.error) await onRefresh()

    setMessage({ open: false })
  }

  return (
    <>
      <Modal>
        {loading && <Loading />}

        <div className="modal-content modalSuccess p-5" style={{ maxWidth: 600 }}>
          <button type="button" className="btn-close btnCloseModal" onClick={hide} aria-label="Close" />

          <h3 className="fs-3 mb-4">Choose Payment Method</h3>
          <p className="mb-5">Choose the card you want to use for this payment.</p>

          {data.map((card) => (
            <div key={card.id} className="card card-secondary py-4 px-5 mb-3">
              <div className="row justify-content-between align-items-center">
                <div className="col-auto ps-0">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      onChange={() => setSelectDefaultCard(card.id)}
                      checked={selectDefaultCard === card.id}
                    />
                  </div>
                </div>

                <PaymentCard
                  brand={card.card.brand}
                  last4={card.card.last4}
                  exp_month={card.card.exp_month}
                  exp_year={card.card.exp_year}
                />
              </div>
            </div>
          ))}

          <div className="row mt-4">
            <div className="col-auto">
              <button
                type="button"
                className="btn btn-ghost text-start"
                onClick={() => {
                  hide()
                  onOpenNewCreditCard()
                }}>
                <Plus size={16} weight="bold" /> Add payment method
              </button>
            </div>
          </div>
          <div className="row justify-content-end mt-5">
            <div className="col-auto">
              <button
                type="button"
                className="btn btn-secondary mr-2"
                onClick={() => {
                  hide()
                }}>
                Cancel
              </button>
            </div>
            <div className="col-auto">
              <button type="submit" onClick={handleSubmit} className="btn btn-primary">
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {message.open && (
        <ModalSuccess
          visible={true}
          setVisible={handleCloseModalMessage}
          title={message.message}
          error={message.error}
        />
      )}
    </>
  )
}

export default ChangePaymentMethodModal
