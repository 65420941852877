import { Check, WarningCircle, X, XCircle } from '@phosphor-icons/react'
import ModalConfirmOrCancel from 'Organisms/ModalConfirmOrCancel'
import { COLOR_BLACK, COLOR_RED, COLOR_YELLOW } from 'constant/colors'
import { useState } from 'react'

const ApproveOrCancel = ({ onApproved, onReject, activeReOpen }) => {
  const [confirmApproved, setConfirmApproved] = useState(false)
  const [confirmRejected, setConfirmRejected] = useState(false)

  return (
    <>
      <div className="d-grid d-sm-flex justify-content-sm-end">
        {!activeReOpen && (
          <button
            type="button"
            className="btn btn-secondary me-sm-2"
            id="btnCancel"
            onClick={() => setConfirmRejected(true)}>
            <X className="icon" size={15} color={COLOR_BLACK} weight="bold" />
            <span>Reject</span>
          </button>
        )}
        <button
          type="submit"
          className="btn btn-primary mt-sm-0 mt-3"
          id="btnConfirm"
          onClick={() => setConfirmApproved(true)}>
          <Check className="icon" size={15} color={COLOR_BLACK} weight="bold" />
          <span>{activeReOpen ? 'Reopen' : 'Approve'}</span>
        </button>
      </div>

      {confirmApproved && (
        <ModalConfirmOrCancel
          title={`Do you want to ${activeReOpen ? 'reopen' : 'approve'} this employee's hours ?`}
          setVisible={setConfirmApproved}
          btnConfirm={`${activeReOpen ? 'Reopen' : 'Approve'}`}
          onConfirm={onApproved}
          icon={<WarningCircle size={56} color={COLOR_YELLOW} />}
        />
      )}

      {confirmRejected && (
        <ModalConfirmOrCancel
          title="Are you sure you want to reject this user's hours?"
          setVisible={setConfirmRejected}
          btnConfirm="Reject"
          onConfirm={onReject}
          includeNote
          icon={<XCircle size={56} color={COLOR_RED} weight="bold" />}
          style="danger"
        />
      )}
    </>
  )
}

export default ApproveOrCancel
