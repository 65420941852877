import { PERIOD_TIME_SHEET } from 'Pages/Timesheet/utils/const'
import { countDecimalsAndAdd0 } from 'Utils/helperFunctions'

const PanelHoursCapacity = ({ currentPeriod, hours, capacity, startDay, endDay }) => {
  const getMyCapacityPerPeriod = ({ myCapacity, startDate, endDate }) => {
    const diferentWeek = Math.round(endDate.diff(startDate, 'days').toObject().days)
    return countDecimalsAndAdd0({ number: (myCapacity / 7) * diferentWeek })
  }

  const handleGetPorcentCapacity = ({ startDate, endDate, myHours, myCapacity }) => {
    if (myCapacity === 0) return myHours > 0 ? 100 : 0

    const diferentWeek = Math.round(endDate.diff(startDate, 'days').toObject().days)

    const result = countDecimalsAndAdd0({ number: (myHours * 100) / ((myCapacity / 7) * diferentWeek) })
    return isNaN(result) ? 0 : result
  }

  return (
    <div className="card panelHoursCapacity p-3 mt-5">
      <div className="row panelHoursCapacity_items">
        <div className="col-sm-4 col-12 text-center">
          <label className="th text-terciary">Hours</label>
          <h3>{countDecimalsAndAdd0({ number: hours })}h</h3>
        </div>
        <div className="col-sm-4 col-12 text-center">
          <label className="th text-terciary">Capacity</label>
          {currentPeriod !== PERIOD_TIME_SHEET.ALL_TIME
            ? (
            <h3>
              {getMyCapacityPerPeriod({
                myCapacity: capacity,
                startDate: startDay,
                endDate: endDay
              })}
              h
            </h3>
              )
            : (
            <h3>{countDecimalsAndAdd0({ number: capacity })}h</h3>
              )}
        </div>
        <div className="col-sm-4 col-12 text-center">
          <label className="th text-terciary">Hours / Capacity</label>
          {currentPeriod !== PERIOD_TIME_SHEET.ALL_TIME
            ? (
            <h3>
              {handleGetPorcentCapacity({
                startDate: startDay,
                endDate: endDay,
                myHours: hours,
                myCapacity: capacity
              })}
              %
            </h3>
              )
            : (
            <h3>{countDecimalsAndAdd0({ number: (hours * capacity) / 100 })}%</h3>
              )}
        </div>
      </div>
    </div>
  )
}

export default PanelHoursCapacity
