import { useState } from 'react'

const Popover = ({ children, buttonShow, buttonStyle, customButton, hover, direction = 'right', showChildren = true }) => {
  const [open, setOpen] = useState(hover)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className={`popoverCustom ${hover ? 'popoverCustom_hover' : ''}`}>
      {customButton || (
        <button type="button" className={`btn ${buttonStyle}`} onClick={() => setOpen(true)}>
          {buttonShow}
        </button>
      )}

      {open && showChildren && (
        <>
          <div className={`popoverCustom_body popoverCustom_body_${direction}`}>{children}</div>

          {!hover && <button className="popoverCustom_close" onClick={handleClose} />}
        </>
      )}
    </div>
  )
}

export default Popover
