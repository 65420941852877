import { Info } from '@phosphor-icons/react'
import { COLOR_WHITE } from 'constant/colors'
import { ResponsiveContainer, Tooltip, Treemap } from 'recharts'
import CustomBarTreeDashboard from './CustomBarTreeDashboard'
import CustomTooltipTreeChart from 'Organisms/TreemapChart/CustomTooltipTreeChart'

const GraphicsPerTaskDashboard = ({ groupHours, totalHours, title = '' }) => {
  return (
    <div className="card dashboard_card_small">
      <h5 className="mb-4">
        {title} <Info size={18} />
      </h5>
      <div style={{ minHeight: 400 }}>
        {groupHours.length > 0 && (
          <ResponsiveContainer width="100%" height="100%">
            <Treemap
              data={groupHours}
              dataKey="hours"
              stroke={COLOR_WHITE}
              animationDuration={0}
              content={<CustomBarTreeDashboard totalHours={totalHours} />}>
              <Tooltip content={<CustomTooltipTreeChart totalHours={totalHours} />} />
            </Treemap>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  )
}

export default GraphicsPerTaskDashboard
