import { PRICING_ROUTER, FAQ_ROUTER } from './router'

export const PUBLIC_SIDEBAR_LIST = [
  {
    name: 'Pricing',
    to: PRICING_ROUTER
  },
  {
    name: 'FAQ',
    to: FAQ_ROUTER
  }
]
