import axios from 'axios'

const BASE_URL_ENTRIES = '/time-entries'
const BASE_URL_SHEETS = '/timesheets'
const BASE_URL_SHEET = '/timesheet'

// TIME ENTRIES
export const createTimeEntry = async (body) => axios.post(BASE_URL_ENTRIES, { ...body }).then(({ data }) => data)

export const getTimeEntriesService = async (queryParams) =>
  axios.get(`${BASE_URL_ENTRIES}?${queryParams}`).then(({ data }) => data)

export const submitHoursTimeEntriesService = async ({ user_id, start_date, end_date }) =>
  axios.post(`${BASE_URL_ENTRIES}/submit`, { user_id, start_date, end_date }).then(({ data }) => data)

export const reSubmitHoursTimeEntriesService = async ({ user_id, start_date, end_date }) =>
  axios.post(`${BASE_URL_ENTRIES}/re-submit`, { user_id, start_date, end_date }).then(({ data }) => data)

export const updateTimeEntriesService = async ({ id, body }) =>
  axios.patch(`${BASE_URL_ENTRIES}/${id}`, body).then(({ data }) => data)

export const activeTimerTimeEntriesService = async ({ id }) =>
  axios.patch(`${BASE_URL_ENTRIES}/active-timer/${id}`).then(({ data }) => data)

export const desActiveTimerTimeEntriesService = async ({ id }) =>
  axios.patch(`${BASE_URL_ENTRIES}/desActive-timer/${id}`).then(({ data }) => data)

export const deleteTimeEntriesService = async ({ id }) =>
  axios.delete(`${BASE_URL_ENTRIES}/${id}`).then(({ data }) => data)

export const getTimeEntryColumns = async ({ userId }) => axios.get(`${BASE_URL_ENTRIES}/columns/${userId}`)

export const updateTimeEntryColumns = async ({ columns, userId }) =>
  axios.post(`${BASE_URL_ENTRIES}/columns/${userId}`, {
    columns
  })

export const downloadTimeEntriesService = async ({ query }) =>
  axios.get(`${BASE_URL_ENTRIES}/download?${query}`).then(({ data }) => data)

// TIME SHEETS
export const getTimeSheetsService = async (queryParams) =>
  axios.get(`${BASE_URL_SHEETS}?${queryParams}`).then(({ data }) => data)

export const getAllSprintService = async ({ query }) =>
  axios.get(`${BASE_URL_SHEETS}/sprint?${query}`).then(({ data }) => data)

export const getAllStatusService = async ({ query }) =>
  axios.get(`${BASE_URL_SHEETS}/status?${query}`).then(({ data }) => data)

// TIME SHEET
export const getTimeSheetService = async (queryParams) =>
  axios.get(`${BASE_URL_SHEET}?${queryParams}`).then(({ data }) => data)

export const getTimeSheetTaskByProjectService = async ({ idProject }, queryParams) =>
  axios.get(`${BASE_URL_SHEETS}/project/${idProject}?${queryParams}`).then(({ data }) => data)

// TIME SHEET PROJECT
export const downloadCsvByProjectTimesheetService = async ({ projectId, query }) =>
  axios.get(`${BASE_URL_SHEETS}/download/project/${projectId}?${query}`).then(({ data }) => data)

export const downloadTimeSheetsService = async ({ query }) =>
  axios.get(`${BASE_URL_SHEETS}/download?${query}`).then(({ data }) => data)

// COLUMNS
export const getColumnsTableTimeSheetService = async ({ query }) => axios.get(`${BASE_URL_SHEETS}/timesheet-view-config?${query}`).then(({ data }) => data)
export const updateColumnsTableTimeSheetService = async ({ body }) => axios.put(`${BASE_URL_SHEETS}/timesheet-view-config`, body).then(({ data }) => data)
export const createColumnsTableTimeSheetService = async ({ body }) => axios.post(`${BASE_URL_SHEETS}/timesheet-view-config`, body).then(({ data }) => data)

// DETAILS
export const getTimeEntryDetailByIdService = async ({ entryId }) => axios.get(`${BASE_URL_ENTRIES}/${entryId}/history`).then(({ data }) => data)
