import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import useModal from '../../Hooks/useModal'
import { Trash } from '@phosphor-icons/react'
import { deleteProject } from 'Services'
import { COLOR_RED } from 'constant/colors'

const DeleteProjectModal = ({ visible, setVisible, selectedProject, reloadProjects }) => {
  const { Modal, hide } = useModal({ identifier: 'deleteproject', visible, setVisible })

  const { handleSubmit, reset } = useForm()

  useEffect(() => {
    reset(selectedProject)
  }, [selectedProject, reset])

  const onSubmit = async (data) => {
    try {
      await deleteProject(selectedProject.id)
      await reloadProjects()
      hide()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Modal>
      <div className="modal-content" style={{ maxWidth: 500 }}>
        <form onSubmit={handleSubmit(onSubmit)} className="modal-body p-5">
          <div className="row justify-content-center">
            <Trash size={56} color={COLOR_RED} weight="bold" />
          </div>
          <div className="row justify-content-center py-4">Are you sure you want to delete this project ?</div>
          <div className="row justify-content-center">
            <div className="col-auto">
              <button type="button" className="btn btn-secondary mr-2" onClick={hide}>
                Cancel
              </button>
            </div>
            <div className="col-auto">
              <button type="submit" onSubmit={handleSubmit(onSubmit)} className="btn btn-danger">
                Delete
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default DeleteProjectModal
