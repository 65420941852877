import { useEffect } from 'react'

const TextTruncate = ({ text, maxCharacter, classText = '', classTooltip = '', children }) => {
  useEffect(() => {
    const listTooltip = document.querySelectorAll('[data-bs-toggle="tooltip"]')

    if (listTooltip.length === 0) return

    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new window.bootstrap.Tooltip(tooltipTriggerEl)
    })

    return () => {
      tooltipList.forEach((tooltip) => tooltip.dispose())
    }
  }, [])

  return (
    <p
      className={`m-0 text-truncate ${classText}`}
      data-bs-toggle={text.length > maxCharacter ? 'tooltip' : ''}
      data-bs-custom-class={`custom-tooltip ${classTooltip}`}
      data-bs-placement="auto"
      data-bs-title={text}>
      {children}
    </p>
  )
}

export default TextTruncate
