import React from 'react'

const DropdownMenuActions = ({ icon, options = [], type = 'primary', idBtn = 'btnGeneric', element = '' }) => {
  const categoryCount = options.length - 1

  return (
    <div className="dropdown" data-bs-toggle="dropdown" aria-expanded="false">
      <button type="button" className={`btn btn-${type}`} data-cypress-menu={idBtn}>
        {icon}
      </button>
      {categoryCount >= 0 && (
        <ul className="dropdown-menu">
          {options.map((category, index) => {
            if (!category) return null

            return (
              <React.Fragment key={category.id}>
                {category.actions.map((action) => {
                  if (!action) return null

                  return (
                    <li key={action.id}>
                      <button className={`dropdown-item ${action.styles || ''}`} onClick={() => action.onClick({ element })} id={action?.elementId || ''}>
                        {action.name}
                      </button>
                    </li>
                  )
                })}

                {index < categoryCount && (
                  <li>
                    <hr className="dropdownDivider" />
                  </li>
                )}
              </React.Fragment>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default DropdownMenuActions
