import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { getStartWeek } from 'Utils/helperFunctions'
import SearchInput from 'Organisms/SearchInput'
import SearchableListButton from 'Organisms/SearchableListButton'
import { DateDisplay } from 'Organisms'
import { ActivityLogItem } from './Components'
import { CustomDatePicker } from 'Organisms/CustomDatePicker'

const Activity = () => {
  const [startDay, setStartDay] = useState(DateTime.now())
  // eslint-disable-next-line
  const [endDay, setEndDay] = useState(DateTime.now())
  // eslint-disable-next-line
  const [period, setPeriod] = useState('Week')

  // const next = () => {
  //   if (period === 'Week') {
  //     setStartDay(startDay.plus({ weeks: 1 }))
  //     setEndDay(endDay.plus({ weeks: 1 }))
  //   }
  //   if (period === 'Semimonth') {
  //     if (isFirstHalfOfMonth(startDay)) {
  //       setStartDay(startDay.startOf('month').plus({ days: 14 }))
  //       setEndDay(startDay.endOf('month'))
  //     } else {
  //       setStartDay(startDay.plus({ month: 1 }).startOf('month').plus({ days: 15 }))
  //       setEndDay(startDay.plus({ month: 1 }).endOf('month'))
  //     }
  //   }
  //   if (period === 'Month') {
  //     setStartDay(startDay.plus({ months: 1 }))
  //     setEndDay(endDay.plus({ months: 1 }))
  //   }
  //   if (period === 'Quarter') {
  //     setStartDay(startDay.plus({ quarters: 1 }))
  //     setEndDay(endDay.plus({ quarters: 1 }))
  //   }
  //   if (period === 'Year') {
  //     setStartDay(startDay.plus({ years: 1 }))
  //     setEndDay(endDay.plus({ years: 1 }))
  //   }
  // }

  // const previous = () => {
  //   if (period === 'Week') {
  //     setStartDay(startDay.plus({ weeks: -1 }))
  //     setEndDay(endDay.plus({ weeks: -1 }))
  //   }
  //   if (period === 'Semimonth') {
  //     if (isFirstHalfOfMonth(startDay)) {
  //       setStartDay(startDay.plus({ month: -1 }).startOf('month').plus({ days: 14 }))
  //       setEndDay(startDay.plus({ month: -1 }).endOf('month'))
  //     } else {
  //       setStartDay(startDay.startOf('month'))
  //       setEndDay(startDay.startOf('month').plus({ days: 14 }))
  //     }
  //   }
  //   if (period === 'Month') {
  //     setStartDay(startDay.plus({ months: -1 }))
  //     setEndDay(endDay.plus({ months: -1 }))
  //   }
  //   if (period === 'Quarter') {
  //     setStartDay(startDay.plus({ quarters: -1 }))
  //     setEndDay(endDay.plus({ quarters: -1 }))
  //   }
  //   if (period === 'Year') {
  //     setStartDay(startDay.plus({ years: -1 }))
  //     setEndDay(endDay.plus({ years: -1 }))
  //   }
  // }

  const isFirstHalfOfMonth = (date) => {
    const startOfMonth = date.startOf('month')
    const startWeekNumber = startOfMonth.weekNumber
    const dateWeekNumber = date.weekNumber

    return dateWeekNumber >= startWeekNumber && dateWeekNumber < startWeekNumber + 2
  }

  // const returnToToday = () => {
  //   if (period !== 'Custom' && period !== 'Semimonth' && period !== 'All time') {
  //     setStartDay(DateTime.now().startOf(period))
  //     setEndDay(DateTime.now().endOf(period))
  //   }
  //   if (period === 'Semimonth') {
  //     if (isFirstHalfOfMonth(DateTime.now())) {
  //       setStartDay(DateTime.now().startOf('month'))
  //       setEndDay(DateTime.now().startOf('month').plus({ days: 14 }))
  //     } else {
  //       setStartDay(DateTime.now().startOf('month').plus({ days: 15 }))
  //       setEndDay(DateTime.now().endOf('month'))
  //     }
  //   }
  // }

  useEffect(() => {
    setStartDay(getStartWeek())
    setEndDay(startDay.plus({ weeks: 1 }))
  }, [])

  useEffect(() => {
    const currentDay = startDay
    if (period !== 'Custom' && period !== 'Semimonth' && period !== 'All time') {
      setStartDay(currentDay.startOf(period))
      setEndDay(currentDay.endOf(period))
    }
    if (period === 'Semimonth') {
      if (isFirstHalfOfMonth(startDay)) {
        setStartDay(startDay.startOf('month'))
        setEndDay(startDay.startOf('month').plus({ days: 14 }))
      } else {
        setStartDay(startDay.startOf('month').plus({ days: 15 }))
        setEndDay(startDay.endOf('month'))
      }
    }
  }, [period])

  // const [users, setUsers] = useState([
  //   { id: 'item1', name: 'Miguel Perez', position: 'Fullstack Developer', capacity: 4, checked: false, index: 0 },
  //   {
  //     id: 'item2',
  //     name: 'Juan Sebastian Castellanos',
  //     position: 'Fullstack Developer',
  //     capacity: 8,
  //     checked: false,
  //     index: 1
  //   },
  //   { id: 'item3', name: 'Sebastian Florez', position: 'Fullstack Developer', capacity: 3, checked: false, index: 2 },
  //   { id: 'item4', name: 'Sergio Rodriguez', position: 'Fullstack Developer', capacity: 9, checked: false, index: 3 },
  //   { id: 'item5', name: 'Jorge Macias', position: 'Fullstack Developer', capacity: 13, checked: false, index: 4 },
  //   { id: 'item6', name: 'Giovanna Sanchez', position: 'Fullstack Developer', capacity: 18, checked: false, index: 5 },
  //   { id: 'item7', name: 'Diego Velasquez', position: 'Fullstack Developer', capacity: 22, checked: false, index: 6 }
  // ])

  const [users2, setUsers2] = useState([
    { id: 'item1', text: 'Miguel Perez', checked: false, index: 0 },
    { id: 'item2', text: 'Juan Sebastian Castellanos', checked: false, index: 1 },
    { id: 'item3', text: 'Sebastian Florez', checked: false, index: 2 },
    { id: 'item4', text: 'Sergio Rodriguez', checked: false, index: 3 },
    { id: 'item5', text: 'Jorge Macias', checked: false, index: 4 },
    { id: 'item6', text: 'Giovanna Sanchez', checked: false, index: 5 },
    { id: 'item7', text: 'Diego Velasquez', checked: false, index: 6 }
  ])

  // const [projects, setProjects] = useState([
  //   { id: 'item1', text: 'Medzoomer', checked: false, index: 0 },
  //   { id: 'item2', text: 'MSA', checked: false, index: 1 },
  //   { id: 'item3', text: 'Springboard', checked: false, index: 2 }
  // ])

  return (
    <div className="row justify-content-center">
      <div className="col-lg-11 col-12 d-grid">
        <CustomDatePicker />
        <hr />
        <div className="row mb-5">
          <div className="col p-4">
            <div className='row card p-4'>
              <div className="row mb-4">
                <div className="col">
                  <SearchInput
                    className="form-control"
                    placeholder="Search"
                  />
                </div>
                <div className="col-auto">
                  <SearchableListButton name="Users" switches={users2 || []} setSwitches={setUsers2} />
                </div>
              </div>
              <hr />
              <div className="row">
                <h5 className="my-4">
                  <DateDisplay date={DateTime.now()} />
                </h5>

                <ActivityLogItem item={{ user: 'Juan Sebastian', type: 'note without ticket number' }} />
                <ActivityLogItem item={{ user: 'Miguel Perez', type: 'Overtime ticket vs Estimate' }} />
                <ActivityLogItem item={{ user: 'Luis Miranda', type: 'No activity' }} />

                <h5 className="my-4">
                  <DateDisplay date={DateTime.now().plus({ days: -1 })} />
                </h5>

                <ActivityLogItem item={{ user: 'Juan Sebastian', type: 'note without ticket number' }} />
                <ActivityLogItem
                  item={{
                    user: 'Miguel Perez',
                    type: 'Overtime ticket vs Estimate',
                    ticket: { type: 'jira', description: 'User Story 1234: As a User I want to be able to log in' }
                  }}
                />

                <h5 className="my-4">
                  <DateDisplay date={DateTime.now().plus({ days: -20 })} />
                </h5>

                <ActivityLogItem item={{ user: 'Juan Sebastian', type: 'note without ticket number' }} />
                <ActivityLogItem
                  item={{
                    user: 'Miguel Perez',
                    type: 'Overtime ticket vs Estimate',
                    ticket: {
                      type: 'azure',
                      description: 'User Story 38891: MSA Report - Pull Correct Files to be Uploaded for CMS'
                    }
                  }}
                />

                <h5 className="my-4">
                  <DateDisplay date={DateTime.now().plus({ days: -50 })} />
                </h5>

                <ActivityLogItem item={{ user: 'Juan Sebastian', type: 'note without ticket number' }} />
                <ActivityLogItem item={{ user: 'Miguel Perez', type: 'Overtime ticket vs Estimate' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Activity
