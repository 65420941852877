import { PERIOD_TIME_SHEET } from 'Pages/Timesheet/utils/const'
import { countDecimalsAndAdd0 } from 'Utils/helperFunctions'
import { COLOR_BLACK, COLOR_WHITE } from 'constant/colors'

const CustomBarDashboardHoursDaily = ({ x, y, width, height, billable, warranty, colorGrafich, isWarranty, period, isMobile, theme }) => {
  const theHoursAreLessThen30Percent = height < 35

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={colorGrafich}
        rx={4}
        ry={4}
      />

      {!theHoursAreLessThen30Percent && period === PERIOD_TIME_SHEET.WEEK && !isMobile && (
        <>
          <text
            x={x + 10}
            y={y + 25}
            fontSize="14"
            fill={isWarranty && theme ? COLOR_BLACK : COLOR_WHITE}
            textAnchor="start">{`${countDecimalsAndAdd0({
            number: isWarranty ? warranty : billable
          })}h`}</text>
          {/* <text
            x={x + 10}
            y={y + 40}
            fontSize="10"
            fill={isWarranty ? COLOR_BLACK : COLOR_WHITE}
            textAnchor="start">{`(${countDecimalsAndAdd0({
            number: ((isWarranty ? warranty : hours) * 100) / totalHours
          })}%)`}</text> */}
        </>
      )}
    </g>
  )
}

export default CustomBarDashboardHoursDaily
