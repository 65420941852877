const StepUpgrade = () => {
  return (
    <div>
      <h4>You are ready to go!</h4>
      <p className="mb-0">You&apos;re on a 30-day free trial, no credit card required.</p>
      <p>You can upgrade after the trail ends (or anytime before)</p>
    </div>
  )
}

export default StepUpgrade
