import { useCallback, useEffect, useState } from 'react'
import { Plus } from '@phosphor-icons/react'

// Hooks
import { useModal, useDebounce, useTasks, useProject } from 'Hooks/'

// Components
import { ButtonsModal } from 'Organisms'

// Const
import SelectWithHint from 'Organisms/SelectWithHint'
import LoadingButton from 'Organisms/LoadingButton'
import TableTasks from './TableTasks'
import { formatAddTaskToProjectAdapter } from 'adapters/project'
import { Loading } from 'Organisms/Loading'

const ModalAddTaskToProject = ({ visible, setVisible, oldTask, idProject, refreshProjectInfo }) => {
  const { Modal, hide } = useModal({ identifier: 'addtasktoproject', visible, setVisible })
  const { listTasksHook, loadingTaskHook, getAllTasksHook, createTaskHook } = useTasks()
  const { loadingProjectHook, addTaskToProjectHook } = useProject()

  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [listSelectedTasks, setListSelectedTask] = useState([])
  const [errors, setErrors] = useState({})
  const [newTask, setNewTask] = useState('')
  const [showDropdown, setShowDropdown] = useState(true)

  useEffect(() => {
    ;(async () => {
      const res = await getAllTasksHook()
      setShowDropdown(res.length > 0)
    })()
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const listTaskCreate = formatAddTaskToProjectAdapter({ listTask: listSelectedTasks })
      await addTaskToProjectHook({ idProject, listIdTask: listTaskCreate })
      await refreshProjectInfo()
    } finally {
      setLoading(false)
      hide()
    }
  }

  const filterDebounce = useCallback(
    useDebounce(({ search }) => {
      getAllTasksHook({ search })
    }, 1000),
    []
  )

  const formatSelect = ({ list }) =>
    list.map((item) => {
      return {
        value: item.id,
        label: item?.name
      }
    })

  const hanldeChangeSearch = (searchParam) => {
    setSearch(searchParam)
    filterDebounce({ search: searchParam })
  }

  const handleChangeSelectTask = (value) => {
    const task = listTasksHook.find((item) => item.id === value.value)

    if ([...listSelectedTasks, ...oldTask.map((task) => task.task)].some((taskS) => taskS.id === task.id)) return

    setListSelectedTask((prevState) => [...prevState, task])
  }

  const handleCreateTask = async () => {
    try {
      if (!newTask) return setErrors({ task: true })
      setLoading(true)
      const task = await createTaskHook({ taskName: newTask })
      setListSelectedTask([...listSelectedTasks, task])
      setNewTask('')
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const handleChangeBillableRate = ({ idTask, newBillableRate }) =>
    setListSelectedTask(
      listSelectedTasks.map((task) => {
        if (task.id === idTask) {
          return {
            ...task,
            billable_rate: newBillableRate
          }
        }

        return task
      })
    )

  const handleDeleteTask = ({ idTask }) => setListSelectedTask(listSelectedTasks.filter((task) => task.id !== idTask))

  return (
    <Modal>
      <>
        {loadingProjectHook && <Loading />}

        <div className="modal-content" style={{ maxWidth: 800 }}>
          <div className="modal-header">
            <h3 className="modal-title fs-5 text-capitalize" id="exampleModalLabel">
              Add Task
            </h3>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col">
                <div className="row justify-content-center">
                  <form id="addTaskToProject" className="col-10 d-grid" onSubmit={onSubmit}>
                    {showDropdown
                      ? (
                      <div className="row align-items-center mb-3 gy-md-0 gy-4">
                        <div className="col">
                          <SelectWithHint
                            loading={loadingTaskHook}
                            value={search}
                            id="selectTask"
                            placeholder="Task"
                            formatSelect={formatSelect({ list: listTasksHook })}
                            handleInputChange={hanldeChangeSearch}
                            handleChange={handleChangeSelectTask}
                            noOptionsMessage="Task not found"
                            loadingMessage="Search"
                          />
                        </div>
                        <div className="col-md-4 col-12">
                          <LoadingButton
                            type="button"
                            onClick={() => setShowDropdown(false)}
                            className="btn btn-secondary btn-sm w-100"
                            loading={loading}>
                            <Plus /> Create new tasks
                          </LoadingButton>
                        </div>
                      </div>
                        )
                      : (
                      <div className="row align-items-center gy-md-0 gy-3 mb-3 gy-md-0">
                        <div className="col-md-9 col-12">
                          <input
                            type="text"
                            className={`form-control ${errors?.task ? 'is-invalid' : ''}`}
                            id="inputTask"
                            placeholder="Write New Task"
                            onChange={(e) => {
                              if (errors?.task) setErrors({ ...errors, task: false })
                              setNewTask(e.target.value)
                            }}
                            value={newTask}
                          />
                          <div className="invalid-feedback">Please write new task.</div>
                        </div>

                        <div className="col-md-3 col-12 d-flex justify-content-center">
                          <div className="row">
                            <div className="col-6">
                              <LoadingButton
                                type="button"
                                onClick={handleCreateTask}
                                className="btn btn-primary btn-sm"
                                id="createNewTask"
                                loading={loading}>
                                Create
                              </LoadingButton>
                            </div>
                            <div className="col-6">
                              <LoadingButton
                                type="button"
                                onClick={() => setShowDropdown(true)}
                                className="btn btn-outline-primary btn-sm"
                                loading={loading}>
                                Dismiss
                              </LoadingButton>
                            </div>
                          </div>
                        </div>
                      </div>
                        )}

                    <TableTasks
                      tasks={listSelectedTasks}
                      onChangeBillableRate={handleChangeBillableRate}
                      onDelete={handleDeleteTask}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <ButtonsModal onClose={hide} loading={loading} formControl="addTaskToProject" id="modalTask" />
          </div>
        </div>
      </>
    </Modal>
  )
}

export default ModalAddTaskToProject
