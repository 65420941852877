import { useCallback, useEffect, useState } from 'react'

// Hooks
import { useModal, useDebounce, useUser } from 'Hooks/'
import TableUserTeam from './TableUserTeam'
import SelectWithHint from 'Organisms/SelectWithHint'
import { ROLES_DISPLAY, ROLE_DEVELOPER_NAME, ROLE_PRODUCT_OWNER_NAME } from 'constant/roles'
import { WarningOctagon } from '@phosphor-icons/react'
import { COLOR_RED } from 'constant/colors'
import { Loading } from 'Organisms/Loading'

const GET_USERS_ROLES = [ROLE_PRODUCT_OWNER_NAME, ROLE_DEVELOPER_NAME]

const AddTeamMemberModal = ({ visible, setVisible, oldUser, onSubmit, error }) => {
  // State
  const [search, setSearch] = useState('')
  const [selectedUser, setSelectedUser] = useState([])
  const [loadingInternal, setLoadingInternal] = useState(true)

  // Hooks
  const { Modal, hide } = useModal({ identifier: 'addteammember', visible, setVisible })
  const { listUsers, loadingUserHook, getUsersHook } = useUser()

  // handler
  const filterDebounce = useCallback(
    useDebounce(({ search }) => {
      getUsersHook({ search, roles: GET_USERS_ROLES })
    }, 1000),
    []
  )

  useEffect(() => {
    ;(async () => {
      await getUsersHook({ search, roles: GET_USERS_ROLES })
      setLoadingInternal(false)
    })()
  }, [])

  const hanldeChangeSearch = (searchParam) => {
    setSearch(searchParam)
    filterDebounce({ search: searchParam })
  }

  const handleChangeSelectUser = (value) => {
    const user = listUsers.find((item) => item.id === value.value)

    if ([...selectedUser, ...oldUser].some((userS) => userS.id === user.id)) return

    setSelectedUser((prevState) => [...prevState, user])
  }

  const handleRemove = ({ userId }) => {
    setSelectedUser(selectedUser.filter((user) => user.id !== userId))
  }

  const formatSelect = ({ list }) => {
    return list.map((item) => {
      return {
        value: item.id,
        label: `${item?.firstname || ''} ${item?.lastname || ''} - ( ${ROLES_DISPLAY[item.role]} )`
      }
    })
  }

  return (
    <Modal>
      <>
        {loadingInternal && <Loading />}

        <div className="modal-content" style={{ maxWidth: 800 }}>
          <div className="modal-header">
            <h3 className="modal-title fs-5 text-center" id="exampleModalLabel">
              Add team member to project
            </h3>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12 my-3 px-5">
                <SelectWithHint
                  id="selectUser"
                  loading={loadingUserHook}
                  value={search}
                  placeholder="User"
                  formatSelect={formatSelect({ list: listUsers })}
                  handleInputChange={hanldeChangeSearch}
                  handleChange={handleChangeSelectUser}
                  noOptionsMessage="User not found"
                  loadingMessage="Search"
                />
              </div>
            </div>
            {error && (
              <div className="row">
                <div className="col-12 px-5">
                  <div className="alert alert-danger" role="alert">
                    <div className="row">
                      <div className="col-auto">
                        <WarningOctagon size={24} color={COLOR_RED} weight="bold" />
                      </div>
                      <div className="col">
                        <p className="mb-0 me-3">{error}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row justify-content-center">
              <div className="col-12 d-grid card p-4 assignedProjectsCard">
                <TableUserTeam users={selectedUser} onDelete={handleRemove} />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={hide}>
              Close
            </button>
            <button
              type="submit"
              onClick={() => {
                onSubmit({ listUser: selectedUser, callback: hide })
              }}
              className="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </>
    </Modal>
  )
}

export default AddTeamMemberModal
