import TimeTrackerItem from 'Pages/TimeTracker/Components/TimeTrackerItem'

const ListTimeTicket = ({ timeEntriesPerDay, onOpenEdit, onRunTime }) => {
  return (
      <div className="card listTimeTicket">
        {timeEntriesPerDay.map((timeEntry) => (
          <TimeTrackerItem key={timeEntry.id} timeEntry={timeEntry} onOpenEdit={onOpenEdit} onRunTime={onRunTime} />
        ))}
      </div>
  )
}

export default ListTimeTicket
