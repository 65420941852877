import { Info } from '@phosphor-icons/react'
import { CustomTooltipBarChart } from 'Organisms/BarChart'
import { BREAKPOINT } from 'Utils/const/breakPoints'
import { GET_COLOR_BY_THEME } from 'constant/colors'
import { useMediaQuery } from 'react-responsive'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import CustomBarDashboard from './CustomBarDashboard'
import { PERIOD_TIME_SHEET } from 'Pages/Timesheet/utils/const'
import useSessionStore from 'Context/useSessionStore'

const GraphicsPerProjectDashboard = ({ groupHours, totalHours, title = '', currentPeriod }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  // Media query
  const isMobile = useMediaQuery({
    query: `(max-width: ${BREAKPOINT.MOBILE})`
  })
  const isTablet = useMediaQuery({
    query: `(max-width: ${BREAKPOINT.TABLE})`
  })

  const isAllOrYear = [PERIOD_TIME_SHEET.ALL_TIME, PERIOD_TIME_SHEET.YEAR].includes(currentPeriod)

  return (
    <div className="card dashboard_card_small">
      <h5 className="mb-4">
        {title} <Info size={18} />
      </h5>
      <div style={{ minHeight: 400 }}>
        {isTablet
          ? (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={groupHours}
              layout="vertical"
              margin={{
                top: 20,
                right: 20,
                left: 10,
                bottom: 0
              }}>
              <XAxis type="number" />
              <YAxis dataKey="name" type="category" stroke={GET_COLOR_BY_THEME({ theme: user.theme }).SECONDARY} tick={{ fontSize: 12 }} />
              <Bar
                dataKey="hours"
                stackId="a"
                shape={<CustomBarDashboard totalHours={totalHours} isMobile={isMobile} isTablet={isTablet} />}
              />
            </BarChart>
          </ResponsiveContainer>
            )
          : (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={groupHours}
              margin={{
                top: 20,
                right: 10,
                left: 0,
                bottom: isAllOrYear ? 60 : 0
              }}>
              <XAxis
                dataKey="name"
                stroke={GET_COLOR_BY_THEME({ theme: user.theme }).SECONDARY}
                tick={{ fontSize: 12 }}
                angle={isAllOrYear ? -90 : 0}
                interval={0}
                tickMargin={isAllOrYear ? 40 : 10}
              />
              <YAxis stroke={GET_COLOR_BY_THEME({ theme: user.theme }).SECONDARY} />
              {isMobile && <Tooltip content={<CustomTooltipBarChart totalHours={totalHours} />} />}
              <Bar
                dataKey="hours"
                stackId="a"
                shape={<CustomBarDashboard totalHours={totalHours} isMobile={isMobile} />}
              />
            </BarChart>
          </ResponsiveContainer>
            )}
      </div>
    </div>
  )
}

export default GraphicsPerProjectDashboard
