import { useEffect, useState } from 'react'

import { useProject } from 'Hooks'

import { CustomDatePicker } from 'Organisms/CustomDatePicker'
import { Loading } from 'Organisms/Loading'
import { PanelHoursCapacity } from 'Organisms/PanelHoursCapacity'

import {
  GraphicsPerProjectDashboard,
  GraphicsPerTaskDashboard
} from 'Pages/Dashboard/components'
import { PERIOD_TIME_SHEET } from 'Pages/Timesheet/utils/const'

import { countDecimalsAndAdd0, getEndWeek, getStartWeek } from 'Utils/helperFunctions'

const ProjectHealth = ({ project }) => {
  const [startDay, setStartDay] = useState(getStartWeek())
  const [endDay, setEndDay] = useState(getEndWeek())
  const [currentPeriod, setCurrentPeriod] = useState(PERIOD_TIME_SHEET.WEEK)
  const [totalHoursPerProject, setTotalHoursPerProject] = useState(0)
  const [groupHoursPerProject, setGroupHoursPerProject] = useState([])

  const [groupHoursPerTask, setGroupHoursPerTask] = useState([])
  const [totalHoursPerTask, setTotalHoursPerTask] = useState(0)

  // Hooks
  const {
    loadingProjectHook,
    metricsGeneralByProject,
    getMetricsUserHoursByProjectHook,
    getMetricsTaskHoursByProjectHook,
    getMetricsGeneralByProjectHook
  } = useProject()

  const getGeneralMetrics = async ({ startDate = startDay, endDate = endDay } = {}) => {
    await getMetricsGeneralByProjectHook({
      startDate,
      endDate,
      idProject: project.id
    })
  }

  const getUserHoursMetrics = async ({ startDate = startDay, endDate = endDay } = {}) => {
    const result = await getMetricsUserHoursByProjectHook({
      startDate,
      endDate,
      idProject: project.id
    })

    setGroupHoursPerProject(
      result.map((item) => ({
        name: item?.user_name || '',
        hours: item?.hours || 0
      }))
    )

    setTotalHoursPerProject(result.reduce((acc, item) => acc + (item?.hours || 0), 0))
  }

  const getTaskHoursMetrics = async ({ startDate = startDay, endDate = endDay } = {}) => {
    const result = await getMetricsTaskHoursByProjectHook({
      startDate,
      endDate,
      idProject: project.id
    })

    setGroupHoursPerTask(
      result.map((item) => ({
        name: item?.task_name || '',
        children: [
          {
            hours: item?.hours || 0
          }
        ]
      }))
    )

    setTotalHoursPerTask(result.reduce((acc, item) => acc + (item.hours || 0), 0))
  }

  const handleSearchDataByDate = async ({ startDay, endDay, period }) => {
    setStartDay(startDay)
    setEndDay(endDay)
    setCurrentPeriod(period)

    await Promise.allSettled([
      getUserHoursMetrics({ startDate: startDay, endDate: endDay }),
      getTaskHoursMetrics({ startDate: startDay, endDate: endDay }),
      getGeneralMetrics({ startDate: startDay, endDate: endDay })
    ])
  }

  useEffect(() => {
    Promise.allSettled([getUserHoursMetrics(), getTaskHoursMetrics(), getGeneralMetrics()])
  }, [])

  return (
    <>
      {loadingProjectHook && <Loading />}
      <div className="row justify-content-center">
        <div className="col-lg-11 col-12 d-grid">
          <h2>{"Project's Health"}</h2>
          <div className="mt-4">
            <CustomDatePicker
              onSearchData={({ startDay, endDay, period }) => handleSearchDataByDate({ startDay, endDay, period })}
              colorPeriod='secondary'
            />
          </div>

          <PanelHoursCapacity
            hours={countDecimalsAndAdd0({ number: metricsGeneralByProject?.hours || 0 })}
            currentPeriod={currentPeriod}
            startDay={startDay}
            endDay={endDay}
            capacity={countDecimalsAndAdd0({ number: metricsGeneralByProject?.capacity || 0 })}
          />

          <div className="mt-4" />

          <GraphicsPerProjectDashboard
            groupHours={groupHoursPerProject}
            totalHours={totalHoursPerProject}
            title="Worked hours per user"
          />

          <div className="mt-4" />

          <GraphicsPerTaskDashboard
            groupHours={groupHoursPerTask}
            totalHours={totalHoursPerTask}
            title="Worked hours per task"
          />
        </div>
      </div>
    </>
  )
}

export default ProjectHealth
