import { useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Eye, EyeSlash } from '@phosphor-icons/react'
import { confirmInvite } from 'Hooks/useAuth'
import { Helmet } from 'react-helmet'

import { InputMessageError } from 'Organisms/InputMessageError'

// Utils
import { getConfirmationCodeInUrl, getUserNameInUrl } from 'Utils/helperFunctions'
import { LOGIN_ROUTER } from 'constant/router'
import { COLOR_BLUE_MATE } from 'constant/colors'
import { Logo } from 'Organisms'
import { PasswordRequirement } from './Components'
import { ModalSuccess } from 'Organisms/ModalSuccess'

const Welcome = () => {
  const [searchParams] = useSearchParams()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [passwordType, setPasswordType] = useState(true)
  const [confirmPasswordType, setConfirmPasswordType] = useState(true)

  const navigate = useNavigate()

  const onSubmit = (data) => {
    if (data.password === data.passwordConfirm) {
      const url = window.location.href
      const userName = getUserNameInUrl({ url })
      const confirmCode = getConfirmationCodeInUrl({ url })

      confirmInvite(userName.toLowerCase().replace(' ', '+'), confirmCode, data.password, {})
        .then(async (res) => {
          if (res.type !== 'Success') {
            throw Error('Unable to confirm')
          }

          setSuccess(true)
        })
        .catch((e) => {
          console.error(e)
          setError('There is a problem with your request. Please try again later')
          setLoading(false)
        })
    } else {
      setError('Passwords must match')
    }
  }

  return (
    <>
      <Helmet>
        <title>Welcome</title>
        <meta
          name="description"
          content="Time and task Tracking. Free trial available. Three pricing plans available."
        />
        <meta
          name="keywords"
          content="time tracker, time tracking, remote employees, productivity, software, remote work"
        />
        <meta name="author" content="Pulse by Canned Head" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Plans & Pricing" />
        <meta
          property="og:description"
          content="Time and task Tracking. Free trial available. Three pricing plans available."
        />
        <meta property="og:image" content="https://example.com/image.jpg" />
        <meta property="og:url" content="https://example.com/time-tracking-software" />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="container login dark-theme">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-8 col-lg-5">
            <div className="row justify-content-center align-items-center">
              <div className="col-12 col-md-10">
                <div className="row justify-content-center">
                  <div className="col-9 logo-wrapper">
                    <Logo />
                  </div>
                </div>
                <div className="row justify-content-center mt-5">
                  <h2>Welcome to Pulse!</h2>
                  <form className="col-12 d-grid" onSubmit={handleSubmit(onSubmit)}>
                    <p className="mb-3">
                      Welcome to your Operations Hub! By entering this space, you&apos;re empowering your company to
                      make informed decisions and drive continuous improvement using data. Please set a password to
                      begin your journey.
                    </p>
                    {error && <InputMessageError error={error} />}
                    {errors.password && (
                      <InputMessageError error="Password must have at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character" />
                    )}
                    <input type="hidden" name="email" defaultValue={searchParams.get('email')} />
                    <div className="mb-4 position-relative">
                      <label className="form-label d-flex align-items-center" htmlFor="inputPassword">
                        Password
                        <PasswordRequirement />
                      </label>
                      <input
                        name="password"
                        type={passwordType ? 'password' : 'text'}
                        {...register('password', {
                          required: true,
                          pattern: /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*([^\w\s]|[_]))\S{8,}$/
                        })}
                        className={`form-control ${error ? 'is-invalid' : ''}`}
                        id="inputPassword"
                        placeholder="Password"
                      />
                      {error && <div className="invalid-feedback">{error}</div>}
                      <button
                        type="button"
                        className="btn btn-ghost position-absolute eye-button"
                        onClick={() => setPasswordType(!passwordType)}>
                        {!passwordType && <Eye size={25} color={COLOR_BLUE_MATE} weight="regular" />}
                        {passwordType && <EyeSlash size={25} color={COLOR_BLUE_MATE} weight="regular" />}
                      </button>
                    </div>
                    <div className="mb-4 position-relative">
                      <label className="form-label" htmlFor="inputConfirmPassword">
                        Confirm password
                      </label>
                      <input
                        type={confirmPasswordType ? 'password' : 'text'}
                        {...register('passwordConfirm', { required: true })}
                        className="form-control"
                        id="inputConfirmPassword"
                        placeholder="Confirm new password"
                      />
                      <button
                        type="button"
                        className="btn btn-ghost position-absolute eye-button"
                        onClick={() => setConfirmPasswordType(!confirmPasswordType)}>
                        {!confirmPasswordType && <Eye size={25} color={COLOR_BLUE_MATE} weight="regular" />}
                        {confirmPasswordType && <EyeSlash size={25} color={COLOR_BLUE_MATE} weight="regular" />}
                      </button>
                    </div>
                    <button type="submit" className="btn btn-block btn-primary" disabled={loading}>
                      {loading && (
                        <>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span className="ms-2">Loading...</span>
                        </>
                      )}
                      {!loading && <span>Set password</span>}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {success && (
        <ModalSuccess
          visible={true}
          setVisible={() => {
            navigate(LOGIN_ROUTER)
          }}
          btnText="Return to Sign In"
          title={<h4>Awesome</h4>}
          content={<p className="text-center">You have successfully setup your password</p>}
          identifier="modalMessage"
          width={400}
        />
      )}
    </>
  )
}

export default Welcome
