import { ModalFilters } from 'Organisms/ModalFilters'
import React, { useState } from 'react'
import { LIST_ROLE_FILTER_USER } from './FilterUserPopover'
import useSessionStore from 'Context/useSessionStore'

const ModalFilterUserMobile = ({ onFilter }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const [selectedRoles, setSelectedRoles] = useState([])

  const handleSelectRole = ({ role }) => {
    setSelectedRoles([...selectedRoles, role])
  }

  const handleRemoveRole = ({ role }) => {
    setSelectedRoles(selectedRoles.filter((item) => item !== role))
  }

  const handleSendFilter = () => {
    onFilter && onFilter({ result: { Role: selectedRoles } })
  }

  const handleClearFilter = () => {
    setSelectedRoles([])
    onFilter && onFilter({ result: { Role: [] } })
  }

  return (
    <ModalFilters onFilter={handleSendFilter} onClear={handleClearFilter}>
      <div className="modalFilterUserMobile">
        <p>Role</p>

        <div className="modalFilterUserMobile_optionRole">
          {LIST_ROLE_FILTER_USER.options.map((role) => {
            const isSelected = selectedRoles.includes(role.option)
            if (role?.allowed && !role.allowed.some((allow) => allow === user.role)) {
              return <React.Fragment key={role} />
            }

            return (
              <p
                key={role.option}
                className={`${isSelected ? 'role_active' : ''} btn btn-secondary modalFilterUserMobile_options`}
                onClick={() =>
                  isSelected ? handleRemoveRole({ role: role.option }) : handleSelectRole({ role: role.option })
                }>
                {role.option}
              </p>
            )
          })}
        </div>
      </div>
    </ModalFilters>
  )
}

export default ModalFilterUserMobile
