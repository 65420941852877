export const OPTIONS_PRINCING = {
  monthly: {
    name: 'monthly',
    price: 15
  },
  annually: {
    name: 'annually',
    price: 12
  }
}

export const PLAN_PACKAGE_FREE = {
  id: 1,
  plan: 'free'
}

export const PLAN_PACKAGE_PROFESSIONAL = {
  id: 2,
  plan: 'professional'
}

export const PLAN_PACKAGE_ENTERPRISE = {
  id: 3,
  plan: 'enterprise'
}

export const GET_LIST_PLAN_PACKAGES = ({ period, hiddenCustom }) => ({
  FREE: {
    ...PLAN_PACKAGE_FREE,
    title: 'Starter',
    mode: 'Free',
    period: 'Forever',
    btnChoose: 'Choose Starter',
    btnColor: 'btn-secondary',
    creditCardRequired: false,
    including: [
      'Unlimited Time Tracking',
      'Unlimited Projects',
      'Free for up to 5 Users',
      'Timesheets',
      'Exportable reports',
      'Payroll'
    ]
  },
  PROFESSIONAL: {
    ...PLAN_PACKAGE_PROFESSIONAL,
    title: 'Professional',
    mode: `$${OPTIONS_PRINCING[period].price} per seat/month`,
    period: `Billed ${period}`,
    btnChoose: 'Start a 30-day free trial',
    btnColor: 'btn-primary',
    description: 'Everything included in Starter, plus...',
    creditCardRequired: true,
    including: [
      "Limit user's access to projects",
      'Jira / Azure DevOps Integrations',
      'Project budgets',
      'Forecast',
      'Proforma invoice'
    ]
  },

  ...(!hiddenCustom
    ? {
        CUSTOM: {
          ...PLAN_PACKAGE_ENTERPRISE,
          title: 'Enterprise',
          mode: 'Custom',
          period: 'Annual billing only',
          btnChoose: 'Contact Sales',
          btnColor: 'btn-terciary',
          description: 'Everything included in Professional, plus...',
          creditCardRequired: true,
          including: [
            'Custom Integrations & Dashboards',
            'Priority support',
            'Expert training and assistance',
            'Volume discounts for large teams on our annual plan'
          ]
        }
      }
    : {})
})
