import { useContext, useState } from 'react'
import Tooltip from './Tooltip'
import ContextTutorial from 'Context/tutorialContext'

const OnboardingTooltip = ({ steps }) => {
  const [stepIndex, setStepIndex] = useState(0)
  const { handleOpenTutorialContext } = useContext(ContextTutorial)

  const handleNext = () => {
    if (stepIndex < steps.length - 1) {
      setStepIndex(stepIndex + 1)
    }
    if (stepIndex >= steps.length - 1) {
      handleOpenTutorialContext()
    }
  }

  const handlePrev = () => {
    if (stepIndex > 0) {
      setStepIndex(stepIndex - 1)
    }
  }

  const currentStep = steps[stepIndex]

  return (
      <Tooltip targetId={currentStep.target} title={currentStep.title} imgUrl={currentStep.imgUrl} text={currentStep.text} displayed={true} setDisplayed={handleOpenTutorialContext} offsetX={currentStep.offsetX} offsetY={currentStep.offsetY}>
        <div className='row justify-content-between'>
          <div className='col-auto'>
            <p className='mb-0 small'>{stepIndex + 1} of {steps.length}</p>
          </div>
          <div className='col-auto'>
            <button className="btn btn-secondary btn-sm me-2" onClick={handlePrev} disabled={stepIndex === 0}>
              Previous
            </button>
            <button className="btn btn-primary btn-sm" onClick={handleNext}>
              { stepIndex === steps.length - 1 ? 'Finish' : 'Next'}
            </button>
          </div>
        </div>
      </Tooltip>
  )
}

export default OnboardingTooltip
