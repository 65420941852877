import React, { useState } from 'react'
import { handleChangeActiveSwitchColumns, handleDragEndColumns, orderListPerKey } from 'Utils/helperFunctions'
import { INITIAL_STATE_TIMESHEET } from 'constant/tableColumns'
import { useTimeEntries } from 'Hooks'

const ContextColumnTimeSheet = React.createContext({})

const NAME_LOCAL_STORAGE = 'orderItemsTimeSheet'

export function TimeSheetContextProvider ({ children }) {
  const { updateColumnsTableHook, createColumnsTableHook, getColumnsTableHook } = useTimeEntries()
  const [loadingColumnsTimeSheetContext, setLoadingColumnsTimeSheetContext] = useState(false)

  const [timeSheetColunm, setTimeSheetColunm] = useState(
    () => JSON.parse(localStorage.getItem(NAME_LOCAL_STORAGE)) || INITIAL_STATE_TIMESHEET
  )

  const resetColumnsAndAddNewColumn = async ({ oldColumns, newColumns }) => {
    try {
      // obtenemos las columnas que hay que crear
      const itemsEnListaAQueNoEstanEnListaB = newColumns.filter(
        (itemA) => !oldColumns.some((itemB) => itemB.name === itemA.name)
      )

      if (itemsEnListaAQueNoEstanEnListaB.length === 0) return oldColumns

      // creamos las nuevas columnas
      const resCreateColumns = await createColumnsTableHook({ columns: itemsEnListaAQueNoEstanEnListaB })

      if (!resCreateColumns.success) {
        console.error('there is an error in new columns timesheet')
        return oldColumns
      }

      // reorganizamos la lista
      const bothList = [...oldColumns, ...resCreateColumns.data]

      // agregamos los id a la nueva lista
      const newList = newColumns.map((item) => ({
        ...item,
        id: bothList.find((itemB) => itemB.name === item.name).id
      }))

      await handleUpdateInBack({ columns: newList })
      return newList
    } catch (e) {
      console.error(e)
    }
  }

  const getColumnsContext = async () => {
    try {
      const columns = await getColumnsTableHook()

      if (!columns.success) {
        console.error('there is an error in columns timesheet')
        localStorage.setItem(NAME_LOCAL_STORAGE, JSON.stringify(INITIAL_STATE_TIMESHEET))
        return setTimeSheetColunm(INITIAL_STATE_TIMESHEET)
      }

      // Si no existen columnas en la bbdd las creamos
      if (columns.data.length === 0) {
        const resCreateColumns = await createColumnsTableHook({ columns: INITIAL_STATE_TIMESHEET })

        if (!resCreateColumns.success) return console.error('There is an error when I am creating columns')

        const columnsOrder = orderListPerKey({ list: resCreateColumns.data, keyByOrder: 'order' })

        localStorage.setItem(NAME_LOCAL_STORAGE, JSON.stringify(columnsOrder))
        return setTimeSheetColunm(columnsOrder)
      }

      // validamos si no se creo una nueva columna en la tabla
      if (columns.data.length !== INITIAL_STATE_TIMESHEET.length) {
        const newList = await resetColumnsAndAddNewColumn({
          oldColumns: columns.data,
          newColumns: INITIAL_STATE_TIMESHEET
        })
        localStorage.setItem(NAME_LOCAL_STORAGE, JSON.stringify(newList))
        return setTimeSheetColunm(newList)
      }

      const columnsOrder = orderListPerKey({ list: columns.data, keyByOrder: 'order' })
      localStorage.setItem(NAME_LOCAL_STORAGE, JSON.stringify(columnsOrder))
      setTimeSheetColunm(columnsOrder)
    } catch (e) {
      console.error(e)
    }
  }

  const handleDragEnd = async (event) => {
    const newOrder = handleDragEndColumns({ event, columns: timeSheetColunm })
    const updateColunmOrder = newOrder.map((item, index) => ({ ...item, order: index }))

    await handleUpdateInBack({ columns: updateColunmOrder })
    localStorage.setItem(NAME_LOCAL_STORAGE, JSON.stringify(updateColunmOrder))
    setTimeSheetColunm(updateColunmOrder)
  }

  const handleChangeActiveSwitch = async ({ item, active }) => {
    const update = handleChangeActiveSwitchColumns({ item, active, columns: timeSheetColunm })
    localStorage.setItem(NAME_LOCAL_STORAGE, JSON.stringify(update))
    setTimeSheetColunm(update)
    await handleUpdateInBack({ columns: update })
  }

  const handleUpdateInBack = async ({ columns }) => {
    try {
      setLoadingColumnsTimeSheetContext(true)

      const clone = structuredClone(columns)

      await updateColumnsTableHook({ columns: clone })
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingColumnsTimeSheetContext(false)
    }
  }

  return (
    <ContextColumnTimeSheet.Provider
      value={{
        timeSheetColunm,
        loadingColumnsTimeSheetContext,
        getColumnsContext,
        handleDragEnd,
        handleChangeActiveSwitch
      }}>
      {children}
    </ContextColumnTimeSheet.Provider>
  )
}

export default ContextColumnTimeSheet
