const HeaderSettings = ({ title }) => {
  return (
    <div className="row mb-4 justify-content-between gy-sm-0 gy-4 align-items-center">
      <div className="col-sm-auto col-12 d-flex align-items-center">
        <h4>{title}</h4>
      </div>
      <div className="col-sm-5 col-12 d-flex justify-content-sm-end">
        <button type="submit" className="btn btn-primary settings_submit">
          Save changes
        </button>
      </div>
    </div>
  )
}

export default HeaderSettings
