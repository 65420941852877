import useSessionStore from 'Context/useSessionStore'
import { decimalToHours } from 'Utils/helperFunctions'

const WeeklyTotalTracker = ({ listTimeEntries }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const handleGetTotalHoursAndCapacity = ({ entries, capacity }) => {
    const totalHours = entries.reduce((acc, item) => {
      return acc + item.hours
    }, 0)

    return `${decimalToHours({ time: totalHours })} / ${decimalToHours({ time: capacity })}`
  }

  return (
    <p className="daysOfWeek_total m-0 text-secondary">
      Weekly total
      <span> {handleGetTotalHoursAndCapacity({ entries: listTimeEntries, capacity: user?.capacity })}</span>
    </p>
  )
}

export default WeeklyTotalTracker
