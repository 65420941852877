import Avatar from 'react-avatar'
import { PencilSimple, Trash, WarningCircle } from '@phosphor-icons/react'

import useSessionStore from 'Context/useSessionStore'

import { COLOR_BLACK, COLOR_PURPLE_LIGHT, COLOR_RED } from 'constant/colors'
import { useState } from 'react'
import { AvatarModalSettings } from '../AvatarModalSettings'
import ModalConfirmOrCancel from 'Organisms/ModalConfirmOrCancel'
import { ModalSuccess } from 'Organisms/ModalSuccess'
import { useUser } from 'Hooks'
import { Loading } from 'Organisms/Loading'

const INIT_STATE_MODAL_MESSAGE = {
  open: false,
  message: '',
  error: false
}

const InfoUserAvatarSettings = () => {
  const { user, updateUser } = useSessionStore((state) => ({ user: state.user, updateUser: state.updateUser }))

  const [openChangeAvatar, setOpenChangeAvatar] = useState(false)
  const [removeAvatar, setRemoveAvatar] = useState(false)
  const [modalMessage, setModalMessage] = useState(INIT_STATE_MODAL_MESSAGE)

  const { loadingUserHook, updateProfileUserHook } = useUser()

  const handleRemove = async () => {
    const body = {
      firstname: user.firstname,
      lastname: user.lastname,
      full_name: `${user?.firstname || ''} ${user?.lastname}`,
      timezone: user.timezone,
      logo: ''
    }

    const res = await updateProfileUserHook({ body })

    if (!res.success) {
      return setModalMessage({
        open: true,
        message: 'An error occurred while updating your profile',
        error: true
      })
    }

    updateUser({
      ...user,
      logo: ''
    })
    setModalMessage({
      open: true,
      message: 'Your profile is updated successfully',
      error: false
    })
    setRemoveAvatar(false)
  }

  return (
    <>
      {loadingUserHook && <Loading />}

      <div className="settings_avatar_section">
        <div className="settings_avatar">
          <div className="settings_avatar_content" onClick={() => setOpenChangeAvatar(true)}>
            <Avatar
              className="avatarImage"
              name={`${user.firstname} ${user.lastname}`}
              src={user?.logo || null}
              color={COLOR_PURPLE_LIGHT}
              fgColor={COLOR_BLACK}
              round
              size="60px"
              textSizeRatio={3}
            />

            <div className="settings_edit_avatar">
              <PencilSimple color={COLOR_BLACK} />
            </div>
          </div>
        </div>

        <div className="settings_fullname">
          <h5>{`${user.firstname || ''} ${user.lastname || ''}`}</h5>
          <p>{user.email}</p>
        </div>
      </div>

      {openChangeAvatar && (
        <AvatarModalSettings
          onClose={() => setOpenChangeAvatar(false)}
          edit={user}
          onRemove={() => setRemoveAvatar(true)}
        />
      )}

      {removeAvatar && (
        <ModalConfirmOrCancel
          title="Are you sure you want to remove this picture ?"
          setVisible={() => setRemoveAvatar(false)}
          btnConfirm="Delete"
          onConfirm={() => handleRemove()}
          icon={<Trash size={56} color={COLOR_RED} weight="bold" />}
          style="danger"
          identifier="modalConfirmDelete"
          center
        />
      )}

      {modalMessage.open && (
        <ModalSuccess
          visible={true}
          setVisible={() => setModalMessage(INIT_STATE_MODAL_MESSAGE)}
          title={modalMessage.message}
          identifier="modalMessageSuccess"
          error={modalMessage.error}
          icon={modalMessage.error && WarningCircle}
        />
      )}
    </>
  )
}

export default InfoUserAvatarSettings
