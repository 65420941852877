const LearnCenter = () => {
  return (
    <div className="container pt-4">
      <div className="row">
        <LearnCenterItem name="slate-gray" backgroundColor="#6D7F9B" textColor="#F3F5F7" />
      </div>
    </div>
  )
}

const LearnCenterItem = ({ name, backgroundColor, textColor }) => {
  return (
    <div className="col'12 col-md-6 col-lg-4 card p-3">
      <svg
        className="bd-placeholder-img card-img-top"
        width="100%"
        height="180"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="Placeholder: Image cap"
        preserveAspectRatio="xMidYMid slice"
        focusable="false"
      >
        <title>{backgroundColor}</title>
        <rect width="100%" height="100%" fill={backgroundColor}></rect>
        <text fill={textColor} x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
          {name}
        </text>
      </svg>
      <div className="card-body">
        <h5 className="card-title">Card title</h5>
        <p className="card-text">
          Some quick example text to build on the card title and make up the bulk of the cards content.
        </p>
        <a href="#" className="btn btn-primary">
          Go somewhere
        </a>
      </div>
    </div>
  )
}

export default LearnCenter
