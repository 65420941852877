import {
  AmericanExpressIcon,
  DiscoverIcon,
  VisaIcon,
  MasterCardIcon,
  CreditCardIcon
} from '../Organisms/CreditCardIcons'

export const getIconCardByType = ({ type }) => {
  const tyoeLower = type.toLowerCase()

  if (tyoeLower === 'discover') return DiscoverIcon
  if (tyoeLower === 'amex') return AmericanExpressIcon
  if (tyoeLower === 'visa') return VisaIcon
  if (tyoeLower === 'mastercard') return MasterCardIcon

  return CreditCardIcon
}
