import { useState } from 'react'

// Context
import useSessionStore from 'Context/useSessionStore'

// Components
import { AddTeamMemberModal, TableUserTeam } from './Components'

// Hooks
import { useProject } from 'Hooks'
import { PERMISSION_BY_ROLE } from 'Utils/permissionByRol'
import { Loading } from 'Organisms/Loading'

const Team = ({ project, refreshProjectInfo }) => {
  // Context
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const [visibleModal, setVisibleModal] = useState(false)
  const [error, setError] = useState(null)

  // Hooks
  const { loadingProjectHook, addUserToProjectHook, deleteUserToProjectHook } = useProject()

  const openTeamMemberModal = async () => {
    if (error) setError(null)
    setVisibleModal(true)
  }

  const handleAddUserToProject = async ({ listUser, callback }) => {
    const listIdUser = listUser.map((user) => ({ id: user.id }))
    const resAssign = await addUserToProjectHook({ listUser: listIdUser.map((item) => item.id), idProject: project.id })

    if (!resAssign.success) return setError(resAssign.message)

    await refreshProjectInfo()
    callback()
  }

  const handleDeleteUserToProject = async ({ userId }) => {
    await deleteUserToProjectHook({ userId, idProject: project.id })
    await refreshProjectInfo()
  }

  return (
    <>
      {loadingProjectHook && <Loading />}

      <div className="row justify-content-center">
        <div className="col-lg-11 col-12 d-grid">
          <div className="row align-items-center mb-sm-0 mb-3">
            <div className="col-sm col-12">
              <h4>Team</h4>
              <p>You can add or delete users to this project.</p>
            </div>

            {PERMISSION_BY_ROLE.PROJECT.ASSIGN_USER_TO_PROJECT.includes(user.role) && (
              <div className="col-sm-auto col-12 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => openTeamMemberModal()}>
                  Manage Team
                </button>
              </div>
            )}
          </div>
          <div className="card p-4 assignedProjectsCard">
            <TableUserTeam users={project?.user_projects || []} onDelete={handleDeleteUserToProject} />
          </div>
        </div>
      </div>

      {visibleModal && (
        <AddTeamMemberModal
          visible={visibleModal}
          setVisible={setVisibleModal}
          oldUser={project?.user_projects || []}
          onSubmit={handleAddUserToProject}
          error={error}
        />
      )}
    </>
  )
}

export default Team
