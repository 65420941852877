import { useEffect, useState } from 'react'
import ChangePaymentMethodModal from './ChangePaymentMethodModal'
import { usePayment } from 'Hooks'
import { ModalNewPaymentCard, PaymentCard } from './Components'
import { Loading } from 'Organisms/Loading'
import { ModalSuccess } from 'Organisms/ModalSuccess'

const PaymentMethod = () => {
  const [openChangePaymentMethodModal, setOpenChangePaymentMethodModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [openAddNewCreditCard, setOpenAddNewCreditCard] = useState(false)
  const [message, setMessage] = useState({ open: false })
  const [loadingInternal, setLoadingInternal] = useState(false)

  const { listPaymentMethodsHook, defaultPaymentHook, getListPaymentMethodsHook } = usePayment()

  const getListPaymentMethod = async () => {
    try {
      await getListPaymentMethodsHook()
    } finally {
      setLoading(false)
    }
  }

  const handleOpenNewCreditCard = () => setOpenAddNewCreditCard(true)

  const handleSuccessCreateNewCreditCard = () => {
    setMessage({
      open: true,
      error: false,
      message: 'Your card has been successfully associated.'
    })
  }

  const handleCloseSuccessCreate = async () => {
    setMessage({ open: false })

    try {
      setLoadingInternal(true)
      await getListPaymentMethod()
    } finally {
      setLoadingInternal(false)
    }
  }

  useEffect(() => {
    getListPaymentMethod()
  }, [])

  return (
    <>
      {loadingInternal && <Loading />}

      <h4 className="mt-5 mb-2">Payment method</h4>
      <div className="row mb-2">
        <p>Change how you pay your plan.</p>
      </div>
      <div className="row mb-4">
        <div className="col-12">
          {loading
            ? (
            <div className="spinner" />
              )
            : (
            <div className="card card-secondary py-4 px-5">
              <div className="row justify-content-between align-items-center">
                <PaymentCard
                  brand={defaultPaymentHook?.card?.brand || ''}
                  last4={defaultPaymentHook?.card?.last4 || ''}
                  exp_month={defaultPaymentHook?.card?.exp_month || ''}
                  exp_year={defaultPaymentHook?.card?.exp_year || ''}
                />

                <div className="col-auto">
                  <button
                    type="button"
                    onClick={() => setOpenChangePaymentMethodModal(true)}
                    className="btn btn-secondary">
                    Change Payment method
                  </button>
                </div>
              </div>
            </div>
              )}
        </div>
      </div>

      {openChangePaymentMethodModal && (
        <ChangePaymentMethodModal
          visible={true}
          identifier="modalManageSeats"
          data={listPaymentMethodsHook}
          defaultCard={defaultPaymentHook?.id}
          onOpenNewCreditCard={handleOpenNewCreditCard}
          onRefresh={getListPaymentMethod}
          onClose={() => {
            setOpenChangePaymentMethodModal(false)
          }}
        />
      )}

      {openAddNewCreditCard && (
        <ModalNewPaymentCard
          visible={true}
          onClose={() => setOpenAddNewCreditCard(false)}
          onSuccess={handleSuccessCreateNewCreditCard}
          onGoBack={() => {
            setOpenChangePaymentMethodModal(true)
          }}
        />
      )}

      {message.open && (
        <ModalSuccess
          visible={true}
          setVisible={handleCloseSuccessCreate}
          title={message.message}
          identifier="modalMessage"
        />
      )}
    </>
  )
}

export default PaymentMethod
