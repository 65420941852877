import { useContext, useEffect, useState } from 'react'
import { ClockAfternoon, WarningCircle } from '@phosphor-icons/react'
import { DateTime } from 'luxon'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom'

// Context
import useSessionStore from 'Context/useSessionStore'
import ContextTutorial from 'Context/tutorialContext'

// Hooks
import { useSchedule, useUser, useTimeEntries } from 'Hooks'

// Components
import TimeTrackerModal from './Components/TimeTrackerModal'
import { ModalSuccess } from 'Organisms/ModalSuccess'
import { Loading } from 'Organisms/Loading'
import { DaysOfWeek } from './Components/DaysOfWeek'
import { ErrorsAndAlertsTracker } from './Components/ErrorsAndAlertsTracker'
import FilterTrackerByDate from './Components/FilterTrackerByDate/FilterTrackerByDate'
import { BtnSubmitTracker } from './Components/BtnSubmitTracker'
import ListTimeTicket from './Components/ListTimeTicket/ListTimeTicket'
import { ModalDeleteTracker } from './Components'
import { WeeklyTotalTracker } from './Components/WeeklyTotalTracker'
import { TYPE_SUBMIT_ACTION } from './Components/BtnSubmitTracker/BtnSubmitTracker'
import { OnboardingTooltip } from 'Organisms/Onboarding'

// Const and utils
import { Mixpanel } from 'Utils/Mixpanel'
import { getValuePerFilter } from 'Utils/helperFunctions'
import { ROLE_DEVELOPER_NAME } from 'constant/roles'
import { DAYS_OF_WEEK, ONBOARDING_STEPS_TRACKER, PARAMS_URL_TRACKER, STATUS_SUBMIT_HOURS } from './utils/const'
import { getQueryParamsTrakcer, getStatusTracker } from './utils/helpers'

const TimeTracker = () => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const { openTutorialContext } = useContext(ContextTutorial)

  const location = useLocation()
  const navigate = useNavigate()
  const { queryParams, startDateParamsQuery, currentDateQuery } = getQueryParamsTrakcer({ location })

  const isDeveloper = user.role === ROLE_DEVELOPER_NAME

  const [approval, setApproval] = useState(STATUS_SUBMIT_HOURS.UNSUBMITTED)
  const [activeDate, setActiveDate] = useState(
    startDateParamsQuery
      ? DateTime.fromISO(startDateParamsQuery).setZone('local')
      : currentDateQuery
        ? DateTime.fromISO(currentDateQuery).setZone('local')
        : DateTime.now()
  )
  const [activeWeek, setActiveWeek] = useState([])
  const [visible, setVisible] = useState(false)
  const [timeEntriesPerDay, setTimeEntriesPerDay] = useState([])
  const [usersFilter, setUsersFilter] = useState([])
  const [selectedTracker, setSelectedTracker] = useState(null)
  const [alertTracker, setAlertTracker] = useState(false)
  const [modalDeleteTracker, setModalDeleteTracker] = useState(false)
  const [modalDetailsApproval, setModalDetailsApproval] = useState({
    open: false
  })
  const [errorTracker, setErrorTracker] = useState('')

  const currentDayName = activeDate.toFormat('cccc')

  // Hooks
  const {
    listTimeEntries,
    loadingTimeEntriesHook,
    getTimeEntriesHook,
    submitHoursTimeEntriesHook,
    reSubmitHoursTimeEntriesHook,
    activeTimerTimeEntriesHook,
    desActiveTimerTimeEntriesHook
  } = useTimeEntries()
  const { getAllUsersByFilterHook } = useUser()
  const { scheduleHook, getScheduleHook } = useSchedule()

  const handleChangeDay = async ({ days, now, date }) => {
    let updateDay

    // If it is now it is to return today
    if (now) {
      updateDay = DateTime.now()
    } else if (date) {
      updateDay = date
    } else {
      updateDay = activeDate.plus({ days })
    }

    const daysOfActiveWeek = activeWeek.map((item) => item.toFormat('dd'))

    setActiveDate(updateDay)

    // update param date by uri
    queryParams.set(PARAMS_URL_TRACKER.CURRENT_DATE, updateDay.toFormat('yyyy-MM-dd'))
    queryParams.delete(PARAMS_URL_TRACKER.DATE)
    queryParams.delete(PARAMS_URL_TRACKER.IS_TRACKER)
    navigate(`?${queryParams.toString()}`, { replace: true })

    if (errorTracker) setErrorTracker('')

    // If the current day is not included in the days of the week, we search for new data.
    if (daysOfActiveWeek.includes(updateDay.toFormat('dd'))) {
      return handleChangeListPerDay({ listEntries: listTimeEntries, currentDay: updateDay })
    }

    // only search old data
    await handleSetWeek({ currentDay: updateDay })
  }

  const handleSetWeek = async ({ currentDay }) => {
    let startOfWeek
    const weekDates = []

    if ([DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY].includes(currentDay.toFormat('cccc'))) {
      startOfWeek = currentDay.plus({ days: 3 }).startOf('week')
    } else {
      startOfWeek = currentDay.startOf('week')
    }

    for (let i = -2; i < 5; i++) {
      weekDates.push(startOfWeek.plus({ days: i }))
    }

    setActiveWeek(weekDates)
    await handleSetWeekTimeEntries({ activeWeek: weekDates, currentDay, filterUser: usersFilter })
  }

  const handleSetWeekTimeEntries = async ({ activeWeek, currentDay, filterUser } = {}) => {
    if (activeWeek.length === 0) return

    const listEntries = await getTimeEntriesHook({
      startDate: activeWeek[0].startOf('day'),
      endDate: activeWeek[6].endOf('day'),
      users: getValuePerFilter({ list: filterUser }),
      tracker: true,
      order: 'asc',
      page: 1,
      limit: 10000
    })

    handleChangeListPerDay({ listEntries, currentDay })
  }

  const handleChangeListPerDay = ({ listEntries, currentDay }) => {
    const timeEntriesByToDay = listEntries.filter(
      (item) => DateTime.fromISO(item.spent_date).setZone('local').toISODate() === currentDay.toFormat('yyyy-MM-dd')
    )

    setApproval(getStatusTracker({ listTimeEntries: timeEntriesByToDay }))

    setTimeEntriesPerDay(timeEntriesByToDay)
  }

  const handleRefreshTraker = async () => {
    await handleSetWeekTimeEntries({ activeWeek, currentDay: activeDate, filterUser: usersFilter })
  }

  const handleChangeSwitches = async ({ values } = {}) => {
    setUsersFilter(values)
    handleSetWeekTimeEntries({ activeWeek, currentDay: activeDate, filterUser: values })
  }

  const getAllUsers = async () => {
    try {
      const users = await getAllUsersByFilterHook({ hiddenUser: user })
      setUsersFilter(users)
    } catch (e) {
      console.error(e)
    }
  }

  const handleOpenEditTracker = ({ tracker }) => {
    setVisible(true)
    setSelectedTracker(tracker)
  }

  const handleSubmitHours = async ({
    resubmit = false,
    noneValidate,
    typeSubmit = TYPE_SUBMIT_ACTION.WEEK.id
  } = {}) => {
    if (!noneValidate && listTimeEntries.some((item) => item.is_running)) {
      return setErrorTracker('Please stop your timer before submitting for approval.')
    } else if (errorTracker) {
      setErrorTracker('')
    }

    let start_date = activeWeek[0].startOf('day')
    let end_date = activeWeek[6].endOf('day')

    if (typeSubmit !== TYPE_SUBMIT_ACTION.WEEK.id) {
      start_date = activeDate.startOf('day')
      end_date = activeDate.endOf('day')
    }

    const hereIsUserFilter = usersFilter.filter((item) => item.checked)?.[0]?.id

    const body = { user_id: hereIsUserFilter || user.id, start_date, end_date }

    const result = resubmit ? await reSubmitHoursTimeEntriesHook(body) : await submitHoursTimeEntriesHook(body)

    Mixpanel.track(`Time Tracker - Submit ${typeSubmit} for approval`)

    if (!result) return

    !noneValidate && (await handleRefreshTraker())
  }

  const handleMoreDetailsApproval = () => {
    const listMessage = {}

    listTimeEntries.forEach((item) => {
      if (!listMessage[item.project_task.project.code]) {
        listMessage[item.project_task.project.code] = {
          project: `[${item.project_task.project.code}] ${item.project_task.project.name}`,
          message: item?.time_entry_detail?.comment || ''
        }
      }
    })

    setModalDetailsApproval({
      open: true,
      messages: listMessage
    })
  }

  const validateAutoClose = async () => {
    const dayOfActiveWeek = DateTime.now().toFormat('cccc').toLowerCase()
    if (!scheduleHook?.data?.weekday || dayOfActiveWeek !== scheduleHook.data.weekday) return false

    const currentHours = DateTime.local()
    const scheduleHours = DateTime.local().set({ hour: scheduleHook.data.hours, minute: scheduleHook.data.minutes })
    const reSubmit = scheduleHours < currentHours

    if (!reSubmit) return false

    const trackerIsRunning = listTimeEntries.filter((item) => item.is_running)

    if (trackerIsRunning.length > 0) {
      await Promise.all(trackerIsRunning.map((item) => desActiveTimerTimeEntriesHook({ id: item.id })))
    }

    await handleSubmitHours({ resubmit: true, noneValidate: true })
    await handleSubmitHours({ noneValidate: true })
    await handleRefreshTraker()
    return true
  }

  const handleRunTime = async ({ run = true, idTimer }) => {
    const res = !run ? await validateAutoClose() : false

    if (res) return

    run ? await activeTimerTimeEntriesHook({ id: idTimer }) : await desActiveTimerTimeEntriesHook({ id: idTimer })
    await handleRefreshTraker()
  }

  const handleOpenAlert = () => setAlertTracker((prevState) => !prevState)

  useEffect(() => {
    const listPromise = [handleSetWeek({ currentDay: activeDate }), getScheduleHook()]

    if (!isDeveloper) listPromise.push(getAllUsers())

    Promise.allSettled(listPromise)
  }, [])

  return (
    <>
      {loadingTimeEntriesHook && <Loading />}

      {(errorTracker || approval !== STATUS_SUBMIT_HOURS.UNSUBMITTED) && (
        <ErrorsAndAlertsTracker
          errorTracker={errorTracker}
          approval={approval}
          onSubmit={handleSubmitHours}
          onDetails={handleMoreDetailsApproval}
        />
      )}
      <Helmet>
        <title>Time Tracker</title>
      </Helmet>

      {openTutorialContext && <OnboardingTooltip steps={ONBOARDING_STEPS_TRACKER} />}
      <div className="tracker mt-4">
        <div className="tracker_time mb-3">
          <div id="date_picker_tracker">
            <FilterTrackerByDate
              onChangeDay={handleChangeDay}
              currentDayName={currentDayName}
              activeDate={activeDate}
              usersFilter={usersFilter}
              onChangeUserFilters={handleChangeSwitches}
              approval={approval}
              onOpenAlert={handleOpenAlert}
              selectedTracker={selectedTracker}
              onChangeSelectedTracker={setSelectedTracker}
              onOpen={setVisible}
              hoursActiveDay={listTimeEntries
                .filter(
                  (item) =>
                    DateTime.fromISO(item.spent_date).setZone('local').toISODate() ===
                    activeDate.toFormat('yyyy-MM-dd')
                )
                .reduce((acc, item) => (acc = acc + item?.hours || 0), 0)}
            />
          </div>

          <div className="d-none d-sm-grid">
            <DaysOfWeek
              onChangeDay={handleChangeDay}
              listTimeEntries={listTimeEntries}
              activeWeek={activeWeek}
              currentDayName={currentDayName}
            />
          </div>
        </div>

        <div className="tracker_tickets">
          <ListTimeTicket
            timeEntriesPerDay={timeEntriesPerDay}
            onOpenEdit={handleOpenEditTracker}
            onRunTime={handleRunTime}
          />

          <div className="mt-4 d-block d-sm-none">
            <WeeklyTotalTracker listTimeEntries={listTimeEntries} />
          </div>

          <BtnSubmitTracker approval={approval} onSubmit={handleSubmitHours} listTimeEntries={listTimeEntries} />
        </div>
      </div>

      {visible && (
        <TimeTrackerModal
          visible={visible}
          setVisible={() => setVisible(false)}
          date={activeDate}
          refreshTracker={handleRefreshTraker}
          tracker={selectedTracker}
          onDelete={() => setModalDeleteTracker(true)}
          selectedUser={getValuePerFilter({ list: usersFilter })}
          isOtherDay={activeDate.ordinal !== DateTime.now().ordinal}
        />
      )}

      {modalDeleteTracker && (
        <ModalDeleteTracker
          visible={modalDeleteTracker}
          setVisible={() => setModalDeleteTracker(false)}
          tracker={selectedTracker}
          onCancel={() => setVisible(true)}
          refreshTracker={handleRefreshTraker}
        />
      )}

      {modalDetailsApproval.open && (
        <ModalSuccess
          visible={modalDetailsApproval.open}
          setVisible={() => setModalDetailsApproval({ open: false })}
          title={<>Details of rejected time</>}
          content={
            <>
              {(Object.keys(modalDetailsApproval.messages) || []).map((item) => (
                <p key={modalDetailsApproval.messages[item].project}>
                  {modalDetailsApproval.messages[item].project}: {modalDetailsApproval.messages[item].message}
                </p>
              ))}
            </>
          }
          btnConfirm="Go back"
          Icon={ClockAfternoon}
        />
      )}

      {alertTracker && (
        <ModalSuccess
          visible={true}
          setVisible={handleOpenAlert}
          title="Please cancel your week submission before adding more time entries"
          Icon={WarningCircle}
          error
        />
      )}
    </>
  )
}

export default TimeTracker
