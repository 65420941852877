import { usePayroll } from 'Hooks'
import { ApproveOrCancel } from '../ApproveOrCancel'
import React, { useState } from 'react'
import { ModalSuccess } from 'Organisms/ModalSuccess'
import useSessionStore from 'Context/useSessionStore'
import { ROLE_ADMIN_NAME, ROLE_DEVELOPER_NAME } from 'constant/roles'
import { Loading } from 'Organisms/Loading'
import { PERMISSION_BY_ROLE } from 'Utils/permissionByRol'
import { countDecimalsAndAdd0 } from 'Utils/helperFunctions'

const INIT_MODAL_MESSAGE = {
  open: false,
  message: '',
  success: false
}

const StepProjects = ({ user, nextStep, startDate, endDate, refreshData, activeReOpen }) => {
  const { user: userContext } = useSessionStore((state) => ({ user: state.user }))

  let totalHours = 0
  let totalPrice = 0

  const hiddenActions =
    userContext.role === ROLE_DEVELOPER_NAME ||
    (!PERMISSION_BY_ROLE.PAYROLL.SELF_ACTIONS.includes(userContext.role) && user.id === userContext.id)

  const {
    loadingPayrollHook,
    handleUpdateApprovedHoursHook,
    handleUpdateReOpenHoursHook,
    handleUpdateRejectHoursHook
  } = usePayroll()
  const [modalMessage, setModalMessage] = useState(INIT_MODAL_MESSAGE)

  const handleApproved = async () => {
    const result = await handleUpdateApprovedHoursHook({
      startDate,
      endDate,
      user_id: user.id,
      project_ids: user.projects.map((item) => item.id)
    })

    if (result.success) {
      return setModalMessage({
        open: true,
        message: `Hours for ${user.full_name || ''} have been approved`,
        success: true
      })
    }

    setModalMessage({
      open: true,
      message: 'An error has occurred',
      success: false
    })
  }

  const handleRejected = async ({ note }) => {
    const result = await handleUpdateRejectHoursHook({
      startDate,
      endDate,
      user_id: user.id,
      project_ids: user.projects.map((item) => item.id),
      comment: note
    })

    if (result.success) {
      return setModalMessage({
        open: true,
        message: `Hours for ${user.full_name || ''} have been rejected`,
        success: true
      })
    }

    setModalMessage({
      open: true,
      message: 'An error has occurred',
      success: false
    })
  }

  const handleReOpen = async () => {
    const result = await handleUpdateReOpenHoursHook({
      startDate,
      endDate,
      user_id: user.id,
      project_ids: user.projects.map((item) => item.id)
    })

    if (result.success) {
      return setModalMessage({
        open: true,
        message: `Hours for ${user.full_name || ''} have been reopen`,
        success: true
      })
    }
    setModalMessage({
      open: true,
      message: 'An error has occurred',
      success: false
    })
  }

  const handleCloseModalMessageAndRefresData = async () => {
    setModalMessage(INIT_MODAL_MESSAGE)

    if (modalMessage.success) await refreshData()
  }

  return (
    <>
      {loadingPayrollHook && <Loading />}

      <div className="card p-4 mb-4">
        <div className="table-responsive projects-card">
          <table className="table align-middle mb-0 payroll_table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Payment Type</th>
                <th scope="col" className="text-end">
                  Hours
                </th>
                {[ROLE_ADMIN_NAME, ROLE_DEVELOPER_NAME].includes(userContext.role) && (
                  <th scope="col" className="text-end">
                    Payable amount
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {user.projects.map((project) => {
                const hoursType = Object.keys(project?.service_details).filter(
                  (item) => project?.service_details[item].total_hours > 0
                )

                return (
                  <React.Fragment key={project.id}>
                    {hoursType.map((hourType) => {
                      const hours = project?.service_details?.[hourType]?.billable_hours || 0
                      const price =
                        hourType === 'hourly_cost'
                          ? project?.service_details?.[hourType]?.billable_cost || 0
                          : user?.cost_rate?.cost_rate || 0

                      totalHours += hours
                      totalPrice += price

                      return (
                        <tr key={hourType} className="hover">
                          <td className="text-no-wrap pointer" onClick={() => nextStep({ project })}>
                            {`[${project?.code || ''}] ${project?.name || ''}`}
                          </td>
                          <td className="text-no-wrap">
                            {hourType === 'hourly_cost'
                              ? (
                              <span className="badge text-bg-quaternaly">Hourly rate</span>
                                )
                              : (
                              <span className="badge text-bg-primary">Monthly payment</span>
                                )}
                          </td>
                          <td className="text-end">{countDecimalsAndAdd0({ number: hours })}h</td>
                          {[ROLE_ADMIN_NAME, ROLE_DEVELOPER_NAME].includes(userContext.role) && (
                            <td className="text-end">
                              ${countDecimalsAndAdd0({ number: price })} {hourType === 'hourly_cost' ? '' : '/month'}
                            </td>
                          )}
                        </tr>
                      )
                    })}
                  </React.Fragment>
                )
              })}

              <br />
              <tr style={{ borderTop: '1px solid rgba(54, 58, 57, 0.25)' }}>
                <td style={{ border: 'none' }} className="text-no-wrap text-normal fw-bold">
                  Total
                </td>
                <td style={{ border: 'none' }} />
                <td style={{ border: 'none' }} className="text-end text-normal fw-bold">
                  {countDecimalsAndAdd0({ number: totalHours })}h
                </td>
                {[ROLE_ADMIN_NAME, ROLE_DEVELOPER_NAME].includes(userContext.role) && (
                  <td style={{ border: 'none' }} className="text-end text-normal fw-bold">
                    ${countDecimalsAndAdd0({ number: totalPrice })}
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {!hiddenActions && (
        <ApproveOrCancel
          onApproved={activeReOpen ? handleReOpen : handleApproved}
          onReject={handleRejected}
          refreshData={refreshData}
          activeReOpen={activeReOpen}
        />
      )}

      {modalMessage.open && (
        <ModalSuccess
          visible={true}
          setVisible={handleCloseModalMessageAndRefresData}
          title={modalMessage.message}
          identifier="modalMessageApproved"
          error={!modalMessage.success}
        />
      )}
    </>
  )
}

export default StepProjects
