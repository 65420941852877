import { useEffect, useState } from 'react'
import { ApproveOrCancel } from '../ApproveOrCancel'
import { usePayroll, useTimeEntries } from 'Hooks'
import { TableTimeSheetAll } from 'Pages/Timesheet/TableTimeSheetAll'
import { ModalSuccess } from 'Organisms/ModalSuccess'
import useSessionStore from 'Context/useSessionStore'
import { ROLE_DEVELOPER_NAME } from 'constant/roles'
import { DistributionOfHours } from 'Organisms/DistributionOfHours'
import { PERMISSION_BY_ROLE } from 'Utils/permissionByRol'
import { countDecimalsAndAdd0 } from 'Utils/helperFunctions'
import { TABS_PAYROLL } from 'Pages/Payroll/PayrollPage'

const INIT_MODAL_MESSAGE = {
  open: false,
  message: '',
  success: false
}

const StepTimeSheet = ({ user, project, startDate, endDate, refreshData, activeReOpen, activeTab }) => {
  const { user: userContext } = useSessionStore((state) => ({ user: state.user }))

  const hiddenActions =
    userContext.role === ROLE_DEVELOPER_NAME ||
    (!PERMISSION_BY_ROLE.PAYROLL.SELF_ACTIONS.includes(userContext.role) && user.id === userContext.id)

  const { listTimeEntries, listGroupTimeEntries, getTimeEntriesHook } = useTimeEntries()
  const { handleUpdateApprovedHoursHook, handleUpdateReOpenHoursHook, handleUpdateRejectHoursHook } = usePayroll()

  const [modalMessage, setModalMessage] = useState(INIT_MODAL_MESSAGE)
  const [totalHoursWork, setTotalHoursWork] = useState(0)
  const [totalWarrantyWork, setTotalWarrantyWork] = useState(0)

  const getData = async () => {
    const result = await getTimeEntriesHook({
      startDate,
      endDate,
      ...(PERMISSION_BY_ROLE.TIME_SHEET.FILTER.BY_USER.includes(userContext.role) ? { users: user.id } : {}),
      projects: project.id,
      ...(activeTab === TABS_PAYROLL.PENDING.name ? { is_submitted: true } : { is_approved: true }),
      page: 1,
      limit: 10000
    })

    const totalHours = result.reduce((acc, item) => {
      return acc + (item?.hours ?? 0)
    }, 0)

    const totalWarranty = result.reduce((acc, item) => {
      return acc + (item.is_warranty ? item.hours : 0)
    }, 0)

    setTotalHoursWork(countDecimalsAndAdd0({ number: totalHours }))
    setTotalWarrantyWork(countDecimalsAndAdd0({ number: totalWarranty }))
  }

  const handleApproved = async () => {
    const result = await handleUpdateApprovedHoursHook({
      startDate,
      endDate,
      user_id: user.id,
      project_ids: [project.id]
    })

    if (result.success) {
      return setModalMessage({
        open: true,
        message: `Hours for ${user.full_name || ''} have been approved`,
        success: true
      })
    }

    setModalMessage({
      open: true,
      message: 'An error has occurred'
    })
  }

  const handleRejected = async ({ note }) => {
    const result = await handleUpdateRejectHoursHook({
      startDate,
      endDate,
      user_id: user.id,
      project_ids: [project.id],
      comment: note
    })

    if (result.success) {
      return setModalMessage({
        open: true,
        message: `Hours for ${user.full_name || ''} have been rejected`,
        success: true
      })
    }

    setModalMessage({
      open: true,
      message: 'An error has occurred'
    })
  }

  const handleReOpen = async () => {
    const result = await handleUpdateReOpenHoursHook({
      startDate,
      endDate,
      user_id: user.id,
      project_ids: [project.id]
    })

    if (result.success) {
      return setModalMessage({
        open: true,
        message: `Hours for ${user.full_name || ''} have been reopen`,
        success: true
      })
    }

    setModalMessage({
      open: true,
      message: 'An error has occurred'
    })
  }

  const handleCloseModalMessageAndRefresData = async () => {
    setModalMessage(INIT_MODAL_MESSAGE)
    if (modalMessage.success) await refreshData()
  }

  useEffect(() => {
    getData()
  }, [activeTab])

  return (
    <>
      <DistributionOfHours totalHoursWork={totalHoursWork || 0} totalWarrantyWork={totalWarrantyWork || 0} />

      <div className="card p-4">
        <TableTimeSheetAll dataList={listTimeEntries} listGroupTimeEntries={listGroupTimeEntries} />
      </div>

      {!hiddenActions && (
        <div className="mt-4">
          <ApproveOrCancel
            onApproved={activeReOpen ? handleReOpen : handleApproved}
            onReject={handleRejected}
            activeReOpen={activeReOpen}
          />
        </div>
      )}

      {modalMessage.open && (
        <ModalSuccess
          visible={true}
          setVisible={handleCloseModalMessageAndRefresData}
          title={modalMessage.message}
          identifier="modalMessageApproved"
          error={!modalMessage.success}
        />
      )}
    </>
  )
}

export default StepTimeSheet
