import { getCompanyInfoService, getUserByCompanyService, isAllowedCreateUserServive, selectPlanCompanyService, updateCompanyService, updateInitConfigCompanyService } from 'Services'
import { useState } from 'react'
import { DateTime } from 'luxon'
import { PLAN_PACKAGE_FREE } from 'Utils/const/packages'

export default function useCompany () {
  const [companyInfoHook, setCompanyInfoHook] = useState({})
  const [userCompanyHook, setUserCompanyHook] = useState({})
  const [loadingCompanyHook, setLoadingCompanyHook] = useState(false)

  const getCompanyInfoHook = async ({ companyId }) => {
    try {
      const res = await getCompanyInfoService({ companyId })

      const start_trial = res.data?.company_plan_package?.start_trial
      const expiration = start_trial ? DateTime.fromISO(start_trial) : null

      const currentDate = DateTime.now()

      const diffDays = expiration ? currentDate.diff(expiration, 'days').toObject().days : null

      const data = {
        ...res.data,
        isTrial:
          res.data.company_plan_package.plan_package_id !== PLAN_PACKAGE_FREE.id && expiration ? diffDays < 30 : false,
        expire_trial: expiration ? expiration.plus({ months: 1 }).toFormat('LLL dd, yyyy') : null
      }

      setCompanyInfoHook(data)
      return {
        success: true,
        data
      }
    } catch (e) {
      return {
        success: false
      }
    }
  }

  const selectPlanCompanyHook = async ({ companyId, period, plan_package_id } = {}) => {
    try {
      const res = await selectPlanCompanyService({ companyId, period, plan_package_id })

      return {
        success: true,
        data: res.data
      }
    } catch (e) {
      return {
        success: false
      }
    }
  }

  const getUserByCompanyHook = async ({ companyId }) => {
    try {
      const res = await getUserByCompanyService({ companyId })
      setUserCompanyHook(res)
      return res
    } catch (e) {
      return {
        success: false
      }
    }
  }

  const updateCompanyHook = async ({ companyId, body }) => {
    try {
      const res = await updateCompanyService({ companyId, body })
      setUserCompanyHook(res)
      return res
    } catch (e) {
      return {
        success: false
      }
    }
  }

  const isAllowedCreateUserHook = async () => {
    try {
      setLoadingCompanyHook(true)
      const res = await isAllowedCreateUserServive()
      return res
    } catch (e) {
      return {
        success: false
      }
    } finally {
      setLoadingCompanyHook(false)
    }
  }

  const updateInitConfigCompanyHook = async ({ body }) => {
    try {
      setLoadingCompanyHook(true)
      const res = await updateInitConfigCompanyService({ body })
      return {
        success: true,
        ...res
      }
    } catch (e) {
      return {
        success: false
      }
    } finally {
      setLoadingCompanyHook(false)
    }
  }

  return {
    companyInfoHook,
    userCompanyHook,
    loadingCompanyHook,
    selectPlanCompanyHook,
    getCompanyInfoHook,
    getUserByCompanyHook,
    updateCompanyHook,
    isAllowedCreateUserHook,
    updateInitConfigCompanyHook
  }
}
