import { DateTime } from 'luxon'

import { DAYS_INFO } from 'Pages/TimeTracker/utils/const'
import { decimalToHours } from 'Utils/helperFunctions'
import { WeeklyTotalTracker } from '../WeeklyTotalTracker'

const DaysOfWeek = ({ onChangeDay, listTimeEntries, activeWeek, currentDayName }) => {
  const handleGetTotalHoursPerDay = ({ day, entries }) => {
    if (!day) return '00:00'

    const allEntriesPerDay = entries.filter(
      (item) => DateTime.fromISO(item.spent_date).setZone('local').toISODate() === day.toFormat('yyyy-MM-dd')
    )

    const totalHours = allEntriesPerDay.reduce((acc, item) => {
      return acc + item.hours
    }, 0)

    return decimalToHours({ time: totalHours })
  }

  return (
    <div className="daysOfWeek">
      {DAYS_INFO.map((day) => (
        <button
          key={day.weekNumber}
          type="button"
          onClick={() => onChangeDay({ date: activeWeek[day.weekNumber] })}
          className={`daysOfWeek_day text-secondary ${currentDayName === day.day ? 'active' : ''}`}>
          {day.name}
          <br />
          <span className="">
            {handleGetTotalHoursPerDay({ entries: listTimeEntries, day: activeWeek[day.weekNumber] })}
          </span>
        </button>
      ))}

      <WeeklyTotalTracker listTimeEntries={listTimeEntries} />
    </div>
  )
}

export default DaysOfWeek
