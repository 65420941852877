import { FIELD_CONNECT_JIRA } from '../../const/connectJiraFields'

const SelectProjectIntegrationModal = ({ register, errors, options }) => {
  return (
    <div className="row">
      <label className="col-12 col-form-label" htmlFor={'inputProjectKey'}>
        Choose a project to link
      </label>
      <div className="col-12">
        <select
          {...register(FIELD_CONNECT_JIRA.PROJECT_KEY, { required: true })}
          className={`form-control form-select ${errors && errors[FIELD_CONNECT_JIRA.PROJECT_KEY] ? 'is-invalid' : ''}`}
          id={'inputProjectKey'}>
          <option value="">Select a Project</option>

          {options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
        <div className="invalid-feedback">Please select a Project.</div>
      </div>
    </div>
  )
}

export default SelectProjectIntegrationModal
