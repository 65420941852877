import { useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import { Play } from '@phosphor-icons/react'

// Utils
import { breakDownDecimalInTime, decimalToHours } from 'Utils/helperFunctions'
import { getDiferentHours } from '../utils/helpers'

// Const
import { MINUTE_IN_DECIMAL } from '../utils/const'
import useSessionStore from 'Context/useSessionStore'
import { Popover } from 'Organisms/Popover'

const Timer = ({ timeEntry, disabled, onRunTime, children }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const [time, setTime] = useState(0)
  const [isRunning, setIsRunning] = useState(timeEntry?.is_running)

  const btnStart = user.theme ? (isRunning ? 'btn-terciary' : 'btn-secondary') : 'btn-primary'

  const getHours = () => {
    return timeEntry?.is_running
      ? getDiferentHours({
        start_date: timeEntry.started_time,
        end_date: DateTime.now(),
        hours: timeEntry.hours
      })
      : timeEntry.hours
  }

  useEffect(() => {
    const hours = getHours()
    setTime(hours)
    setIsRunning(timeEntry?.is_running)
  }, [timeEntry])

  useEffect(() => {
    let interval
    let interval2
    let timeOut

    if (isRunning) {
      // seconds left to reach the minute
      const { secongs } = breakDownDecimalInTime({ time: getHours() })
      const miliSecondsLeft = (60 - secongs) * 1000

      interval = setInterval(() => {
        setTime((prevTime) => prevTime + MINUTE_IN_DECIMAL)
      }, miliSecondsLeft)

      timeOut = setTimeout(() => {
        clearInterval(interval)
        interval2 = setInterval(() => {
          setTime((prevTime) => prevTime + MINUTE_IN_DECIMAL)
        }, 60000)
      }, miliSecondsLeft)
    }

    return () => {
      clearInterval(interval)
      clearInterval(interval2)
      clearTimeout(timeOut)
    }
  }, [isRunning])

  const handleStartStop = () => {
    onRunTime({ run: !isRunning, idTimer: timeEntry.id })
    setIsRunning(!isRunning)
  }

  const getRotateStyle = () => {
    if (isRunning) {
      return {
        animation: 'rotateThreeSixty 3s infinite linear',
        transformOrigin: '50% 50%'
      }
    } else {
      return {}
    }
  }

  return (
    <div className="timer">
      <p id="timeTrackerTimer" className="m-0 d-flex align-items-center">
        {decimalToHours({ time })}
      </p>

      {children}

      <Popover
        hover
        showChildren={isRunning}
        direction="top-right"
        customButton={
          <button className={`timer_btn btn ${btnStart}`} onClick={handleStartStop} disabled={disabled}>
            <div className="col-auto pe-0">
              {isRunning
                ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256">
                  <circle
                    cx="128"
                    cy="128"
                    r="96"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="24"
                  />
                  <polyline
                    style={getRotateStyle()}
                    points="128 128 128 72"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="24"
                  />
                  <polyline
                    points="128 128 184 128"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="24"
                  />
                </svg>
                  )
                : (
                <Play />
                  )}
            </div>

            <span data-test-id={isRunning ? 'timeTrackerStop' : 'timeTrackerStart'} id="timeTrackerIndicatorRun">
              {isRunning ? 'Stop' : 'Start'}
            </span>
          </button>
        }>
        <div className="timer_detial">
          Timer started at {DateTime.fromISO(timeEntry?.started_time).setZone('local').toFormat('h:mm a')}<br/>
          {timeEntry?.hours
            ? `with ${decimalToHours({ time: timeEntry?.hours })} hrs (${decimalToHours({
                time: time - timeEntry?.hours
              })} hrs ago)`
            : ''}
        </div>
      </Popover>
    </div>
  )
}

export default Timer
