import { Funnel } from '@phosphor-icons/react'
import { COLOR_BLACK } from 'constant/colors'
import { useState } from 'react'
import ContentModalFilter from './ContentModalFilter'

const ModalFilters = ({ children, onFilter, onClear, disabled }) => {
  const [openModal, setOpenModal] = useState(false)

  return (
    <>
      <button type="button" className="btn btn-secondary w-100" onClick={() => setOpenModal(true)}>
        <Funnel className="icon" size={15} color={COLOR_BLACK} weight="bold" /> <span>Filter</span>
      </button>

      <ContentModalFilter visible={openModal} setVisible={setOpenModal} onFilter={onFilter} onClear={onClear} disabled={disabled}>
        {children}
      </ContentModalFilter>
    </>
  )
}

export default ModalFilters
