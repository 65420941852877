import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import useModal from '../../../Hooks/useModal'
import { Trash } from '@phosphor-icons/react'
import { COLOR_RED } from 'constant/colors'
import { useUser } from 'Hooks'
import { Loading } from 'Organisms/Loading'

const DeleteUserModal = ({ visible, setVisible, selectedUser, reloadUsers }) => {
  const { Modal, hide } = useModal({ identifier: 'deleteuser', visible, setVisible })

  const { handleSubmit, reset } = useForm()
  const { loadingUserHook, deleteUserHook } = useUser()

  useEffect(() => {
    reset(selectedUser)
  }, [selectedUser, reset])

  const onSubmit = async () => {
    try {
      await deleteUserHook({ id: selectedUser.id })
      await reloadUsers()
      hide()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Modal>
      <>
        {loadingUserHook && <Loading />}

        <div className="modal-content" style={{ maxWidth: 500 }}>
          <form onSubmit={handleSubmit(onSubmit)} className="modal-body p-5">
            <div className="row justify-content-center">
              <Trash size={56} color={COLOR_RED} weight="bold" />
            </div>
            <div className="row justify-content-center py-4">
              <p className="text-center">
                Are you sure you want to delete {selectedUser.firstname} {selectedUser.lastname} ({selectedUser.email})
                ?{' '}
              </p>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <button type="button" className="btn btn-secondary mr-2" onClick={hide}>
                  Cancel
                </button>
              </div>
              <div className="col-auto">
                <button type="submit" onSubmit={handleSubmit(onSubmit)} className="btn btn-danger">
                  Delete
                </button>
              </div>
            </div>
          </form>
        </div>
      </>
    </Modal>
  )
}

export default DeleteUserModal
