import useSessionStore from 'Context/useSessionStore'
import { COLORS_GRAFICH } from 'Utils/const/profile'
import { countDecimalsAndAdd0 } from 'Utils/helperFunctions'
import { COLOR_CONTRACT_BLACK, COLOR_WHITE, GET_COLOR_BY_THEME } from 'constant/colors'

const CustomBarDashboard = ({ x, y, width, height, index, hours, totalHours, isTablet }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const theHoursAreLessThen30Percent = (hours * 100) / totalHours < 20

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={COLORS_GRAFICH[index]}
        rx={4}
        ry={4}
        stroke={COLOR_CONTRACT_BLACK}
        strokeWidth="1"
      />

      <text
        x={isTablet ? (theHoursAreLessThen30Percent ? width + x + 10 : x + 10) : x + 10}
        y={isTablet ? y + height / 2 - 10 : theHoursAreLessThen30Percent ? y - 25 : y + 25}
        fontSize="14"
        fill={theHoursAreLessThen30Percent ? GET_COLOR_BY_THEME({ theme: user.theme }).TERTIARY : COLOR_WHITE}
        textAnchor="start">{`${countDecimalsAndAdd0({
        number: hours
      })}h`}</text>
      <text
        x={isTablet ? (theHoursAreLessThen30Percent ? width + x + 10 : x + 10) : x + 10}
        y={isTablet ? y + height / 2 + 10 : theHoursAreLessThen30Percent ? y - 6 : y + 48}
        fontSize="14"
        fill={theHoursAreLessThen30Percent ? GET_COLOR_BY_THEME({ theme: user.theme }).TERTIARY : COLOR_WHITE}
        textAnchor="start">{`(${countDecimalsAndAdd0({
        number: (hours * 100) / totalHours
      })}%)`}</text>
    </g>
  )
}

export default CustomBarDashboard
