import Buttons from './Buttons'
import Color from './Color'
import Forms from './Forms'
import Navs from './Navs'
import Tables from './Tables'
import Typography from './Typography'

const DesignSystem = () => {
  return (
    <div className="container design-system">
      <h1 className="pt-5 mb-5">
        Design System <small className="h5 text-secondary">v1.0</small>
      </h1>
      <Typography />
      <Color />
      <Buttons />
      <section className="col-12 my-5">
        <h2 className="mt-5">Badges</h2>
        <hr />
        <div className="row">
          <div className="col">
            <span className="badge text-bg-primary">Primary</span>
          </div>
          <div className="col">
            <span className="badge text-bg-secondary">Secondary</span>
          </div>
          <div className="col">
            <span className="badge text-bg-terciary">Terciary</span>
          </div>
          <div className="col">
            <span className="badge text-bg-success">Success</span>
          </div>
          <div className="col">
            <span className="badge text-bg-warning">Warning</span>
          </div>
          <div className="col">
            <span className="badge text-bg-info">Info</span>
          </div>
          <div className="col">
            <span className="badge text-bg-danger">Danger</span>
          </div>
        </div>
      </section>
      <Forms />
      <Navs />
      <Tables />
    </div>
  )
}

export default DesignSystem
