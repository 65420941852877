// import { useState } from 'react'
import { MagnifyingGlass } from '@phosphor-icons/react'
import useSessionStore from 'Context/useSessionStore'
import { GET_COLOR_BY_THEME } from 'constant/colors'

const SearchInput = ({ className = '', name, defaultValue = '', onChange = () => {} }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  return (
    <div className={'col-12'}>
      <div className={'searchInput'}>
        <input
          name={name}
          type="search"
          className={`${className}`}
          placeholder="Search"
          id="searchFilter"
          defaultValue={defaultValue}
          onChange={onChange}
        />
        <MagnifyingGlass size={18} color={GET_COLOR_BY_THEME({ theme: user.theme }).SECONDARY} weight="bold" />
      </div>
    </div>
  )
}

export default SearchInput
