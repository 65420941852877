import { useDevops } from 'Hooks'
import { IntegrationCard } from '../Components/IntegrationCard'
import AzureLogo from 'Assets/images/azureDevops.svg'
import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { ModalConnectAzure } from './Components'
import { Loading } from 'Organisms/Loading'
import { ModalSuccess } from 'Organisms/ModalSuccess'
import ModalConfirmOrCancel from 'Organisms/ModalConfirmOrCancel'
import { Plugs } from '@phosphor-icons/react'
import { COLOR_RED } from 'constant/colors'
import { FIELD_CONNECT_AZURE } from './Components/ModalConnectAzure'

export const AZURE_INTEGRATIONS = {
  id: 'azure',
  logo: AzureLogo,
  title: 'Azure Devops',
  description:
    'Azure DevOps Server is a Microsoft product that provides version control, reporting, requirements management, project management, automated builds, testing and release management capabilities.'
}

const AzureIntegration = ({ project, refreshProjectInfo }) => {
  const [searchParams] = useSearchParams()
  const authorizedAzure = searchParams.get('authorized')

  const [connectAzure, setConnectAzure] = useState(false)
  const [message, setMessage] = useState({ open: false })
  const [deleteIntegration, setDeleteIntegration] = useState(null)
  const [editIntegration, setEditIntegration] = useState(null)

  const { loadingDevopsHook, connectAzureHook, postConnectAzureHook, disconnectAzureIntegrationsHook } = useDevops()

  const handleConnectAzure = async () => {
    const res = await connectAzureHook({ projectId: project.id })

    if (!res.success) return

    window.location.href = res.data
  }

  const handleConnect = async ({ body }) => {
    const res = await postConnectAzureHook({ projectId: project.id, body })

    if (!res.success) {
      return setMessage({
        open: true,
        error: true,
        message: res.message
      })
    }

    await refreshProjectInfo()

    setMessage({
      open: true,
      message: 'Your connection to Azure was successful!'
    })
  }

  const handleConnectIntegrations = ({ edit }) => {
    setEditIntegration({
      [FIELD_CONNECT_AZURE.ORGANIZATION_NAME]: edit.domain,
      [FIELD_CONNECT_AZURE.PROJECT_ID]: edit.devops_project_id,
      [FIELD_CONNECT_AZURE.PROJECT_NAME]: edit.devops_project_name
    })
  }

  const handleConfirmationDeleteIntegration = () => setDeleteIntegration(true)

  const handleDeleteIntegration = async () => {
    const res = await disconnectAzureIntegrationsHook({ projectId: project.id })

    if (!res.success) {
      return setMessage({
        open: true,
        error: true,
        message: 'An error occurred while connecting to Azure. Please try again later.'
      })
    }

    await refreshProjectInfo()
    setDeleteIntegration(null)
    setMessage({
      open: true,
      message: 'The integration has been successfully removed!'
    })
  }

  useEffect(() => {
    if (authorizedAzure) setConnectAzure(true)
  }, [])

  return (
    <>
      {loadingDevopsHook && <Loading />}

      <div className="col-12">
        <div className="card p-5">
          <IntegrationCard
            logo={AZURE_INTEGRATIONS.logo}
            title={AZURE_INTEGRATIONS.title}
            description={AZURE_INTEGRATIONS.description}
            onConnect={handleConnectAzure}
            integrationInfo={(project?.integrations || []).find((integ) => integ.type === AZURE_INTEGRATIONS.id && integ.completed === true)}
            onEdit={handleConnectIntegrations}
            onDelete={handleConfirmationDeleteIntegration}
          />
        </div>
      </div>

      {connectAzure && (
        <ModalConnectAzure
          onClose={() => setConnectAzure(false)}
          logo={AZURE_INTEGRATIONS.logo}
          project={project}
          onConnect={handleConnect}
          edit={editIntegration}
        />
      )}

      {deleteIntegration && (
        <ModalConfirmOrCancel
          title="Are you sure you want to disconnect this integration ?"
          setVisible={() => setDeleteIntegration(null)}
          btnConfirm="Disconnect"
          onConfirm={handleDeleteIntegration}
          icon={<Plugs size={56} color={COLOR_RED} weight="bold" />}
          style="danger"
        />
      )}

      {message.open && (
        <ModalSuccess
          visible={true}
          setVisible={() => setMessage({ open: false })}
          title={message.message}
          identifier="modalSuccessIntegration"
          error={message.error}
        />
      )}
    </>
  )
}

export default AzureIntegration
