import axios from 'axios'

const BASE_URL = '/tasks'

const createTaskService = async ({ taskName }) =>
  axios
    .post(BASE_URL, {
      name: taskName
    })
    .then(({ data }) => data)

const updateTask = async (body, id) => axios.put(`${BASE_URL}/${id}`, { ...body }).then(({ data }) => data)

const getTask = async (id) => axios.get(`${BASE_URL}/${id}`).then(({ data }) => data)

const getTasksService = async ({ query }) => axios.get(`${BASE_URL}?${query}`).then(({ data }) => data)

const deleteTask = async (id) => axios.delete(`${BASE_URL}/${id}`).then(({ data }) => data)

export { createTaskService, updateTask, getTask, getTasksService, deleteTask }
