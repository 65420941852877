import { useState, useEffect, useCallback } from 'react'
import { Helmet } from 'react-helmet'

// Hooks
import UserModal from './Components/UserModal'
import { useCompany, useDebounce, useUser } from 'Hooks'

// Componentes
import { Pagination } from '../../Organisms'
import DeleteUserModal from './Components/DeleteUserModal'
import DeactivateUserModal from './Components/DeactivateUserModal'
import { SuggestionPopupToUpgradePlan } from '../../Organisms/SuggestionPopupToUpgradePlan'

// Utils
import { ROLES_BY_DISPLAY } from 'constant/roles'
import { Loading } from 'Organisms/Loading'
import DownloadAndCreateUser from './Components/DownloadAndCreateUser'
import HeaderUserPage from './Components/HeaderUserPage'
import TableUserList from './Components/TableUserList'
import { BREAKPOINT } from 'Utils/const/breakPoints'
import { useMediaQuery } from 'react-responsive'
import TabsListUserTable from './Components/TabsListUserTable'
import { ModalSuccess } from 'Organisms/ModalSuccess'

// Const

export const TABS_USER = {
  ACTIVE: {
    name: 'active users'
  },
  ARCHIVED: { name: 'archived users' }
}

const Users = () => {
  // Media query
  const isMobile = useMediaQuery({
    query: `(max-width: ${BREAKPOINT.MOBILE})`
  })

  // State
  const [searchQuery, setSearchQuery] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [activeTab, setActiveTab] = useState(TABS_USER.ACTIVE.name)
  const [visible, setVisible] = useState(false)
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const [visibleDeactivateModal, setVisibleDeactivateModal] = useState(false)
  const [visibleProfessionalModal, setVisibleProfessionalModal] = useState(false)
  const [filter, setFilter] = useState({})
  const [rowPerPage, setRowPerPage] = useState(10)
  const [selectedUser, setSelectedUser] = useState(null)
  const [modalMessage, setModalMessage] = useState({ open: false })

  // Hooks
  const {
    listUsers,
    countListUsersHook,
    userInfoHook,
    loadingUserHook,
    getUsersHook,
    getUserByIdHook,
    clearUserHook,
    downloadCsvHook
  } = useUser()

  const { loadingCompanyHook, isAllowedCreateUserHook } = useCompany()

  // Handlers
  const getRoles = ({ roles }) => {
    return roles
      .map((item) => {
        if (item === 'All') return ''

        return ROLES_BY_DISPLAY[item]
      })
      .filter((x) => x)
  }

  const fetchUsers = async ({
    search = searchQuery,
    filterP = filter,
    limit = rowPerPage,
    page = currentPage,
    isActive = activeTab
  } = {}) => {
    try {
      await getUsersHook({
        userActive: isActive === TABS_USER.ACTIVE.name,
        search,
        page,
        roles: getRoles({ roles: filterP?.Role || [] }),
        limit
      })
    } catch (e) {
      console.error(e)
    }
  }

  const validAllowedUser = async () => {
    const result = await isAllowedCreateUserHook()

    if (!result.success) throw Error()

    if (!result.data.allowed) {
      setVisibleProfessionalModal(true)
      throw Error()
    }
  }

  const openUserModal = async (userId) => {
    try {
      if (userId) {
        await getUserByIdHook({ idUser: userId })
      } else {
        await validAllowedUser()
        clearUserHook()
      }
      setVisible(true)
    } catch (e) {}
  }

  const openDeactivateOrActiveOrDeleteUserModal = async ({ user, deleteU = false } = {}) => {
    try {
      setSelectedUser(user)

      if (deleteU) return setVisibleDeleteModal(true)

      activeTab === TABS_USER.ARCHIVED.name && await validAllowedUser()
      setVisibleDeactivateModal(true)
    } catch (e) {
      console.error(e)
    }
  }

  const searchInputValue = ({ search }) => {
    setSearchQuery(search)
    filterDebounce({ search })
  }

  const filterDebounce = useCallback(
    useDebounce(({ search }) => {
      fetchUsers({ search, page: 1 })
      setCurrentPage(1)
    }, 1000),
    [filter, rowPerPage, activeTab]
  )

  const handleFilterList = ({ result }) => {
    setFilter(result)
    setCurrentPage(1)
    fetchUsers({ filterP: result, page: 1 })
  }

  const handleChangeRowPerPage = ({ rows }) => {
    setRowPerPage(rows)
    setCurrentPage(1)
    fetchUsers({ limit: rows, page: 1 })
  }

  const handleChangePage = (page) => {
    setCurrentPage(page)
    fetchUsers({ page })
  }

  const handleChangeTab = ({ tab }) => {
    setActiveTab(tab)
    setCurrentPage(1)
    fetchUsers({ isActive: tab, page: 1 })
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  return (
    <>
      <Helmet>
        <title>Users</title>
      </Helmet>
      {(loadingUserHook || loadingCompanyHook) && <Loading />}

      <div className="containers users pt-4">
        <div className="row justify-content-between align-items-center gy-4">
          {isMobile && <TabsListUserTable tabs={TABS_USER} activeTab={activeTab} onChange={handleChangeTab} />}

          <div className="col-lg-8 col-sm-6 col-12">
            <HeaderUserPage onSearch={searchInputValue} onFilter={handleFilterList} />
          </div>
          <div className="col-sm-4 col-12">
            <DownloadAndCreateUser onOpen={openUserModal} onDownload={downloadCsvHook} />
          </div>
        </div>

        <hr />

        {!isMobile && <TabsListUserTable tabs={TABS_USER} activeTab={activeTab} onChange={handleChangeTab} />}

        <div className="row justify-content-center">
          <TableUserList
            listUsers={listUsers}
            activeTab={activeTab}
            openUserModal={openUserModal}
            onDelete={openDeactivateOrActiveOrDeleteUserModal}
          />

          <Pagination
            currentPage={currentPage}
            setCurrentPage={handleChangePage}
            totalCount={countListUsersHook}
            pageSize={rowPerPage}
            changeRowPerPage={handleChangeRowPerPage}
          />
        </div>
      </div>

      {visible && (
        <UserModal
          visible={visible}
          setVisible={setVisible}
          selectedUser={userInfoHook}
          reloadUsers={() => fetchUsers()}
        />
      )}

      {visibleDeleteModal && (
        <DeleteUserModal
          visible={visibleDeleteModal}
          setVisible={setVisibleDeleteModal}
          selectedUser={selectedUser}
          reloadUsers={() => fetchUsers()}
        />
      )}

      {visibleDeactivateModal && (
        <DeactivateUserModal
          visible={visibleDeactivateModal}
          setVisible={setVisibleDeactivateModal}
          selectedUser={selectedUser}
          reloadUsers={() => fetchUsers()}
          onError={({ data }) => setModalMessage(data)}
        />
      )}

      {modalMessage.open && (
        <ModalSuccess
          error={modalMessage.error}
          setVisible={() => setModalMessage({ open: false })}
          visible={true}
          identifier="modalMessageUser"
          title={modalMessage.message}
        />
      )}

      {visibleProfessionalModal && (
        <SuggestionPopupToUpgradePlan setVisible={() => setVisibleProfessionalModal(false)} visible={true} />
      )}
    </>
  )
}

export default Users
