import { useModal } from 'Hooks'
import { CaretRight } from '@phosphor-icons/react'
import { COLOR_LIGHT_BLUE } from 'constant/colors'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { OPTIONS_PRINCING } from 'Utils/const/packages'
import { BILLING_POLICY_ROUTER } from 'constant/router'

const ManageSeatsModal = ({ onClose, seats, period, onSubmit, countUserActive }) => {
  const { Modal, hide } = useModal({ identifier: 'manageSeatsModal', visible: true, setVisible: onClose })
  const [quantity, setQuantity] = useState(seats)

  const changeSeats = ({ seats }) => {
    if (seats < 0 && quantity + seats < (countUserActive || 1)) return

    setQuantity(quantity + seats)
  }

  const handleSubmit = async () => {
    await onSubmit({ seats: quantity })
    hide()
  }

  return (
    <Modal center>
      <div className="modal-content modalSuccess p-5" style={{ maxWidth: 600 }}>
        <button type="button" className="btn-close btnCloseModal" onClick={hide} aria-label="Close" />
        <h3 className="fs-3 mb-4">Manage Subscription Seats</h3>
        <p className="mb-3">Adjust the number of seats in your plan to accommodate your team size.</p>
        <div className="row mt-3 mb-4 justify-content-between align-items-center">
          <div className="col-auto">
            <h5>Update Seats</h5>
            <p>
              <CaretRight size={12} weight="bold" color={COLOR_LIGHT_BLUE} className="me-1" />
              Each additional seat is <span className="text-active">${OPTIONS_PRINCING[period].price}/mo</span>
            </p>
            <p>
              <CaretRight size={12} weight="bold" color={COLOR_LIGHT_BLUE} className="me-1" />
              Current seats: <span className="text-active">{seats}</span>
            </p>
          </div>
          <div className="col-auto" style={{ maxWidth: '180px' }}>
            <div className="input-group number-input">
              <button type="button" className="btn btn-outline-secondary" onClick={() => changeSeats({ seats: -1 })}>
                -
              </button>
              <input type="number" className="form-control seats-input text-center" value={quantity} readOnly />
              <button type="button" className="btn btn-outline-secondary" onClick={() => changeSeats({ seats: 1 })}>
                +
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-auto">
            <p className="small">
              Changes to the number of seats are prorated. This means you&apos;ll be charged only for the time the seats
              are active during the billing cycle. For more details, see our{' '}
              <Link className="text-active" to={BILLING_POLICY_ROUTER}>
                billing policy
              </Link>{' '}
              or contact customer support.
            </p>
          </div>
        </div>
        <div className="row justify-content-end mt-5">
          <div className="col-auto">
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={() => {
                hide()
              }}>
              Cancel
            </button>
          </div>
          <div className="col-auto">
            <button type="submit" onClick={() => handleSubmit()} className="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ManageSeatsModal
