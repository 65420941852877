import { useCallback, useContext, useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { Helmet } from 'react-helmet'

import ContextTutorial from 'Context/tutorialContext'

// Components
import { TableTimeSheetCondensed } from './TableTimeSheetCondensed'
import { TableTimeSheetAll } from './TableTimeSheetAll'
import {
  countDecimalsAndAdd0,
  downloadCSVasPDF,
  getEndWeek,
  getStartWeek,
  getValuePerFilter
} from 'Utils/helperFunctions'
import { useDebounce, useTimeEntries } from 'Hooks'
import SearchInput from 'Organisms/SearchInput'
import { CustomDatePicker } from 'Organisms/CustomDatePicker'
import ContextColumnTimeSheet from 'Context/ColumnsTimeSheetContext'
import ContextColumnTimeEntry from 'Context/ColumnsTimeEntryContext'
import { Loading } from 'Organisms/Loading'
import { DistributionOfHours } from 'Organisms/DistributionOfHours'
// import useSessionStore from 'Context/useSessionStore'
// import { ROLE_DEVELOPER_NAME } from 'constant/roles'
import { FiltersTimesheet } from './FiltersTimesheet'
import { ModalSuccess } from 'Organisms/ModalSuccess'
import { ModalFiltersTimeSheetMobile } from './Components'
import { useMediaQuery } from 'react-responsive'
import { BREAKPOINT } from 'Utils/const/breakPoints'
import { DetailsTimeEntry } from 'Organisms/DetailsTimeEntry'
import { useLocation, useNavigate } from 'react-router-dom'
import { PARAMS_URL_TIMESHEET, ONBOARDING_STEPS_TRACKER } from './utils/const'
import { getQueryParamsTimesheet } from './utils/helpersTimeSheet'
import { Pagination } from 'Organisms'
import { OnboardingTooltip } from 'Organisms/Onboarding'

const INIT_MODAL_MESSAGE = {
  open: false,
  message: '',
  success: false
}

const Timesheet = () => {
  // Media query
  const isMobile = useMediaQuery({
    query: `(max-width: ${BREAKPOINT.MOBILE})`
  })
  const { openTutorialContext } = useContext(ContextTutorial)
  const location = useLocation()
  const navigate = useNavigate()
  const {
    queryParams,
    periodQuery,
    startDateQuery,
    endDateQuery,
    isDetailQuery,
    searchQuery,
    projectQuery,
    tasksQuery,
    statesQuery,
    usersQuery,
    pageQuery,
    limitQuery
  } = getQueryParamsTimesheet({
    location
  })

  // Context
  // const { user } = useSessionStore((state) => ({ user: state.user }))
  const { getColumnsContext } = useContext(ContextColumnTimeSheet)
  const { getColumnsTimeEntry } = useContext(ContextColumnTimeEntry)

  const [startDay, setStartDay] = useState(startDateQuery ? DateTime.fromISO(startDateQuery) : getStartWeek())
  const [endDay, setEndDay] = useState(
    endDateQuery
      ? DateTime.fromISO(endDateQuery).set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
      : getEndWeek()
  )

  // const [totalHoursWork, setTotalHoursWork] = useState(0)
  // const [totalWarrantyWork, setTotalWarrantyWork] = useState(0)
  const [isCondensed, setCondensed] = useState(!isDetailQuery)
  const [modalMessage, setModalMessage] = useState(INIT_MODAL_MESSAGE)
  const [loadingInternal, setLoadingIntertal] = useState(false)
  const [openDetailTimeEntry, setOpenDetailTimeEntry] = useState(null)

  // Hooks
  const {
    listTimeSheetsHook,
    listTimeEntries,
    listGroupTimeEntries,
    loadingTimeEntriesHook,
    countTotalItemList,
    totalAllBillable,
    totalAllWarrenty,
    getTimeSheetsHook,
    getTimeEntriesHook,
    downloadCsvByTimesheetHook,
    downloadCsvByTimeentriesHook
  } = useTimeEntries()

  // Handlers
  const updateParamsUrlTimeSheet = ({
    startDate,
    endDate,
    users,
    projects,
    tasks,
    states,
    search,
    sprints,
    isCondensed,
    page,
    limit
  }) => {
    queryParams.set(
      PARAMS_URL_TIMESHEET.FRONT_TO_DATE,
      `${startDate.toFormat('yyyy-MM-dd')}:${endDate.toFormat('yyyy-MM-dd')}`
    )

    users ? queryParams.set(PARAMS_URL_TIMESHEET.USERS, users) : queryParams.delete(PARAMS_URL_TIMESHEET.USERS)
    projects
      ? queryParams.set(PARAMS_URL_TIMESHEET.PROJECTS, projects)
      : queryParams.delete(PARAMS_URL_TIMESHEET.PROJECTS)
    tasks ? queryParams.set(PARAMS_URL_TIMESHEET.TASKS, tasks) : queryParams.delete(PARAMS_URL_TIMESHEET.TASKS)
    states ? queryParams.set(PARAMS_URL_TIMESHEET.STATES, states) : queryParams.delete(PARAMS_URL_TIMESHEET.STATES)
    search ? queryParams.set(PARAMS_URL_TIMESHEET.SEARCH, search) : queryParams.delete(PARAMS_URL_TIMESHEET.SEARCH)
    sprints ? queryParams.set(PARAMS_URL_TIMESHEET.SPRINT, sprints) : queryParams.delete(PARAMS_URL_TIMESHEET.SPRINT)

    queryParams.set(PARAMS_URL_TIMESHEET.PAGE, page)
    queryParams.set(PARAMS_URL_TIMESHEET.LIMIT, limit)

    isCondensed
      ? queryParams.delete(PARAMS_URL_TIMESHEET.IS_DETAILS)
      : queryParams.set(PARAMS_URL_TIMESHEET.IS_DETAILS, 'y')

    navigate(`?${queryParams.toString()}`, { replace: true })
  }

  const getTimeSheetCondensed = async ({
    startDate,
    endDate,
    users,
    projects,
    tasks,
    search,
    states,
    sprints,
    isStringFilter,
    page = pageQuery,
    limit = limitQuery
  }) => {
    const body = {
      startDate,
      endDate,
      users: isStringFilter ? users : getValuePerFilter({ list: users }),
      projects: isStringFilter ? projects : getValuePerFilter({ list: projects, byValue: 'id' }),
      tasks: isStringFilter ? tasks : getValuePerFilter({ list: tasks }),
      states: isStringFilter ? states : getValuePerFilter({ list: states }),
      sprints: isStringFilter ? sprints : getValuePerFilter({ list: sprints }),
      search: search ?? searchQuery,
      page,
      limit
    }

    await getTimeSheetsHook(body)

    updateParamsUrlTimeSheet({ ...body, isCondensed: true })

    // const totalHours = result
    //   ? result.reduce((acc, item) => {
    //     return acc + (user.role === ROLE_DEVELOPER_NAME ? item?.my_work ?? 0 : item?.completed_work ?? 0)
    //   }, 0)
    //   : 0

    // const totalWarranty = result
    //   ? result.reduce((acc, item) => {
    //     return acc + (item?.warranty_work ?? 0)
    //   }, 0)
    //   : 0

    // setTotalHoursWork(countDecimalsAndAdd0({ number: totalHours }))
    // setTotalWarrantyWork(countDecimalsAndAdd0({ number: totalWarranty }))
  }

  const getTimeSheetAll = async ({
    startDate,
    endDate,
    users,
    projects,
    tasks,
    states,
    sprints,
    search,
    isStringFilter,
    page = pageQuery,
    limit = limitQuery
  }) => {
    const body = {
      startDate,
      endDate,
      users: isStringFilter ? users : getValuePerFilter({ list: users }),
      projects: isStringFilter ? projects : getValuePerFilter({ list: projects, byValue: 'id' }),
      tasks: isStringFilter ? tasks : getValuePerFilter({ list: tasks }),
      states: isStringFilter ? states : getValuePerFilter({ list: states }),
      sprints: isStringFilter ? sprints : getValuePerFilter({ list: sprints }),
      search: search ?? searchQuery,
      page,
      limit
    }

    await getTimeEntriesHook(body)

    updateParamsUrlTimeSheet({ ...body, isCondensed: false })

    // const totalHours = result.reduce((acc, item) => {
    //   return acc + (item?.hours ?? 0)
    // }, 0)

    // const totalWarranty = result.reduce((acc, item) => {
    //   return acc + (item.is_warranty ? item.hours : 0)
    // }, 0)

    // setTotalHoursWork(countDecimalsAndAdd0({ number: totalHours }))
    // setTotalWarrantyWork(countDecimalsAndAdd0({ number: totalWarranty }))
  }

  const handleChangeFilterMobile = async ({ condensed, filterP } = {}) => {
    try {
      setLoadingIntertal(true)

      if (condensed !== isCondensed && !condensed) await getColumnsTimeEntry()

      setCondensed(condensed)

      const body = {
        startDate: startDay,
        endDate: endDay,
        page: 1,
        ...filterP
      }

      condensed ? await getTimeSheetCondensed(body) : await getTimeSheetAll(body)
    } finally {
      setLoadingIntertal(false)
    }
  }

  const handleChangeCondensed = async ({ condensed }) => {
    try {
      setLoadingIntertal(true)

      if (condensed === isCondensed) return

      if (!condensed) await getColumnsTimeEntry()

      setCondensed(condensed)

      const body = {
        startDate: startDay,
        endDate: endDay,
        isStringFilter: true,
        projects: projectQuery,
        tasks: tasksQuery,
        users: usersQuery,
        states: statesQuery
      }

      condensed ? await getTimeSheetCondensed(body) : await getTimeSheetAll(body)
    } finally {
      setLoadingIntertal(false)
    }
  }

  const handleChangeFilter = async ({ filter } = {}) => {
    const body = { startDate: startDay, endDate: endDay, page: 1, ...filter }

    isCondensed ? await getTimeSheetCondensed(body) : await getTimeSheetAll(body)
  }

  const handleChangeSearch = ({ searchParam }) => {
    filterDebounce({ search: searchParam })
  }

  const filterDebounce = useCallback(
    useDebounce(({ search }) => {
      const body = {
        startDate: startDay,
        endDate: endDay,
        search,
        isStringFilter: true,
        projects: projectQuery,
        tasks: tasksQuery,
        users: usersQuery,
        states: statesQuery,
        page: 1
      }

      isCondensed ? getTimeSheetCondensed(body) : getTimeSheetAll(body)
    }, 1000),
    [isCondensed, startDay, endDay, projectQuery, tasksQuery, usersQuery, statesQuery, searchQuery, limitQuery]
  )

  const handleSearchDataByDate = async ({ startDay, endDay, period }) => {
    setStartDay(startDay)
    setEndDay(endDay)

    queryParams.set(PARAMS_URL_TIMESHEET.PERIOD, period)
    navigate(`?${queryParams.toString()}`, { replace: true })

    const body = {
      isStringFilter: true,
      projects: projectQuery,
      tasks: tasksQuery,
      users: usersQuery,
      states: statesQuery,
      startDate: startDay,
      endDate: endDay
    }

    isCondensed ? await getTimeSheetCondensed(body) : await getTimeSheetAll(body)
  }

  const downloadConsolidateCSV = async () => {
    const res = await downloadCsvByTimesheetHook({
      startDate: startDay,
      endDate: endDay,
      users: usersQuery,
      projects: projectQuery,
      tasks: tasksQuery,
      states: statesQuery,
      search: searchQuery
    })

    return res
  }

  const downloadDetailsCSV = async () => {
    const res = await downloadCsvByTimeentriesHook({
      startDate: startDay,
      endDate: endDay,
      users: usersQuery,
      projects: projectQuery,
      tasks: tasksQuery,
      states: statesQuery,
      search: searchQuery
    })

    return res
  }

  const handleDowmloadCsv = async () => {
    const res = isCondensed ? await downloadConsolidateCSV() : await downloadDetailsCSV()

    if (!res.success) {
      return setModalMessage({
        open: true,
        message: 'An error has occurred',
        success: false
      })
    }

    const date = DateTime.now().toFormat('yyyy-MM-dd HH-mm-ss')
    await downloadCSVasPDF({
      base64CSV: res.data,
      filename: `${isCondensed ? 'Timesheet Report' : 'Time Entries Report'} ${date}`
    })

    setModalMessage({
      open: true,
      message: 'Your report were created successfuly',
      success: true
    })
  }

  const handleOpenDetailTimeEntry = ({ timeEntryId, title }) =>
    setOpenDetailTimeEntry({
      id: timeEntryId,
      title
    })

  useEffect(() => {
    let promisesList

    const body = {
      startDate: startDay,
      endDate: endDay,
      projects: projectQuery,
      tasks: tasksQuery,
      users: usersQuery,
      states: statesQuery,
      isStringFilter: true
    }

    if (isCondensed) {
      promisesList = [getTimeSheetCondensed(body), getColumnsContext()]
    } else {
      promisesList = [getTimeSheetAll(body), getColumnsTimeEntry()]
    }

    Promise.allSettled(promisesList)
  }, [])

  const createParamsByQuery = ({ limit = limitQuery, page = pageQuery }) => {
    return {
      isStringFilter: true,
      projects: projectQuery,
      tasks: tasksQuery,
      users: usersQuery,
      states: statesQuery,
      startDate: startDay,
      endDate: endDay,
      limit,
      page
    }
  }

  // CHANGE ROW PER PAGE
  const handleChangeRowPerPage = ({ rows }) => {
    const body = createParamsByQuery({ limit: rows, page: 1 })

    if (isCondensed) {
      getTimeSheetCondensed(body)
    } else {
      getTimeSheetAll(body)
    }
  }

  // CHANGE  PAGE
  const handleChangePage = (page) => {
    const body = createParamsByQuery({ page })

    if (isCondensed) {
      getTimeSheetCondensed(body)
    } else {
      getTimeSheetAll(body)
    }
  }

  return (
    <>
      <Helmet>
        <title>Timesheet</title>
      </Helmet>

      {openTutorialContext && <OnboardingTooltip steps={ONBOARDING_STEPS_TRACKER} />}

      <div className="row mt-4">
        {(loadingTimeEntriesHook || loadingInternal) && <Loading />}

        <div className="col-12">
          <CustomDatePicker
            onSearchData={({ startDay, endDay, period }) => handleSearchDataByDate({ startDay, endDay, period })}
            defaultPeriod={periodQuery}
            defaultStartDay={startDay}
            defaultEndDay={endDay}
          />

          <hr />

          <DistributionOfHours
            totalHoursWork={countDecimalsAndAdd0({ number: totalAllBillable + totalAllWarrenty })}
            totalWarrantyWork={countDecimalsAndAdd0({ number: totalAllWarrenty })}>
            <SearchInput
              className="form-control"
              placeholder="Search"
              defaultValue={searchQuery}
              onChange={(e) => handleChangeSearch({ searchParam: e.target.value })}
            />
          </DistributionOfHours>

          {/* FILTERS */}
          <div className="row mb-5">
            <div className={`position-relative ${openDetailTimeEntry?.id && isCondensed ? 'col-xl-8 col-12' : 'col'}`}>
              <div className="timesheet-card p-4 card">
                {isMobile
                  ? (
                  <ModalFiltersTimeSheetMobile
                    onFilter={handleChangeFilterMobile}
                    defaultSelectProject={projectQuery?.split(',')}
                    defaultSelectUsers={usersQuery?.split(',')}
                    defaultSelectTasks={tasksQuery?.split(',')}
                    defaultSelectStatus={statesQuery?.split(',')}
                  />
                    )
                  : (
                  <FiltersTimesheet
                    onChangeFilter={handleChangeFilter}
                    isCondensed={isCondensed}
                    onChangeTypeTable={handleChangeCondensed}
                    onDownloadCSV={handleDowmloadCsv}
                    defaultSelectProject={projectQuery?.split(',')}
                    defaultSelectUsers={usersQuery?.split(',')}
                    defaultSelectTasks={tasksQuery?.split(',')}
                    defaultSelectStatus={statesQuery?.split(',')}
                  />
                    )}

                {!loadingInternal && (
                  <>
                    {isCondensed
                      ? (
                      <TableTimeSheetCondensed
                        dataList={listTimeSheetsHook || []}
                        onOpenDetail={handleOpenDetailTimeEntry}
                      />
                        )
                      : (
                      <TableTimeSheetAll dataList={listTimeEntries} listGroupTimeEntries={listGroupTimeEntries} />
                        )}
                  </>
                )}
              </div>

              <div className="my-4">
                <Pagination
                  currentPage={Number(pageQuery)}
                  setCurrentPage={handleChangePage}
                  totalCount={countTotalItemList}
                  pageSize={Number(limitQuery)}
                  changeRowPerPage={handleChangeRowPerPage}
                />
              </div>
            </div>

            {openDetailTimeEntry?.id && isCondensed && (
              <div className="col-xl-4 col-12">
                <DetailsTimeEntry
                  onClose={() => setOpenDetailTimeEntry(null)}
                  title={openDetailTimeEntry.title}
                  idEntry={openDetailTimeEntry.id}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {modalMessage.open && (
        <ModalSuccess
          visible={true}
          setVisible={() => setModalMessage(INIT_MODAL_MESSAGE)}
          title={modalMessage.message}
          identifier="modalMessageApproved"
          error={!modalMessage.success}
        />
      )}
    </>
  )
}

export default Timesheet
