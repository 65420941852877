import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getMe } from '../Services'
import {
  signIn
} from '../Hooks/useAuth'
import useSessionStore from '../Context/useSessionStore'
import { Eye, EyeSlash, WarningOctagon } from '@phosphor-icons/react'
import { COLOR_RED } from 'constant/colors'
import {
  DASHBAORD_ROUTER,
  FORGOT_PASSWORD_ROUTER,
  PRICING_ROUTER,
  RESET_PASSWORD_ROUTER,
  SIGNUP_ROUTER
} from 'constant/router'
import { Mixpanel } from 'Utils/Mixpanel'
import { CURRENT_USER_STORAGE } from 'Utils/const/localstorage'
import { Logo } from 'Organisms'
import { Helmet } from 'react-helmet'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [passwordType, setPasswordType] = useState(true)

  const { updateUser, updateChallenge } = useSessionStore(
    (state) => ({
      user: state.user,
      updateUser: state.updateUser,
      updateChallenge: state.updateChallenge
    })
  )

  const navigate = useNavigate()

  const onLoginRedirect = () => {
    navigate(DASHBAORD_ROUTER)
  }

  const onLogin = (e) => {
    e.preventDefault()
    setLoading(true)

    if (email && password) {
      setError('')
      signIn({ email: email.toLowerCase(), password })
        .then(async (res) => {
          if (res.type === 'Success') {
            const { data: me } = await getMe()

            if (me && me.status && me.status === 404) {
              throw Error('Something went wrong. Please contact support')
            }
            if (me && me.status && me.status === 500) {
              throw Error(
                'Sorry, we are not able to connect to the server. Please check your connection or try again later'
              )
            }

            if (me) {
              Mixpanel.people.set({
                $distinct_id: me.id,
                $email: me.email,
                $name: me.firstname + ' ' + me.lastname,
                $first_name: me.firstname,
                $last_name: me.lastname
              })
              Mixpanel.identify(me.id)
              localStorage.setItem(CURRENT_USER_STORAGE, JSON.stringify({ ...me, ...res.user }))
              await updateUser({ ...me, ...res.user })

              if (!me?.company_is_active) return navigate(PRICING_ROUTER)

              onLoginRedirect()
            } else {
              setError(
                'Sorry, we are not able to connect to the server. Please check your connection or try again later'
              )
              setLoading(false)
            }
          }

          if (res.type === 'NewPasswordRequired') {
            updateChallenge(res.result)
            navigate(RESET_PASSWORD_ROUTER)
          }
        })
        .catch((e) => {
          setError(e.message)
        }).finally(() => {
          setLoading(false)
        })
    } else {
      setError('Please provide an email and password')
      setLoading(false)
    }
  }

  // const signInGoogle = async () => {
  //   signUpUsingProvider('Google')
  // }

  return (
    <>
      <Helmet>
          <title>Login</title>
          <meta
            name="description"
            content="Time and task Tracking. Free trial available. Three pricing plans available."
          />
          <meta
            name="keywords"
            content="time tracker, time tracking, remote employees, productivity, software, remote work"
          />
          <meta name="author" content="Pulse by Canned Head" />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content="Plans & Pricing" />
          <meta
            property="og:description"
            content="Time and task Tracking. Free trial available. Three pricing plans available."
          />
          <meta property="og:image" content="https://example.com/image.jpg" />
          <meta property="og:url" content="https://example.com/time-tracking-software" />
          <meta property="og:type" content="website" />
      </Helmet>
      <div className="container login dark-theme">
        <div className="row justify-content-center align-items-center">
          <div className="col-11 col-md-8 col-lg-5">
            <div className="row justify-content-center align-items-center">
              <div className="col-12 col-md-8">
                <div className="row justify-content-center">
                  <div className="col-9 logo-wrapper">
                    <Logo />
                  </div>
                </div>
                <div className="row justify-content-center mt-5">
                  {error && (
                    <div className="col-12">
                      <div className="alert alert-danger" role="alert">
                        <div className="row">
                          <div className="col-auto">
                            <WarningOctagon size={24} color={COLOR_RED} weight="bold" />
                          </div>
                          <div className="col">
                            <p className="mb-0 me-3">{error}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row justify-content-center">
                  <form className="col-12 d-grid" onSubmit={onLogin}>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="inputEmail">
                        Email
                      </label>
                      <input
                        name="email"
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control form-control-outline"
                        id="inputEmail"
                        aria-describedby="emailHelp"
                        placeholder="Email"
                      />
                    </div>
                    <div className="mb-4 position-relative">
                      <label className="form-label" htmlFor="inputPassword">
                        Password
                      </label>
                      <input
                        name="password"
                        type={passwordType ? 'password' : 'text'}
                        onChange={(e) => setPassword(e.target.value)}
                        className={`form-control form-control-outline ${error ? 'is-invalid' : ''}`}
                        id="inputPassword"
                        placeholder="Password"
                      />
                      {error && <div className="invalid-feedback">{error}</div>}
                      <button
                        type="button"
                        className="btn btn-ghost position-absolute eye-button"
                        onClick={() => setPasswordType(!passwordType)}>
                        {!passwordType && <Eye size={25} color="#363A39" weight="regular" />}
                        {passwordType && <EyeSlash size={25} color="#363A39" weight="regular" />}
                      </button>
                    </div>
                    <button type="submit" className="btn btn-block btn-primary" disabled={loading}>
                      {loading && (
                        <>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span className="ms-2">Loading...</span>
                        </>
                      )}
                      {!loading && <span>Log in</span>}
                    </button>
                  </form>
                </div>
                <div className="row mt-4">
                  <div className="col-12 text-center">
                    <a className="forgot-password" href={FORGOT_PASSWORD_ROUTER}>
                      Forgot password
                    </a>
                  </div>
                </div>
                <div className="row mt-5">
                    <div className="col-12 text-center">
                      <p>
                        Don&apos;t have an account?{' '}
                        <Link className="forgot-password" to={SIGNUP_ROUTER}>
                          Sign Up
                        </Link>
                      </p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
