const ColorPaletteItem = ({ name, backgroundColor, textColor }) => {
  return (
    <div className="card">
      <svg
        className="bd-placeholder-img card-img-top"
        width="100%"
        height="180"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="Placeholder: Image cap"
        preserveAspectRatio="xMidYMid slice"
        focusable="false"
      >
        <title>{backgroundColor}</title>
        <rect width="100%" height="100%" fill={backgroundColor}></rect>
        <text fill={textColor} x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
          {name}
        </text>
      </svg>
      <div className="card-body">
        <h5 className="card-title">{name}</h5>
        <p className="card-text">{backgroundColor}</p>
      </div>
    </div>
  )
}

export default ColorPaletteItem
