import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { DotsSixVertical } from '@phosphor-icons/react'

const DraggableListItem = ({ item, onChange }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.id
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <div className="dropdownOrderItems_item">
      <div {...attributes} {...listeners} ref={setNodeRef} style={style} className='dropdownOrderItems_item_all'>
        <div className="d-flex align-items-center gap-1">
          <DotsSixVertical size={17} weight="bold" />
          <p className="form-check-label mx-2">
            {item.text}
          </p>
        </div>
      </div>
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          id={item.id}
          checked={item.checked}
          data-dnd-droppable="false"
          onChange={(e) => onChange({ item, active: e.target.checked })}
          type="checkbox"
          role="switch"
        />
      </div>
    </div>
  )
}

export default DraggableListItem
