import { STATUS_SUBMIT_HOURS } from 'Pages/TimeTracker/utils/const'
import { MessagesTracker } from '../MessagesTracker'
import { Check, Info, Warning } from '@phosphor-icons/react'
import { COLOR_BLUE, COLOR_LIGHT_GREEN, COLOR_YELLOW } from 'constant/colors'

const ErrorsAndAlertsTracker = ({ errorTracker, approval, onSubmit, onDetails }) => {
  const MESSAGES_BY_APPRPOVAL = {
    [STATUS_SUBMIT_HOURS.PENDING]: {
      title: 'Your daily timesheet has been submitted',
      message: "Please wait for approval. Once approved you won't be able to change anything.",
      textBtn: 'Resubmit',
      onClick: () => onSubmit({ resubmit: true }),
      icon: <Info size={24} color={COLOR_BLUE} weight="bold" />,
      status: STATUS_SUBMIT_HOURS.PENDING
    },
    [STATUS_SUBMIT_HOURS.REJECTED]: {
      title: 'Weekly timesheet not approved',
      message: 'For more information please click on the link. Submit as soon as possible.',
      textBtn: 'More details',
      onClick: onDetails,
      icon: <Warning size={24} color={COLOR_YELLOW} weight="bold" />,
      status: STATUS_SUBMIT_HOURS.REJECTED
    },
    [STATUS_SUBMIT_HOURS.APPROVED]: {
      title: 'Weekly timesheet approved',
      message: 'For more information visit submissions.',
      icon: <Check size={24} color={COLOR_LIGHT_GREEN} weight="bold" />,
      status: STATUS_SUBMIT_HOURS.APPROVED
    }
  }

  return (
    <div className="mt-4 d-flex">
        {errorTracker && (
          <div className="alert alert-warning text-center" role="alert">
            {errorTracker}
          </div>
        )}
        {MESSAGES_BY_APPRPOVAL[approval] && (
          <MessagesTracker
            title={MESSAGES_BY_APPRPOVAL[approval].title}
            message={MESSAGES_BY_APPRPOVAL[approval].message}
            textBtn={MESSAGES_BY_APPRPOVAL[approval].textBtn}
            onClick={MESSAGES_BY_APPRPOVAL[approval].onClick}
            icon={MESSAGES_BY_APPRPOVAL[approval].icon}
          />
        )}
    </div>
  )
}

export default ErrorsAndAlertsTracker
