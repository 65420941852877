const MessagesTrackerPending = ({ title, message, textBtn, onClick, icon }) => {
  return (
    <div className="card px-4 py-3 messagesTrackerStatusHours mb-0">
      <div>{icon}</div>
      <div>
        <h6>{title}</h6>
        <p className="mb-0 me-3">{message}</p>
      </div>

      {onClick && (
        <div className="messagesTrackerStatusHours_btn align-self-center">
          <button type="button" className="btn btn-sm btn-secondary rounded" onClick={onClick}>
            {textBtn || 'go'}
          </button>
        </div>
      )}
    </div>
  )
}

export default MessagesTrackerPending
