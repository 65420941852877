import { CaretLeft, CaretRight } from '@phosphor-icons/react'
import useSessionStore from 'Context/useSessionStore'
import { BREAKPOINT } from 'Utils/const/breakPoints'
import { COLOR_BLACK, COLOR_WHITE } from 'constant/colors'
import { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

const Pagination = ({ currentPage, setCurrentPage, totalCount, pageSize, changeRowPerPage }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))
  const isDarkMode = user.theme

  // Media query
  const isMobile = useMediaQuery({
    query: `(max-width: ${BREAKPOINT.MOBILE})`
  })

  const [pageNumbers, setPageNumbers] = useState([])
  const [offset, setOffset] = useState(0)

  const setupPageNumbers = () => {
    const numbers = []
    const maxPage = Math.ceil(totalCount / pageSize) || 1

    let startPage
    let endPage

    if (maxPage <= 5 || (currentPage <= 3)) {
      startPage = 1
      endPage = maxPage <= 5 ? maxPage : 5
    } else {
      if (currentPage + 2 >= maxPage) {
        startPage = maxPage - 4
        endPage = maxPage
      } else {
        startPage = currentPage - 2
        endPage = currentPage + 2
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      numbers.push(i)
    }

    setPageNumbers(numbers)
  }

  const nextPage = () => {
    if (currentPage < Math.ceil(totalCount / pageSize)) {
      setOffset(offset + 1)
      setCurrentPage(currentPage + 1)
    }
  }

  const previousPage = () => {
    if (currentPage > 1) {
      setOffset(offset - 1)
      setCurrentPage(currentPage - 1)
    }
  }

  useEffect(() => {
    setupPageNumbers()
  }, [currentPage, totalCount, pageSize, offset])

  return (
    <nav aria-label="navigation" className="row align-items-center gy-3">
      <div className="col-sm-6 col-12 mt-0">
        <ul className={`pagination m-0 ${isMobile ? 'justify-content-center' : ''}`}>
          <li className="page-item">
            <button className={`page-link ${currentPage <= 1 && 'd-none'}`} onClick={previousPage}>
              <CaretLeft size={15} color={isDarkMode ? COLOR_WHITE : COLOR_BLACK} />
            </button>
          </li>
          {pageNumbers.map((number) => (
            <li className={`page-item ${currentPage === number && 'active'}`} key={number}>
              <button className="page-link" onClick={() => setCurrentPage(number)}>
                {number}
              </button>
            </li>
          ))}
          <li className="page-item">
            <button
              className={`page-link ${currentPage >= pageNumbers[pageNumbers.length - 1] && 'd-none'}`}
              onClick={nextPage}>
              <CaretRight size={15} color={isDarkMode ? COLOR_WHITE : COLOR_BLACK} />
            </button>
          </li>
        </ul>
      </div>

      <div className="col-sm-6 col-12">
        <div className={`row ${isMobile ? 'justify-content-center' : 'justify-content-end'} align-items-center`}>
          <div className="col-auto">
            <p className="m-0 text-quinary">Results per page:</p>
          </div>
          <div className="col-auto p-0">
            <select
              id="selectPagination"
              value={pageSize}
              className="form-select width-100"
              onChange={(e) => changeRowPerPage({ rows: e.target.value })}>
              <option value={10}>{10}</option>
              <option value={25}>{25}</option>
              <option value={50}>{50}</option>
              <option value={100}>{100}</option>
            </select>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Pagination
