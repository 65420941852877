import { DotsThree } from '@phosphor-icons/react'
import useSessionStore from 'Context/useSessionStore'
import { countDecimalsAndAdd0 } from 'Utils/helperFunctions'
import { PERMISSION_BY_ROLE } from 'Utils/permissionByRol'
import { COLOR_BLACK } from 'constant/colors'
import { PROJECT_DETAILS_ROUTER } from 'constant/router'
import { Link } from 'react-router-dom'
import { TABS_PROJECT } from 'Pages/Projects'
import { DropdownMenuActions } from 'Organisms/DropdownMenuActions'

const TableListProject = ({ projectList, activeTab, onEdit, onActive, onDeactive, onDelete }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const calculateProjectProgress = (spent, budget) => {
    if (budget === 0) { return 0 }
    return (100 - ((((budget - spent) / budget) * 100))).toFixed(2)
  }

  const OPTIONS_MENU = [
    {
      id: 1,
      actions: [
        activeTab === TABS_PROJECT.ACTIVE.name &&
          {
            id: 1,
            onClick: ({ element }) => onEdit(element.id),
            name: 'Edit',
            elementId: 'editProject'
          },
        activeTab === TABS_PROJECT.ARCHIVED.name &&
          {
            id: 2,
            onClick: ({ element }) => onActive(element.id),
            name: 'Reactivate',
            elementId: 'reactivateProject'
          }
      ]
    },
    {
      id: 2,
      actions: [
        activeTab === TABS_PROJECT.ACTIVE.name && {
          id: 3,
          name: 'Deactivate',
          onClick: ({ element }) => onDeactive(element.id),
          styles: 'text-danger',
          elementId: 'deActivateProject'
        },
        activeTab === TABS_PROJECT.ARCHIVED.name &&
          {
            id: 4,
            name: 'Delete',
            onClick: ({ element }) => onDelete(element.id),
            styles: 'text-danger',
            elementId: 'deleteProject'
          }
      ]
    }
  ]

  const getPercent = ({ spent, budget }) => {
    if (spent === 0) return 0

    if (budget === 0) return 100

    return countDecimalsAndAdd0({ number: (spent * 100) / (budget || 0) })
  }

  return (
    <div className="card mb-3 p-4 projects-card">
      <div className="table-responsive projects-card">
        <table className="table align-middle">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Client</th>
              <th scope="col">Budget</th>
              <th scope="col" />
              <th scope="col">Spent</th>
              <th scope="col">Remaining</th>
              {PERMISSION_BY_ROLE.PROJECT.TABLE.COLUNM_COST.includes(user.role) && <th scope="col">Costs</th>}
              {PERMISSION_BY_ROLE.PROJECT.TABLE.COLUNM_ACTIONS.includes(user.role) && <th scope="col" />}
            </tr>
          </thead>
          <tbody>
            {projectList.map((project) => (
              <tr key={project.id}>
                <td className="text-no-wrap">
                  <Link to={PROJECT_DETAILS_ROUTER({ projectId: project.id })}>
                    {`${project.code ? `[${project.code}]` : ''}`} {project.name}
                  </Link>
                </td>
                <td className="text-no-wrap">{project?.client?.name || ''}</td>
                <td>{countDecimalsAndAdd0({ number: project?.budget || 0 })}</td>
                <td>
                  <div className='row align-items-center'>
                    <div className='col-auto'>

                      <div
                        style={{ width: '100px' }}
                        className="progress"
                        role="progressbar"
                        aria-label="Basic example"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {/* Blue section for the budgeted amount */}
                        <div
                          className="progress-bar"
                          style={{
                            width: `${getPercent({ spent: Math.min(project?.spent || 0, project?.budget || 0), budget: project?.budget || 0 })}%`,
                            backgroundColor: '#016A67' // Bootstrap primary color
                          }}
                        ></div>

                        {/* Red section for the exceeded amount */}
                        {project?.spent > project?.budget && (
                          <div
                            className="progress-bar"
                            style={{
                              width: `${getPercent({ spent: project?.spent - project?.budget, budget: project?.budget || 0 })}%`,
                              backgroundColor: '#BC5776ff'
                            }}
                          ></div>
                        )}
                      </div>

                    </div>
                    <div className='px-0 col-auto'>
                      <span className='small'>{calculateProjectProgress(project?.spent, project?.budget)}%</span>
                    </div>
                  </div>
                </td>
                <td>{countDecimalsAndAdd0({ number: project?.spent || 0 })}</td>
                <td>{countDecimalsAndAdd0({ number: (project?.budget || 0) - (project?.spent || 0) })}</td>
                {PERMISSION_BY_ROLE.PROJECT.TABLE.COLUNM_COST.includes(user.role) && (
                  <td>{`$${countDecimalsAndAdd0({ number: project?.cost || 0 })}`}</td>
                )}

                {PERMISSION_BY_ROLE.PROJECT.TABLE.COLUNM_ACTIONS.includes(user.role) && (
                  <td className="text-end">
                    <DropdownMenuActions options={OPTIONS_MENU} element={project} icon={<DotsThree size={18} color={COLOR_BLACK} weight="bold" />} type='secondary' idBtn='menuProject' />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TableListProject
