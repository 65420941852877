import FilterAllDropdown from 'Organisms/FilterAllDropdown'
import { ModalFilters } from 'Organisms/ModalFilters'
import { useState } from 'react'

const ModalFilterPayrollMobile = ({ onFilter, hiddenUsers, disabled }) => {
  const [filter, setFilter] = useState([])
  const [clear, setClear] = useState(true)

  const handleChangeFilter = ({ filter }) => setFilter(filter)

  const handleSendFilter = () => {
    onFilter && onFilter({ filter })
  }

  const handleClearFilter = () => {
    setClear(!clear)
    setFilter([])
    onFilter && onFilter({ filter: [] })
  }

  return (
    <div className="my-4">
      <ModalFilters onFilter={handleSendFilter} onClear={handleClearFilter} disabled={disabled}>
        <div className="modalFilterPayrollMobile">
          <FilterAllDropdown
            hiddenSprint
            hiddenStatus
            hiddenTasks
            hiddenClear
            hiddenUsers={hiddenUsers}
            disabled={disabled}
            onChangeFilter={handleChangeFilter}
            clearOut={clear}
          />
        </div>
      </ModalFilters>
    </div>
  )
}

export default ModalFilterPayrollMobile
