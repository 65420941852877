const TabsListTable = ({ tabs, activeTab, onChange, disabled }) => {
  return (
    <ul className="nav nav-underline">
      {Object.keys(tabs).map((item) => (
        <li key={item} className="nav-item">
          <p
            className={`mb-0 pointer nav-link text-capitalize ${activeTab === tabs[item].name ? 'active' : ''}`}
            onClick={!disabled ? () => onChange({ tab: tabs[item].name }) : undefined}>
            {tabs[item].name}
          </p>
        </li>
      ))}
    </ul>
  )
}

export default TabsListTable
