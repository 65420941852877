import { useDevops, useModal } from 'Hooks'
import { Loading } from 'Organisms/Loading'
import { useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import ChooseOrganizationAzureIntegration from './ChooseOrganizationAzureIntegration'
import ChooseProjectAzureIntegration from './ChooseProjectAzureIntegration'
import FieldMappingAzureIntegration from './FieldMappingAzureIntegration'

const INTEGRATIONS_STEPS = {
  SELECT_ORGANIZATION: 'SELECT_ORGANIZATION',
  SELECT_PROJECT: 'SELECT_PROJECT',
  FIELD_MAPPING: 'FIELD_MAPPING'
}

export const FIELD_CONNECT_AZURE = {
  ORGANIZATION_NAME: 'organization_name',
  PROJECT_ID: 'project_id',
  PROJECT_NAME: 'project_name',
  FIELD_SPRINT: 'sprint',
  FIELD_STORY_POINTS: 'story_points',
  FIELD_ESTIMATE: 'estimate'
}

const ModalConnectAzure = ({ onClose, logo, project, onConnect, edit }) => {
  const { Modal, hide } = useModal({ identifier: 'modalIntegrationAzure', visible: true, setVisible: onClose })
  const { loadingDevopsHook } = useDevops()
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    defaultValues: {
      [FIELD_CONNECT_AZURE.ORGANIZATION_NAME]: edit?.[FIELD_CONNECT_AZURE.ORGANIZATION_NAME] || '',
      [FIELD_CONNECT_AZURE.PROJECT_ID]: edit?.[FIELD_CONNECT_AZURE.PROJECT_ID] || '',
      [FIELD_CONNECT_AZURE.PROJECT_NAME]: edit?.[FIELD_CONNECT_AZURE.PROJECT_NAME] || ''
    }
  })

  const organizationName = useWatch({
    control,
    name: FIELD_CONNECT_AZURE.ORGANIZATION_NAME
  })

  const projectName = useWatch({
    control,
    name: FIELD_CONNECT_AZURE.PROJECT_NAME
  })

  const [step, setStep] = useState(INTEGRATIONS_STEPS.SELECT_ORGANIZATION)

  const onSubmit = async (data) => {
    if (step === INTEGRATIONS_STEPS.SELECT_ORGANIZATION) return setStep(INTEGRATIONS_STEPS.SELECT_PROJECT)

    if (step === INTEGRATIONS_STEPS.SELECT_PROJECT) return setStep(INTEGRATIONS_STEPS.FIELD_MAPPING)

    const body = {
      [FIELD_CONNECT_AZURE.ORGANIZATION_NAME]: data[FIELD_CONNECT_AZURE.ORGANIZATION_NAME] ?? '',
      [FIELD_CONNECT_AZURE.PROJECT_ID]: data[FIELD_CONNECT_AZURE.PROJECT_ID] ?? '',
      [FIELD_CONNECT_AZURE.PROJECT_NAME]: data[FIELD_CONNECT_AZURE.PROJECT_NAME] ?? '',

      mappings: {
        [FIELD_CONNECT_AZURE.FIELD_SPRINT]: data[FIELD_CONNECT_AZURE.FIELD_SPRINT] ?? '',
        [FIELD_CONNECT_AZURE.FIELD_ESTIMATE]: data[FIELD_CONNECT_AZURE.FIELD_ESTIMATE] ?? '',
        [FIELD_CONNECT_AZURE.FIELD_STORY_POINTS]: data[FIELD_CONNECT_AZURE.FIELD_STORY_POINTS] ?? ''

      }
    }
    await onConnect({ body })
    hide()
  }
  const handleClose = () => {
    if (step === INTEGRATIONS_STEPS.SELECT_PROJECT) return setStep(INTEGRATIONS_STEPS.SELECT_ORGANIZATION)
    if (step === INTEGRATIONS_STEPS.FIELD_MAPPING) return setStep(INTEGRATIONS_STEPS.SELECT_PROJECT)

    hide()
  }

  return (
    <Modal onClose={hide}>
      <>
        {loadingDevopsHook && <Loading />}
        <div className="modal-content p-5 connectModal" style={{ maxWidth: 500 }}>
          <div className="modal-header">
            <div className="row text-center gy-2">
              <div className="col-12 d-grid justify-content-center">
                <div className="connectModal_logo">
                  <img src={logo} />
                </div>
              </div>
              <div className="col-12">
                <h3 className="modal-title fs-5 text-capitalize" id="exampleModalLabel">
                  {[INTEGRATIONS_STEPS.SELECT_ORGANIZATION, INTEGRATIONS_STEPS.SELECT_PROJECT].includes(step)
                    ? 'Add Azure Devops Integration'
                    : 'Set up integration'}
                </h3>
              </div>
              <div className="col-12">
                <p className="m-0">
                  {[INTEGRATIONS_STEPS.SELECT_ORGANIZATION, INTEGRATIONS_STEPS.SELECT_PROJECT].includes(step)
                    ? 'Please help us with your Azure Devops account credentials to get your Azure Devops dashboard integrated.'
                    : 'These were the fields assigned for your project, help us to do the right match so we can get everything ready for you to start!'}
                </p>
              </div>
            </div>
            <button type="button" className="btn-close modalConnect_close" onClick={() => hide()} />
          </div>

          <div className="modal-body">
            <form id="modalConnect" className="d-grid" onSubmit={handleSubmit(onSubmit)}>
              {step === INTEGRATIONS_STEPS.SELECT_ORGANIZATION && (
                <ChooseOrganizationAzureIntegration register={register} errors={errors} project={project} />
              )}

              {step === INTEGRATIONS_STEPS.SELECT_PROJECT && (
                <ChooseProjectAzureIntegration
                  register={register}
                  errors={errors}
                  project={project}
                  organization={organizationName}
                  setValue={setValue}
                />
              )}

              {step === INTEGRATIONS_STEPS.FIELD_MAPPING && (
                <FieldMappingAzureIntegration project={project} projectName={projectName} register={register} organization={organizationName} />
              )}

              <div className="row justify-content-between">
                <div className="col-auto">
                  <button type="button" onClick={handleClose} className="btn btn-secondary">
                    {[INTEGRATIONS_STEPS.SELECT_PROJECT, INTEGRATIONS_STEPS.FIELD_MAPPING].includes(step)
                      ? 'Back'
                      : 'Cancel'}
                  </button>
                </div>
                <div className="col-auto">
                  <button type="submit" className="btn btn-primary">
                    {[INTEGRATIONS_STEPS.SELECT_ORGANIZATION, INTEGRATIONS_STEPS.SELECT_PROJECT].includes(step)
                      ? 'Next'
                      : 'Connect'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    </Modal>
  )
}

export default ModalConnectAzure
