import { COLOR_LIGHT_BLUE } from 'constant/colors'

const GoggleIcon = ({ color = COLOR_LIGHT_BLUE, size, styles = {} }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 181 143" width={size} height={size} fill="none" style={styles}>
    <path
      stroke={color}
      d="M103.9,12.7c0-1.6.6-3.1,1.7-4.2,2.3-2.3,5.1-4.2,8.2-5.5,3.1-1.3,6.3-1.9,9.6-1.9s6.6.7,9.7,1.9c3.1,1.3,5.8,3.1,8.2,5.5h0c.5.5.9,1.1,1.2,1.8h0s33.4,76,33.4,76h0s0,0,0,0c.7,1.4,1.3,2.8,1.9,4.3,1.9,5.2,2.6,10.8,2.1,16.3-.5,5.5-2.2,10.9-5,15.7-2.8,4.8-6.6,8.9-11.2,12.1-4.6,3.2-9.8,5.3-15.2,6.2-5.5.9-11.1.7-16.4-.8s-10.3-4.1-14.6-7.6c-4.2-3.6-7.7-8-10-13.1-2.3-5-3.6-10.5-3.5-16.1h0v-58.4h-26.7v58.4c0,5.6-1.2,11-3.6,16.1-2.3,5-5.8,9.5-10,13.1-4.2,3.6-9.2,6.2-14.6,7.6-5.4,1.4-11,1.7-16.4.8-5.5-.9-10.7-3.1-15.2-6.2-4.6-3.2-8.4-7.3-11.2-12.1-2.8-4.8-4.5-10.1-5-15.7-.5-5.5.2-11.1,2.1-16.3.5-1.5,1.2-2.9,1.9-4.3h0s0,0,0,0L38.5,10.3h0c.3-.7.7-1.3,1.2-1.8h0c2.3-2.3,5.1-4.2,8.2-5.5,3.1-1.3,6.3-1.9,9.7-1.9s6.6.7,9.7,1.9c3.1,1.3,5.8,3.1,8.2,5.5.6.6,1,1.2,1.3,1.9.3.7.5,1.5.4,2.3h0v20.6h26.7V12.7ZM49,16.1h0c0,0,0,.2,0,.2l-22.1,50.3-.4,1,1.1-.3c6.3-2.1,13.1-2.4,19.6-1,6.5,1.4,12.5,4.4,17.5,8.9l.8.7V15.4h-.2c-2.4-1.8-5.3-2.6-8.2-2.4-2.9.2-5.7,1.2-7.9,3.1ZM115.9,15.3h-.2v60.6l.8-.7c5-4.4,11-7.5,17.5-8.9,6.5-1.4,13.3-1,19.6,1l1.1.3-.4-1-22.1-50.3h0c0-.1,0-.2,0-.2-2.2-1.9-5-3-7.9-3.1-2.9-.2-5.8.7-8.2,2.3ZM24.5,125.3c4.3,2.9,9.4,4.4,14.6,4.4s13.6-2.8,18.5-7.7c4.9-4.9,7.7-11.6,7.7-18.5s-1.5-10.3-4.4-14.6c-2.9-4.3-7-7.7-11.8-9.7-4.8-2-10.1-2.5-15.1-1.5-5.1,1-9.8,3.5-13.4,7.2-3.7,3.7-6.2,8.3-7.2,13.4-1,5.1-.5,10.4,1.5,15.1s5.3,8.9,9.7,11.8ZM127.4,125.3c4.3,2.9,9.4,4.4,14.6,4.4s13.6-2.8,18.5-7.7,7.7-11.6,7.7-18.5-1.5-10.3-4.4-14.6c-2.9-4.3-7-7.7-11.8-9.7-4.8-2-10.1-2.5-15.1-1.5-5.1,1-9.8,3.5-13.4,7.2-3.7,3.7-6.2,8.3-7.2,13.4-1,5.1-.5,10.4,1.5,15.1s5.3,8.9,9.7,11.8Z"
    />
  </svg>
)
export default GoggleIcon
