import axios from 'axios'

const BASE_URL = '/projects'

// GET
const getProject = async (id) => axios.get(`${BASE_URL}/${id}`).then(({ data }) => data)
const getProjectsServices = async (queryParams) => axios.get(`${BASE_URL}?${queryParams}`).then(({ data }) => data)
const getMetricsUserHoursByProjectService = async ({ queryParams, idProject }) => axios.get(`/metrics/daily/user/project/${idProject}/hours?${queryParams}`).then(({ data }) => data)
const getMetricsTaskHoursByProjectService = async ({ queryParams, idProject }) => axios.get(`/metrics/daily/task/project/${idProject}/hours?${queryParams}`).then(({ data }) => data)
const getMetricsGeneralByProjectService = async ({ queryParams, idProject }) => axios.get(`/metrics/daily/project/${idProject}/hours?${queryParams}`).then(({ data }) => data)

// POST
const createProject = async (body) => axios.post(BASE_URL, { ...body }).then(({ data }) => data)
const addUserToProjectService = async ({ listUser, idProject }) =>
  axios.post(`${BASE_URL}/${idProject}/users`, { user_ids: listUser }).then(({ data }) => data)
const addTaskToProjectService = async ({ listIdTask, idProject }) =>
  axios
    .post(`${BASE_URL}/${idProject}/tasks`, {
      project_tasks: listIdTask
    })
    .then(({ data }) => data)

// PUT / PATCH
const updateProjectService = async (body, id) => axios.put(`${BASE_URL}/${id}`, { ...body }).then(({ data }) => data)
const updateTaskToProjectService = async ({ idTask, newBillableRate, idProject, newBudget }) =>
  axios
    .put(`${BASE_URL}/${idProject}/tasks/${idTask}`, {
      ...(newBillableRate && { billable_rate: parseFloat(newBillableRate) }),
      ...(newBudget && { budget: parseFloat(newBudget) })
    })
    .then(({ data }) => data)

// DELETE
const deleteProject = async (id) => axios.delete(`${BASE_URL}/${id}`).then(({ data }) => data)
const deleteUserToProjectService = async ({ userId, idProject }) =>
  axios.delete(`${BASE_URL}/${idProject}/users/${userId}`).then(({ data }) => data)
const deleteTaskToProjectService = async ({ idTask, idProject }) =>
  axios.delete(`${BASE_URL}/${idProject}/tasks/${idTask}`).then(({ data }) => data)

export {
  createProject,
  updateProjectService,
  getProject,
  getProjectsServices,
  deleteProject,
  addUserToProjectService,
  deleteUserToProjectService,
  addTaskToProjectService,
  updateTaskToProjectService,
  deleteTaskToProjectService,
  getMetricsUserHoursByProjectService,
  getMetricsTaskHoursByProjectService,
  getMetricsGeneralByProjectService
}
