import useSessionStore from 'Context/useSessionStore'
import { GET_COLOR_BY_THEME } from 'constant/colors'
import { DateRange } from 'react-date-range'
import { DateTime } from 'luxon'

import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file

const DatePickerCustomFromTo = ({ startDate, endDate, onChangeDate }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const state = [
    {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      key: 'selection'
    }
  ]

  const handleChange = (item) => {
    onChangeDate({ startDate: item.selection.startDate, endDate: item.selection.endDate })
  }

  return (
    <div className="dropdown ">
      <button
        className={'btn btn-secondary'}
        type="button"
        data-bs-toggle="dropdown"
        id="idDatePicker"
        aria-expanded="false"
        data-bs-auto-close="outside">
        {DateTime.fromJSDate(state[0].startDate).toFormat('dd LLL yyyy')} to{' '}
        {DateTime.fromJSDate(state[0].endDate).toFormat('dd LLL yyyy')}
      </button>

      <div className="dropdown-menu pt-0" id="idDatePicker">
        <DateRange
          className="datePicker_custom"
          rangeColors={[GET_COLOR_BY_THEME({ theme: user.theme }).PRIMARY]}
          editableDateInputs={true}
          onChange={handleChange}
          moveRangeOnFirstSelection={false}
          ranges={state}
        />
      </div>
    </div>
  )
}

export default DatePickerCustomFromTo
