import { X } from '@phosphor-icons/react'
import { useState, useEffect, useRef } from 'react'

const Tooltip = ({ targetId, imgUrl, title, text, displayed, setDisplayed, children, offsetX = 0, offsetY = 0 }) => {
  const [position, setPosition] = useState({ top: 0, left: 0 })
  const tooltipRef = useRef(null)

  const handleResize = () => {
    const targetElement = document.getElementById(targetId)
    if (targetElement && displayed) {
      const { top, left, width } = targetElement.getBoundingClientRect()
      setPosition({
        top: top - 50,
        left: left + width / 2
      })
    }
  }

  useEffect(() => {
    handleResize()
  }, [targetId, displayed])

  if (!displayed) return null

  const tooltipStyle = {
    position: 'absolute',
    top: position.top - offsetY,
    left: position.left - offsetX,
    transform: 'translateX(-50%)',
    zIndex: 1000
  }

  return (
    <div ref={tooltipRef} className="tooltips show p-4" style={tooltipStyle} role="tooltip">
      <div className='row justify-content-between mb-2'>
        { title &&
          <div className='col'>
            <h5 className='mb-0 mt-1'>{title}</h5>
          </div>
        }
        <div className='col-auto'>
          <button className="btn btn-secondary btn-sm btn-x" onClick={() => setDisplayed(false)}>
            <X size={16} />
          </button>
        </div>
      </div>

      { imgUrl &&
        <div className='row'><img className='img-fluid mb-3' src={imgUrl}/></div>
      }

      <p>{text}</p>
      <div className="col mt-2">
        {children}
      </div>
    </div>
  )
}

export default Tooltip
