const LoadingButton = ({
  children,
  type = 'button',
  onSubmit = () => {},
  form = null,
  loadingText = 'Saving...',
  className = '',
  loading = false,
  disabled = false,
  id = '',
  onClick = () => {}
}) => {
  return (
    <button type={type} onSubmit={onSubmit} onClick={onClick} form={form} className={className} disabled={loading || disabled} id={id}>
      {loading && (
        <>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span className="ms-2">{loadingText}</span>
        </>
      )}
      {!loading && <span>{children}</span>}
    </button>
  )
}

export default LoadingButton
