import { Plus } from '@phosphor-icons/react'
import useSessionStore from 'Context/useSessionStore'
import SearchInput from 'Organisms/SearchInput'
import { PERMISSION_BY_ROLE } from 'Utils/permissionByRol'
import { COLOR_BLACK } from 'constant/colors'

const HeaderProject = ({ onSearch, onOpenCreate }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  return (
    <div className="row justify-content-between align-items-center gy-3">
      <div className="col-md-auto col-sm-6 col-12">
        <div className="row align-items-center gy-3">
          <div className="col-auto">
            <h1 className="mb-0">Projects</h1>
          </div>
          <div className="col-md-7 col-12">
            <SearchInput
              className="form-control form-control-outline"
              placeholder="Search"
              onChange={(e) => onSearch({ search: e.target.value })}
            />
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-12">
        <div className="row justify-content-end">
          {PERMISSION_BY_ROLE.PROJECT.CREATE.includes(user.role) && (
            <div className="col-auto ps-0">
              <button type="button" className="btn btn-primary" id="createProject" onClick={() => onOpenCreate()}>
                <Plus className="icon" size={15} color={COLOR_BLACK} weight="bold" />
                Add project
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default HeaderProject
