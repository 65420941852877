import {
  activeTimerTimeEntriesService,
  createColumnsTableTimeSheetService,
  deleteTimeEntriesService,
  desActiveTimerTimeEntriesService,
  downloadCsvByProjectTimesheetService,
  downloadTimeEntriesService,
  downloadTimeSheetsService,
  getColumnsTableTimeSheetService,
  getTimeEntriesService,
  getTimeEntryDetailByIdService,
  getTimeSheetTaskByProjectService,
  getTimeSheetsService,
  reSubmitHoursTimeEntriesService,
  submitHoursTimeEntriesService,
  updateColumnsTableTimeSheetService,
  updateTimeEntriesService
} from 'Services'
import { createQueryString } from 'Utils/helperFunctions'
import { formatSendPagination } from 'adapters/pagination'
import { useState } from 'react'

export default function useTimeEntries () {
  const [loadingTimeEntriesHook, setLoadingTimeEntriesHook] = useState(false)
  const [listTimeEntries, setListTimeEntries] = useState([])
  const [listTimeSheetsHook, setListTimeSheetsHook] = useState([])
  const [listGroupTimeEntries, setListGroupTimeEntries] = useState([])
  const [listTimeSheetTaskByProject, setListTimeSheetTaskByProject] = useState([])
  const [ticketHistory, setTicketHistory] = useState([])
  const [countTotalItemList, setCountTotalItemList] = useState(0)
  const [totalAllBillable, setTotalAllBillable] = useState(0)
  const [totalAllWarrenty, setTotalAllWarrenty] = useState(0)

  const groupTimeEntriesHook = ({ timeEntries }) => {
    const groupTime = {}

    timeEntries.forEach((time) => {
      const title = time.title.trim()
      if (groupTime[title]) {
        groupTime[title] = {
          ...groupTime[title],
          completedWork: groupTime[title].completedWork + (!time.is_warranty ? time.hours : 0),
          warrantyWork: groupTime[title].warrantyWork + (time.is_warranty ? time.hours : 0)
        }
      } else {
        groupTime[title] = {
          completedWork: !time.is_warranty ? time.hours : 0,
          warrantyWork: time.is_warranty ? time.hours : 0
        }
      }
    })

    return groupTime
  }

  const getTimeEntriesHook = async ({
    startDate,
    endDate,
    users,
    projects,
    tasks,
    states,
    search,
    tracker,
    sprints,
    order,
    page,
    limit,
    is_submitted,
    is_approved
  }) => {
    try {
      setLoadingTimeEntriesHook(true)
      const query = createQueryString({
        date: startDate && endDate ? `${startDate}:${endDate}` : '',
        user_ids: users,
        project_ids: projects,
        task_ids: tasks,
        states,
        search,
        is_tracker: tracker,
        sprints,
        order,
        is_submitted,
        is_approved,
        pagination: formatSendPagination({ page, limit })
      })
      const data = await getTimeEntriesService(query)

      const list = data?.data?.list || []

      const groupData = groupTimeEntriesHook({ timeEntries: list })

      setListTimeEntries(list)
      setCountTotalItemList(data?.data?.count || 0)
      setListGroupTimeEntries(groupData)
      setTotalAllBillable(data?.data?.total_all_billable || 0)
      setTotalAllWarrenty(data?.data?.total_all_warrenty || 0)
      return list
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingTimeEntriesHook(false)
    }
  }

  const getTimeSheetsHook = async ({
    startDate,
    endDate,
    users,
    projects,
    tasks,
    states,
    search,
    sprints,
    page,
    limit
  }) => {
    try {
      setLoadingTimeEntriesHook(true)
      const query = createQueryString({
        date: startDate && endDate ? `${startDate}:${endDate}` : '',
        user_ids: users,
        project_ids: projects,
        task_ids: tasks,
        states,
        search,
        sprints,
        pagination: formatSendPagination({ page, limit })
      })
      const data = await getTimeSheetsService(query)
      const list = data?.data?.list || []

      setListTimeSheetsHook(list)
      setCountTotalItemList(data?.data?.count || 0)
      setTotalAllBillable(data?.data?.total_all_billable || 0)
      setTotalAllWarrenty(data?.data?.total_all_warrenty || 0)
      return list
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingTimeEntriesHook(false)
    }
  }

  const submitHoursTimeEntriesHook = async ({ user_id, start_date, end_date }) => {
    try {
      setLoadingTimeEntriesHook(true)
      await submitHoursTimeEntriesService({ user_id, start_date, end_date })
      return true
    } catch (e) {
      console.error(e)
      return false
    } finally {
      setLoadingTimeEntriesHook(false)
    }
  }

  const reSubmitHoursTimeEntriesHook = async ({ user_id, start_date, end_date }) => {
    try {
      setLoadingTimeEntriesHook(true)
      await reSubmitHoursTimeEntriesService({ user_id, start_date, end_date })
      return true
    } catch (e) {
      console.error(e)
      return false
    } finally {
      setLoadingTimeEntriesHook(false)
    }
  }

  const activeTimerTimeEntriesHook = async ({ id }) => {
    try {
      setLoadingTimeEntriesHook(true)
      await activeTimerTimeEntriesService({ id })
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingTimeEntriesHook(false)
    }
  }

  const desActiveTimerTimeEntriesHook = async ({ id }) => {
    try {
      setLoadingTimeEntriesHook(true)
      await desActiveTimerTimeEntriesService({ id })
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingTimeEntriesHook(false)
    }
  }

  const updateTimeEntriesHook = async ({ id, body }) => {
    try {
      setLoadingTimeEntriesHook(true)
      await updateTimeEntriesService({ id, body })
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingTimeEntriesHook(false)
    }
  }

  const deleteTimeEntryHook = async ({ id }) => {
    try {
      setLoadingTimeEntriesHook(true)
      await deleteTimeEntriesService({ id })
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingTimeEntriesHook(false)
    }
  }

  const getTimeSheetTaskByProjectHook = async ({ startDate, endDate, projectId, search, sprints }) => {
    try {
      setLoadingTimeEntriesHook(true)
      const query = createQueryString({
        date: startDate && endDate ? `${startDate}:${endDate}` : '',
        search,
        sprints
      })
      const data = await getTimeSheetTaskByProjectService({ idProject: projectId }, query)
      setListTimeSheetTaskByProject(data?.data || [])
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingTimeEntriesHook(false)
    }
  }

  const downloadCsvByProjectTimesheetHook = async ({ projectId, startDate, endDate, search }) => {
    try {
      setLoadingTimeEntriesHook(true)
      const query = createQueryString({
        date: startDate && endDate ? `${startDate}:${endDate}` : '',
        search
      })
      const res = await downloadCsvByProjectTimesheetService({ projectId, query })
      return res
    } catch (e) {
      console.error(e)
      return {
        success: false
      }
    } finally {
      setLoadingTimeEntriesHook(false)
    }
  }

  const downloadCsvByTimesheetHook = async ({
    startDate,
    endDate,
    users,
    projects,
    tasks,
    states,
    search,
    sprints
  }) => {
    try {
      setLoadingTimeEntriesHook(true)
      const query = createQueryString({
        date: startDate && endDate ? `${startDate}:${endDate}` : '',
        user_ids: users,
        project_codes: projects,
        task_ids: tasks,
        states,
        search,
        sprints,
        pagination: formatSendPagination({ page: 1, limit: 100000 })
      })

      const res = await downloadTimeSheetsService({ query })
      return res
    } catch (e) {
      console.error(e)
      return {
        success: false
      }
    } finally {
      setLoadingTimeEntriesHook(false)
    }
  }

  const downloadCsvByTimeentriesHook = async ({
    startDate,
    endDate,
    users,
    projects,
    tasks,
    states,
    search,
    sprints
  }) => {
    try {
      setLoadingTimeEntriesHook(true)
      const query = createQueryString({
        date: startDate && endDate ? `${startDate}:${endDate}` : '',
        user_ids: users,
        project_codes: projects,
        task_ids: tasks,
        states,
        search,
        sprints,
        pagination: formatSendPagination({ page: 1, limit: 100000 })
      })

      const res = await downloadTimeEntriesService({ query })
      return res
    } catch (e) {
      console.error(e)
      return {
        success: false
      }
    } finally {
      setLoadingTimeEntriesHook(false)
    }
  }

  // COLUMNS
  const getColumnsTableHook = async ({ is_detail = false } = {}) => {
    try {
      setLoadingTimeEntriesHook(true)

      const query = createQueryString({
        is_detail
      })

      const res = await getColumnsTableTimeSheetService({ query })

      return res
    } catch (e) {
      return {
        success: false
      }
    } finally {
      setLoadingTimeEntriesHook(false)
    }
  }

  const createColumnsTableHook = async ({ columns }) => {
    try {
      setLoadingTimeEntriesHook(true)
      const body = columns.map((item) => {
        delete item.id

        return item
      })

      const res = await createColumnsTableTimeSheetService({ body })
      return res
    } catch (e) {
      return {
        success: false
      }
    } finally {
      setLoadingTimeEntriesHook(false)
    }
  }

  const updateColumnsTableHook = async ({ columns }) => {
    try {
      setLoadingTimeEntriesHook(true)
      const res = await updateColumnsTableTimeSheetService({ body: columns })
      return res
    } catch (e) {
      return {
        success: false
      }
    } finally {
      setLoadingTimeEntriesHook(false)
    }
  }

  const getTimeEntryDetailByIdHook = async ({ entryId }) => {
    try {
      setLoadingTimeEntriesHook(true)
      const res = await getTimeEntryDetailByIdService({ entryId })
      setTicketHistory(res.data)
      return res
    } catch (e) {
      return {
        success: false
      }
    } finally {
      setLoadingTimeEntriesHook(false)
    }
  }

  return {
    listTimeEntries,
    listTimeSheetsHook,
    loadingTimeEntriesHook,
    listGroupTimeEntries,
    listTimeSheetTaskByProject,
    ticketHistory,
    countTotalItemList,
    totalAllBillable,
    totalAllWarrenty,
    getTimeEntriesHook,
    getTimeSheetsHook,
    updateTimeEntriesHook,
    deleteTimeEntryHook,
    submitHoursTimeEntriesHook,
    reSubmitHoursTimeEntriesHook,
    activeTimerTimeEntriesHook,
    desActiveTimerTimeEntriesHook,
    getTimeSheetTaskByProjectHook,
    downloadCsvByProjectTimesheetHook,
    getColumnsTableHook,
    updateColumnsTableHook,
    createColumnsTableHook,
    downloadCsvByTimesheetHook,
    downloadCsvByTimeentriesHook,
    getTimeEntryDetailByIdHook
  }
}
