import { DateTime } from 'luxon'

const DateDisplay = ({ date }) => {
  // Convert the given date to a Luxon DateTime object
  const luxonDate = date instanceof DateTime ? date : DateTime.fromISO(date)

  // Get the current date
  const currentDate = DateTime.now()

  // Calculate the difference in days between the current date and the given date
  const daysDifference = currentDate.diff(luxonDate, 'days').days

  // Determine the appropriate display based on the days difference
  let displayText = ''
  if (daysDifference < 1) {
    displayText = 'Today'
  } else if (daysDifference >= 1 && daysDifference < 2) {
    displayText = 'Yesterday'
  } else if (daysDifference >= 2 && daysDifference <= 30) {
    displayText = `${parseInt(daysDifference)} days ago`
  } else {
    displayText = `${luxonDate.toFormat('DD')}`
  }

  // Render the display text
  return <span>{displayText}</span>
}

export default DateDisplay
