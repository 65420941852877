import {
  ROLE_ADMIN_NAME,
  ROLE_OPERATION_MANAGER_NAME,
  ROLE_PRODUCT_OWNER_NAME
} from 'constant/roles'

export const PERMISSION_BY_ROLE = {
  USER: {
    VIEW_PAGE: [ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME],
    CREATE: [ROLE_ADMIN_NAME],
    FILTER_BY_ROLE: {
      ALLOWED: [ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME],
      BY_ADMIN: [ROLE_ADMIN_NAME],
      BY_OPM: [ROLE_ADMIN_NAME],
      BY_PO: [ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME],
      BY_DEV: [ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME, ROLE_PRODUCT_OWNER_NAME]
    },
    TABS_ACTIVE_AND_ARCHIVED: [ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME],
    TABLE: {
      ACTIONS: [ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME]
    },
    EDIT: {
      SHOW_COST_BY_USER: [ROLE_ADMIN_NAME]
    }
  },
  PROJECT: {
    CREATE: [ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME],
    ARCHIVED_PROJECT: [ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME],
    TABLE: {
      COLUNM_COST: [ROLE_ADMIN_NAME],
      COLUNM_ACTIONS: [ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME]
    },
    ASSIGN_USER_TO_PROJECT: [ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME],
    TASK_SECTION: {
      VIEW: [ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME]
    },
    BUDGET_SECTION: {
      VIEW: [ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME, ROLE_PRODUCT_OWNER_NAME]
    },
    TEAM_SECTION: {
      DELETE_USER: [ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME]
    },
    SETTINGS_SECTION: {
      VIEW: [ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME]
    },
    INTEGRATIONS_SECTION: {
      VIEW: [ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME]
    }
  },
  TIME_SHEET: {
    FILTER: {
      BY_USER: [ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME, ROLE_PRODUCT_OWNER_NAME]
    }
  },
  CLIENT: {
    CREATE: {
      ALLOWED: [ROLE_ADMIN_NAME]
    }
  },
  PAYROLL: {
    SELF_ACTIONS: [ROLE_ADMIN_NAME]
  },
  SETTINGS: {
    AUTOMATIC_CLOSE_HOURS: [ROLE_ADMIN_NAME]
  },
  INIT_CONFIG: {
    SEARCH_COMPANY_INFO: [ROLE_ADMIN_NAME]
  }
}
