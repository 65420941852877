import { useCallback, useEffect, useState } from 'react'
import { CloudArrowDown } from '@phosphor-icons/react'

import { useDebounce, useTimeEntries } from 'Hooks'

import { CustomDatePicker } from 'Organisms/CustomDatePicker'
import { Loading } from 'Organisms/Loading'
import { ModalSuccess } from 'Organisms/ModalSuccess'
import SearchInput from 'Organisms/SearchInput'

import { downloadCSVasPDF, getEndWeek, getStartWeek } from 'Utils/helperFunctions'
import { TableTimesheetProject } from './Components/TableTimesheetProject'

const INIT_MODAL_MESSAGE = {
  open: false,
  message: '',
  success: false
}

const ProjectTimesheet = ({ project }) => {
  const { project_tasks } = project

  const [searchFilter, setSearchFilter] = useState('')
  const [startDay, setStartDay] = useState(getStartWeek())
  const [endDay, setEndDay] = useState(getEndWeek())
  const [modalMessage, setModalMessage] = useState(INIT_MODAL_MESSAGE)

  const {
    loadingTimeEntriesHook,
    listTimeSheetTaskByProject,
    getTimeSheetTaskByProjectHook,
    downloadCsvByProjectTimesheetHook
  } = useTimeEntries()

  const handleChangeSearch = ({ searchParam }) => {
    setSearchFilter(searchParam)
    searchDebounce({ search: searchParam })
  }

  const searchDebounce = useCallback(
    useDebounce(({ search }) => {
      getTimeSheet({ startDate: startDay, endDate: endDay, projectId: project.id, search })
    }, 500),
    [startDay, endDay]
  )

  const handleSearchDataByDate = async ({ startDay, endDay, search }) => {
    setStartDay(startDay)
    setEndDay(endDay)

    await getTimeSheet({ startDate: startDay, endDate: endDay, projectId: project.id, search })
  }

  const getTimeSheet = async ({ startDate, endDate, projectId, search }) => {
    await getTimeSheetTaskByProjectHook({
      startDate,
      endDate,
      projectId,
      search
    })
  }

  const handleDowmloadCsv = async () => {
    const res = await downloadCsvByProjectTimesheetHook({
      projectId: project.id,
      startDate: startDay,
      endDate: endDay,
      search: searchFilter
    })

    if (!res.success) {
      return setModalMessage({
        open: true,
        message: 'An error has occurred',
        success: false
      })
    }

    await downloadCSVasPDF({
      base64CSV: res.data,
      filename: `${project.code}-${project.name}-tasks-report`.replaceAll(' ', '-')
    })

    setModalMessage({
      open: true,
      message: 'Your report were created successfuly',
      success: true
    })
  }

  useEffect(() => {
    getTimeSheet({ startDate: startDay, endDate: endDay, projectId: project.id, search: searchFilter })
  }, [])

  return (
    <>
      {loadingTimeEntriesHook && <Loading />}
      <div className="row justify-content-center">
        <div className="col-lg-11 col-12 d-grid">
          <h2 className="mb-3">Timesheet</h2>
          <div className="card p-4">
            <div className="row mb-4">
              <CustomDatePicker
                onSearchData={({ startDay, endDay }) =>
                  handleSearchDataByDate({ startDay, endDay, search: searchFilter })
                }
              />
            </div>
            <div className="row justify-content-between mb-4 gy-sm-0 gy-3">
              <div className="col-sm-auto col-12">
                <SearchInput
                  className="form-control"
                  placeholder="Search"
                  value={searchFilter}
                  onChange={(e) => handleChangeSearch({ searchParam: e.target.value })}
                />
              </div>
              <div className="col-auto">
                <button className="btn btn-secondary" type="button" onClick={handleDowmloadCsv}>
                  <CloudArrowDown className="icon" size={17} weight="bold" /> Download
                </button>
              </div>
            </div>

            <TableTimesheetProject projectTask={project_tasks} timesheetList={listTimeSheetTaskByProject} />
          </div>
        </div>
      </div>

      {modalMessage.open && (
        <ModalSuccess
          visible={true}
          setVisible={() => setModalMessage(INIT_MODAL_MESSAGE)}
          title={modalMessage.message}
          identifier="modalMessageApproved"
          error={!modalMessage.success}
        />
      )}
    </>
  )
}

export default ProjectTimesheet
