import { WarningOctagon } from '@phosphor-icons/react'

const InputMessageError = ({ error }) => {
  return (
    <div className="col-12">
      <div className="alert alert-danger" role="alert">
        <div className="row">
          <div className="col-auto">
            <WarningOctagon size={24} color="#262422" weight="bold" />
          </div>
          <div className="col">{error && <p className="mb-0 me-3">{error}</p>}</div>
        </div>
      </div>
    </div>
  )
}

export default InputMessageError
