import { useEffect, useState } from 'react'
import { CustomDatePicker } from 'Organisms/CustomDatePicker'
import {
  countDecimalsAndAdd0,
  getDaysBetweenDates,
  getDifferentDaysBetweenDates,
  getEndWeek,
  getStartWeek,
  getWeekBetweenDates,
  groupHoursPerDayUtils
} from 'Utils/helperFunctions'
import { useProfile, useUser } from 'Hooks'
import { Loading } from 'Organisms/Loading'
import { INIT_HOURS_PER_DAYS } from 'Utils/const/profile'
import {
  GraphicsPerDayDashboard,
  GraphicsPerProjectDashboard,
  GraphicsPerTaskDashboard
} from 'Pages/Dashboard/components'
import { PERIOD_TIME_SHEET } from 'Pages/Timesheet/utils/const'
import { PanelHoursCapacity } from 'Organisms/PanelHoursCapacity'
import { useNavigate, useParams } from 'react-router-dom'
import { DASHBAORD_ROUTER } from 'constant/router'
import { DateTime } from 'luxon'
import { getPeriodByDiffDay } from 'Pages/TimeTracker/utils/helpers'
import { useMediaQuery } from 'react-responsive'
import { BREAKPOINT } from 'Utils/const/breakPoints'
import { DashboardHoursEmpty } from 'Organisms/Empty'
import { Helmet } from 'react-helmet'

const Profile = () => {
  const navigate = useNavigate()

  const { id: userId } = useParams()

  // Media query
  const isMobile = useMediaQuery({
    query: `(max-width: ${BREAKPOINT.MOBILE})`
  })

  // State
  const [startDay, setStartDay] = useState(getStartWeek())
  const [endDay, setEndDay] = useState(getEndWeek())
  const [loading, setLoading] = useState(true)
  const [groupHoursPerDay, setGroupHoursPerDay] = useState(INIT_HOURS_PER_DAYS)
  const [totalHoursPerDay, setTotalHoursPerDay] = useState(0)
  const [groupHoursPerProject, setGroupHoursPerProject] = useState([])
  const [totalHoursPerProject, setTotalHoursPerProject] = useState(0)
  const [groupHoursPerTask, setGroupHoursPerTask] = useState([])
  const [totalHoursPerTask, setTotalHoursPerTask] = useState(0)
  const [currentPeriod, setCurrentPeriod] = useState(PERIOD_TIME_SHEET.WEEK)

  // Hooks
  const {
    listHoursByUserHook,
    loadingProfileHook,
    getHoursProfileByUserHook,
    getHoursProfilePerProjectByUserHook,
    getHoursProfilePerTaskByUserHook
  } = useProfile()

  const { userInfoHook, getUserByIdHook } = useUser()

  // Handlers
  const handleSearchDataByDate = async ({ startDay, endDay, period }) => {
    setStartDay(startDay)
    setEndDay(endDay)
    setCurrentPeriod(period)

    await Promise.allSettled([
      getHours({ start_date: startDay, end_date: endDay, period }),
      getHoursPerProject({ start_date: startDay, end_date: endDay }),
      getHoursPerTack({ start_date: startDay, end_date: endDay })
    ])
  }

  const getHours = async ({ start_date, end_date, period = PERIOD_TIME_SHEET.WEEK }) => {
    let getByPeriod = period

    if (getByPeriod === PERIOD_TIME_SHEET.CUSTOM) {
      const diffDays = getDifferentDaysBetweenDates({ startDate: start_date, endDate: end_date })

      getByPeriod = getPeriodByDiffDay({ diffDays })
    }

    const result = await getHoursProfileByUserHook({ start_date, end_date, user_id: userId, period: getByPeriod })

    if (!result.success) return console.error('There is an error')

    let resultHoursPerDay = []

    if (getByPeriod === PERIOD_TIME_SHEET.WEEK) {
      resultHoursPerDay = groupHoursPerDayUtils({
        listHours: result.data.hours_per_day || [],
        initHours: INIT_HOURS_PER_DAYS
      })
    }

    if (getByPeriod === PERIOD_TIME_SHEET.SEMIMONTH) {
      const daysBetweenDates = getDaysBetweenDates({ startDate: start_date, endDate: end_date })

      resultHoursPerDay = daysBetweenDates.map((day) => {
        const total = {
          billable: 0,
          warranty: 0
        }

        result?.data?.hours_per_day.forEach((entry) => {
          if (
            DateTime.fromISO(entry.spent_date).setZone('local').toFormat('yyyy-MM-dd') === day.toFormat('yyyy-MM-dd')
          ) {
            total.billable += entry?.hours || 0
            total.warranty += entry?.warranty_hours || 0
          }
        })

        return {
          name: day.toFormat('yyyy-MM-dd'),
          billable: countDecimalsAndAdd0({ number: total?.billable || 0 }),
          warranty: countDecimalsAndAdd0({ number: total?.warranty || 0 })
        }
      })
    }

    if ([PERIOD_TIME_SHEET.MONTH, PERIOD_TIME_SHEET.QUARTER, PERIOD_TIME_SHEET.YEAR].includes(getByPeriod)) {
      const daysBetweenDates = getWeekBetweenDates({ startDate: start_date, endDate: end_date })

      resultHoursPerDay = daysBetweenDates.map((day) => {
        const total = {
          billable: 0,
          warranty: 0
        }

        result?.data?.hours_per_day.forEach((entry) => {
          if (
            DateTime.fromISO(entry.day_of_week).setZone('local').toFormat('yyyy-MM-dd') >=
              day.start_week.toFormat('yyyy-MM-dd') &&
            DateTime.fromISO(entry.day_of_week).setZone('local').toFormat('yyyy-MM-dd') <=
              day.end_week.toFormat('yyyy-MM-dd')
          ) {
            total.billable += entry?.hours || 0
            total.warranty += entry?.warranty_hours || 0
          }
        })

        return {
          name: day.start_week.toFormat('yyyy-MM-dd'),
          billable: countDecimalsAndAdd0({ number: total?.billable || 0 }),
          warranty: countDecimalsAndAdd0({ number: total?.warranty || 0 })
        }
      })
    }

    if (getByPeriod === PERIOD_TIME_SHEET.ALL_TIME) {
      resultHoursPerDay = result?.data?.hours_per_day.map((day) => ({
        name: DateTime.fromISO(day.day_of_week).setZone('local').toFormat('yyyy-MM-dd'),
        billable: countDecimalsAndAdd0({ number: day?.hours || 0 }),
        warranty: countDecimalsAndAdd0({ number: day?.warranty_hours || 0 })
      }))
    }

    setGroupHoursPerDay(resultHoursPerDay)
    setTotalHoursPerDay(result?.data?.hours)
  }

  const getHoursPerProject = async ({ start_date, end_date }) => {
    const result = await getHoursProfilePerProjectByUserHook({ start_date, end_date, user_id: userId })

    if (!result.success) return console.error('There is an error')

    let totalHoursProject = 0

    const resultHoursPerProject = result.data.map((item) => {
      totalHoursProject += item.hours

      return {
        name: item.project_name,
        hours: item.hours
      }
    })

    setGroupHoursPerProject(resultHoursPerProject)
    setTotalHoursPerProject(totalHoursProject)
  }

  const getHoursPerTack = async ({ start_date, end_date }) => {
    const result = await getHoursProfilePerTaskByUserHook({ start_date, end_date, user_id: userId })

    if (!result.success) return console.error('There is an error')

    let totalHoursTask = 0

    const resultHoursPerTask = result.data.map((item) => {
      totalHoursTask += item.hours

      return {
        name: item.task_name,
        children: [
          {
            hours: item.hours
          }
        ]
      }
    })

    setGroupHoursPerTask(resultHoursPerTask)
    setTotalHoursPerTask(totalHoursTask)
  }

  const getUser = async () => {
    const getUser = await getUserByIdHook({ idUser: userId })

    if (!getUser.success) return navigate(DASHBAORD_ROUTER)

    await Promise.allSettled([
      getHours({ start_date: startDay, end_date: endDay }),
      getHoursPerProject({ start_date: startDay, end_date: endDay }),
      getHoursPerTack({ start_date: startDay, end_date: endDay })
    ])

    setLoading(false)
  }

  useEffect(() => {
    ;(async () => {
      await getUser()
    })()
  }, [])

  if (loading) return <Loading />

  return (
    <>
      <Helmet>
        <title>Profile</title>
      </Helmet>

      <div className="containers dashboard">
        {loadingProfileHook && <Loading />}

        <h1 className="text-capitalize pt-4 fs-2">
          {userInfoHook.firstname} {userInfoHook.lastname}
        </h1>

        <div className="row justify-content-between mt-5">
          <div className="col">
            <CustomDatePicker
              onSearchData={({ startDay, endDay, period }) => handleSearchDataByDate({ startDay, endDay, period })}
            />
          </div>
        </div>

        <PanelHoursCapacity
          hours={listHoursByUserHook?.hours || 0}
          currentPeriod={currentPeriod}
          startDay={startDay}
          endDay={endDay}
          capacity={listHoursByUserHook?.capacity || 0}
        />

        {(listHoursByUserHook?.hours || 0) === 0 && (
          <div className="mt-4">
            <DashboardHoursEmpty />
          </div>
        )}

        {(listHoursByUserHook?.hours || 0) > 0 && (
          <>
            <GraphicsPerDayDashboard
              period={currentPeriod}
              groupHoursPerDay={groupHoursPerDay}
              isMobile={isMobile}
              currentPeriod={currentPeriod}
              totalHours={totalHoursPerDay}
            />

            <div className="profile_graph mt-4">
              <GraphicsPerProjectDashboard
                groupHours={groupHoursPerProject}
                totalHours={totalHoursPerProject}
                title="Worked hours per project"
                currentPeriod={currentPeriod}
              />

              <GraphicsPerTaskDashboard
                groupHours={groupHoursPerTask}
                totalHours={totalHoursPerTask}
                title="Worked hours per task"
              />
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Profile
