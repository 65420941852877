import React, { useState } from 'react'

const ContextTutorial = React.createContext({})

export function TutorialContextProvider ({ children }) {
  const [openTutorialContext, setOpenTutorialContext] = useState(false)

  const handleOpenTutorialContext = () => setOpenTutorialContext(prevState => !prevState)

  return (
    <ContextTutorial.Provider
      value={{
        openTutorialContext,
        handleOpenTutorialContext
      }}>
      {children}
    </ContextTutorial.Provider>
  )
}

export default ContextTutorial
