import { TABS_USER } from '..'
import { forgotPassword } from 'Hooks/useAuth'
import { useMediaQuery } from 'react-responsive'
import { BREAKPOINT } from 'Utils/const/breakPoints'
import TableUserContentDesktop from './TableUserContentDesktop'
import TableUserContentMobile from './TableUserContentMobile'

const TableUserList = ({ listUsers, activeTab, openUserModal, onDelete }) => {
  // Media query
  const isMobile = useMediaQuery({
    query: `(max-width: ${BREAKPOINT.MOBILE})`
  })

  const OPTIONS_MENU = [
    activeTab === TABS_USER.ACTIVE.name && {
      id: 1,
      actions: [
        {
          id: 1,
          onClick: ({ element }) => openUserModal(element.id),
          name: 'Edit user'
        },
        {
          id: 2,
          onClick: ({ element }) => forgotPasswordUser(element.email),
          name: 'Reset password'
        }
      ]
    },
    {
      id: 2,
      actions: [
        {
          id: 3,
          name: activeTab === TABS_USER.ACTIVE.name ? 'Deactivate' : 'Reactivate',
          onClick: ({ element }) => onDelete({ user: element }),
          styles: activeTab === TABS_USER.ACTIVE.name ? 'text-danger' : ''
        },
        activeTab === TABS_USER.ARCHIVED.name && {
          id: 4,
          name: 'Delete',
          onClick: ({ element }) => onDelete({ user: element, deleteU: true }),
          styles: 'text-danger'
        }
      ]
    }
  ]

  const forgotPasswordUser = async (email) => {
    forgotPassword(email).catch((e) => {
      console.error(e)
    })
  }

  return (
    <div className="col-12">
      <div className="card mb-3 p-4 users-card">
        <div className="table-responsive users-card">
          {!isMobile && <TableUserContentDesktop listUsers={listUsers} menuOptions={OPTIONS_MENU} />}
          {isMobile && <TableUserContentMobile listUsers={listUsers} menuOptions={OPTIONS_MENU} />}
        </div>
      </div>
    </div>
  )
}

export default TableUserList
