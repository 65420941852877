import { useState } from 'react'
import { getAllSprintService, getAllStatusService } from 'Services'
import { createQueryString } from 'Utils/helperFunctions'

export default function useTicket () {
  const [loadingTicketHook, setLoadingTicketHook] = useState(false)

  const getAllSprintByFilterHook = async ({ projectsId } = {}) => {
    try {
      setLoadingTicketHook(true)
      const query = createQueryString({ project_ids: projectsId })
      const sprints = await getAllSprintService({ query })
      return sprints.data.map((item) => ({
        id: item,
        value: item,
        text: item,
        checked: false
      }))
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingTicketHook(false)
    }
  }

  const getAllStatusByFilterHook = async ({ projectsId } = {}) => {
    try {
      setLoadingTicketHook(true)
      const query = createQueryString({ project_ids: projectsId })
      const states = await getAllStatusService({ query })
      return states.data.map((item) => ({
        id: item,
        value: item,
        text: item,
        checked: false
      }))
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingTicketHook(false)
    }
  }

  return {
    loadingTicketHook,
    getAllSprintByFilterHook,
    getAllStatusByFilterHook
  }
}
