import { countDecimalsAndAdd0 } from 'Utils/helperFunctions'

const SummaryHoursTaskAndBudget = ({ totalBudget, totalSpent }) => {
  return (
    <div className="row my-3 gy-md-0 gy-3">
      <div className="col-md-4 col-12 d-flex justify-content-md-start justify-content-center">
        <h3>
          {totalBudget}h <span>Total</span>
        </h3>
      </div>
      <div className="col-md-4 col-12 d-flex justify-content-md-start justify-content-center">
        <h3>
          {countDecimalsAndAdd0({ number: totalSpent })}h <span>Spent</span>
        </h3>
      </div>
      <div className="col-md-4 col-12 d-flex justify-content-md-start justify-content-center">
        <h3>
          {countDecimalsAndAdd0({ number: totalBudget - totalSpent })}h <span>Remaining</span>
        </h3>
      </div>
    </div>
  )
}

export default SummaryHoursTaskAndBudget
