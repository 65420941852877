import {
  connectAzureService,
  connectIntegrationService,
  disconnectIntegrationService,
  getProjectIntegrationService,
  updateIntegrationService,
  getOrganizationAzureService,
  getProjectAzureService,
  postConnectAzureService,
  disconnectAzureService,
  getFieldAzureService
} from 'Services'
import { getDomainByURL } from 'Utils/helperFunctions'
import { useState } from 'react'

export default function useDevops () {
  const [loadingDevopsHook, setLoadingDevopsHook] = useState(false)
  const [listProjectsIntegrationHook, setListProjectsIntegrationHook] = useState([])
  const [listProjectsDefault, setListProjectsDefault] = useState([])
  const [listOrganizationHook, setListOrganizationHook] = useState([])
  const [listFieldAzureHook, setListFieldAzureHook] = useState([])

  const connectIntegrationsHook = async ({
    domain,
    email,
    token,
    project_key,
    projectId,
    fields,
    devops_project_id
  }) => {
    try {
      setLoadingDevopsHook(true)

      const headerIntegration = btoa(
        JSON.stringify({
          host: getDomainByURL({ url: domain }),
          email,
          token,
          project_key,
          devops_project_id
        })
      )

      await connectIntegrationService({ header: headerIntegration, projectId, fields })

      return {
        success: true,
        data: []
      }
    } catch (e) {
      return {
        message: e?.response?.data?.message || 'An error has occurred',
        success: false
      }
    } finally {
      setLoadingDevopsHook(false)
    }
  }

  const updateIntegrationsHook = async ({
    domain,
    email,
    token,
    project_key,
    projectId,
    fields,
    devops_project_id
  }) => {
    try {
      setLoadingDevopsHook(true)

      const headerIntegration = btoa(
        JSON.stringify({
          host: getDomainByURL({ url: domain }),
          email,
          token,
          project_key,
          devops_project_id
        })
      )

      await updateIntegrationService({ header: headerIntegration, projectId, fields })

      return {
        success: true,
        data: []
      }
    } catch (e) {
      return {
        success: false
      }
    } finally {
      setLoadingDevopsHook(false)
    }
  }

  const disconnectIntegrationsHook = async ({ projectId }) => {
    try {
      setLoadingDevopsHook(true)
      await disconnectIntegrationService({ projectId })
      return {
        success: true
      }
    } catch (e) {
      return {
        success: false
      }
    } finally {
      setLoadingDevopsHook(false)
    }
  }

  const getProjectsIntegrationsHook = async ({ domain, email, token }) => {
    try {
      setLoadingDevopsHook(true)

      const headerIntegration = btoa(
        JSON.stringify({
          host: getDomainByURL({ url: domain }),
          email,
          token,
          project_key: null
        })
      )

      const res = await getProjectIntegrationService({ header: headerIntegration })

      const list = res.data.projects.map((item) => ({
        id: item.key,
        name: item.name,
        fields: item.issuetypes,
        project_id_jira: item.id
      }))

      setListProjectsIntegrationHook(list)
      setListProjectsDefault(res.data.default_fields)
      return {
        success: true,
        data: list
      }
    } catch (e) {
      return {
        success: false
      }
    } finally {
      setLoadingDevopsHook(false)
    }
  }

  const connectAzureHook = async ({ projectId }) => {
    try {
      setLoadingDevopsHook(true)
      const res = await connectAzureService({ projectId })
      return {
        success: true,
        data: res.data
      }
    } catch (e) {
      return {
        success: false
      }
    } finally {
      setLoadingDevopsHook(false)
    }
  }

  const getOrganizationAzureHook = async ({ projectId }) => {
    try {
      setLoadingDevopsHook(true)
      const res = await getOrganizationAzureService({ projectId })
      setListOrganizationHook(res.data)
      return res
    } catch (e) {
      return {
        success: false
      }
    } finally {
      setLoadingDevopsHook(false)
    }
  }

  const getProjectAzureHook = async ({ projectId, organizationName }) => {
    try {
      setLoadingDevopsHook(true)
      const res = await getProjectAzureService({ projectId, organizationName })
      setListProjectsIntegrationHook(res.data)
      return res
    } catch (e) {
      return {
        success: false
      }
    } finally {
      setLoadingDevopsHook(false)
    }
  }

  const postConnectAzureHook = async ({ projectId, body }) => {
    try {
      setLoadingDevopsHook(true)
      await postConnectAzureService({ projectId, body })

      return {
        success: true
      }
    } catch (e) {
      return {
        success: false,
        message: e?.response?.data?.message || 'An error occurred while connecting to Azure. Please try again later.'
      }
    } finally {
      setLoadingDevopsHook(false)
    }
  }

  const disconnectAzureIntegrationsHook = async ({ projectId }) => {
    try {
      setLoadingDevopsHook(true)
      await disconnectAzureService({ projectId })

      return {
        success: true
      }
    } catch (e) {
      return {
        success: false
      }
    } finally {
      setLoadingDevopsHook(false)
    }
  }

  const getFieldAzureIntegrationHook = async ({ projectId, projectName, organization }) => {
    try {
      setLoadingDevopsHook(true)
      const res = await getFieldAzureService({ projectId, projectName, organization })
      setListFieldAzureHook(res.data)
    } catch (e) {
      return {
        success: false
      }
    } finally {
      setLoadingDevopsHook(false)
    }
  }

  return {
    loadingDevopsHook,
    listProjectsIntegrationHook,
    listProjectsDefault,
    listOrganizationHook,
    listFieldAzureHook,
    connectIntegrationsHook,
    disconnectIntegrationsHook,
    getProjectsIntegrationsHook,
    updateIntegrationsHook,
    connectAzureHook,
    getOrganizationAzureHook,
    getProjectAzureHook,
    postConnectAzureHook,
    disconnectAzureIntegrationsHook,
    getFieldAzureIntegrationHook
  }
}
