import ColorPaletteItem from './ColorPaletteItem'

const Color = () => {
  return (
    <section className="col-12">
      <h2 className="mt-5">Colors</h2>
      <hr />
      <div className="row">
        <div className="col-6 col-md-4 col-lg mb-3">
          <ColorPaletteItem name="rich-black" backgroundColor="#060A11" textColor="#F3F5F7" />
        </div>
        <div className="col-6 col-md-4 col-lg mb-3">
          <ColorPaletteItem name="celadon" backgroundColor="#31f7f2" textColor="#060A11" />
        </div>
        <div className="col-6 col-md-4 col-lg mb-3">
          <ColorPaletteItem name="slate-gray" backgroundColor="#6D7F9B" textColor="#F3F5F7" />
        </div>
        <div className="col-6 col-md-4 col-lg mb-3">
          <ColorPaletteItem name="alice-blue" backgroundColor="#E2E8F0" textColor="#6D7F9B" />
        </div>
        <div className="col-6 col-md-4 col-lg mb-3">
          <ColorPaletteItem name="alice-blue-2" backgroundColor="#F4F8FC" textColor="#6D7F9B" />
        </div>
        <div className="col-6 col-md-4 col-lg mb-3">
          <ColorPaletteItem name="antiflash-white" backgroundColor="#F3F5F7" textColor="#6D7F9B" />
        </div>
      </div>
    </section>
  )
}

export default Color
