import { useContext } from 'react'
import { ArrowsIn, ArrowsOut, DownloadSimple } from '@phosphor-icons/react'
import ContextColumnTimeEntry from 'Context/ColumnsTimeEntryContext'
import ContextColumnTimeSheet from 'Context/ColumnsTimeSheetContext'
import useSessionStore from 'Context/useSessionStore'
import DraggableList from 'Organisms/DraggableList'
import FilterAllDropdown from 'Organisms/FilterAllDropdown'
import { Loading } from 'Organisms/Loading'
import { COLOR_WHITE } from 'constant/colors'
import { ROLE_DEVELOPER_NAME } from 'constant/roles'

const FiltersTimesheet = ({
  onChangeFilter,
  isCondensed,
  filter,
  onChangeTypeTable,
  onDownloadCSV,
  defaultSelectProject,
  defaultSelectUsers,
  defaultSelectTasks,
  defaultSelectStatus
}) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))
  const { loadingColumnsTimeSheetContext, timeSheetColunm, handleDragEnd, handleChangeActiveSwitch } =
    useContext(ContextColumnTimeSheet)
  const { loadingColumnsTimeEntryContext, timeEntryColunm, handleDragEndTimeEntry, handleChangeActiveSwitchTimeEntry } =
    useContext(ContextColumnTimeEntry)

  return (
    <div className="row justify-content-between mb-4 gy-3">
      {(loadingColumnsTimeSheetContext || loadingColumnsTimeEntryContext) && <Loading />}

      {/* PROJECT, STATE TASK, USER */}
      <FilterAllDropdown
        onChangeFilter={onChangeFilter}
        hiddenUsers={user.role === ROLE_DEVELOPER_NAME}
        hiddenSprint
        defaultSelectProject={defaultSelectProject}
        defaultSelectUsers={defaultSelectUsers}
        defaultSelectTasks={defaultSelectTasks}
        defaultSelectStatus={defaultSelectStatus}

      />

      {/* COLAPSE, DETIALS, DOWNLOAD, COLUMNS */}
      <div className="col-auto">
        <div className="row gy-3">
          <div className="col-auto">
            <div className="btn-group" role="group" id="toggleTimeEntries">
              <button
                type="button"
                className={`btn btn-quaternary ${isCondensed ? 'active' : ''}`}
                title="Condense"
                onClick={() => onChangeTypeTable({ condensed: true, filterP: filter })}>
                <ArrowsIn size={17} weight="bold" /> <span className="small">Aggregated</span>
              </button>
              <button
                type="button"
                className={`btn btn-quaternary ${isCondensed ? '' : 'active'}`}
                title="Expand"
                onClick={() => onChangeTypeTable({ condensed: false, filterP: filter })}>
                <ArrowsOut size={17} weight="bold" /> <span className="small">Detailed</span>
              </button>
            </div>
          </div>
          <div className="col-auto">
            <button className="btn btn-quaternary" type="button" onClick={onDownloadCSV} id="downloadBtn">
              <DownloadSimple className="icon" size={17} color={COLOR_WHITE} weight="bold" /> Export CSV
            </button>
          </div>
          <div className="col-auto" id="dndBtn">
            {isCondensed
              ? (
              <DraggableList
                columns={timeSheetColunm}
                onDragEnd={handleDragEnd}
                onChangeActiveSwitch={handleChangeActiveSwitch}
              />
                )
              : (
              <DraggableList
                columns={timeEntryColunm}
                onDragEnd={handleDragEndTimeEntry}
                onChangeActiveSwitch={handleChangeActiveSwitchTimeEntry}
              />
                )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FiltersTimesheet
