const BASE_URL_SIGN_UP = `${process.env.REACT_APP_API_URL}/signup`

export const postSignUpService = async ({ body }) =>
  fetch(BASE_URL_SIGN_UP, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  }).then((data) => data.json()).then(data => data)
