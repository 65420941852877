import { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
const { document, bootstrap } = window

function useModal ({ identifier = '', visible = false, setVisible = () => {} }) {
  const hide = () => {
    const myModalEl = document.getElementById(`${identifier}`)
    const myModal = bootstrap.Modal.getOrCreateInstance(myModalEl)
    myModal.hide()
  }

  useEffect(() => {
    if (identifier) {
      const myModalEl = document.getElementById(`${identifier}`)
      const myModal = bootstrap.Modal.getOrCreateInstance(myModalEl)
      if (visible) {
        myModal.show()
      } else {
        myModal.hide()
      }
    }
  }, [visible])

  useEffect(() => {
    if (identifier) {
      const myModalEl = document.getElementById(`${identifier}`)
      myModalEl.addEventListener('hidden.bs.modal', () => {
        setVisible(false)
      })
    }
  }, [])

  const Modal = function ({ children, size, center }) {
    return (
      <div
        className="modal fade"
        id={identifier}
        tabIndex="-1"
        aria-labelledby={`${identifier}Label`}
        aria-hidden="true"
      >
        <div className={`modal-dialog ${size || ''} ${center ? 'modalCenter' : ''}`} role="document">
          {children}
        </div>
      </div>
    )
  }

  Modal.propTypes = {
    children: PropTypes.object,
    size: PropTypes.string
  }

  return useMemo(() => ({ Modal, hide }), [identifier])
}

export default useModal
