import SelectWithHint from 'Organisms/SelectWithHint'
import { OPTIONS_WELCOME_STEP } from './listOptions'

const StepWelcome = ({ data, setData, isFirstAdmin }) => {
  return (
    <div>
      <h4>Welcome onboard!</h4>

      {isFirstAdmin
        ? (
        <p>To enhance your experience, please specify the industry you work in.</p>
          )
        : (
        <p>Welcome to Pulse! Let&apos;s turn your minutes into milestones. <br/>Start tracking your productivity now!</p>
          )}

      {isFirstAdmin && (
        <SelectWithHint
          value={data?.my_function ? OPTIONS_WELCOME_STEP.find((item) => item.value === data.my_function) : null}
          id="selectRole"
          placeholder="Select..."
          formatSelect={OPTIONS_WELCOME_STEP}
          handleChange={({ value }) => setData({ ...data, my_function: value })}
        />
      )}
    </div>
  )
}

export default StepWelcome
