import { useState } from 'react'
import { createTaskService, getTasksService } from 'Services'
import { createQueryString } from 'Utils/helperFunctions'

export default function useTasks () {
  const [loadingTaskHook, setLoadingTaskHook] = useState(false)
  const [listTasksHook, setListTasksHook] = useState([])

  const getAllTasksHook = async ({ projectsId } = {}) => {
    try {
      setLoadingTaskHook(true)
      const query = createQueryString({ project_ids: projectsId })
      const tasks = await getTasksService({ query })
      setListTasksHook(tasks?.data || [])
      return tasks?.data || []
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingTaskHook(false)
    }
  }

  const createTaskHook = async ({ taskName }) => {
    try {
      setLoadingTaskHook(true)
      const task = await createTaskService({ taskName })
      return task?.data || {}
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingTaskHook(false)
    }
  }

  // helpers
  const getAllTasksByFilterHook = async ({ projectsId } = {}) => {
    try {
      const tasks = await getAllTasksHook({ projectsId })
      return tasks.map((item) => ({
        id: item.id,
        value: item.id,
        text: item.name,
        checked: false
      }))
    } catch (e) {
      console.error(e)
      return []
    }
  }

  return {
    loadingTaskHook,
    listTasksHook,
    getAllTasksHook,
    createTaskHook,
    getAllTasksByFilterHook
  }
}
