import { useModal } from 'Hooks'

const ContentModalFilter = ({ visible, setVisible, children, onFilter, onClear, disabled }) => {
  const { Modal, hide } = useModal({ identifier: 'modalFilter', visible, setVisible })

  return (
    <Modal>
      <div className="modal-content" style={{ maxWidth: 400 }}>
        <div className="modal-body p-3">
          <button type="button" className="btn-close modalConnect_close" onClick={hide} />
          <h3>Filter</h3>

          <hr />

          {children}

          <hr />

          <div className="d-flex justify-content-center">
            <button
              disabled={disabled}
              type="button"
              className="btn btn-secondary px-4"
              onClick={() => {
                onClear()
                hide()
              }}>
              Clear
            </button>
            <button
              disabled={disabled}
              type="button"
              className="btn btn-primary ms-4 px-4"
              onClick={() => {
                onFilter()
                hide()
              }}>
              Apply
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ContentModalFilter
