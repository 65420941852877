import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import useSessionStore from './Context/useSessionStore'
import RequireAuth from './Organisms/RequireAuth'
import Navbar from './Organisms/Navbar'
import DesignSystem from './Pages/DesignSystem'
import Developer from './Templates/Developer'
import Login from './Pages/Login'
import Signup from './Pages/Signup'
import ForgotPassword from './Pages/ForgotPassword'
import ResetPassword from './Pages/ResetPassword'
import Users from './Pages/Users'
import LearnCenter from './Pages/LearnCenter'
import { Profile } from './Pages/Profile'
import { Dashboard } from 'Pages/Dashboard'
import Projects from 'Pages/Projects'
import { ProjectPage } from 'Pages/ProjectPage'
import { Welcome } from 'Pages/Welcome'

import {
  ROLE_ADMIN_NAME,
  ROLE_DEVELOPER_NAME,
  ROLE_OPERATION_MANAGER_NAME,
  ROLE_PRODUCT_OWNER_NAME
} from 'constant/roles'
import {
  BECOME_PARTNER_ROUTER,
  CHECKOUT_ROUTER,
  CONTACTUS_ROUTER,
  CONFIRM_SIGNUP_ROUTER,
  DASHBAORD_ROUTER,
  DESIGN_ROUTER,
  FORGOT_PASSWORD_ROUTER,
  LEARN_CENTER_ROUTER,
  LOGIN_ROUTER,
  SIGNUP_ROUTER,
  PAYROLL_ROUTER,
  PRICING_ROUTER,
  PROFILE_DETAILS_ROUTER,
  PROJECT_DETAILS_ROUTER,
  PROJECT_ROUTER,
  RESET_PASSWORD_ROUTER,
  SETTINGS_ROUTER,
  SUPPORT_ROUTER,
  TIME_ROUTER,
  USER_ROUTER,
  WELCOME_ROUTER,
  LANDING_PAGE_ROUTER,
  COOKIE_POLICY_ROUTER
} from 'constant/router'

import Checkout from 'Pages/Checkout'
import { TimeSheetContextProvider } from 'Context/ColumnsTimeSheetContext'
import { TimeEntryContextProvider } from 'Context/ColumnsTimeEntryContext'
import { useEffect } from 'react'
import { PERMISSION_BY_ROLE } from 'Utils/permissionByRol'
import { SettingPage } from 'Pages/SettingPage'
import { PricingPage } from 'Pages/PricingPage'
import { LandingPage } from 'Pages/LandingPage'
import { CookiePolicy } from 'Pages/Legal'
import StripeContext from 'Context/StripeContext'
import { BecomePartner, ContactUs, Support } from 'Pages/CustomerService'
import TrackPageView from 'Organisms/TrackPageView'
import { initializeAnalytics } from 'Utils/GoogleAnalytics'
import { SuscriptionToNewsLatter } from 'Organisms/SuscriptionToNewsLatter'
import { TutorialContextProvider } from 'Context/tutorialContext'
import { PayrollPage } from 'Pages/Payroll'

/* import { checkTokenExpirationAndRefresh } from 'Hooks/useAuth' */

const hideNavbar = [
  FORGOT_PASSWORD_ROUTER,
  RESET_PASSWORD_ROUTER,
  CONFIRM_SIGNUP_ROUTER,
  CHECKOUT_ROUTER,
  WELCOME_ROUTER
]

function Main () {
  const navigate = useNavigate()
  const { user, updateUser } = useSessionStore((state) => ({ user: state.user, updateUser: state.updateUser }))

  const isAdmin = user?.role === ROLE_ADMIN_NAME

  useEffect(() => {
    initializeAnalytics()
  }, [])

  useEffect(() => {
    if (user?.id && localStorage.getItem('amplify-signin-with-hostedUI') === null) {
      updateUser(null)
      return navigate(LOGIN_ROUTER)
    }
  })

  /* useEffect(() => {
        const expirationCheckInterval = 60000 // 60000 Check every 1 minute
        const intervalId = setInterval(checkTokenExpirationAndRefresh, expirationCheckInterval)
        return () => clearInterval(intervalId) // Clear interval on component unmount
    }, []) */

  const customClass = () => {
    let classes = ''

    if ([LANDING_PAGE_ROUTER, PRICING_ROUTER].includes(location.pathname)) classes += 'content-pb-0 '
    if (location.pathname === SIGNUP_ROUTER) classes += 'content-condese '

    if (!user || (user && user.theme)) classes += 'dark-theme '
    if (hideNavbar.includes(location.pathname)) classes += 'content_out '

    return classes
  }

  return (
    <TutorialContextProvider>
      <Navbar user={user} theme={!user || (user && user.theme) ? 'dark-theme' : ''} hideNavbar={hideNavbar} />
      <TrackPageView />

      {user && !user?.init_config && user.company_is_active && <SuscriptionToNewsLatter />}

      <div className={`container-fluid content ${customClass()}`}>
        {user && (!isAdmin || user?.company_is_active) && (
          <Routes>
            <Route
              path={DASHBAORD_ROUTER}
              element={
                <RequireAuth
                  scope={[ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME, ROLE_PRODUCT_OWNER_NAME, ROLE_DEVELOPER_NAME]}>
                  <Dashboard />
                </RequireAuth>
              }
            />
            <Route
              path={PROFILE_DETAILS_ROUTER()}
              element={
                <RequireAuth scope={[ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME, ROLE_PRODUCT_OWNER_NAME]}>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path={TIME_ROUTER}
              element={
                <TimeSheetContextProvider>
                  <TimeEntryContextProvider>
                    <RequireAuth
                      scope={[
                        ROLE_ADMIN_NAME,
                        ROLE_OPERATION_MANAGER_NAME,
                        ROLE_PRODUCT_OWNER_NAME,
                        ROLE_DEVELOPER_NAME
                      ]}>
                      <Developer />
                    </RequireAuth>
                  </TimeEntryContextProvider>
                </TimeSheetContextProvider>
              }
            />

            <Route
              path={PAYROLL_ROUTER}
              element={
                <TimeSheetContextProvider>
                  <TimeEntryContextProvider>
                    <RequireAuth scope={[ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME, ROLE_PRODUCT_OWNER_NAME, ROLE_DEVELOPER_NAME]}>
                      <PayrollPage />
                    </RequireAuth>
                  </TimeEntryContextProvider>
                </TimeSheetContextProvider>
              }
            />

            <Route
              path={USER_ROUTER}
              element={
                <RequireAuth scope={PERMISSION_BY_ROLE.USER.VIEW_PAGE}>
                  <Users />
                </RequireAuth>
              }
            />

            <Route
              path={PROJECT_DETAILS_ROUTER()}
              element={
                <RequireAuth scope={[ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME, ROLE_PRODUCT_OWNER_NAME]}>
                  <ProjectPage />
                </RequireAuth>
              }
            />

            <Route
              path={PROJECT_ROUTER}
              element={
                <RequireAuth scope={[ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME, ROLE_PRODUCT_OWNER_NAME]}>
                  <Projects />
                </RequireAuth>
              }
            />

            <Route
              path={LEARN_CENTER_ROUTER}
              element={
                <RequireAuth
                  scope={[ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME, ROLE_PRODUCT_OWNER_NAME, ROLE_DEVELOPER_NAME]}>
                  <LearnCenter />
                </RequireAuth>
              }
            />

            <Route
              path={SETTINGS_ROUTER}
              element={
                <StripeContext>
                  <RequireAuth
                    scope={[
                      ROLE_ADMIN_NAME,
                      ROLE_OPERATION_MANAGER_NAME,
                      ROLE_PRODUCT_OWNER_NAME,
                      ROLE_DEVELOPER_NAME
                    ]}>
                    <SettingPage />
                  </RequireAuth>
                </StripeContext>
              }
            />

            <Route
              path={PRICING_ROUTER}
              element={
                <RequireAuth scope={[ROLE_ADMIN_NAME]}>
                  <PricingPage />
                </RequireAuth>
              }
            />

            <Route path="*" element={<Navigate to={DASHBAORD_ROUTER} />} />
            <Route path={CHECKOUT_ROUTER} element={<Checkout />} />
            <Route path={SUPPORT_ROUTER} element={<Support />} />
          </Routes>
        )}

        {user && isAdmin && !user?.company_is_active && (
          <Routes>
            <Route path={PRICING_ROUTER} element={<PricingPage />} />
            <Route path="*" element={<Navigate to={PRICING_ROUTER} />} />
          </Routes>
        )}

        {!user && (
          <Routes>
            <Route path={LANDING_PAGE_ROUTER} element={<LandingPage />} />
            <Route path={CONTACTUS_ROUTER} element={<ContactUs />} />
            <Route path={BECOME_PARTNER_ROUTER} element={<BecomePartner />} />
            <Route path={CHECKOUT_ROUTER} element={<Checkout />} />
            <Route path={PRICING_ROUTER} element={<PricingPage />} />
            <Route path={DESIGN_ROUTER} element={<DesignSystem />} />
            <Route path={FORGOT_PASSWORD_ROUTER} element={<ForgotPassword />} />
            <Route path={RESET_PASSWORD_ROUTER} element={<ResetPassword />} />
            <Route path={COOKIE_POLICY_ROUTER} element={<CookiePolicy />} />
            <Route path={WELCOME_ROUTER} element={<Welcome />} />
            <Route path={LOGIN_ROUTER} element={<Login />} />
            <Route path={SIGNUP_ROUTER} element={<Signup />} />
            <Route path="*" element={<Navigate to={LANDING_PAGE_ROUTER} />} />
          </Routes>
        )}
      </div>
    </TutorialContextProvider>
  )
}

export default Main
