import { useNavigate } from 'react-router-dom'
import { Clock, Plus } from '@phosphor-icons/react'
import { GET_COLOR_BY_THEME } from 'constant/colors'
import { TIME_ROUTER } from 'constant/router'
import useSessionStore from 'Context/useSessionStore'

const DashboardHoursEmpty = () => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const navigate = useNavigate()

  return (
    <div className="card p-4 dashboardHoursEmpty">
      <div className="row gy-3">
        <div className="col-12 d-grid justify-content-center">
          <Clock color={GET_COLOR_BY_THEME({ theme: user.theme }).PRIMARY} size={90} />
        </div>
        <div className="col-12 text-center">
          <h4>There is still no hours reported here!</h4>
          <p>Remember to report your hours in order to make your performance metrics visible here</p>
        </div>
        <div className="col-12 d-grid justify-content-center">
          <button type="button" className="btn btn-primary" onClick={() => navigate(TIME_ROUTER)}>
            <Plus className="icon" size={15} color={GET_COLOR_BY_THEME({ theme: user.theme }).AVATAR_TEXT} weight="bold" /> <span>Submit hours</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default DashboardHoursEmpty
