import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { support } from 'Services/Loopboard.service'

const Support = () => {
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      const obj = {
        organizationId: 'dh72FtKEz',
        workflowId: 'vb6kAYU3v',
        title: 'Pulse - Support Form',
        column: 'qPoeEJKpF',
        data: {
          '362d21b0-4c65-11ef-b5bf-3b05159e37f0': 'juan',
          '3d852ac0-4c65-11ef-b5bf-3b05159e37f0': 'canned',
          '47450c10-4c65-11ef-b5bf-3b05159e37f0': 'jdavid@cannedhea',
          '4e0b8380-4c65-11ef-b5bf-3b05159e37f0': data.message,
          date: '2024-07-01'
        },
        externalFormId: 'kZJHy7Rr3',
        files: []
      }
      const res = await support(obj)
      console.log(res)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  return (
        <>
            <Helmet>
                <title>Support</title>
                <meta
                name="description"
                content="Discover the best time tracking software designed htmlFor remote employees. Boost productivity, manage time efficiently, and gain insights into your remote team's performance."
                />
                <meta name="keywords" content="time tracker, time tracking, remote employees, productivity, software, remote work" />
                <meta name="author" content="Pulse by Canned Head" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Time Tracking Software htmlFor Remote Employees" />
                <meta property="og:description" content="Discover the best time tracking software designed htmlFor remote employees. Boost productivity, manage time efficiently, and gain insights into your remote team's performance." />
                <meta property="og:image" content="https://example.com/image.jpg" />
                <meta property="og:url" content="https://example.com/time-tracking-software" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className='contactUs container-fluid'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-md-6'>
                            <h1>Support and feedback</h1>
                            <p>Got any questions about our product, report a bug, request a new feature or share your feedback.</p>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-md-6 mx-auto' id="hubspotForm"></div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-md-6 mx-auto'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlTextarea1" className="form-label">Message</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="6" {...register('message', { required: true })}></textarea>
                            {errors.exampleRequired && <span>This field is required</span>}
                          </div>
                          <input type="submit" className="btn btn-primary" disabled={loading} value="Submit"/>
                          <p className='mt-3 small'>By submitting. you agree to Pulse <a className="text-active" href="/terms-of-service">TOS</a> and <a className="text-active" href="/privacy-policy">Privacy Policy</a>.</p>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default Support
