import { useContext, useState } from 'react'
import { TabsListTable } from 'Organisms/TabsListTable'
import { TimeTracker } from 'Pages/TimeTracker'
import ContextTutorial from 'Context/tutorialContext'
import { useLocation, useNavigate } from 'react-router-dom'
import { Timesheet } from 'Pages/Timesheet'

const TABS_TIMES = {
  TIME_TRACKER: {
    name: 'tracker'
  },
  TIME_SHEET: {
    name: 'timesheet'
  }
}

export const PARAMS_URL_IS_TIMESHEET = 'it'

const Developer = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const defaultActiveTab = queryParams.get(PARAMS_URL_IS_TIMESHEET)

  const [activeTab, setActiveTab] = useState(defaultActiveTab ? TABS_TIMES.TIME_SHEET.name : TABS_TIMES.TIME_TRACKER.name)
  const { openTutorialContext, handleOpenTutorialContext } = useContext(ContextTutorial)

  const handleChangeTab = ({ tab }) => {
    if (openTutorialContext) handleOpenTutorialContext()

    if (tab === TABS_TIMES.TIME_SHEET.name) {
      queryParams.set(PARAMS_URL_IS_TIMESHEET, 'y')
    } else {
      queryParams.delete(PARAMS_URL_IS_TIMESHEET)
    }

    navigate(`?${queryParams.toString()}`, { replace: true })

    setActiveTab(tab)
  }

  return (
    <div className="containers">
      <TabsListTable tabs={TABS_TIMES} activeTab={activeTab} onChange={handleChangeTab} />

      {activeTab === TABS_TIMES.TIME_TRACKER.name && <TimeTracker />}
      {activeTab === TABS_TIMES.TIME_SHEET.name && <Timesheet />}
    </div>
  )
}

export default Developer
