import axios from 'axios'
import { currentSession } from '../Hooks/useAuth'
import { LOGIN_ROUTER } from 'constant/router'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.interceptors.request.use(
  async (config) => {
    try {
      const session = await currentSession()
      if (config.baseURL.includes(process.env.REACT_APP_API_URL) && session) {
        if (!(config.data instanceof FormData)) {
          config.headers['Content-Type'] = 'application/json; charset=utf-8'
        }
        config.headers.Authorization = 'Bearer ' + session.idToken.jwtToken
      }
    } catch (e) {
      if (e === 'No current user') {
        window.location.href = LOGIN_ROUTER
      } else {
        console.error(e)
      }
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

export * from './Clients'
export * from './Users'
export * from './Projects'
export * from './Tasks'
export * from './TimeEntries'
export * from './Timesheet'
export * from './Profile.service'
export * from './Devops.service'
export * from './SignUp.service'
export * from './company.service'
export * from './Payment.service'
