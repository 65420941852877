import { useDevops } from 'Hooks'
import { useEffect } from 'react'
import { FIELD_CONNECT_AZURE } from './ModalConnectAzure'
import { Loading } from 'Organisms/Loading'

const ChooseOrganizationAzureIntegration = ({ project, register, errors }) => {
  const { listOrganizationHook, loadingDevopsHook, getOrganizationAzureHook } = useDevops()

  const getOrganization = async () => {
    await getOrganizationAzureHook({ projectId: project.id })
  }

  useEffect(() => {
    getOrganization()
  }, [])

  return (
    <>
      {loadingDevopsHook && <Loading />}
      <div className="row">
        <label className="col-12 col-form-label" htmlFor="inputOrganization">
          Choose an organization to link
        </label>
        <div className="col-12">
          <select
            {...register(FIELD_CONNECT_AZURE.ORGANIZATION_NAME, { required: true })}
            className={`form-control form-select ${errors && errors[FIELD_CONNECT_AZURE.ORGANIZATION_NAME] ? 'is-invalid' : ''}`}
            id="inputOrganization">
            <option value="">Select a Organization</option>

            {listOrganizationHook.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <div className="invalid-feedback">Please select an Organization.</div>
        </div>
      </div>
    </>
  )
}

export default ChooseOrganizationAzureIntegration
