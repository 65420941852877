import { Popover } from 'Organisms/Popover'
import FilterUserPopover from './FilterUserPopover'
import { Funnel } from '@phosphor-icons/react'
import { COLOR_BLACK } from 'constant/colors'
import { useState } from 'react'

const PopoverFilterUser = ({ onFilter }) => {
  const [resultChildren, setResultChildren] = useState(null)

  const handleFilter = (filter) => {
    setResultChildren(filter)
  }

  return (
    <Popover
      buttonStyle="btn-quaternary w-100"
      buttonShow={
        <>
          <Funnel className="icon" size={15} color={COLOR_BLACK} weight="bold" /> <span>Filter</span>
        </>
      }>
      <FilterUserPopover onFilter={onFilter} filter={resultChildren} updateState={handleFilter} />
    </Popover>
  )
}

export default PopoverFilterUser
