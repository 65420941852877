import { CaretRight } from '@phosphor-icons/react'
import { COLOR_BLUE_MATE } from 'constant/colors'

const BreakPointsPayroll = ({ user, project }) => {
  return (
    <div className="d-flex gap-2 align-items-center mb-4" id="breadcrumbs">
      {user && (
        <>
          <p className="m-0">Payroll</p>
          <CaretRight size={15} color={COLOR_BLUE_MATE} />
          <p className="m-0">{user.full_name || ''}</p>
        </>
      )}

      {project && (
        <>
          <CaretRight size={15} color={COLOR_BLUE_MATE} />
          <p className="m-0">{`[${project.code}] ${project.name}`}</p>
        </>
      )}
    </div>
  )
}

export default BreakPointsPayroll
