export const PERIOD_TIME_SHEET = {
  CUSTOM: 'custom',
  SEMIMONTH: 'semimonth',
  ALL_TIME: 'all',
  WEEK: 'week',
  MONTH: 'month',
  QUARTER: 'quarter',
  YEAR: 'year'
}

export const MAX_QUANTITY_DAYS_OF_PERIOD = {
  WEEK: 7,
  SEMIMONTH: 15
}

export const LIST_PERIOD_TIME_SHEET = [
  {
    name: 'Week',
    value: PERIOD_TIME_SHEET.WEEK
  },
  {
    name: 'Semimonth',
    value: PERIOD_TIME_SHEET.SEMIMONTH
  },
  {
    name: 'Month',
    value: PERIOD_TIME_SHEET.MONTH
  },
  {
    name: 'Quarter',
    value: PERIOD_TIME_SHEET.QUARTER
  },
  {
    name: 'Year',
    value: PERIOD_TIME_SHEET.YEAR
  },
  {
    name: 'All time',
    value: PERIOD_TIME_SHEET.ALL_TIME
  },
  {
    name: 'Custom',
    value: PERIOD_TIME_SHEET.CUSTOM
  }
]

export const PARAMS_URL_TIMESHEET = {
  PERIOD: 'p',
  FRONT_TO_DATE: 'ft',
  IS_DETAILS: 'd',
  USERS: 'u',
  PROJECTS: 'pj',
  TASKS: 't',
  STATES: 'st',
  SEARCH: 'sh',
  SPRINT: 'sp',
  PAGE: 'pg',
  LIMIT: 'lm'
}

export const ONBOARDING_STEPS_TRACKER = [
  {
    target: 'clearFilter',
    title: 'Filter your data',
    text: 'Use the filter to check time entries from specific dates, projects & users.',
    offsetX: 200,
    offsetY: -100
  },
  {
    target: 'toggleTimeEntries',
    title: 'Get an in-depth look of the tracked time',
    text: 'Switch between aggregated or detailed time entries.',
    offsetX: 0,
    offsetY: -100
  },
  {
    target: 'downloadBtn',
    title: 'Export your time entries',
    text: 'Download a CSV fie with all your filtered data.',
    offsetX: 50,
    offsetY: -100
  },
  {
    target: 'dndBtn',
    title: 'Manage your data visualization',
    text: 'Reorder and display the columns that matter.',
    imgUrl: 'https://d3m37vrpuudhl6.cloudfront.net/onboarding/reorder_columns.gif',
    offsetX: 100,
    offsetY: -100
  }
]
