import { CheckCircle, WarningCircle } from '@phosphor-icons/react'
import useSessionStore from 'Context/useSessionStore'
import { useModal } from 'Hooks'
import { COLOR_RED, GET_COLOR_BY_THEME } from 'constant/colors'

const ModalSuccess = ({ visible, setVisible, title, content, Icon, identifier = 'modalSuccess', error, width = 300, btnText = 'Close' }) => {
  const { Modal, hide } = useModal({ identifier, visible, setVisible })

  const { user } = useSessionStore((state) => ({ user: state.user }))

  const theme = user !== null ? user.theme : true

  return (
    <Modal center>
      <div className="modal-content modalSuccess" style={{ maxWidth: width }}>
        <div className="modal-body p-3">
          <div className="row justify-content-center">
            {Icon
              ? (
              <Icon size={60} color={error ? COLOR_RED : GET_COLOR_BY_THEME({ theme }).PRIMARY} />
                )
              : (
              <>
                {error
                  ? (
                  <WarningCircle size={60} color={COLOR_RED} />
                    )
                  : (
                  <CheckCircle size={60} color={GET_COLOR_BY_THEME({ theme }).PRIMARY} />
                    )}
              </>
                )}
          </div>
          <div className="row justify-content-center py-4" style={{ wordWrap: 'break-word' }}>
            {title && <p className="text-center m-0">{title}</p>}
            {content && content}
          </div>
          <div className="row justify-content-center">
            <div className="col-auto">
              <button
                type="button"
                id="closeModalMessage"
                onClick={hide}
                className={`btn ${error ? 'btn-danger' : 'btn-primary'}`}>
                {btnText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalSuccess
