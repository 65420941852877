import { COLOR_GREY } from 'constant/colors'
import { CaretRight } from '@phosphor-icons/react'

const FAQsPricing = () => {
  return (
    <div className="row justify-content-center faq m-0 py-5">
      <div className="col-12 col-lg-9">
        <div className="row">
          <h2 className="text-center">FAQ</h2>
        </div>
        <p className="row">
          <button
            className="btn text-start"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse1"
            aria-expanded="false"
            aria-controls="collapse1">
            <CaretRight size={24} weight="bold" color={COLOR_GREY} /> Who is responsible for account&apos;s payment?
          </button>
        </p>
        <div className="collapse" id="collapse1">
          <div className="card card-body">
            <p>
              Any user can upgrade from the Starter plan to the Professional plan by entering payment information. (This
              will make them a admin.) After upgrading, only admins can access the Admin features and manage future
              payments for the account.
            </p>
          </div>
        </div>
        <p className="row">
          <button
            className="btn text-start"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse2"
            aria-expanded="false"
            aria-controls="collapse2">
            <CaretRight size={24} weight="bold" color={COLOR_GREY} /> What if more people are added to my account every
            month?
          </button>
        </p>
        <div className="collapse" id="collapse2">
          <div className="card card-body">
            <p>
              You can add new users to your account at any time. If you originally purchased the annual Professional
              plan, additional users can be added to a monthly or annual plan, you will be charged a prorated fee based
              on the number of days they will use the service until the month/year ends.
            </p>
          </div>
        </div>
        <p className="row">
          <button
            className="btn text-start"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse3"
            aria-expanded="false"
            aria-controls="collapse3">
            <CaretRight size={24} weight="bold" color={COLOR_GREY} /> How can I adjust who has admin privileges?
          </button>
        </p>
        <div className="collapse" id="collapse3">
          <div className="card card-body">
            <p>
              Any admin can adjust the permissions of any other member. You can change permissions of each member on the
              User&apos;s page. There is no limit on the number of admins on the account.
            </p>
          </div>
        </div>
        <p className="row">
          <button
            className="btn text-start"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse4"
            aria-expanded="false"
            aria-controls="collapse4">
            <CaretRight size={24} weight="bold" color={COLOR_GREY} /> Can we switch our billing frequency?
          </button>
        </p>
        <div className="collapse" id="collapse4">
          <div className="card card-body">
            <p>
              If you’re paying monthly, you can switch to yearly payment at any time. If you paid for the year in
              advance, you can switch to monthly payment at the end of your current yearly billing cycle.
            </p>
          </div>
        </div>
        <p className="row">
          <button
            className="btn text-start"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse5"
            aria-expanded="false"
            aria-controls="collapse5">
            <CaretRight size={24} weight="bold" color={COLOR_GREY} /> Can I have more than one account?
          </button>
        </p>
        <div className="collapse" id="collapse5">
          <div className="card card-body">
            <p>
              Yes, any active user can have more than one account and switch permissions between them. For example you
              can be an Admin on an account A and a regular user on an account B.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQsPricing
