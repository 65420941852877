import axios from 'axios'

const BASE_URL_USER = '/metrics/daily'

// GET MY HOURS
export const getMyHoursServices = async ({ query }) =>
  axios.get(`${BASE_URL_USER}/hours${query && `?${query}`}`).then(({ data }) => data)

export const getMyHoursPerProjectServices = async ({ query }) =>
  axios.get(`${BASE_URL_USER}/project/hours${query && `?${query}`}`).then(({ data }) => data)

export const getMyHoursPerTaskServices = async ({ query }) =>
  axios.get(`${BASE_URL_USER}/task/hours${query && `?${query}`}`).then(({ data }) => data)

// GET HOURS ANOTHER USER
export const getHoursByUserServices = async ({ query, user_id }) =>
  axios.get(`${BASE_URL_USER}/user/${user_id}/hours${query && `?${query}`}`).then(({ data }) => data)

export const getHoursPerProjectByUserServices = async ({ query, user_id }) =>
  axios.get(`${BASE_URL_USER}/user/${user_id}/project/hours${query && `?${query}`}`).then(({ data }) => data)

export const getHoursPerTaskByUserServices = async ({ query, user_id }) =>
  axios.get(`${BASE_URL_USER}/user/${user_id}/project/hours${query && `?${query}`}`).then(({ data }) => data)
