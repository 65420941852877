import useSessionStore from 'Context/useSessionStore'
import { getIconCardByType } from 'Utils/creditCardType'
import { DateTime } from 'luxon'

const PaymentCard = ({ brand, last4, exp_month, exp_year }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const Icon = getIconCardByType({ type: brand })

  return (
    <div className="col">
      <div className="row">
        <div className="col-auto">
          <div className="card creditCardWrapper">
            <Icon theme={user.theme} />
          </div>
        </div>
        <div className="col-auto">
          {last4 && (
            <div className="row">
              <p className="text-capitalize">
                {brand} ending in {last4}
              </p>
            </div>
          )}
          {exp_month && (
            <div className="row">
              <p>
                Expiry on {DateTime.fromObject({ month: exp_month }).toFormat('LLL')} {exp_year}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PaymentCard
