import { Check } from '@phosphor-icons/react'
import useSessionStore from 'Context/useSessionStore'
import { GET_COLOR_BY_THEME } from 'constant/colors'

const CardPricing = ({
  onChoosePlan,
  creditCardRequired,
  title,
  mode,
  period,
  btnChoose,
  description,
  including,
  btnColor
}) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  return (
    <div className="col-12 col-md-4 col-lg-3">
      <div className="card p-5 starter-plan">
        <div className="row">
          <h3 className="mb-4">{title}</h3>
        </div>
        <div className="row">
          <h2 className="mb-3 text-center p-0">{mode}</h2>
        </div>
        <div className="row justify-content-center">
          {!creditCardRequired && (
            <div className="col-auto">
              <span className="badge text-bg-terciary d-inline-flex mb-3 mb-lg-5">Forever</span>
            </div>
          )}
          {creditCardRequired && <p className="mb-3 mb-lg-5 text-center text-quinary">{period}</p>}
        </div>
        <div className="row">
          <button
            onClick={onChoosePlan}
            type="button"
            className={`btn ${btnColor} mb-2 ${creditCardRequired ? 'mb-5' : ''}`}>
            {btnChoose}
          </button>
          {!creditCardRequired && <span className="small mb-5 text-center text-normal">*No credit card required</span>}
        </div>
        {description && (
          <div className={`row ${creditCardRequired ? 'mt-4' : ''}`}>
            <p className="mb-3">{description}</p>
          </div>
        )}
        {(including || []).map((inclu, index) => (
          <div className="row" key={index}>
            <p>
              <Check
                size={12}
                weight="bold"
                color={GET_COLOR_BY_THEME({ theme: user?.theme ?? true }).PRIMARY}
                className="me-1"
              />
              {inclu}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CardPricing
