import { useDevops, useModal } from 'Hooks'
import { emailValidate, formatUrlValidate, getDomainByURL, getListFieldsByProjectKey } from 'Utils/helperFunctions'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { BasicInfoIntegrationModal } from './Components/BasicInfoIntegrationModal'
import { ConnectFieldsIntegrationModal } from './Components/ConnectFieldsIntegrationModal'
import { Loading } from 'Organisms/Loading'
import { SelectProjectIntegrationModal } from './Components/SelectProjectIntegrationModal'
import { FIELD_CONNECT_JIRA } from './const/connectJiraFields'

const INTEGRATIONS_STEPS = {
  BASIC_INFO: 'BASIC_INFO',
  SELECT_PROJECT: 'SELECT_PROJECT',
  CONNECT_FIELDS: 'CONNECT FIELDS'
}

const ConnectModal = ({ onClose, logo, type, onConnect, edit, onError }) => {
  const { Modal, hide } = useModal({ identifier: 'modalIntegration', visible: true, setVisible: onClose })

  const [listOptionsField, setListOptionsField] = useState([])
  const [projectIdJira, setProjectIdJira] = useState(null)
  const [step, setStep] = useState(INTEGRATIONS_STEPS.BASIC_INFO)

  const { loadingDevopsHook, listProjectsIntegrationHook, listProjectsDefault, getProjectsIntegrationsHook } =
    useDevops()

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    setValue
  } = useForm({
    defaultValues: {
      [FIELD_CONNECT_JIRA.DOMAIN]: edit?.domain ?? '',
      [FIELD_CONNECT_JIRA.EMAIL]: edit?.email ?? '',
      [FIELD_CONNECT_JIRA.COMPLETED_WORK]: edit?.completed_work ?? '',
      [FIELD_CONNECT_JIRA.ESTIMATE]: edit?.estimate ?? '',
      [FIELD_CONNECT_JIRA.SPRINT]: edit?.sprint ?? '',
      [FIELD_CONNECT_JIRA.STORY_POINTS]: edit?.story_points ?? ''
    }
  })

  const onSubmit = async (data) => {
    if (!emailValidate({ email: data.email })) return setError(FIELD_CONNECT_JIRA.EMAIL)

    if (!formatUrlValidate({ url: data.domain })) {
      return setError(FIELD_CONNECT_JIRA.DOMAIN, { type: 'format', message: 'Format is not valid' })
    }

    if (!getDomainByURL({ url: data.domain })) {
      return setError(FIELD_CONNECT_JIRA.DOMAIN, { type: 'format', message: 'Error getting domain' })
    }

    if (step === INTEGRATIONS_STEPS.BASIC_INFO) {
      const res = await getProjectsIntegrationsHook({
        domain: data[FIELD_CONNECT_JIRA.DOMAIN],
        email: data[FIELD_CONNECT_JIRA.EMAIL],
        token: data[FIELD_CONNECT_JIRA.TOKEN]
      })

      if (!res.success) {
        hide()
        return onError()
      }

      edit &&
        setValue('project_key', res.data.find((item) => item.project_id_jira === edit.devops_project_id)?.id ?? '')

      return setStep(INTEGRATIONS_STEPS.SELECT_PROJECT)
    }

    if (step === INTEGRATIONS_STEPS.SELECT_PROJECT) {
      const newProjectKeyJira = listProjectsIntegrationHook.find((item) => item.id === data.project_key).project_id_jira

      setProjectIdJira(newProjectKeyJira)

      if (edit && newProjectKeyJira !== edit.devops_project_id) {
        setValue(FIELD_CONNECT_JIRA.COMPLETED_WORK, '')
        setValue(FIELD_CONNECT_JIRA.ESTIMATE, '')
        setValue(FIELD_CONNECT_JIRA.SPRINT, '')
        setValue(FIELD_CONNECT_JIRA.STORY_POINTS, '')
      }

      const res = getListFieldsByProjectKey({
        projectKey: data.project_key,
        listProjects: listProjectsIntegrationHook,
        listDefault: listProjectsDefault
      })
      setListOptionsField(res)
      return setStep(INTEGRATIONS_STEPS.CONNECT_FIELDS)
    }

    hide()
    onConnect({ data, project_id_jira: projectIdJira })
  }

  const handleClose = () => {
    if (step === INTEGRATIONS_STEPS.SELECT_PROJECT) return setStep(INTEGRATIONS_STEPS.BASIC_INFO)
    if (step === INTEGRATIONS_STEPS.CONNECT_FIELDS) return setStep(INTEGRATIONS_STEPS.SELECT_PROJECT)

    hide()
  }

  return (
    <Modal onClose={hide}>
      <>
        {loadingDevopsHook && <Loading />}
        <div className="modal-content p-5 connectModal" style={{ maxWidth: 500 }}>
          <div className="modal-header">
            <div className="row text-center gy-2">
              <div className="col-12 d-grid justify-content-center">
                <div className="connectModal_logo">
                  <img src={logo} />
                </div>
              </div>
              <div className="col-12">
                <h3 className="modal-title fs-5 text-capitalize" id="exampleModalLabel">
                  {[INTEGRATIONS_STEPS.BASIC_INFO, INTEGRATIONS_STEPS.SELECT_PROJECT].includes(step)
                    ? `Add ${type} Integration`
                    : 'Set up integration'}
                </h3>
              </div>
              <div className="col-12">
                <p className="m-0">
                  {[INTEGRATIONS_STEPS.BASIC_INFO, INTEGRATIONS_STEPS.SELECT_PROJECT].includes(step)
                    ? `Please help us with your ${type} account credentials to get your ${type} dashboard integrated.`
                    : 'These were the fields assigned for your project, help us to do the right match so we can get everything ready for you to start!'}
                </p>
              </div>
            </div>
            <button type="button" className="btn-close modalConnect_close" onClick={() => hide()} />
          </div>

          <div className="modal-body">
            <form id="modalConnect" className="d-grid" onSubmit={handleSubmit(onSubmit)}>
              {step === INTEGRATIONS_STEPS.BASIC_INFO && (
                <BasicInfoIntegrationModal register={register} errors={errors} />
              )}

              {step === INTEGRATIONS_STEPS.SELECT_PROJECT && (
                <SelectProjectIntegrationModal
                  register={register}
                  errors={errors}
                  options={listProjectsIntegrationHook}
                />
              )}

              {step === INTEGRATIONS_STEPS.CONNECT_FIELDS && (
                <ConnectFieldsIntegrationModal register={register} errors={errors} options={listOptionsField} />
              )}
              <div className="row justify-content-between">
                <div className="col-auto">
                  <button type="button" onClick={handleClose} className="btn btn-secondary">
                    {[INTEGRATIONS_STEPS.SELECT_PROJECT, INTEGRATIONS_STEPS.CONNECT_FIELDS].includes(step)
                      ? 'Back'
                      : 'Cancel'}
                  </button>
                </div>
                <div className="col-auto">
                  <button type="submit" className="btn btn-primary">
                    {[INTEGRATIONS_STEPS.BASIC_INFO, INTEGRATIONS_STEPS.SELECT_PROJECT].includes(step)
                      ? 'Next'
                      : 'Connect'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    </Modal>
  )
}

export default ConnectModal
