import { AzureIntegration } from './AzureIntegration'
import JiraIntegration from './JiraIntegration/JiraIntegration'

const Integrations = ({ project, refreshProjectInfo }) => {
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-lg-11 col-12 d-grid">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-3">Integrations</h2>
              <p className="mb-4">
                Automate your high volume processes and improve your business productivity by integrating to any of the
                available platforms.
              </p>
            </div>
            <JiraIntegration project={project} refreshProjectInfo={refreshProjectInfo} />

            <AzureIntegration project={project} refreshProjectInfo={refreshProjectInfo} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Integrations
