export const LIST_CURRENCY = {
  USD: '$',
  EUR: '€',
  JPY: '¥',
  GBP: '£',
  AUD: 'A$',
  CAD: 'CA$',
  CHF: 'CHF',
  CNY: '¥',
  SEK: 'kr',
  NZD: 'NZ$',
  KRW: '₩',
  SGD: 'S$',
  NOK: 'kr',
  MXN: 'Mex$',
  INR: '₹',
  RUB: '₽',
  ZAR: 'R',
  BRL: 'R$',
  TRY: '₺',
  HKD: 'HK$',
  IDR: 'Rp',
  PLN: 'zł',
  THB: '฿',
  DKK: 'kr',
  MYR: 'RM',
  HUF: 'Ft',
  CZK: 'Kč',
  ILS: '₪',
  PHP: '₱',
  CLP: 'CLP$',
  AED: 'د.إ',
  COP: 'COL$',
  SAR: '﷼',
  TWD: 'NT$',
  EGP: 'ج.م.',
  NPR: 'रू'
}
