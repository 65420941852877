import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { LOGIN_ROUTER } from 'constant/router'
import { Mixpanel } from 'Utils/Mixpanel'
import { useForm } from 'react-hook-form'
import { Logo } from 'Organisms'
import { emailValidate } from 'Utils/helperFunctions'
import { DateTime } from 'luxon'
import { useSignUp } from 'Hooks'
import { ModalSuccess } from 'Organisms/ModalSuccess'
import { WarningCircle } from '@phosphor-icons/react'
import { Helmet } from 'react-helmet'
import { trackEvent } from 'Utils/GoogleAnalytics'

const Signup = () => {
  const [loading, setLoading] = useState(false)
  const [modalMessage, setModalMessage] = useState({
    open: false
  })

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm()

  const { signUpHook } = useSignUp()

  const navigate = useNavigate()

  const onSubmit = async (data) => {
    try {
      setLoading(true)

      if (!emailValidate({ email: data.email })) {
        return setError('email', { type: 'format', message: 'Format is invalid' })
      }

      const split_name = data.full_name.split(' ')

      const timezone = `GMT${DateTime.local().toFormat('ZZ')}`
      Mixpanel.track('Sign up Intent')
      const res = await signUpHook({ ...data, timezone, firstname: split_name.shift(), lastname: split_name.join(' ') })

      setModalMessage({
        open: true,
        title: res.success
          ? (
          <span>
            You are almost done! <br /> Now, verify your email
          </span>
            )
          : (
              ''
            ),
        message: res.success
          ? (
          <p className="m-0 text-center">
            {"You're"} in. An email has been sent to <br />
            <span className="text-quaternary">{data.email}</span>
            <br /> Hit confirm and {"you'll"} be ready to start working.
          </p>
            )
          : (
              res?.message || 'Oops! Something went wrong while creating your account. Please try again later.'
            ),
        error: !res.success
      })

      if (res.success) {
        Mixpanel.track('Signed up')
        Mixpanel.identify(res.data)
        Mixpanel.people.set({
          $distinct_id: res.data,
          $email: data.email,
          $name: split_name.shift() + ' ' + split_name.join(' '),
          $first_name: split_name.shift(),
          $last_name: split_name.join(' ')
        })

        trackEvent({
          category: 'User',
          action: 'Sign up',
          label: ''
        })
      } else {
        Mixpanel.track('Signed up Failed')
      }
    } finally {
      setLoading(false)
    }
  }

  const handleCloseModal = () => {
    if (modalMessage.error) return setModalMessage({ open: false, message: '' })

    return navigate(LOGIN_ROUTER)
  }

  return (
    <>
      <Helmet>
        <title>Sign up</title>
        <meta
          name="description"
          content="Time and task Tracking. Free trial available. Three pricing plans available."
        />
        <meta
          name="keywords"
          content="time tracker, time tracking, remote employees, productivity, software, remote work"
        />
        <meta name="author" content="Canned Head" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Sign Up" />
        <meta
          property="og:description"
          content="Time and task Tracking. Free trial available. Three pricing plans available."
        />
        <meta property="og:image" content="https://example.com/image.jpg" />
        <meta property="og:url" content="/signup" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="container login dark-theme">
        <div className="row justify-content-center align-items-center">
          <div className="col-11 col-md-8 col-lg-5">
            <div className="row justify-content-center align-items-center">
              <div className="col-12 col-md-8">
                <div className="row justify-content-center">
                  <div className="col-9 logo-wrapper">
                    <Logo />
                  </div>
                </div>

                <p className="mt-5">Let&apos;s get started! Create an account for free</p>

                <div className="row justify-content-center">
                  <form id="formSignUp" className="col-12 d-grid" onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="inputCompany">
                        Company / Organization
                      </label>
                      <div className="col-12">
                        <input
                          type="text"
                          {...register('company', { required: true })}
                          className={`form-control form-control-outline ${
                            errors && errors.company ? 'is-invalid' : ''
                          }`}
                          id="inputCompany"
                          placeholder="Enter company name"
                        />
                        <div className="invalid-feedback">Please provide a company name.</div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <label className="form-label" htmlFor="inputFullName">
                        Full name
                      </label>
                      <div className="col-12">
                        <input
                          type="text"
                          {...register('full_name', { required: true })}
                          className={`form-control form-control-outline ${
                            errors && errors.full_name ? 'is-invalid' : ''
                          }`}
                          id="inputFullName"
                          placeholder="Enter your First name"
                        />
                        <div className="invalid-feedback">Please provide your full name.</div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <label className="form-label" htmlFor="inputEmail">
                        Email
                      </label>
                      <div className="col-12">
                        <input
                          type="text"
                          {...register('email', { required: true })}
                          className={`form-control form-control-outline ${errors && errors.email ? 'is-invalid' : ''}`}
                          id="inputEmail"
                          placeholder="Enter your email"
                        />

                        <div className="invalid-feedback">
                          {errors?.email?.message ? errors.email.message : 'Please provide your email.'}
                        </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-block btn-primary mt-4"
                      disabled={loading}
                      form="formSignUp">
                      {loading && (
                        <>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span className="ms-2">Loading...</span>
                        </>
                      )}
                      {!loading && <span>Sign up</span>}
                    </button>
                  </form>
                  <p className="mt-3 text-center small">
                    By clicking &quot;Sign up&quot;, you agree to Pulse{' '}
                    <Link className="text-active" to="/terms-of-service">
                      TOS
                    </Link>{' '}
                    and{' '}
                    <Link className="text-active" to="/privacy-policy">
                      Privacy Policy
                    </Link>
                    .
                  </p>
                </div>
                <div className="row mt-5">
                  <div className="col-12 text-center">
                    <p>
                      Already have an account?{' '}
                      <Link className="forgot-password" to={LOGIN_ROUTER}>
                        Log in
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalMessage.open && (
        <ModalSuccess
          visible={true}
          width={400}
          setVisible={() => handleCloseModal()}
          title={modalMessage.error ? modalMessage.message : ''}
          content={
            !modalMessage.error
              ? (
              <>
                <h1 className="fs-3 text-center">{modalMessage.title}</h1>
                <p className="m-0">{modalMessage.message}</p>
              </>
                )
              : (
                  ''
                )
          }
          identifier="modalMessageSuccess"
          error={modalMessage.error}
          icon={modalMessage.error && WarningCircle}
        />
      )}
    </>
  )
}

export default Signup
