import { BrowserRouter } from 'react-router-dom'
import './Assets/scss/App.scss'
import Main from './Main'

const App = () => {
  return (
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  )
}

export default App
