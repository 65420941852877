import { useState } from 'react'

// Hooks
import { useModal, useProject } from 'Hooks/'

// Components
import { ButtonsModal } from 'Organisms'
import TableBudgets from './TableBudgets'

const FORM_CONTROL = 'addBudgetToTask'

const ModalAddBudgetToTask = ({ visible, setVisible, budgets, idProject, refreshProjectInfo }) => {
  const { Modal, hide } = useModal({ identifier: 'modalAddBudgetToTask', visible, setVisible })
  const { loadingProjectHook, addBudgetToTaskPerProjectHook } = useProject()

  const [addBudgetList, setAddBudgetList] = useState([])

  const handleAddBudget = ({ idTask, budget }) => {
    if (addBudgetList.some((item) => item.id === idTask)) {
      return setAddBudgetList(
        addBudgetList.map((item) => {
          if (item.id === idTask) {
            return {
              ...item,
              budget
            }
          }

          return item
        })
      )
    }

    setAddBudgetList([...addBudgetList, { id: idTask, budget }])
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    await Promise.allSettled(
      addBudgetList.map((item) =>
        addBudgetToTaskPerProjectHook({ idProject, idTask: item.id, newBudget: item.budget })
      )
    )

    await refreshProjectInfo()
    hide()
  }

  return (
    <Modal>
      <div className="modal-content" style={{ maxWidth: 1100 }}>
        <div className="modal-header">
          <h3 className="modal-title fs-5 text-capitalize" id="exampleModalLabel">
            Add Budget
          </h3>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col">
              <div className="row justify-content-center">
                <form id={FORM_CONTROL} className="d-grid" onSubmit={onSubmit}>
                  <TableBudgets budgets={budgets} onAddBudget={handleAddBudget} />
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <ButtonsModal onClose={hide} loading={loadingProjectHook} formControl={FORM_CONTROL} />
        </div>
      </div>
    </Modal>
  )
}

export default ModalAddBudgetToTask
