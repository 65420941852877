import { useEffect } from 'react'
import { Helmet } from 'react-helmet'

const BecomePartner = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = '//js.hsforms.net/forms/v2.js'
    script.async = true
    document.body.appendChild(script)

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1', // Change to your HubSpot region
          portalId: '1903707', // Replace with your HubSpot Portal ID
          formId: '870fc481-dce0-4b8f-91f9-1477bebafe7f', // Replace with your HubSpot Form ID
          target: '#hubspotForm' // The element ID where the form will be embedded
        })
      }
    }
  }, [])

  return (
        <>
            <Helmet>
                <title>Become a partner</title>
                <meta
                name="description"
                content="Discover the best time tracking software designed for remote employees. Boost productivity, manage time efficiently, and gain insights into your remote team's performance."
                />
                <meta name="keywords" content="time tracker, time tracking, remote employees, productivity, software, remote work" />
                <meta name="author" content="Pulse by Canned Head" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Time Tracking Software For Remote Employees" />
                <meta property="og:description" content="Discover the best time tracking software designed for remote employees. Boost productivity, manage time efficiently, and gain insights into your remote team's performance." />
                <meta property="og:image" content="https://example.com/image.jpg" />
                <meta property="og:url" content="https://example.com/time-tracking-software" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className='contactUs container-fluid'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-md-6'>
                            <h2>Partnership program</h2>
                            <h1>Become a partner</h1>
                            <p>Let&apos;s join hands and grow together. Fill out the following form to start your track of becoming a partner.</p>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-md-6 mx-auto' id="hubspotForm"></div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default BecomePartner
