import React from 'react'
import useSessionStore from 'Context/useSessionStore'

const TabsListProject = ({ tabsList, onChangeTab, activeTab }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  return (
    <ul className="nav nav-pills flex-column">
      {tabsList.map((item) => {
        if (item?.scope && !item.scope.includes(user.role)) return <React.Fragment key={item.tab} />

        return (
          <li key={item.tab} className="nav-item">
            <p
              className={`nav-link pointer m-0 ${activeTab === item.tab ? 'active' : ''}`}
              onClick={() => onChangeTab(item.tab)}>
              {item.name}
            </p>
          </li>
        )
      })}
    </ul>
  )
}

export default TabsListProject
