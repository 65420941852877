import { FileArrowDown } from '@phosphor-icons/react'
import { usePayment } from 'Hooks'
import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { LIST_CURRENCY } from 'Utils/const/currency'

const InvoicesSectionBillingSettings = () => {
  const [initLoading, setInitLoading] = useState(true)
  const { listInvoicesHook, getInvoicesByCompanyHook } = usePayment()

  useEffect(() => {
    ;(async () => {
      try {
        await getInvoicesByCompanyHook()
      } finally {
        setInitLoading(false)
      }
    })()
  }, [])

  const handleDownloadInvoice = ({ url }) => {
    window.location.href = url
  }

  if (initLoading) return <div className="spinner" />

  return (
    <>
      <div className="row justify-content-between align-items-end mb-4">
        <div className="col-auto">
          <h4 className="mt-4 mb-2">Billing history</h4>
          <div className="row">
            <p className="mb-0">View invoices for all payments in your account.</p>
          </div>
        </div>
        <div className="col-auto">
          <button type="button" className="btn btn-secondary">
            <FileArrowDown className="me-2" size={24} />
            Download all
          </button>
        </div>
      </div>
      <table className="table align-middle">
        <thead>
          <tr>
            <th>
              <input className="form-check-input" type="checkbox" value="" />
            </th>
            <th>Subscription</th>
            <th># Invoice</th>
            <th>Amount</th>
            <th>Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {listInvoicesHook.map((invoice) => (
            <tr key={invoice.id}>
              <td>
                <input className="form-check-input" type="checkbox" value="" />
              </td>
              <td>{invoice.lines.data[0].description}</td>
              <td>{invoice.number}</td>
              <td>
                {invoice.currency.toUpperCase()} {LIST_CURRENCY[invoice.currency.toUpperCase()] || '$'}
                {(invoice.total * 0.01).toFixed(2)}
              </td>
              <td>{DateTime.fromSeconds(invoice.created).toFormat('dd LLLL yyyy')}</td>
              <td className="text-end">
                <button type="button" className="btn btn-ghost" onClick={() => handleDownloadInvoice({ url: invoice.invoice_pdf })}>
                  <FileArrowDown size={24} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default InvoicesSectionBillingSettings
