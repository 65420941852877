import { countDecimalsAndAdd0 } from 'Utils/helperFunctions'

const CustomTooltipPerDayChart = ({ active, payload, totalHours, theme }) => {
  if (!active) return null

  return (
    <div className="customTooltipBarChartPerDay">
      <p style={{ color: theme ? 'white' : 'black' }}>{payload[0].payload.name}</p>
      {payload.map(({ name, value, fill }) => (
        <div key={name} className="fs-6 mb-3">
          <p style={{ color: fill }}>{name}</p>
          <p style={{ color: fill }}>{countDecimalsAndAdd0({ number: value })}h</p>
          <p style={{ color: fill }}>{`(${countDecimalsAndAdd0({
            number: (value * 100) / totalHours
          })}%)`}</p>
        </div>
      ))}
    </div>
  )
}

export default CustomTooltipPerDayChart
