import { postSignUpService } from 'Services'

export default function useSignUp () {
  const signUpHook = async ({ firstname, lastname, company, email, timezone }) => {
    const body = {
      company: { name: company },
      user: { firstname, lastname, email, timezone }
    }

    try {
      const res = await postSignUpService({ body })

      if (res && res.success) {
        return { success: true }
      }

      const errorMessage = res?.error?.options?.description || 'Unknown error'
      throw new Error(errorMessage)
    } catch (error) {
      return {
        success: false,
        message: error.message || 'An error occurred during sign-up'
      }
    }
  }

  return {
    signUpHook
  }
}
