import { CURRENT_USER_STORAGE } from 'Utils/const/localstorage'
import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

const useSessionStore = create(
  persist(
    (set) => ({
      user: JSON.parse(localStorage.getItem(CURRENT_USER_STORAGE)) || null,
      challenge: null,
      updateUser: (newUser) => set({ user: newUser }),
      updateChallenge: (newChallenge) => set({ challenge: newChallenge })
    }),
    {
      name: 'session-storage',
      storage: createJSONStorage(() => sessionStorage)
    }
  )
)

export default useSessionStore
