import useSessionStore from 'Context/useSessionStore'
import { NewPortal } from 'Organisms/NewPortal'
import { useEffect, useState } from 'react'
import { StepNewsLetter } from './Components/StepNewsLetter'
import { StepUpgrade } from './Components/StepUpgrade'
import { useNavigate } from 'react-router-dom'
import { SETTINGS_ROUTER } from 'constant/router'
import { useCompany, useUser } from 'Hooks'
import { Loading } from 'Organisms/Loading'
import { StepWelcome } from './Components/StepWelcome'
import { StepAboutUs } from './Components/StepAboutUs'
import { ROLE_ADMIN_NAME } from 'constant/roles'
import { TABS_OPTIONS_SETTINGS } from 'Pages/SettingPage/Components/TabsSettings/TabsSettings'
import { StepCompanySize } from './Components/StepCompanySize'
import { PERMISSION_BY_ROLE } from 'Utils/permissionByRol'
import { PLAN_PACKAGE_FREE } from 'Utils/const/packages'
import { CURRENT_USER_STORAGE } from 'Utils/const/localstorage'

const STEP_FIRST_LOGIN = {
  WELCOME: 'WELCOME',
  ABOUT_PULSE: 'ABOUT_PULSE',
  NEWS_LETTER: 'NEWS_LETTER',
  UPGRADE: 'UPGRADE',
  COMPANY_SIZE: 'COMPANY_SIZE'
}

const SuscriptionToNewsLatter = () => {
  const { user, updateUser } = useSessionStore((state) => ({ user: state.user, updateUser: state.updateUser }))
  const navigate = useNavigate()

  const { updateProfileUserHook } = useUser()
  const { companyInfoHook, updateInitConfigCompanyHook, getCompanyInfoHook } = useCompany()

  const isAdmin = user.role === ROLE_ADMIN_NAME
  const isWelcomeFirstAdmin = isAdmin && !user?.company_init_config
  const isUpgradePlan = companyInfoHook?.company_plan_package?.plan_package_id === PLAN_PACKAGE_FREE.id

  const [step, setStep] = useState(STEP_FIRST_LOGIN.WELCOME)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({
    news_letter: false
  })

  const btnGoBack =
    step !== STEP_FIRST_LOGIN.WELCOME ? (step === STEP_FIRST_LOGIN.UPGRADE ? 'Upgrade now' : 'Back') : null

  const btnNext = () => {
    if (step === STEP_FIRST_LOGIN.UPGRADE) return 'Take me to Pulse'

    console.log({ isUpgradePlan })
    if ((step === STEP_FIRST_LOGIN.NEWS_LETTER && (!isAdmin || !isUpgradePlan))) return 'Save'

    return 'Next'
  }

  // handlers
  useEffect(() => {
    ;(async () => {
      try {
        if (PERMISSION_BY_ROLE.INIT_CONFIG.SEARCH_COMPANY_INFO.includes(user.role)) {
          await getCompanyInfoHook({ companyId: user.company_id })
        }
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const nextTo = async () => {
    if (step === STEP_FIRST_LOGIN.WELCOME) {
      if (isWelcomeFirstAdmin && !data?.my_function) return

      return setStep(isWelcomeFirstAdmin ? STEP_FIRST_LOGIN.COMPANY_SIZE : STEP_FIRST_LOGIN.NEWS_LETTER)
    }

    if (step === STEP_FIRST_LOGIN.COMPANY_SIZE) {
      if (!data?.company_size) return

      return setStep(STEP_FIRST_LOGIN.ABOUT_PULSE)
    }

    if (step === STEP_FIRST_LOGIN.ABOUT_PULSE) {
      if (!data?.about_pulse) return

      return setStep(STEP_FIRST_LOGIN.NEWS_LETTER)
    }

    if (step === STEP_FIRST_LOGIN.NEWS_LETTER && isAdmin && isUpgradePlan) return setStep(STEP_FIRST_LOGIN.UPGRADE)

    setLoading(true)

    const resUser = await updateProfileUserHook({ body: { news_letter: data.news_letter, init_config: true } })

    if (!resUser.success) return

    if (isWelcomeFirstAdmin) {
      const resCompany = await updateInitConfigCompanyHook({
        body: {
          my_function: data?.my_function || '',
          about_pulse: data?.about_pulse || '',
          company_size: data?.company_size || ''
        }
      })

      if (!resCompany.success) return
    }

    await updateUser({ ...user, ...{ news_letter: data.news_letter, init_config: true } })
    localStorage.setItem(CURRENT_USER_STORAGE, JSON.stringify({ ...user, ...{ news_letter: data.news_letter, init_config: true } }))
  }

  const goBack = () => {
    if (step === STEP_FIRST_LOGIN.COMPANY_SIZE) return setStep(STEP_FIRST_LOGIN.WELCOME)

    if (step === STEP_FIRST_LOGIN.ABOUT_PULSE) return setStep(STEP_FIRST_LOGIN.COMPANY_SIZE)

    if (step === STEP_FIRST_LOGIN.NEWS_LETTER) {
      return setStep(isWelcomeFirstAdmin ? STEP_FIRST_LOGIN.ABOUT_PULSE : STEP_FIRST_LOGIN.WELCOME)
    }

    if (step === STEP_FIRST_LOGIN.UPGRADE) {
      nextTo()
      return navigate(`${SETTINGS_ROUTER}?section=${TABS_OPTIONS_SETTINGS.BILLING.key}`)
    }
  }

  return (
    <NewPortal>
      <div className={`modalCustom ${user?.theme ? 'dark-theme' : ''}`}>
        {loading && <Loading />}
        <div className="modalCustom_content modalSuccess rounded-20 p-5">
          {step === STEP_FIRST_LOGIN.WELCOME && (
            <StepWelcome isFirstAdmin={isWelcomeFirstAdmin} data={data} setData={setData} />
          )}

          {step === STEP_FIRST_LOGIN.COMPANY_SIZE && <StepCompanySize data={data} setData={setData} />}
          {step === STEP_FIRST_LOGIN.ABOUT_PULSE && <StepAboutUs data={data} setData={setData} />}
          {step === STEP_FIRST_LOGIN.NEWS_LETTER && <StepNewsLetter data={data} setData={setData} />}
          {step === STEP_FIRST_LOGIN.UPGRADE && isAdmin && <StepUpgrade />}

          <div className="d-flex justify-content-between mt-4">
            {btnGoBack
              ? (
              <button type="button" className="btn btn-secondary" onClick={goBack}>
                {btnGoBack}
              </button>
                )
              : (
              <div />
                )}
            <button type="button" className="btn btn-primary" onClick={nextTo}>
              {btnNext()}
            </button>
          </div>
        </div>
      </div>
    </NewPortal>
  )
}

export default SuscriptionToNewsLatter
