import { NavLink } from 'react-router-dom'
import { CaretLeft } from '@phosphor-icons/react'

import { GET_COLOR_BY_THEME } from 'constant/colors'
import { PROJECT_ROUTER } from 'constant/router'
import useSessionStore from 'Context/useSessionStore'

const HeaderProjectPage = ({ project }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  return (
    <div className="row align-items-center">
      <div className="col-auto">
        <NavLink to={PROJECT_ROUTER}>
          <CaretLeft size={28} color={GET_COLOR_BY_THEME({ theme: user.theme }).SECONDARY} weight="bold" />
        </NavLink>
      </div>
      <div className="col-auto">
        <h1 className="mb-0">
          {project.name || ''}
          {!project.is_active && <span className="badge text-bg-terciary ms-2">Archived</span>}
        </h1>
      </div>
    </div>
  )
}

export default HeaderProjectPage
