import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import useModal from '../../../Hooks/useModal'
import { createTimeEntry } from 'Services'
import TimeInput from 'Organisms/TimeInput'
import { decimalToHours, formatTime, validateFormatTime } from 'Utils/helperFunctions'
import { useProject, useTimeEntries } from 'Hooks'
import { DateTime } from 'luxon'
import { Loading } from 'Organisms/Loading'
import { Mixpanel } from 'Utils/Mixpanel'

const DEFAULT_PROJECT_LOCAL_STORAGE = 'defaultProject'
const DEFAULT_TASK_LOCAL_STORAGE = 'defaultTask'

const TimeTrackerModal = ({
  visible,
  setVisible,
  date,
  refreshTracker,
  tracker,
  onDelete,
  selectedUser,
  isOtherDay
}) => {
  // States
  const [projects, setProjects] = useState([])
  const [tasks, setTasks] = useState([])
  const [loading, setLoading] = useState(false)
  const [isChangeDate, setIsChangeDate] = useState(false)
  const [loadingInternal, setLoadingInternal] = useState(false)

  // hooks
  const { Modal, hide } = useModal({ identifier: 'timeTrackerModal', visible, setVisible })
  const { loadingTimeEntriesHook, updateTimeEntriesHook } = useTimeEntries()
  const { loadingProjectHook, getProjectsHook, getProjectByIdHook } = useProject()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    setValue
  } = useForm({
    defaultValues: {
      is_warranty: tracker ? tracker.is_warranty : false,
      title: tracker?.title || '',
      notes: tracker?.notes || '',
      time: decimalToHours({ time: tracker?.hours || 0 })
    }
  })

  const LOADING = loadingTimeEntriesHook || loadingProjectHook || loading || loadingInternal

  // Handlers
  const fetchProjects = async () => {
    try {
      setLoading(true)
      const data = await getProjectsHook({ limit: 100, userId: selectedUser })

      let projectDefault = tracker?.project_task?.project?.id || data?.[0]?.id
      const projectIdDefault = window.localStorage.getItem(DEFAULT_PROJECT_LOCAL_STORAGE)

      if (!tracker?.id && data.some((project) => project.id === projectIdDefault)) projectDefault = projectIdDefault

      await fetchTasks({ projectId: projectDefault, taskDefault: tracker?.project_task?.task?.id })

      setProjects(data)
      setValue('project_id', projectDefault)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const fetchTasks = async ({ projectId, taskDefault }) => {
    try {
      const data = await getProjectByIdHook({ idProject: projectId })

      let taskDefaultId = taskDefault || data?.project_tasks?.[0]?.task?.id || ''
      const taskLocalstorage = window.localStorage.getItem(DEFAULT_TASK_LOCAL_STORAGE)

      if (!tracker?.id && data.project_tasks.some((task) => task.task.id === taskLocalstorage)) taskDefaultId = taskLocalstorage

      setTasks(data.project_tasks)
      setValue('task_id', taskDefaultId)
    } catch (e) {
      console.error(e)
    }
  }

  const handleChangeProject = async ({ project }) => {
    await fetchTasks({ projectId: project })
    setValue('project_id', project)
  }

  const onSubmit = async (data) => {
    try {
      setLoadingInternal(true)
      const time = formatTime({ time: data.time })

      const body = { ...data, time, ...(selectedUser && { user_id: selectedUser }) }

      if (tracker) {
        await updateTimeEntriesHook({
          body,
          id: tracker.id
        })
        Mixpanel.track('Update Time Entry')
      } else {
        if (time === '00:00') {
          body.is_running = true
        }

        await createTimeEntry({
          ...body,
          spent_date: date
        })

        window.localStorage.setItem(DEFAULT_PROJECT_LOCAL_STORAGE, data.project_id)
        window.localStorage.setItem(DEFAULT_TASK_LOCAL_STORAGE, data.task_id)
        Mixpanel.track('Start Time Entry')
      }
      hide()
      await refreshTracker()
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingInternal(false)
    }
  }

  const handleDisabledButton = () =>
    !watch('task_id') || !watch('project_id') || !watch('title') || !validateFormatTime({ time: watch('time') })

  const handleChangeSpentDate = ({ date }) => setValue('spent_date', date)

  useEffect(() => {
    fetchProjects()
  }, [])

  return (
    <>
      {LOADING && <Loading />}

      <Modal>
        {!loading && (
          <form onSubmit={handleSubmit(onSubmit)} className="modal-content" style={{ maxWidth: 800 }}>
            <div className="modal-header">
              <h3 className="modal-title fs-5" id="exampleModalLabel">
                {tracker ? 'Edit' : 'New'} time entry for {date.toFormat('cccc')}, {date.toFormat('dd')}{' '}
                {date.toFormat('LLL')}
              </h3>
              <button type="button" className="btn-close" onClick={() => hide()} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row mb-3 gy-3">
                <div className="col-12 col-md-6">
                  <label className="form-label">Project</label>
                  <select
                    {...register('project_id')}
                    className="form-select"
                    id="selectProject"
                    onChange={(e) => handleChangeProject({ project: e.target.value })}>
                    {projects &&
                      projects.map((project) => (
                        <option key={project.id} value={project.id}>
                          [{project.code}] {project.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-12 col-md-6">
                  <label className="form-label">Task Type</label>
                  <select
                    {...register('task_id')}
                    className="form-select"
                    disabled={!watch('project_id')}
                    id="selectTask">
                    {tasks &&
                      tasks.map((t) => (
                        <option key={t.task.id} value={t.task.id}>
                          {t.task.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="row mb-3 gy-3">
                <div className="col-sm-9 col-12">
                  <label className="form-label">User Story / Bug / Task</label>
                  <input
                    {...register('title')}
                    type="text"
                    id="inputTitle"
                    className="form-control form-control-lg"
                    placeholder="User Story / Bug / Task"
                    disabled={!watch('task_id')}
                  />
                </div>
                <div className="col-sm-3 col-12">
                  <label className="form-label">Time</label>
                  <TimeInput
                    setError={setError}
                    errors={errors}
                    register={register}
                    className="form-control form-control-lg"
                    disabled={!watch('task_id')}
                    clearErrors={clearErrors}
                    setValue={setValue}
                    defaultValue={tracker?.hours || 0}
                  />
                </div>
              </div>
              <div className="row gy-3 align-items-center">
                <div className="col-sm-9 col-12">
                  <label className="form-label">Notes</label>
                  <textarea
                    {...register('notes')}
                    className="form-control"
                    placeholder="Notes"
                    id="inputNotes"
                    rows="2"
                    disabled={!watch('task_id')}
                  />
                </div>
                <div className="col-sm-3 col-12">
                  <div className="form-check">
                    <input
                      {...register('is_warranty')}
                      className="form-check-input"
                      type="checkbox"
                      id="checkWarrenty"
                      disabled={!watch('task_id')}
                    />
                    <label className="form-check-label" htmlFor="checkWarrenty">
                      Warranty
                    </label>
                  </div>
                </div>
              </div>
              {isOtherDay && (
                <div className="row align-items-center mt-4">
                  <div className="col-12">
                    <div className="alert-warning" role="alert">
                      This is not today&apos;s timesheet. Are you sure you want to start this timer?
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="modal-footer justify-content-between">
              <div className="col-auto d-flex">
                {tracker && onDelete && (
                  <button
                    type="button"
                    className="btn text-primary me-2"
                    onClick={() => {
                      hide()
                      onDelete()
                    }}>
                    Delete
                  </button>
                )}
                {tracker && (
                  <>
                    {!isChangeDate && (
                      <button
                        type="button"
                        className="btn text-secondary me-2"
                        id="timeTackerEditDate"
                        onClick={() => setIsChangeDate(true)}>
                        Change Date
                      </button>
                    )}

                    {isChangeDate && (
                      <input
                        type="date"
                        value={watch('spent_date') || date.toFormat('yyyy-MM-dd')}
                        className="form-control form-control-date"
                        id="timeTrackerInputDate"
                        max={DateTime.now().toFormat('yyyy-MM-dd')}
                        onChange={(e) => handleChangeSpentDate({ date: e.target.value })}
                      />
                    )}
                  </>
                )}
              </div>
              <div className="col-auto">
                <button type="button" className="btn btn-secondary me-2" onClick={() => hide()}>
                  Close
                </button>
                <button type="submit" className="btn btn-primary" disabled={handleDisabledButton()}>
                  {tracker ? 'Edit changes' : 'Save changes'}
                </button>
              </div>
            </div>
          </form>
        )}
      </Modal>
    </>
  )
}

export default TimeTrackerModal
