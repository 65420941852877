import { countDecimalsAndAdd0, decimalToHours } from 'Utils/helperFunctions'
import CurrencyInput from 'react-currency-input-field'

const TableBudgets = ({ budgets, onAddBudget }) => {
  const getPercent = ({ spent, budget }) => {
    if (spent === 0) return 0

    if (budget === 0) return 100

    return countDecimalsAndAdd0({ number: (spent * 100) / (budget || 0) })
  }

  const getRest = ({ spent, budget }) => {
    return countDecimalsAndAdd0({ number: budget - spent })
  }

  const showHours = ({ budget }) => {
    const hours = decimalToHours({ time: budget }).split(':')

    return `${hours[0]}h ${hours[1]}m`
  }

  return (
    <div className="table-responsive">
      <table className="table align-middle">
        <thead>
          <tr>
            <th scope="col">Task</th>
            <th scope="col" className='text-no-wrap'>Logged hours</th>
            <th scope="col">Budget</th>
            <th scope="col" colSpan="2">
              Budget Spent
            </th>
            <th scope="col" className='text-no-wrap'>Budget Left</th>

            {onAddBudget && <th className='text-no-wrap'>Aditional hours</th>}
          </tr>
        </thead>
        <tbody>
          {budgets.map((budget) => (
            <tr key={budget.id} className="table_row_color">
              <td className='text-no-wrap'>{budget?.task?.name || ''}</td>
              <td className='text-no-wrap'>{countDecimalsAndAdd0({ number: budget?.spent || 0 })}h</td>
              <td className='text-no-wrap'>{`${showHours({ budget: budget?.budget || 0 })}`}</td>
              <td>
                <div
                  style={{ width: '100px' }}
                  className="progress"
                  role="progressbar"
                  aria-label="Basic example"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100">
                  <div
                    className="progress-bar"
                    style={{
                      width: `${getPercent({ spent: budget?.spent || 0, budget: budget?.budget || 0 })}%`
                    }}></div>
                </div>
              </td>
              <td>{getPercent({ spent: budget?.spent || 0, budget: budget?.budget || 0 })}%</td>
              <td>{getRest({ spent: budget?.spent || 0, budget: budget?.budget || 0 })}h</td>
              {onAddBudget && (
                <td className="width-150">
                  <CurrencyInput
                    onValueChange={(value) => onAddBudget({ idTask: budget?.task?.id, budget: value })}
                    thousandseparator=","
                    className="form-control"
                    placeholder="0"
                    allowDecimals={true}
                    allowNegativeValue={false}
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default TableBudgets
