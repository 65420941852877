import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { trackPageView } from '../Utils/GoogleAnalytics' // Tracking functions

const TrackPageView = () => {
  const location = useLocation()
  const pageTitle = document.title || ''

  useEffect(() => {
    const timer = setTimeout(() => {
      trackPageView(location.pathname, pageTitle)
    }, 50)

    return () => clearTimeout(timer)
  }, [location])

  return null
}

export default TrackPageView
