import { Trash } from '@phosphor-icons/react'
import LoadingButton from 'Organisms/LoadingButton'
import moment from 'moment'
import CurrencyInput from 'react-currency-input-field'

const TableTasks = ({ tasks, onChangeBillableRate, onDelete }) => {
  return (
    <div className="table-responsive">
      <table className="table align-middle">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col" className="width-150 text-center text-no-wrap">
              Last modification
            </th>
            <th scope="col" className="width-150 text-end text-no-wrap">
              Billable Rate
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => (
            <tr key={task.id} className="table_row_color">
              <td className="text-no-wrap">{task?.name || task?.task?.name || ''}</td>
              <td className="text-center text-no-wrap">
                {task?.updated_at ? moment(task.updated_at).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY')}
              </td>
              <td>
                <CurrencyInput
                  name="billable_rate"
                  onValueChange={(value) =>
                    onChangeBillableRate({ idTask: task?.task?.id || task?.id || '', newBillableRate: value })
                  }
                  defaultValue={task?.billable_rate || 0}
                  prefix="$"
                  decimalSeparator="."
                  thousandseparator=","
                  precision={2}
                  className="form-control input-primary"
                  decimalsLimit={2}
                  id="inputBillableRate"
                  placeholder="$0.00"
                />
              </td>
              <td className="text-end">
                <LoadingButton
                  type="button"
                  onClick={() => onDelete({ idTask: task?.task?.id || task?.id || '' })}
                  className="btn btn-secondary">
                  <Trash size={18} color="#262422" weight="bold" />
                </LoadingButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default TableTasks
