import { Info } from '@phosphor-icons/react'
import { PERIOD_TIME_SHEET } from 'Pages/Timesheet/utils/const'
import { COLORS_GRAFICH } from 'Utils/const/profile'
import { getMaxElementByArray } from 'Utils/helperFunctions'
import { COLOR_EMERALD2 } from 'constant/colors'
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import CustomBarDashboardHoursDaily from './CustomBarDashboardHoursDaily'
import CustomTooltipPerDayChart from 'Organisms/TreemapChart/CustomTooltipPerDayChart'
import useSessionStore from 'Context/useSessionStore'

const GraphicsPerDayDashboard = ({ groupHoursPerDay, isMobile, currentPeriod, period, totalHours }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const isAllOrYear = [PERIOD_TIME_SHEET.ALL_TIME, PERIOD_TIME_SHEET.YEAR].includes(currentPeriod)

  return (
    <div className="card dashboard_card mt-4">
      <div className="row">
        <div className="col-12">
          <h5 className="mb-4">
            Worked hours per day <Info size={18} />
          </h5>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={groupHoursPerDay}
              margin={{
                top: 20,
                right: 10,
                left: 0,
                bottom: !isAllOrYear ? 60 : 0
              }}>
              <XAxis
                dataKey="name"
                tick={{ fontSize: isAllOrYear ? 0 : 12 }}
                angle={-90}
                interval={0}
                tickMargin={isAllOrYear ? 0 : 40}
              />
              {!isMobile && (
                <YAxis
                  axisLine={false}
                  label={{ value: 'Hours', angle: -90, position: 'insideLeft' }}
                  tick={{ fontSize: 12 }}
                  domain={[
                    0,
                    parseInt(getMaxElementByArray({ arrayObject: groupHoursPerDay, property: 'billable' })) + 1
                  ]}
                />
              )}
              <Tooltip
                cursor={{ fill: user.theme ? '#272B2A' : '#EDEDEDED' }}
                content={<CustomTooltipPerDayChart totalHours={totalHours} theme={user.theme} />}
              />
              <Legend wrapperStyle={{ marginBottom: -60 }} />
              <Bar
                legendType="circle"
                dataKey="billable"
                stackId="a"
                activeBar={
                  <CustomBarDashboardHoursDaily
                    theme={user.theme}
                    colorGrafich={user.theme ? COLOR_EMERALD2 : COLORS_GRAFICH[1]}
                    isMobile={isMobile}
                    period={period}
                  />
                }
                fill={user.theme ? COLOR_EMERALD2 : COLORS_GRAFICH[1]}
                shape={
                  <CustomBarDashboardHoursDaily
                    theme={user.theme}
                    colorGrafich={user.theme ? COLOR_EMERALD2 : COLORS_GRAFICH[1]}
                    isMobile={isMobile}
                    period={period}
                  />
                }
              />
              <Bar
                dataKey="warranty"
                stackId="a"
                legendType="circle"
                activeBar={
                  <CustomBarDashboardHoursDaily
                    theme={user.theme}
                    colorGrafich={user.theme ? COLORS_GRAFICH.at(-1) : COLORS_GRAFICH[3]}
                    isMobile={isMobile}
                    period={period}
                    isWarranty
                  />
                }
                fill={user.theme ? COLORS_GRAFICH.at(-1) : COLORS_GRAFICH[3]}
                shape={
                  <CustomBarDashboardHoursDaily
                    theme={user.theme}
                    colorGrafich={user.theme ? COLORS_GRAFICH.at(-1) : COLORS_GRAFICH[3]}
                    isMobile={isMobile}
                    period={period}
                    isWarranty
                  />
                }
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}

export default GraphicsPerDayDashboard
