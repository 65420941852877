import React, { useState } from 'react'
import { handleChangeActiveSwitchColumns, handleDragEndColumns, orderListPerKey } from 'Utils/helperFunctions'
import { INITIAL_STATE_TIMEENTRY } from 'constant/tableColumns'
import { useTimeEntries } from 'Hooks'

const ContextColumnTimeEntry = React.createContext({})

const NAME_LOCAL_STORAGE = 'orderItemsTimeEntry'

export function TimeEntryContextProvider ({ children }) {
  const { updateColumnsTableHook, createColumnsTableHook, getColumnsTableHook } = useTimeEntries()

  const [timeEntryColunm, setTimeEntryColunm] = useState(
    () => JSON.parse(localStorage.getItem(NAME_LOCAL_STORAGE)) || INITIAL_STATE_TIMEENTRY
  )

  const [loadingColumnsTimeEntryContext, setLoadingColumnsTimeEntryContext] = useState(false)

  const resetColumnsAndAddNewColumn = async ({ oldColumns, newColumns }) => {
    try {
      // obtenemos las columnas que hay que crear
      const itemsEnListaAQueNoEstanEnListaB = newColumns.filter(
        (itemA) => !oldColumns.some((itemB) => itemB.name === itemA.name)
      )

      if (itemsEnListaAQueNoEstanEnListaB.length === 0) return oldColumns

      // creamos las nuevas columnas
      const resCreateColumns = await createColumnsTableHook({ columns: itemsEnListaAQueNoEstanEnListaB })

      if (!resCreateColumns.success) {
        console.error('there is an error in new columns timesheet')
        return oldColumns
      }

      // reorganizamos la lista
      const bothList = [...oldColumns, ...resCreateColumns.data]

      // agregamos los id a la nueva lista
      const newList = newColumns.map((item) => ({
        ...item,
        id: bothList.find((itemB) => itemB.name === item.name).id
      }))

      await handleUpdateInBack({ columns: newList })
      return newList
    } catch (e) {
      console.error(e)
    }
  }

  const getColumnsTimeEntry = async () => {
    try {
      const columns = await getColumnsTableHook({ is_detail: true })

      if (!columns.success) {
        console.error('there is an error in columns time entry')
        localStorage.setItem(NAME_LOCAL_STORAGE, JSON.stringify(INITIAL_STATE_TIMEENTRY))
        return setTimeEntryColunm(INITIAL_STATE_TIMEENTRY)
      }

      if (columns.data.length === 0) {
        const resCreateColumns = await createColumnsTableHook({ columns: INITIAL_STATE_TIMEENTRY })

        if (!resCreateColumns.success) return console.error('There is an error when I am creating columns')

        const columnsOrder = orderListPerKey({ list: resCreateColumns.data, keyByOrder: 'order' })

        localStorage.setItem(NAME_LOCAL_STORAGE, JSON.stringify(columnsOrder))
        return setTimeEntryColunm(columnsOrder)
      }

      // validamos si no se creo una nueva columna en la tabla
      if (columns.data.length !== INITIAL_STATE_TIMEENTRY.length) {
        const newList = await resetColumnsAndAddNewColumn({
          oldColumns: columns.data,
          newColumns: INITIAL_STATE_TIMEENTRY
        })
        localStorage.setItem(NAME_LOCAL_STORAGE, JSON.stringify(newList))
        return setTimeEntryColunm(newList)
      }

      const columnsOrder = orderListPerKey({ list: columns.data, keyByOrder: 'order' })
      localStorage.setItem(NAME_LOCAL_STORAGE, JSON.stringify(columnsOrder))
      setTimeEntryColunm(columnsOrder)
    } catch (e) {
      console.error(e)
    }
  }

  const handleDragEndTimeEntry = async (event) => {
    const newOrder = handleDragEndColumns({ event, columns: timeEntryColunm })
    const updateColunmOrder = newOrder.map((item, index) => ({ ...item, order: index }))

    await handleUpdateInBack({ columns: updateColunmOrder })
    localStorage.setItem(NAME_LOCAL_STORAGE, JSON.stringify(updateColunmOrder))
    setTimeEntryColunm(newOrder)
  }

  const handleChangeActiveSwitchTimeEntry = async ({ item, active }) => {
    const update = handleChangeActiveSwitchColumns({ item, active, columns: timeEntryColunm })
    await handleUpdateInBack({ columns: update })
    localStorage.setItem(NAME_LOCAL_STORAGE, JSON.stringify(update))
    setTimeEntryColunm(update)
  }

  const handleUpdateInBack = async ({ columns }) => {
    try {
      setLoadingColumnsTimeEntryContext(true)

      const clone = structuredClone(columns)

      await updateColumnsTableHook({ columns: clone })
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingColumnsTimeEntryContext(false)
    }
  }

  return (
    <ContextColumnTimeEntry.Provider
      value={{
        timeEntryColunm,
        loadingColumnsTimeEntryContext,
        handleDragEndTimeEntry,
        handleChangeActiveSwitchTimeEntry,
        getColumnsTimeEntry
      }}>
      {children}
    </ContextColumnTimeEntry.Provider>
  )
}

export default ContextColumnTimeEntry
