import { useEffect, useState } from 'react'
import { CustomDatePicker } from 'Organisms/CustomDatePicker'
import useSessionStore from 'Context/useSessionStore'
import {
  countDecimalsAndAdd0,
  getDaysBetweenDates,
  getDifferentDaysBetweenDates,
  getEndWeek,
  getStartWeek,
  getWeekBetweenDates,
  groupHoursPerDayUtils
} from 'Utils/helperFunctions'
import { useProfile } from 'Hooks'
import { Loading } from 'Organisms/Loading'
import { INIT_HOURS_PER_DAYS } from 'Utils/const/profile'
import { GraphicsPerDayDashboard, GraphicsPerProjectDashboard, GraphicsPerTaskDashboard } from './components'
import { PERIOD_TIME_SHEET } from 'Pages/Timesheet/utils/const'
import { PanelHoursCapacity } from 'Organisms/PanelHoursCapacity'
import { DashboardHoursEmpty } from 'Organisms/Empty'
import { DateTime } from 'luxon'
import { getPeriodByDiffDay } from 'Pages/TimeTracker/utils/helpers'
import { useMediaQuery } from 'react-responsive'
import { BREAKPOINT } from 'Utils/const/breakPoints'
import { Helmet } from 'react-helmet'
const Dashboard = () => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  // Media query
  const isMobile = useMediaQuery({
    query: `(max-width: ${BREAKPOINT.MOBILE})`
  })

  // State
  const [startDay, setStartDay] = useState(getStartWeek())
  const [endDay, setEndDay] = useState(getEndWeek())
  const [loading, setLoading] = useState(true)
  const [groupHoursPerDay, setGroupHoursPerDay] = useState(INIT_HOURS_PER_DAYS)
  const [totalHoursPerDay, setTotalHoursPerDay] = useState(0)
  const [groupHoursPerProject, setGroupHoursPerProject] = useState([])
  const [totalHoursPerProject, setTotalHoursPerProject] = useState(0)
  const [groupHoursPerTask, setGroupHoursPerTask] = useState([])
  const [totalHoursPerTask, setTotalHoursPerTask] = useState(0)
  const [currentPeriod, setCurrentPeriod] = useState(PERIOD_TIME_SHEET.WEEK)

  // Hooks
  const {
    listMyHoursHook,
    loadingProfileHook,
    getMyHoursProfileHook,
    getMyHoursProfilePerProjectHook,
    getMyHoursProfilePerTaskHook
  } = useProfile()

  // Handlers
  const handleSearchDataByDate = async ({ startDay, endDay, period }) => {
    setStartDay(startDay)
    setEndDay(endDay)
    setCurrentPeriod(period)

    await Promise.allSettled([
      getMyHours({ start_date: startDay, end_date: endDay, period }),
      getMyHoursPerProject({ start_date: startDay, end_date: endDay }),
      getMyHoursPerTack({ start_date: startDay, end_date: endDay })
    ])
  }

  const getMyHours = async ({ start_date, end_date, period = PERIOD_TIME_SHEET.WEEK }) => {
    let getByPeriod = period

    if (getByPeriod === PERIOD_TIME_SHEET.CUSTOM) {
      const diffDays = getDifferentDaysBetweenDates({ startDate: start_date, endDate: end_date })

      getByPeriod = getPeriodByDiffDay({ diffDays })
    }

    const result = await getMyHoursProfileHook({ start_date, end_date, period: getByPeriod })

    if (!result.success) return console.error('There is an error')

    let resultHoursPerDay = []

    if (getByPeriod === PERIOD_TIME_SHEET.WEEK) {
      resultHoursPerDay = groupHoursPerDayUtils({
        listHours: result.data.hours_per_day || [],
        initHours: INIT_HOURS_PER_DAYS
      })
    }

    if (getByPeriod === PERIOD_TIME_SHEET.SEMIMONTH) {
      const daysBetweenDates = getDaysBetweenDates({ startDate: start_date, endDate: end_date })

      resultHoursPerDay = daysBetweenDates.map((day) => {
        const total = {
          billable: 0,
          warranty: 0
        }

        result?.data?.hours_per_day.forEach((entry) => {
          if (DateTime.fromISO(entry.spent_date).toFormat('yyyy-MM-dd') === day.toFormat('yyyy-MM-dd')) {
            total.billable += entry?.hours || 0
            total.warranty += entry?.warranty_hours || 0
          }
        })

        return {
          name: day.toFormat('yyyy-MM-dd'),
          billable: countDecimalsAndAdd0({ number: total?.billable || 0 }),
          warranty: countDecimalsAndAdd0({ number: total?.warranty || 0 })
        }
      })
    }

    if ([PERIOD_TIME_SHEET.MONTH, PERIOD_TIME_SHEET.QUARTER, PERIOD_TIME_SHEET.YEAR].includes(getByPeriod)) {
      const daysBetweenDates = getWeekBetweenDates({ startDate: start_date, endDate: end_date })

      resultHoursPerDay = daysBetweenDates.map((day) => {
        const total = {
          billable: 0,
          warranty: 0
        }

        result?.data?.hours_per_day.forEach((entry) => {
          if (
            DateTime.fromISO(entry.day_of_week).setZone('local').toFormat('yyyy-MM-dd') >=
              day.start_week.toFormat('yyyy-MM-dd') &&
            DateTime.fromISO(entry.day_of_week).setZone('local').toFormat('yyyy-MM-dd') <=
              day.end_week.toFormat('yyyy-MM-dd')
          ) {
            total.billable += entry?.hours || 0
            total.warranty += entry?.warranty_hours || 0
          }
        })

        return {
          name: day.start_week.toFormat('yyyy-MM-dd'),
          billable: countDecimalsAndAdd0({ number: total?.billable || 0 }),
          warranty: countDecimalsAndAdd0({ number: total?.warranty || 0 })
        }
      })
    }

    if (getByPeriod === PERIOD_TIME_SHEET.ALL_TIME) {
      resultHoursPerDay = result?.data?.hours_per_day.map((day) => ({
        name: DateTime.fromISO(day.day_of_week).setZone('local').toFormat('yyyy-MM-dd'),
        billable: countDecimalsAndAdd0({ number: day?.hours || 0 }),
        warranty: countDecimalsAndAdd0({ number: day?.warranty_hours || 0 })
      }))
    }

    setGroupHoursPerDay(resultHoursPerDay)
    setTotalHoursPerDay(result?.data?.hours)
  }

  const getMyHoursPerProject = async ({ start_date, end_date }) => {
    const result = await getMyHoursProfilePerProjectHook({ start_date, end_date })

    if (!result.success) return console.error('There is an error')

    let totalHoursProject = 0

    const resultHoursPerProject = result.data.map((item) => {
      totalHoursProject += item.hours

      return {
        name: item.project_name,
        hours: item.hours
      }
    })

    setGroupHoursPerProject(resultHoursPerProject)
    setTotalHoursPerProject(totalHoursProject)
  }

  const getMyHoursPerTack = async ({ start_date, end_date }) => {
    const result = await getMyHoursProfilePerTaskHook({ start_date, end_date })

    if (!result.success) return console.error('There is an error')

    let totalHoursTask = 0

    const resultHoursPerTask = result.data.map((item) => {
      totalHoursTask += item.hours

      return {
        name: item.task_name,
        children: [
          {
            hours: item.hours
          }
        ]
      }
    })

    setGroupHoursPerTask(resultHoursPerTask)
    setTotalHoursPerTask(totalHoursTask)
  }

  useEffect(() => {
    ;(async () => {
      await Promise.allSettled([
        getMyHours({ start_date: startDay, end_date: endDay }),
        getMyHoursPerProject({ start_date: startDay, end_date: endDay }),
        getMyHoursPerTack({ start_date: startDay, end_date: endDay })
      ])

      setLoading(false)
    })()
  }, [])

  if (loading) return <Loading />

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <div className="containers dashboard">
        {loadingProfileHook && <Loading />}

        <h1 className="text-capitalize pt-4 fs-2">
          Hello, {user.firstname} {user.lastname}!
        </h1>

        <div className="row justify-content-between mt-5">
          <div className="col">
            <CustomDatePicker
              onSearchData={({ startDay, endDay, period }) => handleSearchDataByDate({ startDay, endDay, period })}
            />
          </div>
        </div>

        <PanelHoursCapacity
          hours={listMyHoursHook?.hours || 0}
          currentPeriod={currentPeriod}
          startDay={startDay}
          endDay={endDay}
          capacity={listMyHoursHook?.capacity || 0}
        />

        {(listMyHoursHook?.hours || 0) === 0 && (
          <div className="mt-4">
            <DashboardHoursEmpty />
          </div>
        )}

        {(listMyHoursHook?.hours || 0) > 0 && (
          <>
            <GraphicsPerDayDashboard
              period={currentPeriod}
              groupHoursPerDay={groupHoursPerDay}
              isMobile={isMobile}
              currentPeriod={currentPeriod}
              totalHours={totalHoursPerDay}
            />

            <div className="profile_graph mt-4">
              <GraphicsPerProjectDashboard
                groupHours={groupHoursPerProject}
                totalHours={totalHoursPerProject}
                title="Worked hours per project"
                currentPeriod={currentPeriod}
              />

              <GraphicsPerTaskDashboard
                groupHours={groupHoursPerTask}
                totalHours={totalHoursPerTask}
                title="Worked hours per task"
              />
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Dashboard
