import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

// Components
import { FormSettingsProject } from 'Organisms/Forms'
import LoadingButton from 'Organisms/LoadingButton'
import { ModalSuccess } from 'Organisms/ModalSuccess'
import { Loading } from 'Organisms/Loading'

// Hooks
import { useClient, useProject } from 'Hooks'

// Const
import { ROLE_ADMIN_NAME } from 'constant/roles'

// Context
import useSessionStore from 'Context/useSessionStore'
import { FIELD_CLIENT_ID_PROJECT } from 'Pages/Projects/ProjectModal'
import { PROJECT_CODE_ALREADY_EXISTS } from 'Utils/const/messages'

const Settings = ({ project, refreshProjectInfo }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const isAdmin = user.role === ROLE_ADMIN_NAME

  const [showDropdown, setShowDropdown] = useState(true)
  const [loading, setLoading] = useState(false)
  const [errorState, setErrorState] = useState('')
  const [modalSuccess, setModalSuccess] = useState(false)

  // Hooks
  const { loadingProjectHook, updateProjectHook } = useProject()
  const { listClients, loadingClientHook, getClientsHook, createClientHook } = useClient()

  const LOADING = loadingClientHook || loadingProjectHook

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setError
  } = useForm({
    defaultValues: { ...project, [FIELD_CLIENT_ID_PROJECT]: project.client.id }
  })

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      const body = data

      if (!showDropdown) {
        const resCreateClient = await createClientHook({ nameClient: data[FIELD_CLIENT_ID_PROJECT] })

        if (!resCreateClient.success) {
          return setError('client_id', { type: 'exist', message: resCreateClient.message })
        }

        body[FIELD_CLIENT_ID_PROJECT] = resCreateClient.data.id
      }

      const res = await updateProjectHook({ body, projectId: project.id })

      if (res.error) {
        if ((res?.message || '').includes(PROJECT_CODE_ALREADY_EXISTS)) {
          setError('code', { type: 'custom', message: res?.message })
        }

        return
      }

      await Promise.allSettled([getClientsHook(), refreshProjectInfo()])
      setModalSuccess(true)
    } catch (e) {
      setErrorState(e.message)
    } finally {
      setLoading(false)
    }
  }

  const handleChangeDropdownClient = () => {
    reset({
      [FIELD_CLIENT_ID_PROJECT]: showDropdown ? '' : project.client.id
    })

    setShowDropdown((prevtState) => !prevtState)
  }

  useEffect(() => {
    ;(async () => {
      await getClientsHook()
      reset({
        [FIELD_CLIENT_ID_PROJECT]: project.client.id
      })
    })()
  }, [])

  return (
    <>
      {LOADING && <Loading />}

      <div className="row justify-content-center">
        <div className="col-lg-11 col-12 d-grid">
          <h2 className="mb-3">Settings</h2>
          <FormSettingsProject
            onSubmit={handleSubmit(onSubmit)}
            error={errorState}
            showDropdown={showDropdown}
            register={register}
            clients={listClients}
            errors={errors}
            isAdmin={isAdmin}
            onChangeShowDropdown={handleChangeDropdownClient}
            formControl="settingProject"
            defaultClient={project.client.id}
          />
          <div className="row justify-content-start">
            <div className="col-auto">
              <LoadingButton
                type="submit"
                form="settingProject"
                className="btn btn-block btn-primary"
                disabled={loading}>
                Save changes
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>

      {modalSuccess && (
        <ModalSuccess
          visible={modalSuccess}
          setVisible={setModalSuccess}
          title={
            <>
              The changes have been saved <br /> successfully
            </>
          }
          btnConfirm="Back to Project"
        />
      )}
    </>
  )
}

export default Settings
