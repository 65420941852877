import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import useSessionStore from '../Context/useSessionStore'
import { forgotPasswordSubmit } from '../Hooks/useAuth'
import { InputMessageError } from 'Organisms/InputMessageError'
import { LOGIN_ROUTER } from 'constant/router'
import { PasswordRequirement } from './Welcome/Components'
import { Logo } from 'Organisms'

const ResetPassword = () => {
  const [searchParams] = useSearchParams()

  const [errorMessage, setErrorMessage] = useState('')

  const { challenge, updateChallenge } = useSessionStore(
    (state) => ({
      user: state.user,
      updateUser: state.updateUser,
      challenge: state.challenge,
      updateChallenge: state.updateChallenge
    })
  )

  const { register, handleSubmit, formState: { errors } } = useForm()

  const navigate = useNavigate()

  const onSubmit = (data) => {
    if (data.password === data.passwordConfirm) {
      updateChallenge(challenge) // aqui este challenge esta null
      forgotPasswordSubmit(
        searchParams.get('user_name').toLowerCase().replace(' ', '+'),
        searchParams.get('confirmation_code'),
        data.password
      )
        .then(async () => {
          navigate(LOGIN_ROUTER)
        })
        .catch((error) => {
          console.error(error)
        })
    } else {
      setErrorMessage('Passwords must match')
    }
  }

  return (
    <div className="container login dark-theme">
      <div className="row justify-content-center align-items-center">
        <div className="col-12 col-md-8 col-lg-5">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-8">
              <div className="row justify-content-center">
                <div className="col-9 logo-wrapper">
                  <Logo />
                </div>
              </div>
              <div className="row justify-content-center mt-5">
                  <h2>Set new password</h2>
                  <p>Must be at least 8 characters.</p>
                <form className="col-12 d-grid" onSubmit={handleSubmit(onSubmit)}>
                  {errorMessage && <InputMessageError error={errorMessage} />}
                  {errors.password && <InputMessageError error='Password must have at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character' />}
                  <div className="mb-3">
                    <label className="form-label d-flex align-items-center" htmlFor="inputPassword">
                      Password
                      <PasswordRequirement />
                    </label>
                    <input
                      type="password"
                      {...register('password', {
                        required: true,
                        pattern: /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*([^\w\s]|[_]))\S{8,}$/
                      })}
                      className={`form-control ${errorMessage ? 'is-invalid' : ''}`}
                      id="inputPassword"
                      aria-describedby="emailHelp"
                      placeholder="New password"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="inputConfirmPassword">
                      Confirm password
                    </label>
                    <input
                      type="password"
                      {...register('passwordConfirm', { required: true })}
                      className="form-control"
                      id="inputConfirmPassword"
                      placeholder="Confirm new password"
                    />
                  </div>
                  <button type="submit" className="btn btn-block btn-primary">
                    Set new password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
