// TABLE TIME SHEET
export const TABLE_ITEM_TITLE_TIMESHEET = 'title'
export const TABLE_ITEM_STATE_TIMESHEET = 'state'
export const TABLE_ITEM_STORY_POINTS_TIMESHEET = 'story_points'
export const TABLE_ITEM_ESTIMATE_TIMESHEET = 'estimate'
export const TABLE_ITEM_BILLABLE_TIMESHEET = 'billable'
export const TABLE_ITEM_WARRANTY_WORK_TIMESHEET = 'warranty_work'
export const TABLE_ITEM_MY_WORK_TIMESHEET = 'my_work'
export const TABLE_ITEM_TAKS_TIMESHEET = 'task'

export const INITIAL_STATE_TIMESHEET = [
  { id: TABLE_ITEM_TITLE_TIMESHEET, name: TABLE_ITEM_TITLE_TIMESHEET, text: 'Item', checked: true, is_detailed: false, order: 0 },
  { id: TABLE_ITEM_TAKS_TIMESHEET, name: TABLE_ITEM_TAKS_TIMESHEET, text: 'Task', checked: true, is_detailed: false, order: 1 },
  { id: TABLE_ITEM_STATE_TIMESHEET, name: TABLE_ITEM_STATE_TIMESHEET, text: 'State', checked: true, is_detailed: false, order: 2 },
  { id: TABLE_ITEM_STORY_POINTS_TIMESHEET, name: TABLE_ITEM_STORY_POINTS_TIMESHEET, text: 'Story points', checked: true, is_detailed: false, order: 3 },
  { id: TABLE_ITEM_ESTIMATE_TIMESHEET, name: TABLE_ITEM_ESTIMATE_TIMESHEET, text: 'Estimate', checked: true, is_detailed: false, order: 4 },
  { id: TABLE_ITEM_BILLABLE_TIMESHEET, name: TABLE_ITEM_BILLABLE_TIMESHEET, text: 'Billable', checked: true, is_detailed: false, order: 5 },
  { id: TABLE_ITEM_WARRANTY_WORK_TIMESHEET, name: TABLE_ITEM_WARRANTY_WORK_TIMESHEET, text: 'Warranty', checked: true, is_detailed: false, order: 6 },
  { id: TABLE_ITEM_MY_WORK_TIMESHEET, name: TABLE_ITEM_MY_WORK_TIMESHEET, text: 'My Hours', checked: true, is_detailed: false, order: 7 }
]

// TABLE TIME ENTRY
export const TABLE_ITEM_DATE_TIME_ENTRY = 'date'
export const TABLE_ITEM_TITLE_TIME_ENTRY = 'title'
export const TABLE_ITEM_NOTES_TIME_ENTRY = 'notes'
export const TABLE_ITEM_USER_TIME_ENTRY = 'user'
export const TABLE_ITEM_STATE_TIME_ENTRY = 'state'
export const TABLE_ITEM_STORY_POINT_TIME_ENTRY = 'story_points'
export const TABLE_ITEM_ESTIMATE_TIME_ENTRY = 'estimate'
export const TABLE_ITEM_HOURS_TIME_ENTRY = 'hours'
export const TABLE_ITEM_COMPLETED_WORK_TIME_ENTRY = 'completed_work'
export const TABLE_ITEM_TAKS_TIMEENTRY = 'task'

export const INITIAL_STATE_TIMEENTRY = [
  { id: TABLE_ITEM_DATE_TIME_ENTRY, text: 'Date', checked: true, name: TABLE_ITEM_DATE_TIME_ENTRY, is_detailed: true, order: 0 },
  { id: TABLE_ITEM_TITLE_TIME_ENTRY, text: 'Item', checked: true, name: TABLE_ITEM_TITLE_TIME_ENTRY, is_detailed: true, order: 1 },
  { id: TABLE_ITEM_NOTES_TIME_ENTRY, text: 'Notes', checked: true, name: TABLE_ITEM_NOTES_TIME_ENTRY, is_detailed: true, order: 2 },
  { id: TABLE_ITEM_TAKS_TIMEENTRY, name: TABLE_ITEM_TAKS_TIMEENTRY, text: 'Task', checked: true, is_detailed: true, order: 3 },
  { id: TABLE_ITEM_USER_TIME_ENTRY, text: 'User', checked: true, name: TABLE_ITEM_USER_TIME_ENTRY, is_detailed: true, order: 4 },
  { id: TABLE_ITEM_STATE_TIME_ENTRY, text: 'State', checked: true, name: TABLE_ITEM_STATE_TIME_ENTRY, is_detailed: true, order: 5 },
  { id: TABLE_ITEM_STORY_POINT_TIME_ENTRY, text: 'Story Point', checked: true, name: TABLE_ITEM_STORY_POINT_TIME_ENTRY, is_detailed: true, order: 6 },
  { id: TABLE_ITEM_ESTIMATE_TIME_ENTRY, text: 'Estimate', checked: true, name: TABLE_ITEM_ESTIMATE_TIME_ENTRY, is_detailed: true, order: 7 },
  { id: TABLE_ITEM_HOURS_TIME_ENTRY, text: 'Hours', checked: true, name: TABLE_ITEM_HOURS_TIME_ENTRY, is_detailed: true, order: 8 },
  { id: TABLE_ITEM_COMPLETED_WORK_TIME_ENTRY, text: 'Completed Work', checked: true, name: TABLE_ITEM_COMPLETED_WORK_TIME_ENTRY, is_detailed: true, order: 9 }
]
