const RocketIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 200">
      <path
        fill="none"
        stroke={color}
        strokeWidth={2}
        d="M109.9,32.6l-59.5.4h0c-2,0-3.9.4-5.8,1.1-1.8.8-3.5,1.9-4.9,3.3h0S5.4,71.7,5.4,71.7h0c-2,2-3.4,4.5-4,7.2-.6,2.7-.5,5.6.4,8.2.9,2.6,2.5,5,4.6,6.8,2.1,1.8,4.7,2.9,7.5,3.3,0,0,0,0,0,0l38.5,5.4h.3s.2.3.2.3l44.2,44.2.2.2v.3s8.7,46,8.7,46M109.1,33l.3-.3M109.1,33l.3-.3M109.4,32.7l4.7-4.8s0,0,0,0C140.5,1.5,172.3.4,184.8,1.1c3.7.2,7.1,1.8,9.7,4.4,2.6,2.6,4.1,6,4.4,9.7.7,12.5-.4,44.3-26.8,70.7l-4.8,4.8-.3.3v58.7h0c0,2-.4,3.9-1.1,5.8-.8,1.8-1.9,3.5-3.3,4.9h0s-34.3,34.3-34.3,34.3h0c-1.4,1.4-3,2.5-4.9,3.3-1.8.8-3.8,1.1-5.7,1.1h0c-1.6,0-3.2-.3-4.8-.8h0M112.9,198.2c-2.6-.9-5.2-2.9-7-5M50.4,47h-.4l-.3.3L15.3,81.7l-1.4,1.4,2,.3,37.2,5.2h.5c0,0,.4-.3.4-.3l39.5-39.5,1.7-1.7h-44.7ZM184.9,16v-.9c0,0-.9,0-.9,0-11-.7-22.1,1-32.4,4.9-10.3,3.9-19.7,10-27.5,17.8,0,0,0,0,0,0l-57.5,57.5-.7.7.7.7,36.7,36.7.7.7.7-.7,57.5-57.4s0,0,0,0c7.8-7.8,13.9-17.2,17.8-27.5,3.9-10.3,5.6-21.4,4.9-32.4ZM152.7,150.4l.3-.3v-45.2l-1.7,1.7-39.5,39.5-.4.4v.5c0,0,5.3,37.2,5.3,37.2l.3,2,1.4-1.4,34.3-34.4ZM77.1,158.4v.3c-2.3,4.8-7.1,12.9-16.6,19.7-9.4,6.8-23.6,12.5-44.5,12.5s-3.6-.7-5-2.1c-1.3-1.3-2.1-3.1-2.1-4.9,0-21,5.7-35.1,12.5-44.5,6.9-9.4,14.9-14.2,19.7-16.4.8-.4,1.7-.6,2.7-.6.9,0,1.8.1,2.7.4.9.3,1.7.8,2.3,1.4.7.6,1.2,1.4,1.6,2.2.4.8.6,1.7.6,2.7,0,.9-.1,1.8-.4,2.7-.3.9-.8,1.7-1.4,2.3-.6.7-1.4,1.2-2.2,1.6-6.7,3.1-21.2,12.8-23.7,39.7v1.2c-.1,0,1.1-.1,1.1-.1,27-2.5,36.7-17,39.7-23.7.4-.8.9-1.6,1.6-2.2.7-.6,1.5-1.1,2.3-1.4.9-.3,1.8-.5,2.7-.4.9,0,1.8.2,2.7.6.8.4,1.6.9,2.2,1.6.6.7,1.1,1.5,1.4,2.3.3.9.5,1.8.4,2.7,0,.8-.2,1.6-.5,2.3Z"
      />
    </svg>
  )
}

export default RocketIcon
