export const INIT_HOURS_PER_DAYS = [
  {
    name: 'Saturday',
    billable: 0,
    warranty: 0
  },
  {
    name: 'Sunday',
    billable: 0,
    warranty: 0
  },
  {
    name: 'Monday',
    billable: 0,
    warranty: 0
  },
  {
    name: 'Tuesday',
    billable: 0,
    warranty: 0
  },
  {
    name: 'Wednesday',
    billable: 0,
    warranty: 0
  },
  {
    name: 'Thursday',
    billable: 0,
    warranty: 0
  },
  {
    name: 'Friday',
    billable: 0,
    warranty: 0
  }
]

export const COLORS_GRAFICH = ['#052020', '#0C3736', '#004d40', '#00695c', '#00796b', '#00897b', '#50C5B7', '#CCE8E6']
