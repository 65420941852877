import axios from 'axios'

const BASE_URL = '/schedule'

export const getScheduleService = async () => axios.get(BASE_URL).then(({ data }) => data)

export const postScheduleService = async ({ weekday, hour, timezone }) =>
  axios.post(BASE_URL, { weekday, hour, timezone }).then(({ data }) => data)

export const updateScheduleService = async ({ weekday, hour, timezone }) =>
  axios.patch(BASE_URL, { weekday, hour, timezone }).then(({ data }) => data)

export const deleteScheduleService = async () => axios.delete(BASE_URL).then(({ data }) => data)
