import useSessionStore from 'Context/useSessionStore'
import { COLOR_EMERALD, COLOR_RED, GET_COLOR_BY_THEME } from 'constant/colors'
import Avatar from 'react-avatar'
import { DateTime } from 'luxon'
import { decimalToHours, mapStates } from 'Utils/helperFunctions'
import { ArrowRight, CheckCircle, XCircle } from '@phosphor-icons/react'

import {
  STATUS_COLOR_TICKET_INFO,
  STATUS_COLOR_TICKET_SECONDARY,
  STATUS_COLOR_TICKET_SUCCESS,
  STATUS_TICKET_DONE,
  STATUS_TICKET_IN_PROGRESS,
  STATUS_TICKET_TO_DO
} from 'constant/StatusTicket'

const COLOR_STATUS_DEFAULT = {
  [STATUS_TICKET_IN_PROGRESS.toLowerCase()]: STATUS_COLOR_TICKET_INFO,
  [STATUS_TICKET_TO_DO.toLowerCase()]: STATUS_COLOR_TICKET_SECONDARY,
  [STATUS_TICKET_DONE.toLowerCase()]: STATUS_COLOR_TICKET_SUCCESS
}

const CardDetailsTimeEntry = ({ date, event, userDetail }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const TYPE_EVENTS = {
    START: {
      title: 'started the time entry',
      description: event?.hours && `${decimalToHours({ time: event.hours })} worked hours`
    },
    SUBMITED: {
      title: 'submitted the hours for approval',
      description: `${decimalToHours({ time: event.hours })} worked hours`
    },
    PAYROLL: {
      title: event?.is_rejected ? 'rejected the hours' : 'approved the hours',
      description: event?.is_rejected ? event?.comment || '' : '',
      caption: event?.is_rejected
        ? (
        <>
          <XCircle size={15} color={COLOR_RED} /> Have been rejected
        </>
          )
        : (
        <>
          <CheckCircle size={15} color={COLOR_EMERALD} /> Have been aprroved
        </>
          )
    },
    STOP: {
      title: 'stopped the time entry',
      description: `${decimalToHours({ time: event.hours })} worked hours`
    },
    NOTE: {
      title: 'left a note',
      description: event.note
    },
    STATUS_CHANGE: {
      title: 'Change the ticket status',
      description: (
        <>
          {' '}
          from{' '}
          <span className={`badge text-bg-${COLOR_STATUS_DEFAULT[(event?.old_status || '').toLowerCase()]}`}>
            {mapStates(event.old_status)}
          </span>{' '}
          <ArrowRight color={GET_COLOR_BY_THEME({ theme: user.theme }).MENU_NAVBAR} size={15} />{' '}
          <span className={`badge text-bg-${COLOR_STATUS_DEFAULT[(event?.new_status || '').toLowerCase()]}`}>
            {mapStates(event.new_status)}
          </span>
        </>
      )
    }
  }

  const theme = user.theme

  return (
    <div className="row mb-5">
      <div className="col-12">
        <div className="row">
          <div className="col-auto cardDetailsTimeEntry_avatar">
            <Avatar
              name="Sergio Rodrigez"
              src={userDetail?.logo || ''}
              className="avatarImage"
              color={GET_COLOR_BY_THEME({ theme }).AVATAR}
              fgColor={GET_COLOR_BY_THEME({ theme }).AVATAR_TEXT}
              round={true}
              size="30px"
            />
          </div>
          <div className="col">
            <p className="m-0 fs-7">{DateTime.fromISO(date).toFormat('h:mm a / MMMM dd, yyyy')}</p>
            <p className="text-normal m-0">
              <span className="text-primary m-0">{userDetail?.firstname || ''}</span> {TYPE_EVENTS[event.type].title}
            </p>

            {TYPE_EVENTS[event.type]?.description && <p className="m-0">{TYPE_EVENTS[event.type].description}</p>}

            {TYPE_EVENTS[event.type]?.caption && <p className="m-0">{TYPE_EVENTS[event.type].caption}</p>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardDetailsTimeEntry
