import { countDecimalsAndAdd0 } from 'Utils/helperFunctions'

const CustomTooltipTreeChart = ({ active, payload, totalHours }) => {
  if (!active) return null
  const {
    root: { name },
    hours
  } = payload[0].payload

  return (
    <div className="CustomTooltipBarChart">
      <p>{name}</p>
      <p>{countDecimalsAndAdd0({ number: hours })}h</p>
      <p>{`(${countDecimalsAndAdd0({
        number: (hours * 100) / totalHours
      })}%)`}</p>
    </div>
  )
}

export default CustomTooltipTreeChart
