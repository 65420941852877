import {
  STATUS_COLOR_TICKET_DANGER,
  STATUS_COLOR_TICKET_INFO,
  STATUS_COLOR_TICKET_SECONDARY,
  STATUS_COLOR_TICKET_SUCCESS,
  STATUS_COLOR_TICKET_WARNING
} from 'constant/StatusTicket'
import { setBackgroundColorTimeSheet } from 'Pages/Timesheet/utils/helpersTimeSheet'

const ProgressBarTimeSheet = ({ warrantyWork, estimate, status, completedWork, item }) => {
  const COLOR_STATUS = setBackgroundColorTimeSheet({
    status,
    completedWork,
    estimate,
    warrantyWork
  })

  return (
    <div
      style={{ width: '100px' }}
      className="progress"
      role="progressbar"
      aria-label="Basic example"
      aria-valuenow="0"
      aria-valuemin="0"
      aria-valuemax="100">
      {warrantyWork > 0
        ? (
        <>
          <div
            className={`progress-bar ${COLOR_STATUS === STATUS_COLOR_TICKET_WARNING ? 'text-bg-warning' : ''} ${
              !estimate && completedWork === 0 ? 'text-bg-terciary' : ''
            }`}
            style={{
              // si hay estimado calculamos cuanto es el % y si no hay estimado mostramos 100%
              width: estimate ? (completedWork * 100) / estimate + '%' : `${((completedWork * 100) / (completedWork + warrantyWork))}%`
            }}
          />
          <div
            className="progress-bar text-bg-danger"
            style={{
              // si hay estimado y el tiempo completado - la garantia supera el estimado, calculamos cuanto % se esta pasando
              // y si no hay estimado lo dejamos en 0% porque cuando no hay estimado el 100% es la horas trabajas en azul
              width:
                estimate && completedWork > estimate
                  ? ((completedWork - estimate) * 100) / estimate + '%'
                  : '0%'
            }}
          />
          <div
            className="progress-bar text-bg-terciary"
            style={{
              // si hay estimado y garantia, cualculamos el % de esa garantia
              // y si no hay estimado lo dejamos en 0% porque cuando no hay estimado el 100% es la horas trabajas en azul
              width: estimate ? (warrantyWork * 100) / estimate + '%' : `${((warrantyWork * 100) / (completedWork + warrantyWork))}%`
            }}
          />
        </>
          )
        : (
        <>
          {COLOR_STATUS === STATUS_COLOR_TICKET_SECONDARY && <div className="progress-bar" style={{ width: '0%' }} />}
          {COLOR_STATUS === STATUS_COLOR_TICKET_INFO && (
            <div
              className="progress-bar"
              style={{
                width: estimate ? (completedWork * 100) / estimate + '%' : completedWork > 0 ? '100%' : '0%'
              }}
            />
          )}
          {COLOR_STATUS === STATUS_COLOR_TICKET_WARNING && (
            <div
              className="progress-bar text-bg-warning"
              style={{ width: estimate ? (completedWork * 100) / estimate + '%' : '100%' }}
            />
          )}
          {COLOR_STATUS === STATUS_COLOR_TICKET_DANGER && (
            <>
              <div className="progress-bar" style={{ width: '100%' }} />
              <div
                className="progress-bar text-bg-danger"
                style={{
                  width: estimate ? (completedWork * 100) / estimate - 100 + '%' : '100%'
                }}
              />
            </>
          )}
          {COLOR_STATUS === STATUS_COLOR_TICKET_SUCCESS && (
            <div
              className="progress-bar"
              style={{ width: estimate ? (completedWork * 100) / estimate + '%' : '100%' }}
            />
          )}
        </>
          )}
    </div>
  )
}

export default ProgressBarTimeSheet
