import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import useModal from '../../../Hooks/useModal'
import { PersonSimpleRun } from '@phosphor-icons/react'
import { COLOR_RED, GET_COLOR_BY_THEME } from 'constant/colors'
import { useUser } from 'Hooks'
import { Loading } from 'Organisms/Loading'
import useSessionStore from 'Context/useSessionStore'

const DeactivateUserModal = ({ visible, setVisible, selectedUser, reloadUsers = () => {}, onError }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const { Modal, hide } = useModal({ identifier: 'deactivateuser', visible, setVisible })

  const { handleSubmit, reset } = useForm()
  const { loadingUserHook, updateUserHook } = useUser()

  useEffect(() => {
    reset(selectedUser)
  }, [selectedUser, reset])

  const onSubmit = async () => {
    try {
      const res = await updateUserHook({
        userId: selectedUser.id,
        body: {
          is_active: !selectedUser.is_active
        }
      })

      hide()

      if (!res.success) {
        return onError({
          data: {
            open: true,
            error: true,
            message: res.message
          }
        })
      } else {
        reloadUsers()
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Modal>
      <>
        {loadingUserHook && <Loading />}

        <div className="modal-content" style={{ maxWidth: 500 }}>
          <form onSubmit={handleSubmit(onSubmit)} className="modal-body p-5">
            <div className="row justify-content-center">
              {selectedUser.is_active && <PersonSimpleRun size={56} color={COLOR_RED} weight="bold" />}
              {!selectedUser.is_active && (
                <PersonSimpleRun
                  style={{ transform: 'scaleX(-1)' }}
                  size={56}
                  color={GET_COLOR_BY_THEME({ theme: user.theme }).PRIMARY}
                  weight="bold"
                />
              )}
            </div>
            <div className="row justify-content-center py-4">
              <p className="text-center">
                Are you sure you want to {!selectedUser.is_active ? 'activate' : 'deactivate'} {selectedUser.firstname}{' '}
                {selectedUser.lastname} ({selectedUser.email}) ?{' '}
              </p>
              {selectedUser.is_active && <p>We&apos;ll still keep this user information and related data</p>}
              {!selectedUser.is_active && (
                <p>We&apos;ll restore this user information and related data to the system</p>
              )}
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <button type="button" className="btn btn-secondary mr-2" onClick={hide}>
                  Cancel
                </button>
              </div>
              <div className="col-auto">
                <button
                  type="submit"
                  onSubmit={handleSubmit(onSubmit)}
                  className={`btn ${!selectedUser.is_active ? 'btn-primary' : 'btn-danger'}`}>
                  {!selectedUser.is_active ? 'Activate' : 'Deactivate'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </>
    </Modal>
  )
}

export default DeactivateUserModal
