import { useState } from 'react'
import { IntegrationCard } from '../Components/IntegrationCard'
import JiraLogo from 'Assets/images/jira.svg'
import { useDevops } from 'Hooks'
import { Loading } from 'Organisms/Loading'
import { ModalSuccess } from 'Organisms/ModalSuccess'
import ModalConfirmOrCancel from 'Organisms/ModalConfirmOrCancel'
import { Plugs } from '@phosphor-icons/react'
import { COLOR_RED } from 'constant/colors'
import { ConnectJiraModal } from './Components/ConnectJiraModal'
import {
  FIELD_CONNECT_INTEGRATION_ESTIMATE,
  FIELD_CONNECT_INTEGRATION_SPRINT,
  FIELD_CONNECT_INTEGRATION_STORY_POINTS,
  FIELD_CONNECT_JIRA
} from './Components/ConnectJiraModal/const/connectJiraFields'

export const JIRA_INTEGRATIONS = {
  id: 'jira',
  logo: JiraLogo,
  title: 'Jira Board',
  description: 'Atlassian bug tracking and project management software.'
}

const JiraIntegration = ({ project, refreshProjectInfo }) => {
  const [editIntegration, setEditIntegration] = useState(null)
  const [deleteIntegration, setDeleteIntegration] = useState(null)
  const [integrationConenct, setIntegrationConnect] = useState(null)
  const [successfully, setSuccessfully] = useState({
    open: false
  })
  const [messageDelete, setMessageDelete] = useState(false)
  const [errorConnect, setErrorConnect] = useState({ open: false })

  const { loadingDevopsHook, connectIntegrationsHook, disconnectIntegrationsHook, updateIntegrationsHook } = useDevops()

  const handleConnect = async ({ data, project_id_jira }) => {
    const body = {
      domain: data[FIELD_CONNECT_JIRA.DOMAIN],
      email: data[FIELD_CONNECT_JIRA.EMAIL],
      token: data[FIELD_CONNECT_JIRA.TOKEN],
      project_key: data[FIELD_CONNECT_JIRA.PROJECT_KEY],
      projectId: project.id,
      devops_project_id: project_id_jira,
      fields: {
        [FIELD_CONNECT_INTEGRATION_ESTIMATE.field]: data[FIELD_CONNECT_INTEGRATION_ESTIMATE.field],
        [FIELD_CONNECT_INTEGRATION_STORY_POINTS.field]: data[FIELD_CONNECT_INTEGRATION_STORY_POINTS.field],
        [FIELD_CONNECT_INTEGRATION_SPRINT.field]: data[FIELD_CONNECT_INTEGRATION_SPRINT.field]
      }
    }

    const res = editIntegration ? await updateIntegrationsHook(body) : await connectIntegrationsHook(body)

    if (!res.success) return setErrorConnect({ open: true, message: res.message })

    await refreshProjectInfo()

    setSuccessfully({
      open: true,
      message: editIntegration
        ? 'The integration has been successfully updated'
        : 'The new integration has been successfully added'
    })
  }

  const handleConnectIntegrations = ({ edit }) => {
    const fields = project.integration_mapping.find((mapping) => mapping.type === edit.type)

    if (edit) {
      setEditIntegration({
        ...edit,
        [FIELD_CONNECT_JIRA.DOMAIN]: `https://${edit.domain}.atlassian.net`,
        [FIELD_CONNECT_JIRA.COMPLETED_WORK]: fields?.completed_work ?? '',
        [FIELD_CONNECT_JIRA.ESTIMATE]: fields?.estimate ?? '',
        [FIELD_CONNECT_JIRA.SPRINT]: fields?.sprint ?? '',
        [FIELD_CONNECT_JIRA.STORY_POINTS]: fields?.story_points ?? ''
      })
    }

    setIntegrationConnect(true)
  }

  const handleConfirmationDeleteIntegration = () => setDeleteIntegration(true)

  const handleDeleteIntegration = async () => {
    const res = await disconnectIntegrationsHook({ projectId: project.id })

    if (!res.success) return setErrorConnect({ open: true })

    await refreshProjectInfo()
    setDeleteIntegration(null)
    setMessageDelete(true)
  }

  return (
    <>
      {loadingDevopsHook && <Loading />}

      <div className="col-12 mb-4">
        <div className="card p-5">
          <IntegrationCard
            logo={JIRA_INTEGRATIONS.logo}
            title={JIRA_INTEGRATIONS.title}
            description={JIRA_INTEGRATIONS.description}
            onConnect={handleConnectIntegrations}
            integrationInfo={(project?.integrations || []).find((integ) => integ.type === JIRA_INTEGRATIONS.id)}
            onEdit={handleConnectIntegrations}
            onDelete={handleConfirmationDeleteIntegration}
          />
        </div>
      </div>

      {integrationConenct && (
        <ConnectJiraModal
          logo={JIRA_INTEGRATIONS.logo}
          type={JIRA_INTEGRATIONS.title}
          onClose={() => setIntegrationConnect(null)}
          onConnect={handleConnect}
          edit={editIntegration}
          onError={() => setErrorConnect({ open: true })}
        />
      )}

      {successfully.open && (
        <ModalSuccess
          visible={true}
          setVisible={() => setSuccessfully({ open: false, message: '' })}
          title={successfully.message}
          identifier="modalSuccessIntegration"
        />
      )}

      {deleteIntegration && (
        <ModalConfirmOrCancel
          title="Are you sure you want to disconnect this integration ?"
          setVisible={() => setDeleteIntegration(null)}
          btnConfirm="Disconnect"
          onConfirm={handleDeleteIntegration}
          icon={<Plugs size={56} color={COLOR_RED} weight="bold" />}
          style="danger"
        />
      )}

      {messageDelete && (
        <ModalSuccess
          visible={true}
          setVisible={() => setMessageDelete(false)}
          title="The integration has been successfully removed"
          identifier="modalRemoveIntegration"
        />
      )}

      {errorConnect?.open && (
        <ModalSuccess
          visible={true}
          setVisible={() => setErrorConnect({ open: false })}
          title={errorConnect?.message}
          identifier="modalErrorIntegration"
          error
        />
      )}
    </>
  )
}

export default JiraIntegration
