import { useState, useEffect } from 'react'
import useSessionStore from 'Context/useSessionStore'
import BillingPlan from './BillingPlan'
import { GET_LIST_PLAN_PACKAGES } from 'Utils/const/packages'
import { useCompany } from 'Hooks'
import { Loading } from 'Organisms/Loading'
import PaymentMethod from './PaymentMethod'
import BillingInformation from './BillingInformation'
import InvoicesSectionBillingSettings from './InvoicesSectionBillingSettings'

const BillingSettings = () => {
  const { user } = useSessionStore((state) => ({ user: state.user }))
  const [loading, setLoading] = useState(true)
  const [companyPlan, setCompanyPlan] = useState(null)

  const { companyInfoHook, getCompanyInfoHook } = useCompany()

  const getCompanyInfo = async () => {
    const res = await getCompanyInfoHook({ companyId: user.company_id })

    if (!res.success) return

    const planlist = GET_LIST_PLAN_PACKAGES({ period: res?.data?.company_plan_package?.period })

    setCompanyPlan(Object.values(planlist).find((item) => item.id === res.data.company_plan_package.plan_package_id))
  }

  useEffect(() => {
    ;(async () => {
      try {
        await getCompanyInfo()
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  return (
    <div className="col-12 d-grid align-content-start">
      {loading && <Loading />}
      <div className="row mb-2 justify-content-between gy-sm-0 gy-4 align-items-center">
        <div className="col-sm-auto col-12 d-flex align-items-center">
          <h2>Billing</h2>
        </div>
      </div>
      <div className="row mb-2">
        <p>Manage your information and invoices.</p>
      </div>

      {companyPlan && (
        <BillingPlan
          plan={companyPlan}
          expirationDate={companyInfoHook?.company_plan_package?.expiration_date}
          seats={companyInfoHook?.company_plan_package?.seats}
          period={companyInfoHook?.company_plan_package?.period}
          isTrial={companyInfoHook.isTrial}
          expire_trial={companyInfoHook.expire_trial}
          onRefreshCompany={getCompanyInfo}
          isCancel={!!companyInfoHook?.company_plan_package?.cancel_plan}
        />
      )}

      {companyInfoHook?.company_plan_package?.subscription_id && <PaymentMethod />}

      <hr />
      <BillingInformation billing={companyInfoHook.billing_information} onRefresh={getCompanyInfo} />
      <hr />

      <InvoicesSectionBillingSettings />
    </div>
  )
}

export default BillingSettings
