import useSessionStore from 'Context/useSessionStore'
import { COLOR_EMERALD } from 'constant/colors'
import Select from 'react-select'

const THEME = {
  DARK: {
    primary: '#363A39', // active select
    primary25: '#363A39', // hover option
    primary50: '#363A39', // active option
    neutral0: '#1D2020', // bg
    neutral20: '#363A39', // border
    neutral30: '#363A39', // hover select
    neutral40: 'white', // message
    neutral50: 'white', // placeholder
    neutral60: '#363A39', // active arrow
    neutral80: 'white' // value
  },
  NORMAL: {
    primary: '#1D2020', // active select
    primary25: COLOR_EMERALD, // hover option
    primary50: '#32a591', // active option
    neutral0: 'white', // bg
    neutral20: '#1D2020', // border
    neutral30: '#1D2020', // hover select
    neutral40: '#1D2020', // message
    neutral50: '#1D2020', // placeholder
    neutral60: '#1D2020', // active arrow
    neutral80: '#1D2020' // value
  }
}
const SelectWithHint = ({
  loading,
  value,
  placeholder,
  formatSelect,
  handleInputChange,
  handleChange,
  noOptionsMessage,
  loadingMessage,
  id = ''
}) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  return (
    <Select
      isLoading={loading}
      theme={(theme) => ({
        ...theme,
        borderRadius: 4,
        colors: {
          ...theme.colors,
          ...(user.theme ? THEME.DARK : THEME.NORMAL)
        }
      })}
      isSearchable
      id={id}
      value={value}
      placeholder={placeholder}
      className='selectWithHint'
      options={formatSelect}
      onInputChange={handleInputChange}
      onChange={handleChange}
      noOptionsMessage={() => noOptionsMessage}
      loadingMessage={() => loadingMessage}
    />
  )
}

export default SelectWithHint
