export const FIELD_CONNECT_INTEGRATION_STORY_POINTS = {
  name: 'Story points',
  field: 'story_points'
}

export const FIELD_CONNECT_INTEGRATION_ESTIMATE = {
  name: 'Estimate',
  field: 'estimate'
}

export const FIELD_CONNECT_INTEGRATION_SPRINT = {
  name: 'Sprint',
  field: 'sprint'
}

export const FIELD_CONNECT_JIRA = {
  DOMAIN: 'domain',
  EMAIL: 'email',
  TOKEN: 'token',
  PROJECT_KEY: 'project_key',
  STORY_POINTS: FIELD_CONNECT_INTEGRATION_STORY_POINTS.field,
  ESTIMATE: FIELD_CONNECT_INTEGRATION_ESTIMATE.field,
  SPRINT: FIELD_CONNECT_INTEGRATION_SPRINT.field
}
