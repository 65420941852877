import useSessionStore from 'Context/useSessionStore'
import { PERMISSION_BY_ROLE } from 'Utils/permissionByRol'
import {
  ROLE_ADMIN_DISPLAY,
  ROLE_DEVELOPER_DISPLAY,
  ROLE_OPERATION_MANAGER_DISPLAY,
  ROLE_PRODUCT_OWNER_DISPLAY
} from 'constant/roles'
import React from 'react'

export const LIST_ROLE_FILTER_USER = {
  header: 'Role',
  options: [
    { option: ROLE_ADMIN_DISPLAY, allowed: PERMISSION_BY_ROLE.USER.FILTER_BY_ROLE.BY_ADMIN },
    { option: ROLE_OPERATION_MANAGER_DISPLAY, allowed: PERMISSION_BY_ROLE.USER.FILTER_BY_ROLE.BY_OPM },
    {
      option: ROLE_PRODUCT_OWNER_DISPLAY,
      allowed: PERMISSION_BY_ROLE.USER.FILTER_BY_ROLE.BY_PO
    },
    {
      option: ROLE_DEVELOPER_DISPLAY,
      allowed: PERMISSION_BY_ROLE.USER.FILTER_BY_ROLE.BY_DEV
    }
  ]
}

const FILTER = [LIST_ROLE_FILTER_USER]

const FilterUserPopover = ({ onFilter, filter, updateState }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const isDarkMode = user.theme ? 'dark-theme' : ''

  const handleAddSelectedFilter = ({ group, field }) => {
    const updateListFilter = {
      ...filter,
      [group]: filter?.[group]?.length > 0 ? [...filter?.[group], field] : [field]
    }

    onFilter({ result: updateListFilter })
    updateState(updateListFilter)
  }

  const handleRemoveSelectedFilter = ({ group, field }) => {
    const getFilter = {
      ...filter,
      [group]: filter[group].filter((item) => item !== field)
    }

    onFilter({ result: getFilter })
    return updateState(getFilter)
  }

  return (
    <div className={`filterUserPopover ${isDarkMode}`}>
      {FILTER.map((item) => (
        <div key={item.header} className="filterUserPopover_group">
          <div className="filterUserPopover_header p-2">{item.header}</div>

          {item.options.map((option) => {
            const isSelected = filter?.[item.header]?.some((item) => item === option.option)

            if (option?.allowed && !option.allowed.some((role) => role === user.role)) {
              return <React.Fragment key={option.option}></React.Fragment>
            }

            return (
              <div key={option.option} className={`filterUserPopover_option ${isDarkMode}`}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`${item.header}${option.option}`}
                    checked={isSelected}
                    onChange={() =>
                      isSelected
                        ? handleRemoveSelectedFilter({ group: item.header, field: option.option })
                        : handleAddSelectedFilter({ group: item.header, field: option.option })
                    }
                  />
                  <label className="form-check-label text-no-wrap" htmlFor={`${item.header}${option.option}`}>
                    {option.option}
                  </label>
                </div>
              </div>
            )
          })}
        </div>
      ))}
    </div>
  )
}

export default FilterUserPopover
