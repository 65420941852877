import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import { usePayroll } from 'Hooks'
import { BreakPointsPayroll, StepProjects, StepTimeSheet, StepUsers } from './Components'
import { CustomDatePickerGoBack } from 'Organisms/CustomDatePicker'
import FilterAllDropdown from 'Organisms/FilterAllDropdown'
import { getEndWeek, getStartWeek, getValuePerFilter } from 'Utils/helperFunctions'
import useSessionStore from 'Context/useSessionStore'
import { ROLE_ADMIN_NAME, ROLE_DEVELOPER_NAME } from 'constant/roles'
import { Loading } from 'Organisms/Loading'
import { TabsListTable } from 'Organisms/TabsListTable'
import { useMediaQuery } from 'react-responsive'
import { BREAKPOINT } from 'Utils/const/breakPoints'
import { ModalFilterPayrollMobile } from './Components/ModalFilterPayrollMobile'
import ApprovedPendingPiechart from './Components/Reports/ApprovedPendingPiechart'
import { TrendUp, TrendDown } from '@phosphor-icons/react'
import { useLocation } from 'react-router-dom'
import { DateTime } from 'luxon'
import { PERIOD_TIME_SHEET } from 'Pages/Timesheet/utils/const'
import { GoggleIcon } from 'Organisms/Svg'
import { GET_COLOR_BY_THEME } from 'constant/colors'

const STEPS_PAYROLL = {
  USERS: 'users',
  PROJECTS: 'projects',
  TIME_SHEET: 'time_sheet'
}

export const TABS_PAYROLL = {
  PENDING: {
    name: 'pending approval'
  },
  APPROVED: { name: 'approved' }
}

const PayrollPage = () => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const dateRange = queryParams.get('date')
  const isApprovedQuery = queryParams.get('is_approved')

  // Media query
  const isMobile = useMediaQuery({
    query: `(max-width: ${BREAKPOINT.MOBILE})`
  })

  // Hooks
  const { loadingPayrollHook, payrollHook, getAllPayroll } = usePayroll()

  // States
  const [step, setStep] = useState(null)
  const [startDay, setStartDay] = useState(getStartWeek())
  const [endDay, setEndDay] = useState(getEndWeek())

  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedProject, setSelectedProject] = useState(null)
  const [activeTab, setActiveTab] = useState(
    isApprovedQuery === 'true' ? TABS_PAYROLL.APPROVED.name : TABS_PAYROLL.PENDING.name
  )
  const [filter, setFilter] = useState({})

  const isUser = user.role === ROLE_DEVELOPER_NAME
  const disabledActions = isUser ? step !== STEPS_PAYROLL.PROJECTS : step !== STEPS_PAYROLL.USERS

  // Handlers
  useEffect(() => {
    setStep(isUser ? STEPS_PAYROLL.PROJECTS : STEPS_PAYROLL.USERS)

    if (dateRange) {
      const dates = dateRange.split(':')
      handleSearchDataByDate({
        startDay: DateTime.fromISO(dates[0]).setZone('local'),
        endDay: DateTime.fromISO(dates[1]).setZone('local')
      })
    } else {
      handleSearchDataByDate({ startDay, endDay })
    }
  }, [])

  const getPayroll = async ({ startDay, endDay, is_approved, projects, users }) => {
    const byFilter = {
      project_ids: getValuePerFilter({ list: projects, byValue: 'valueId' }),
      users_ids: getValuePerFilter({ list: users })
    }

    const res = await getAllPayroll({
      startDate: startDay,
      endDate: endDay,
      is_approved,
      ...byFilter
    })

    if (isUser) {
      setSelectedUser(res?.[0] || { full_name: user.full_name, projects: [] })
    }

    if (!isUser && selectedUser?.id) {
      const findUser = res.find((item) => item.id === selectedUser.id)
      setSelectedUser(findUser || { ...selectedUser, projects: [] })
    }
  }

  const handleSearchDataByDate = async ({ startDay, endDay } = {}) => {
    setStartDay(startDay)
    setEndDay(endDay)

    setSelectedUser(null)
    setSelectedProject(null)
    setStep(isUser ? STEPS_PAYROLL.PROJECTS : STEPS_PAYROLL.USERS)

    await getPayroll({ startDay, endDay, is_approved: activeTab === TABS_PAYROLL.APPROVED.name, ...filter })
  }

  const hangleChangeTab = async ({ tab, filter }) => {
    if (tab === activeTab) return

    setActiveTab(tab)

    await getPayroll({ startDay, endDay, is_approved: tab === TABS_PAYROLL.APPROVED.name, ...filter })
  }

  const handleGoBack = () => {
    let newStep = STEPS_PAYROLL.USERS

    if (step === STEPS_PAYROLL.TIME_SHEET) {
      newStep = STEPS_PAYROLL.PROJECTS
      setSelectedProject(null)
    }

    if (step === STEPS_PAYROLL.PROJECTS) {
      setSelectedUser(null)
    }

    setStep(newStep)
  }

  const handleChangeFilter = async ({ filter } = {}) => {
    setFilter(filter)

    await getPayroll({ startDay, endDay, is_approved: activeTab === TABS_PAYROLL.APPROVED.name, ...filter })
  }

  return (
    <>
      <Helmet>
        <title>Payroll</title>
      </Helmet>
      {loadingPayrollHook && <Loading />}
      <div className={`containers payroll ${!isMobile ? 'pt-4' : ''}`}>
        <div className="row align-items-end mt-4 mb-5 d-none">
          <div className="col">
            <div className="row">
              <h1 className="mb-4">{user.role === ROLE_ADMIN_NAME ? 'Payroll' : 'Payments'}</h1>
            </div>
            <div className="row">
              {user.role === ROLE_ADMIN_NAME && (
                <>
                  <div className="col">
                    <div className="card p-4">
                      <div className="row">
                        <div className="col-7">
                          <p className="text-secondary">Total Income</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-auto">
                          <h3 className="text-secondary my-2">$6123</h3>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-auto">
                          <p className="text-secondary small">
                            <TrendUp size={16} color="#00695c" weight="bold" /> +2% since last week
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card p-4">
                      <div className="row">
                        <div className="col-12">
                          <p className="text-secondary">Total Expense</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-auto">
                          <h3 className="text-secondary my-2">$234.23</h3>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-auto">
                          <p className="text-secondary small">
                            <TrendUp size={16} color="#FFB4AB" weight="bold" /> +7% since last week
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card p-4">
                      <div className="row">
                        <div className="col-12">
                          <p className="text-secondary">Gross Margin</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-auto">
                          <h3 className="text-secondary my-2">
                            $2345 <span className="h6">/ 13%</span>
                          </h3>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-auto">
                          <p className="text-secondary small">
                            <TrendDown size={16} color="#FFB4AB" weight="bold" /> -3% since last week
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {user.role !== ROLE_ADMIN_NAME && (
                <>
                  <div className="col">
                    <div className="card p-4">
                      <div className="row">
                        <div className="col-7">
                          <p className="text-secondary">Pending</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-auto">
                          <h3 className="text-secondary my-2">$6123</h3>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-auto">
                          <p className="text-secondary small">
                            <TrendUp size={16} color="#00695c" weight="bold" /> +2% since last week
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card p-4">
                      <div className="row">
                        <div className="col-12">
                          <p className="text-secondary">Approved</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-auto">
                          <h3 className="text-secondary my-2">$234.23</h3>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-auto">
                          <p className="text-secondary small">
                            <TrendUp size={16} color="#FFB4AB" weight="bold" /> +7% since last week
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-5">
            <div className="card pt-4 px-4 py-2">
              <div className="row justify-content-center">
                <div className="col-4 align-items-between">
                  <div className="row">
                    <div className="col-12">
                      <p className="text-secondary">Total Billable Hours</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-auto">
                      <h3 className="text-secondary my-2">1454 h</h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-auto">
                      <p className="text-secondary small">+12% since last week</p>
                    </div>
                  </div>
                </div>
                <div className="col-8">
                  <ApprovedPendingPiechart />
                </div>
              </div>
            </div>
          </div>
        </div>

        {isMobile && (
          <div className="pb-4">
            <TabsListTable
              disabled={disabledActions}
              tabs={TABS_PAYROLL}
              activeTab={activeTab}
              onChange={({ tab }) => hangleChangeTab({ tab, filter })}
            />
          </div>
        )}

        {selectedUser && <BreakPointsPayroll user={selectedUser} project={selectedProject} />}

        <CustomDatePickerGoBack
          onSearchData={handleSearchDataByDate}
          disabledAll={disabledActions}
          goBack={disabledActions && handleGoBack}
          startDay={startDay}
          endDay={endDay}
          titleMobile={isMobile ? 'Payroll' : ''}
          defaultPeriod={dateRange && PERIOD_TIME_SHEET.CUSTOM}
        />

        <hr className="mt-4" />

        {!isMobile && (
          <>
            <div className="payroll_filter mb-3">
              <TabsListTable
                tabs={TABS_PAYROLL}
                activeTab={activeTab}
                onChange={({ tab }) => hangleChangeTab({ tab, filter })}
              />

              {!selectedUser && (
                <FilterAllDropdown
                  hiddenSprint
                  hiddenStatus
                  hiddenTasks
                  hiddenUsers={isUser}
                  disabled={disabledActions}
                  onChangeFilter={handleChangeFilter}
                  defaultSelectProject={(filter?.projects || []).filter((item) => item.checked).map((item) => item.id)}
                  defaultSelectUsers={(filter?.users || []).filter((item) => item.checked).map((item) => item.id)}
                />
              )}
            </div>
          </>
        )}

        {isMobile && !selectedUser && (
          <ModalFilterPayrollMobile hiddenUsers={isUser} disabled={disabledActions} onFilter={handleChangeFilter} />
        )}

        {step === STEPS_PAYROLL.USERS && payrollHook.length !== 0 && (
          <StepUsers
            users={payrollHook}
            nextStep={({ userId }) => {
              setStep(STEPS_PAYROLL.PROJECTS)
              setSelectedUser(payrollHook.find((item) => item.id === userId))
            }}
          />
        )}

        {step === STEPS_PAYROLL.PROJECTS && selectedUser && selectedUser?.projects.length > 0 && (
          <StepProjects
            user={selectedUser}
            nextStep={({ project }) => {
              setStep(STEPS_PAYROLL.TIME_SHEET)
              setSelectedProject(project)
            }}
            startDate={startDay}
            endDate={endDay}
            refreshData={async () => await handleSearchDataByDate({ startDay, endDay })}
            activeReOpen={activeTab === TABS_PAYROLL.APPROVED.name}
          />
        )}

        {step === STEPS_PAYROLL.TIME_SHEET && selectedProject && Object.keys(selectedProject).length > 0 && (
          <StepTimeSheet
            user={selectedUser}
            project={selectedProject}
            startDate={startDay}
            endDate={endDay}
            activeTab={activeTab}
            refreshData={async () => await handleSearchDataByDate({ startDay, endDay })}
            activeReOpen={activeTab === TABS_PAYROLL.APPROVED.name}
          />
        )}

        {step !== STEPS_PAYROLL.TIME_SHEET && (payrollHook.length === 0 || selectedUser?.projects.length === 0) && (
          <div className="card p-5">
            <div className="row justify-content-center">
              <div className="col-md-2 col-12 d-flex align-items-center justify-content-center me-5">
                <GoggleIcon color={GET_COLOR_BY_THEME({ theme: user.theme }).PRIMARY} styles={{ maxWidth: 280 }} />
              </div>
              <div className="col col-md-5 my-5">
                <h3 className="text-md-start text-center">
                  {activeTab === TABS_PAYROLL.APPROVED.name ? 'Nothing yet approved' : 'Nothing yet pending'}
                </h3>
                <p className="text-md-start text-center">
                  {activeTab === TABS_PAYROLL.APPROVED.name
                    ? 'There have been no approved time entries.'
                    : 'There are no pending time entries to approve.'}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default PayrollPage
