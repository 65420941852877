import { Play } from '@phosphor-icons/react'
import { STATUS_SUBMIT_HOURS } from 'Pages/TimeTracker/utils/const'

const BtnAddTracker = ({ approval, onOpenAlert, selectedTracker, onChangeSelectedTracker, onOpen }) => {
  return (
    <div className="startTimeBtnTracker">
      <button
        type="button"
        className="btn btn-primary"
        id="add_tracker"
        onClick={
          STATUS_SUBMIT_HOURS.PENDING === approval
            ? onOpenAlert
            : () => {
                if (selectedTracker) onChangeSelectedTracker(null)
                onOpen(true)
              }
        }>
        <Play /> <span>Start Timer</span>
      </button>
    </div>
  )
}

export default BtnAddTracker
