import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useUser, useModal } from 'Hooks'
import BasicInfo from './BasicInfo'
import AssignedProjects from './AssignedProjects'
// import Notifications from './Notifications'
import { createUser } from 'Services'
import { ButtonsModal } from 'Organisms'
import { ROLE_DEVELOPER_NAME, ROLE_PRODUCT_OWNER_NAME } from 'constant/roles'
import { formatAddProjectToUserAdapter } from 'adapters/user'
import { Loading } from 'Organisms/Loading'
import { X } from '@phosphor-icons/react'
import { COLOR_BLUE_MATE } from 'constant/colors'
import { PERMISSION_BY_ROLE } from 'Utils/permissionByRol'
import useSessionStore from 'Context/useSessionStore'
import { useMediaQuery } from 'react-responsive'
import { BREAKPOINT } from 'Utils/const/breakPoints'
import { Mixpanel } from 'Utils/Mixpanel'
import { EMAIL_ALREATY_EXISTS } from 'Utils/const/messages'
import { SuggestionPopupToUpgradePlan } from 'Organisms/SuggestionPopupToUpgradePlan'

const MODAL_SECTION = {
  BASIC_INFO: 1,
  ASSIGNED_PROJECT: 2,
  ASSIGNED_PEOPLE: 3,
  NOTIFICATION: 4
}

const ROLE_ALLOWED_SECTION_ASSIGNED = [ROLE_PRODUCT_OWNER_NAME, ROLE_DEVELOPER_NAME]

const MODAL_TABS = [
  {
    name: 'Basic info',
    tab: MODAL_SECTION.BASIC_INFO
  },
  {
    name: 'Assigned projects',
    tab: MODAL_SECTION.ASSIGNED_PROJECT,
    only: ROLE_ALLOWED_SECTION_ASSIGNED,
    validDisabled: true
  }
  // {
  //   name: 'Assigned people',
  //   tab: MODAL_SECTION.ASSIGNED_PEOPLE
  // },
  // {
  //   name: 'Notifications',
  //   tab: MODAL_SECTION.NOTIFICATION
  // }
]

const UserModal = ({ visible, setVisible, selectedUser, reloadUsers = () => {} }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  // Media query
  const isMobile = useMediaQuery({
    query: `(max-width: ${BREAKPOINT.MOBILE})`
  })

  // Hooks
  const { loadingUserHook, assignProjectToUserHook, updateUserHook, createUserHook, deleteResourceUser } = useUser()
  const { Modal, hide } = useModal({ identifier: 'adduser', visible, setVisible })

  // State
  const [modalSection, setModalSection] = useState(MODAL_SECTION.BASIC_INFO)
  const [loading, setLoading] = useState(false)
  const [selectedRole, setSelectedRole] = useState(selectedUser?.role || ROLE_DEVELOPER_NAME)
  const [userCreate, setUserCreate] = useState({})
  const [errorAssign, setErrorAssign] = useState('')
  const [visibleProfessionalModal, setVisibleProfessionalModal] = useState(false)

  const methods = useForm()
  const { reset, setError } = methods

  function removeEmptyFields (data) {
    Object.keys(data).forEach((key) => {
      if (data[key] === '' || data[key] == null) {
        delete data[key]
      }
    })
    return data
  }

  const handleCreateOrEditUser = async ({ data }) => {
    const transformedData = {
      firstname: data.firstname,
      lastname: data.lastname,
      secondary_email: data.secondary_email,
      role: data.role,
      position: data.position,
      capacity: parseFloat(data.capacity),
      is_hourly_based: data.is_hourly_based,
      ...(PERMISSION_BY_ROLE.USER.EDIT.SHOW_COST_BY_USER.includes(user.role)
        ? { cost_rate: parseFloat(data?.cost_rates || 0) }
        : {}),
      timezone: data.timezone,
      last_activity: new Date(),
      nationality: data.nationality,
      location: data.location,
      resources: data.resources
        .filter((item) => !item.id)
        .map((item, i) => ({
          value: item.value,
          name: `resource ${i}`
        })),
      ...(!selectedUser?.id && { email: data.email })
    }

    if (selectedUser?.id) {
      const res = await updateUserHook({ body: transformedData, userId: selectedUser?.id })

      if (res.message === 'Your plan does not allow you to update this type of user.') {
        return setVisibleProfessionalModal(true)
      }

      if (ROLE_ALLOWED_SECTION_ASSIGNED.includes(selectedUser.role)) {
        setModalSection(MODAL_SECTION.ASSIGNED_PROJECT)
      }
    } else {
      const res = await createUserHook({ body: removeEmptyFields(transformedData) })

      if (!res.success) {
        if (res.message === 'An account with the given email already exists.') {
          return setError('email', { type: 'exists', message: EMAIL_ALREATY_EXISTS })
        }

        if (res.message === 'Your plan does not allow you to create this type of user.') {
          return setVisibleProfessionalModal(true)
        }
        return
      }

      if (res?.data?.id && ROLE_ALLOWED_SECTION_ASSIGNED.includes(res?.data?.role)) {
        setModalSection(MODAL_SECTION.ASSIGNED_PROJECT)
        setUserCreate(res.data)
      }
    }
  }

  const handleAssignProject = async ({ listProject }) => {
    const userId = selectedUser?.id || userCreate?.id

    if (!listProject && userId) return handleCloseModal()

    const listProjectId = formatAddProjectToUserAdapter({ listProject })

    if (listProjectId.length === 0) return

    const res = await assignProjectToUserHook({ userId, listProjectId })

    if (!res.success) {
      setErrorAssign(res?.message || '')
    }

    return res.success
  }

  const onSubmit = async (data) => {
    await Mixpanel.track('Invite User Intent')
    try {
      setLoading(true)

      let res

      if (MODAL_SECTION.BASIC_INFO === modalSection) {
        await handleCreateOrEditUser({ data })
      }

      if (MODAL_SECTION.ASSIGNED_PROJECT === modalSection) {
        res = await handleAssignProject({ listProject: data.assignProject })
      }

      if (
        (MODAL_SECTION.ASSIGNED_PROJECT === modalSection && res) ||
        !ROLE_ALLOWED_SECTION_ASSIGNED.includes(data?.role)
      ) {
        handleCloseModal()
      }
      await Mixpanel.track('User Invited')
    } catch (e) {
      await Mixpanel.track('User Invited Failed')
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const handleCloseModal = async () => {
    reloadUsers()
    hide()
  }

  const handleRemoveResource = async ({ id }) => {
    await deleteResourceUser({ idUser: selectedUser.id, idResource: id })
  }

  useEffect(() => {
    if (selectedUser?.id) {
      reset({
        ...selectedUser,
        resources: selectedUser.resources.map((item) => ({
          ...item,
          id_resource: item.id
        }))
      })
    }
  }, [selectedUser, reset])

  return (
    <>
      <Modal>
        <>
          {loadingUserHook && <Loading />}

          <div className="modal-content" style={{ maxWidth: 1000 }}>
            <div className="modal-header">
              <h3 className="modal-title fs-5 text-capitalize" id="exampleModalLabel">
                {!selectedUser?.id ? 'Add member' : selectedUser.firstname + ' ' + selectedUser.lastname}
              </h3>

              <p className="modal-close" onClick={handleCloseModal}>
                <X size={18} color={COLOR_BLUE_MATE} />
              </p>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-auto">
                  <ul className="nav nav-pills flex-column">
                    {MODAL_TABS.map((item) => {
                      if (Array.isArray(item.only) && !item.only.includes(selectedRole)) {
                        return <React.Fragment key={item.tab} />
                      }

                      const disabled = item?.validDisabled && !(selectedUser?.id || userCreate?.id)

                      return (
                        <li key={item.tab} className={`nav-item ${disabled ? 'cursor-disabled' : 'pointer'}`}>
                          <p
                            className={`nav-link ${modalSection === item.tab ? 'active' : ''}`}
                            onClick={disabled ? undefined : () => setModalSection(item.tab)}>
                            {item.name}
                          </p>
                        </li>
                      )
                    })}
                  </ul>
                </div>

                {isMobile && <hr />}

                <div className="col">
                  <div className="row justify-content-center">
                    <FormProvider {...methods}>
                      <form id="addMember" className="col-12 d-grid" onSubmit={methods.handleSubmit(onSubmit)}>
                        {modalSection === MODAL_SECTION.BASIC_INFO && (
                          <BasicInfo
                            selectedUser={selectedUser?.id}
                            onChangeRole={({ role }) => setSelectedRole(role)}
                            onRemoveResource={handleRemoveResource}
                          />
                        )}
                        {modalSection === MODAL_SECTION.ASSIGNED_PROJECT && (
                          <AssignedProjects
                            userId={selectedUser?.id || createUser?.id}
                            error={errorAssign}
                            projectsAssigned={selectedUser?.user_projects}
                          />
                        )}
                        {/* {modalSection === MODAL_SECTION.NOTIFICATION && <Notifications />} */}
                      </form>
                    </FormProvider>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <ButtonsModal onClose={handleCloseModal} loading={loading} formControl="addMember" />
            </div>
          </div>
        </>
      </Modal>

      {visibleProfessionalModal && (
        <SuggestionPopupToUpgradePlan setVisible={() => setVisibleProfessionalModal(false)} visible={true} />
      )}
    </>
  )
}

export default UserModal
