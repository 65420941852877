import React, { useContext } from 'react'
import { TimesheetTableItemCondensed } from './'
import ContextColumnTimeSheet from 'Context/ColumnsTimeSheetContext'

const TableTimeSheetCondensed = ({ dataList, onOpenDetail = () => {} }) => {
  const { timeSheetColunm } = useContext(ContextColumnTimeSheet)

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            {timeSheetColunm.map((item) => {
              const { checked, id, text } = item

              if (!checked) return <React.Fragment key={id} />

              return (
                <th key={id} scope="col">
                  {text}
                </th>
              )
            })}
            <th scope="col" colSpan={2} />
          </tr>
        </thead>
        <tbody>
          {dataList.map((timesheetItem, index) => (
            <TimesheetTableItemCondensed
              key={`${timesheetItem.title}${timesheetItem.time_entry_id}${timesheetItem.task}${timesheetItem.user_id}${index}`}
              item={timesheetItem}
              columns={timeSheetColunm}
              onOpenDetail={() =>
                onOpenDetail({ timeEntryId: timesheetItem.time_entries_ids[0], title: timesheetItem.title })
              }
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}
export default TableTimeSheetCondensed
