import { NewPortal } from 'Organisms/NewPortal'
import { useEffect, useRef, useState } from 'react'

const PopoverPortal = ({ children, buttonShow, buttonStyle }) => {
  const buttonRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [menuStyle, setMenuStyle] = useState({})

  const handleClose = () => {
    setOpen(false)
  }

  const getStyle = () => {
    const rect = buttonRef.current.getBoundingClientRect()
    setMenuStyle({
      position: 'absolute',
      top: `${window.scrollY - 70 + rect.bottom}px`,
      left: `${window.scrollX + 60 + rect.left}px`,
      zIndex: 1000
    })
  }

  const handleOpen = () => {
    getStyle()
    setOpen(true)
  }

  useEffect(() => {
    const handleResize = () => {
      if (open && buttonRef.current) {
        getStyle()
      }
    }

    window.addEventListener('resize', handleResize)
    window.addEventListener('scroll', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('scroll', handleResize)
    }
  }, [open])

  return (
    <div className='popoverCustom'>
      <button ref={buttonRef} type="button" className={`btn ${buttonStyle}`} onClick={open ? handleClose : handleOpen}>
        {buttonShow}
      </button>

      {open && (
        <NewPortal>
            <div style={menuStyle}>
              {children}
            </div>
          <button className="popoverCustom_close" onClick={handleClose} />
        </NewPortal>
      )}
    </div>
  )
}

export default PopoverPortal
