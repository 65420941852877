import { useDevops } from 'Hooks'
import { FIELD_CONNECT_AZURE } from './ModalConnectAzure'
import { Loading } from 'Organisms/Loading'
import { useEffect } from 'react'

const ChooseProjectAzureIntegration = ({ register, errors, project, organization, setValue }) => {
  const { listProjectsIntegrationHook, loadingDevopsHook, getProjectAzureHook } = useDevops()

  const getProjects = async () => {
    await getProjectAzureHook({ projectId: project.id, organizationName: organization })
  }

  useEffect(() => {
    getProjects()
  }, [])

  const handleChangeSelect = (data) => {
    setValue(FIELD_CONNECT_AZURE.PROJECT_NAME, listProjectsIntegrationHook.find(item => item.id === data.target.value).name)
  }

  return (
    <>
      {loadingDevopsHook && <Loading />}
      <div className="row">
        <label className="col-12 col-form-label" htmlFor="inputProject">
          Choose a project
        </label>
        <div className="col-12">
          <select
            {...register(FIELD_CONNECT_AZURE.PROJECT_ID, { required: true })}
            onChange={handleChangeSelect}
            className={`form-control form-select ${errors && errors[FIELD_CONNECT_AZURE.PROJECT_ID] ? 'is-invalid' : ''}`}
            id="inputProject">
            <option value="">Select a Project</option>

            {listProjectsIntegrationHook.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
          <div className="invalid-feedback">Please select a Project.</div>
        </div>
      </div>
    </>
  )
}

export default ChooseProjectAzureIntegration
