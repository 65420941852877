import { arrayMove } from '@dnd-kit/sortable'
import { STATUS_TICKET_DONE, STATUS_TICKET_IN_PROGRESS, STATUS_TICKET_TO_DO } from 'constant/StatusTicket'
import { ROLES_DISPLAY, ROLE_DEVELOPER_NAME } from 'constant/roles'
import { DateTime } from 'luxon'

const getRole = (role) => {
  return ROLES_DISPLAY[role || ROLE_DEVELOPER_NAME]
}

const mapStates = (state) => {
  if (!state) return STATUS_TICKET_IN_PROGRESS

  switch (state.toLowerCase()) {
    case 'to do':
    case 'refinement':
      return STATUS_TICKET_TO_DO
    // case 'design clarification needed':
    // case 'design':
    // case 'design review':
    // case 'ready to build':
    // case 'development':
    // case 'in progress':
    // case 'dev clarification needed':
    // case 'qa':
    // case 'qa clarification needed':
    // case 'peer review':
    //   return 'In progress'
    case 'uat':
    case 'ready to deploy':
    case 'done':
      return STATUS_TICKET_DONE
    default:
      return STATUS_TICKET_IN_PROGRESS
  }
}

export const buildParams = ({ query, params }) => {
  return params
    .map((param) => {
      if (query === '') return ''

      return `${param}=${query}`
    })
    .filter((x) => x)
    .join('&')
}

export const createQueryString = (params = {}) => {
  const keys = Object.keys(params).filter((key) => ![undefined, null, ''].includes(params[key]))

  if (keys.length === 0) return ''

  return keys
    .reduce((arr, key) => {
      arr.push(`${key}=${params[key]}`)
      return arr
    }, [])
    .join('&')
}

export const formatTime = ({ time }) => {
  let [hora, minutos] = time.split(':')

  if (hora.length < 2) {
    hora = '0' + hora
  }

  return `${hora}:${minutos}`
}

export const breakDownDecimalInTime = ({ time }) => {
  const hours = Math.floor(time)

  const decimalMinutes = (time - hours) * 60
  const minutes = Math.floor(decimalMinutes)

  const secongs = Math.floor((decimalMinutes - minutes) * 60)

  return {
    hours,
    minutes,
    secongs
  }
}
export const decimalToHours = ({ time }) => {
  const { hours, minutes } = breakDownDecimalInTime({ time: time < 0 ? 0 : time })

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
}

export const sumHoursPerMinutes = ({ time }) => {
  const timeSplit = time.split(':')
  let hours = parseInt(timeSplit[0], 10)
  let minutes = parseInt(timeSplit[1], 10)

  if (minutes >= 60) {
    const additionalHours = Math.floor(minutes / 60)
    hours += additionalHours
    minutes %= 60
  }

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
}

export const validateFormatTime = ({ time }) => {
  const timePattern = /^([0-1]?[0-9]|2[0-3]):[0-9]*$/

  return timePattern.test(time)
}

export const countDecimalsAndAdd0 = ({ number }) => {
  const string = number.toString()

  const parts = string.split('.')

  if (parts[1] && parts[1].length > 0) return Number(number).toFixed(2)

  return Number(number)
}

export const getValuePerFilter = ({ list = [], byValue = 'value' } = {}) =>
  list
    .filter((item) => item.checked)
    .map((item) => item[byValue])
    .join(',')

export const handleDragEndColumns = ({ event, columns }) => {
  const { active, over } = event

  if (active.id === over.id) return

  const oldIndex = columns.findIndex((item) => item.id === active.id)
  const newIndex = columns.findIndex((item) => item.id === over.id)

  return arrayMove(columns, oldIndex, newIndex)
}

export const handleChangeActiveSwitchColumns = ({ item, active, columns }) => {
  const update = columns.map((itemMap) => {
    if (itemMap.id === item.id) {
      return {
        ...itemMap,
        checked: active
      }
    }

    return itemMap
  })

  return update
}

export const getStartWeek = () => DateTime.now().plus({ days: 2 }).startOf('week').minus({ days: 2 })
export const getEndWeek = () => DateTime.now().plus({ days: 2 }).endOf('week').minus({ days: 2 })

export const groupHoursPerDayUtils = ({ listHours, initHours }) => {
  const groupHours = {}
  const groupWarranty = {}

  listHours.forEach((item) => {
    const date = item.day_of_week

    if (!groupHours[date]) {
      groupHours[date] = 0
    }

    groupHours[date] += item.hours

    if (!groupWarranty[date]) {
      groupWarranty[date] = 0
    }

    groupWarranty[date] += item.warranty_hours
  })

  return initHours.map((item) => ({
    name: item.name,
    billable: countDecimalsAndAdd0({ number: groupHours[item.name] ?? 0 }),
    warranty: countDecimalsAndAdd0({ number: groupWarranty[item.name] ?? 0 })
  }))
}

export const downloadCSVasPDF = async ({ base64CSV, filename }) => {
  try {
    const csvContent = atob(base64CSV)

    const blob = new Blob([csvContent], { type: 'text/csv' })

    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.download = `${filename}.csv`
    document.body.appendChild(link)
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    )
  } catch (error) {
    console.error('Error downloading CSV as PDF:', error)
  }
}

const tzStrings = [
  { label: '(GMT-12:00) International Date Line West', value: 'GMT-12:00' },
  { label: '(GMT-11:00) Midway Island, Samoa', value: 'GMT-11:00' },
  { label: '(GMT-10:00) Hawaii', value: 'GMT-10:00' },
  { label: '(GMT-09:00) Alaska', value: 'GMT-09:00' },
  { label: '(GMT-08:00) Pacific Time (US & Canada)', value: 'GMT-08:00' },
  { label: '(GMT-08:00) Tijuana, Baja California', value: 'GMT-08:00' },
  { label: '(GMT-07:00) Arizona', value: 'GMT-07:00' },
  { label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan', value: 'GMT-07:00' },
  { label: '(GMT-07:00) Mountain Time (US & Canada)', value: 'GMT-07:00' },
  { label: '(GMT-06:00) Central America', value: 'GMT-06:00' },
  { label: '(GMT-06:00) Central Time (US & Canada)', value: 'GMT-06:00' },
  { label: '(GMT-06:00) Guadalajara, Mexico City, Monterrey', value: 'GMT-06:00' },
  { label: '(GMT-06:00) Saskatchewan', value: 'GMT-06:00' },
  { label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco', value: 'GMT-05:00' },
  { label: '(GMT-05:00) Eastern Time (US & Canada)', value: 'GMT-05:00' },
  { label: '(GMT-05:00) Indiana (East)', value: 'GMT-05:00' },
  { label: '(GMT-04:00) Atlantic Time (Canada)', value: 'GMT-04:00' },
  { label: '(GMT-04:00) Caracas, La Paz', value: 'GMT-04:00' },
  { label: '(GMT-04:00) Manaus', value: 'GMT-04:00' },
  { label: '(GMT-04:00) Santiago', value: 'GMT-04:00' },
  { label: '(GMT-03:30) Newfoundland', value: 'GMT-03:30' },
  { label: '(GMT-03:00) Brasilia', value: 'GMT-03:00' },
  { label: '(GMT-03:00) Buenos Aires, Georgetown', value: 'GMT-03:00' },
  { label: '(GMT-03:00) Greenland', value: 'GMT-03:00' },
  { label: '(GMT-03:00) Montevideo', value: 'GMT-03:00' },
  { label: '(GMT-02:00) Mid-Atlantic', value: 'GMT-02:00' },
  { label: '(GMT-01:00) Cape Verde Is.', value: 'GMT-01:00' },
  { label: '(GMT-01:00) Azores', value: 'GMT-01:00' },
  { label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik', value: 'GMT+00:00' },
  { label: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London', value: 'GMT+00:00' },
  { label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna', value: 'GMT+01:00' },
  { label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague', value: 'GMT+01:00' },
  { label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris', value: 'GMT+01:00' },
  { label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb', value: 'GMT+01:00' },
  { label: '(GMT+01:00) West Central Africa', value: 'GMT+01:00' },
  { label: '(GMT+02:00) Amman', value: 'GMT+02:00' },
  { label: '(GMT+02:00) Athens, Bucharest, Istanbul', value: 'GMT+02:00' },
  { label: '(GMT+02:00) Beirut', value: 'GMT+02:00' },
  { label: '(GMT+02:00) Cairo', value: 'GMT+02:00' },
  { label: '(GMT+02:00) Harare, Pretoria', value: 'GMT+02:00' },
  { label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius', value: 'GMT+02:00' },
  { label: '(GMT+02:00) Jerusalem', value: 'GMT+02:00' },
  { label: '(GMT+02:00) Minsk', value: 'GMT+02:00' },
  { label: '(GMT+02:00) Windhoek', value: 'GMT+02:00' },
  { label: '(GMT+03:00) Kuwait, Riyadh, Baghdad', value: 'GMT+03:00' },
  { label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd', value: 'GMT+03:00' },
  { label: '(GMT+03:00) Nairobi', value: 'GMT+03:00' },
  { label: '(GMT+03:00) Tbilisi', value: 'GMT+03:00' },
  { label: '(GMT+03:30) Tehran', value: 'GMT+03:30' },
  { label: '(GMT+04:00) Abu Dhabi, Muscat', value: 'GMT+04:00' },
  { label: '(GMT+04:00) Baku', value: 'GMT+04:00' },
  { label: '(GMT+04:00) Yerevan', value: 'GMT+04:00' },
  { label: '(GMT+04:30) Kabul', value: 'GMT+04:30' },
  { label: '(GMT+05:00) Yekaterinburg', value: 'GMT+05:00' },
  { label: '(GMT+05:00) Islamabad, Karachi, Tashkent', value: 'GMT+05:00' },
  { label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi', value: 'GMT+05:30' },
  { label: '(GMT+05:30) Sri Jayawardenapura', value: 'GMT+05:30' },
  { label: '(GMT+05:45) Kathmandu', value: 'GMT+05:45' },
  { label: '(GMT+06:00) Almaty, Novosibirsk', value: 'GMT+06:00' },
  { label: '(GMT+06:00) Astana, Dhaka', value: 'GMT+06:00' },
  { label: '(GMT+06:30) Yangon (Rangoon)', value: 'GMT+06:30' },
  { label: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: 'GMT+07:00' },
  { label: '(GMT+07:00) Krasnoyarsk', value: 'GMT+07:00' },
  { label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi', value: 'GMT+08:00' },
  { label: '(GMT+08:00) Kuala Lumpur, Singapore', value: 'GMT+08:00' },
  { label: '(GMT+08:00) Irkutsk, Ulaan Bataar', value: 'GMT+08:00' },
  { label: '(GMT+08:00) Perth', value: 'GMT+08:00' },
  { label: '(GMT+08:00) Taipei', value: 'GMT+08:00' },
  { label: '(GMT+09:00) Osaka, Sapporo, Tokyo', value: 'GMT+09:00' },
  { label: '(GMT+09:00) Seoul', value: 'GMT+09:00' },
  { label: '(GMT+09:00) Yakutsk', value: 'GMT+09:00' },
  { label: '(GMT+09:30) Adelaide', value: 'GMT+09:30' },
  { label: '(GMT+09:30) Darwin', value: 'GMT+09:30' },
  { label: '(GMT+10:00) Brisbane', value: 'GMT+10:00' },
  { label: '(GMT+10:00) Canberra, Melbourne, Sydney', value: 'GMT+10:00' },
  { label: '(GMT+10:00) Hobart', value: 'GMT+10:00' },
  { label: '(GMT+10:00) Guam, Port Moresby', value: 'GMT+10:00' },
  { label: '(GMT+10:00) Vladivostok', value: 'GMT+10:00' },
  { label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia', value: 'GMT+11:00' },
  { label: '(GMT+12:00) Auckland, Wellington', value: 'GMT+12:00' },
  { label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.', value: 'GMT+12:00' },
  { label: "(GMT+13:00) Nuku'alofa", value: 'GMT+13:00' }
]

const tzInts = [
  { label: '(GMT-12:00) International Date Line West', value: '-12' },
  { label: '(GMT-11:00) Midway Island, Samoa', value: '-11' },
  { label: '(GMT-10:00) Hawaii', value: '-10' },
  { label: '(GMT-09:00) Alaska', value: '-9' },
  { label: '(GMT-08:00) Pacific Time (US & Canada)', value: '-8' },
  { label: '(GMT-08:00) Tijuana, Baja California', value: '-8' },
  { label: '(GMT-07:00) Arizona', value: '-7' },
  { label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan', value: '-7' },
  { label: '(GMT-07:00) Mountain Time (US & Canada)', value: '-7' },
  { label: '(GMT-06:00) Central America', value: '-6' },
  { label: '(GMT-06:00) Central Time (US & Canada)', value: '-6' },
  { label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco', value: '-5' },
  { label: '(GMT-05:00) Eastern Time (US & Canada)', value: '-5' },
  { label: '(GMT-05:00) Indiana (East)', value: '-5' },
  { label: '(GMT-04:00) Atlantic Time (Canada)', value: '-4' },
  { label: '(GMT-04:00) Caracas, La Paz', value: '-4' },
  { label: '(GMT-04:00) Manaus', value: '-4' },
  { label: '(GMT-04:00) Santiago', value: '-4' },
  { label: '(GMT-03:30) Newfoundland', value: '-3.5' },
  { label: '(GMT-03:00) Brasilia', value: '-3' },
  { label: '(GMT-03:00) Buenos Aires, Georgetown', value: '-3' },
  { label: '(GMT-03:00) Greenland', value: '-3' },
  { label: '(GMT-03:00) Montevideo', value: '-3' },
  { label: '(GMT-02:00) Mid-Atlantic', value: '-2' },
  { label: '(GMT-01:00) Cape Verde Is.', value: '-1' },
  { label: '(GMT-01:00) Azores', value: '-1' },
  { label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik', value: '0' },
  { label: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London', value: '0' },
  { label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna', value: '1' },
  { label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague', value: '1' },
  { label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris', value: '1' },
  { label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb', value: '1' },
  { label: '(GMT+01:00) West Central Africa', value: '1' },
  { label: '(GMT+02:00) Amman', value: '2' },
  { label: '(GMT+02:00) Athens, Bucharest, Istanbul', value: '2' },
  { label: '(GMT+02:00) Beirut', value: '2' },
  { label: '(GMT+02:00) Cairo', value: '2' },
  { label: '(GMT+02:00) Harare, Pretoria', value: '2' },
  { label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius', value: '2' },
  { label: '(GMT+02:00) Jerusalem', value: '2' },
  { label: '(GMT+02:00) Minsk', value: '2' },
  { label: '(GMT+02:00) Windhoek', value: '2' },
  { label: '(GMT+03:00) Kuwait, Riyadh, Baghdad', value: '3' },
  { label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd', value: '3' },
  { label: '(GMT+03:00) Nairobi', value: '3' },
  { label: '(GMT+03:00) Tbilisi', value: '3' },
  { label: '(GMT+03:30) Tehran', value: '3.5' },
  { label: '(GMT+04:00) Abu Dhabi, Muscat', value: '4' },
  { label: '(GMT+04:00) Baku', value: '4' },
  { label: '(GMT+04:00) Yerevan', value: '4' },
  { label: '(GMT+04:30) Kabul', value: '4.5' },
  { label: '(GMT+05:00) Yekaterinburg', value: '5' },
  { label: '(GMT+05:00) Islamabad, Karachi, Tashkent', value: '5' },
  { label: '(GMT+05:30) Sri Jayawardenapura', value: '5.5' },
  { label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi', value: '5.5' },
  { label: '(GMT+05:45) Kathmandu', value: '5.75' },
  { label: '(GMT+06:00) Almaty, Novosibirsk', value: '6' },
  { label: '(GMT+06:00) Astana, Dhaka', value: '6' },
  { label: '(GMT+06:30) Yangon (Rangoon)', value: '6.5' },
  { label: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: '7' },
  { label: '(GMT+07:00) Krasnoyarsk', value: '7' },
  { label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi', value: '8' },
  { label: '(GMT+08:00) Kuala Lumpur, Singapore', value: '8' },
  { label: '(GMT+08:00) Irkutsk, Ulaan Bataar', value: '8' },
  { label: '(GMT+08:00) Perth', value: '8' },
  { label: '(GMT+08:00) Taipei', value: '8' },
  { label: '(GMT+09:00) Osaka, Sapporo, Tokyo', value: '9' },
  { label: '(GMT+09:00) Seoul', value: '9' },
  { label: '(GMT+09:00) Yakutsk', value: '9' },
  { label: '(GMT+09:30) Adelaide', value: '9.5' },
  { label: '(GMT+09:30) Darwin', value: '9.5' },
  { label: '(GMT+10:00) Brisbane', value: '10' },
  { label: '(GMT+10:00) Canberra, Melbourne, Sydney', value: '10' },
  { label: '(GMT+10:00) Hobart', value: '10' },
  { label: '(GMT+10:00) Guam, Port Moresby', value: '10' },
  { label: '(GMT+10:00) Vladivostok', value: '10' },
  { label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia', value: '11' },
  { label: '(GMT+12:00) Auckland, Wellington', value: '12' },
  { label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.', value: '12' },
  { label: "(GMT+13:00) Nuku'alofa", value: '13' }
]

const getUserNameInUrl = ({ url }) => {
  const regex = /user_name=([^&]+)/
  const match = url.match(regex)

  if (match && match[1]) {
    return match[1]
  }

  return null
}

export const orderListPerKey = ({ list, keyByOrder }) => {
  list.sort((a, b) => {
    return a[keyByOrder] - b[keyByOrder]
  })

  return list
}

const getConfirmationCodeInUrl = ({ url }) => {
  const confirmationCodeStart = url.indexOf('confirmation_code=') + 'confirmation_code='.length
  const confirmationCodeEnd = url.indexOf('&user_name', confirmationCodeStart)
  const confirmationCode = url.substring(
    confirmationCodeStart,
    confirmationCodeEnd !== -1 ? confirmationCodeEnd : undefined
  )
  const specialCharsRegex =
    /(%22|%20|%24|%25|%E2|%80|%93|%27|%21|%23|%24|%26|%27|%28|%29|%2A|%2B|%2C|%2F|%3A|%3B|%3D|%3F|%40|%5B|%5D|%20|%25|%2D|%2E|%3C|%3E|%5C|%5E|%5F|%60|%7B|%7C|%7D|%7E)/g

  if (specialCharsRegex.test(confirmationCode)) {
    const decodedUrl = confirmationCode.replace(specialCharsRegex, (match) => decodeURIComponent(match))
    return decodedUrl
  }
  return confirmationCode
}

export { getRole, mapStates, tzInts, tzStrings, getUserNameInUrl, getConfirmationCodeInUrl }

export const getDifferentDaysBetweenDates = ({ startDate, endDate }) => {
  return endDate.diff(startDate, 'days').toObject().days
}

export const getDaysBetweenDates = ({ startDate, endDate }) => {
  const days = []
  let currentDate = startDate

  while (currentDate <= endDate) {
    days.push(currentDate)
    currentDate = currentDate.plus({ days: 1 })
  }

  return days
}

export const getWeekBetweenDates = ({ startDate, endDate }) => {
  const semanas = []
  let currentDate = startDate.startOf('week')

  while (currentDate <= endDate) {
    semanas.push({
      start_week: currentDate,
      end_week: currentDate.endOf('week')
    })
    currentDate = currentDate.plus({ weeks: 1 })
  }

  return semanas
}

export const emailValidate = ({ email }) => {
  const regexEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return regexEmail.test(email)
}

export const formatUrlValidate = ({ url }) => {
  return /^https:\/\/.+/.test(url)
}

export const getDomainByURL = ({ url }) => {
  try {
    const objectUrl = new URL(url)
    const domainParts = objectUrl.hostname.split('.')
    return domainParts[0]
  } catch (error) {
    console.error('Error getting url')
    return null
  }
}

export const getListFieldsByProjectKey = ({ projectKey, listProjects, listDefault }) => {
  const getList = listProjects.find((item) => item.id === projectKey)

  const list_field_repeated = []
  const list_general_group = {
    category: 'General',
    fields: []
  }
  const list_fixed_group = {
    category: 'Fixed',
    fields: Object.values(listDefault)
  }

  const list_unique_group = []

  getList.fields.forEach((item) => {
    Object.keys(item.fields).forEach((field) => {
      if (!list_field_repeated.includes(field)) list_field_repeated.push(field)
    })
  })

  getList.fields.forEach((item) => {
    const category_create = {
      category: item.name,
      fields: []
    }

    Object.keys(item.fields).forEach((field) => {
      if (list_field_repeated.includes(field) && !list_general_group.fields.some((general) => general.key === field)) { list_general_group.fields.push(item.fields[field]) }

      if (!list_field_repeated.includes(field) && !category_create.fields.some((general) => general.key === field)) {
        category_create.fields.push(item.fields[field])
      }
    })

    if (category_create.fields.length > 0) list_unique_group.push(category_create)
  })

  return [list_general_group, list_fixed_group, ...list_unique_group]
}

export const getMaxElementByArray = ({ arrayObject, property }) => {
  let maxHours = 0
  let maxElement = null

  arrayObject.forEach(element => {
    const hours = parseFloat(element[property])
    if (hours > maxHours) {
      maxHours = hours
      maxElement = element
    }
  })

  return maxElement
}
