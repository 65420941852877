import { useState } from 'react'
import { Helmet } from 'react-helmet'

import { BasicInfoSettings } from './Components/BasicInfoSettings'
import { InfoUserAvatarSettings } from './Components/InfoUserAvatarSettings'
import { TabsSettings } from './Components/TabsSettings'
import { TABS_OPTIONS_SETTINGS } from './Components/TabsSettings/TabsSettings'
import { SettingsSet } from './Components/SettingsSet'
import { BillingSettings } from './Components/BillingSettings'
import { useLocation } from 'react-router-dom'

const SettingPage = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const defaultSection = queryParams.get('section')

  const [activeTab, setActiveTab] = useState(defaultSection ? TABS_OPTIONS_SETTINGS[defaultSection].id : TABS_OPTIONS_SETTINGS.BASIC_INFO.id)
  const handleChangeTab = ({ tab }) => {
    if (tab === activeTab) return

    setActiveTab(tab)
  }

  return (
    <>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <div className="containers pt-4">
        <div className="card settings p-5">
          <div className="settings_navbar">
            <InfoUserAvatarSettings />

            <TabsSettings onChangeTab={handleChangeTab} activeTab={activeTab} />
          </div>

          {activeTab === TABS_OPTIONS_SETTINGS.BASIC_INFO.id && <BasicInfoSettings />}
          {activeTab === TABS_OPTIONS_SETTINGS.SETTINGS.id && <SettingsSet />}
          {activeTab === TABS_OPTIONS_SETTINGS.BILLING.id && <BillingSettings />}
        </div>
      </div>
    </>
  )
}

export default SettingPage
