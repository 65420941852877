import { DotsThree } from '@phosphor-icons/react'
import useSessionStore from 'Context/useSessionStore'
import { DateDisplay } from 'Organisms'
import { DropdownMenuActions } from 'Organisms/DropdownMenuActions'
import { getRole } from 'Utils/helperFunctions'
import { PERMISSION_BY_ROLE } from 'Utils/permissionByRol'
import { COLOR_BLACK, COLOR_CONTRACT_BLACK, COLOR_PURPLE_LIGHT } from 'constant/colors'
import { PROFILE_DETAILS_ROUTER } from 'constant/router'
import Avatar from 'react-avatar'
import { Link } from 'react-router-dom'

const TableUserContentMobile = ({ listUsers, menuOptions }) => {
  const { user: UserContext } = useSessionStore((state) => ({ user: state.user }))

  return (
    <table className="table align-middle">
      <thead>
        <tr>
          <th scope="col" colSpan={3}>
            Name
          </th>
        </tr>
      </thead>
      <tbody>
        {listUsers.map((user) => (
          <tr key={user.id}>
            <td>
              <Avatar
                className="avatarImage"
                name={`${user.firstname} ${user.lastname}`}
                src={user?.logo || null}
                color={COLOR_PURPLE_LIGHT}
                fgColor={COLOR_BLACK}
                round={true}
                size="38px"
                textSizeRatio={3}
              />
            </td>
            <td className='tableMobileUserContent'>
              <span className="badge text-bg-secondary">{getRole(UserContext.role)}</span>
              <Link to={PROFILE_DETAILS_ROUTER({ profileId: user.id })}>
                {user.firstname} {user.lastname}
              </Link>
              <span className="form-text">{user.email}</span>
              <DateDisplay date={user.created_at} />
            </td>
            {PERMISSION_BY_ROLE.USER.TABLE.ACTIONS.includes(UserContext.role) && (
              <td>
                <DropdownMenuActions
                  options={menuOptions}
                  icon={<DotsThree size={18} color={COLOR_CONTRACT_BLACK} weight="bold" />}
                  type="secondary"
                  element={user}
                  idBtn="menuUser"
                />
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default TableUserContentMobile
