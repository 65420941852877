import { useState } from 'react'
import {
  getHoursByUserServices,
  getHoursPerProjectByUserServices,
  getHoursPerTaskByUserServices,
  getMyHoursPerProjectServices,
  getMyHoursPerTaskServices,
  getMyHoursServices
} from 'Services'
import { createQueryString } from 'Utils/helperFunctions'

export default function useProfile () {
  const [loadingProfileHook, setLoadingProfileHook] = useState(false)
  const [listMyHoursHook, setListMyHoursHook] = useState([])
  const [listMyHoursPerProject, setListMyHoursPerProject] = useState([])
  const [listMyHoursPerTask, setListMyHoursPerTask] = useState([])

  const [listHoursByUserHook, setListHoursByUserHook] = useState([])
  const [listHoursPerProjectByUserHook, setListHoursPerProjectByUserHook] = useState([])
  const [listHoursPerTaskByUserHook, setListHoursPerTaskByUserHook] = useState([])

  // My hours
  const getMyHoursProfileHook = async ({ start_date, end_date, period } = {}) => {
    try {
      setLoadingProfileHook(true)
      const query = createQueryString({ period, date: start_date && end_date ? `${start_date}:${end_date}` : '' })
      const res = await getMyHoursServices({ query })
      setListMyHoursHook(res.data)
      return res
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingProfileHook(false)
    }
  }

  const getMyHoursProfilePerProjectHook = async ({ start_date, end_date } = {}) => {
    try {
      setLoadingProfileHook(true)
      const query = createQueryString({ date: start_date && end_date ? `${start_date}:${end_date}` : '' })
      const res = await getMyHoursPerProjectServices({ query })
      setListMyHoursPerProject(res.data)
      return res
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingProfileHook(false)
    }
  }

  const getMyHoursProfilePerTaskHook = async ({ start_date, end_date } = {}) => {
    try {
      setLoadingProfileHook(true)
      const query = createQueryString({ date: start_date && end_date ? `${start_date}:${end_date}` : '' })
      const res = await getMyHoursPerTaskServices({ query })
      setListMyHoursPerTask(res.data)
      return res
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingProfileHook(false)
    }
  }

  // Other user
  const getHoursProfileByUserHook = async ({ start_date, end_date, user_id, period } = {}) => {
    try {
      setLoadingProfileHook(true)
      const query = createQueryString({ period, date: start_date && end_date ? `${start_date}:${end_date}` : '' })
      const res = await getHoursByUserServices({ query, user_id })
      setListHoursByUserHook(res.data)
      return res
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingProfileHook(false)
    }
  }

  const getHoursProfilePerProjectByUserHook = async ({ start_date, end_date, user_id } = {}) => {
    try {
      setLoadingProfileHook(true)
      const query = createQueryString({ date: start_date && end_date ? `${start_date}:${end_date}` : '' })
      const res = await getHoursPerProjectByUserServices({ query, user_id })
      setListHoursPerProjectByUserHook(res.data)
      return res
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingProfileHook(false)
    }
  }

  const getHoursProfilePerTaskByUserHook = async ({ start_date, end_date, user_id } = {}) => {
    try {
      setLoadingProfileHook(true)
      const query = createQueryString({ date: start_date && end_date ? `${start_date}:${end_date}` : '' })
      const res = await getHoursPerTaskByUserServices({ query, user_id })
      setListHoursPerTaskByUserHook(res.data)
      return res
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingProfileHook(false)
    }
  }

  return {
    loadingProfileHook,
    listMyHoursHook,
    listMyHoursPerProject,
    listMyHoursPerTask,
    listHoursByUserHook,
    listHoursPerProjectByUserHook,
    listHoursPerTaskByUserHook,
    getMyHoursProfileHook,
    getMyHoursProfilePerProjectHook,
    getMyHoursProfilePerTaskHook,
    getHoursProfileByUserHook,
    getHoursProfilePerProjectByUserHook,
    getHoursProfilePerTaskByUserHook
  }
}
