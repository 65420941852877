import { usePayment } from 'Hooks'
import { Loading } from 'Organisms/Loading'
import { ModalSuccess } from 'Organisms/ModalSuccess'
import { useEffect, useState } from 'react'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'

const BillingInformation = ({ billing, onRefresh }) => {
  const [enableForm, setEnableForm] = useState(false)
  const [billingState, setBillingState] = useState()
  const [message, setMessage] = useState({ open: false })
  const [loading, setLoading] = useState(false)

  const { changeBillingCompanyHook } = usePayment()

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      setLoading(true)
      const res = await changeBillingCompanyHook({
        body: { ...billingState, location: '' }
      })

      if (!res.success) {
        throw new Error()
      }

      setMessage({
        open: true,
        message: 'Your billing information has been successfully updated.'
      })

      await onRefresh()

      setEnableForm(false)
    } catch {
      setMessage({
        open: true,
        error: true,
        message: 'An error occurred while updating your billing information.'
      })
    } finally {
      setLoading(false)
    }
  }

  const handleChange = ({ field, value }) => {
    const data = {
      ...billingState,
      [field]: value
    }
    setBillingState(data)
  }

  const handleSetDefault = () => {
    setBillingState({
      full_name: billing?.full_name || '',
      country: billing?.country || '',
      address_line_1: billing?.address_line_1 || '',
      address_line_2: billing?.address_line_2 || '',
      city: billing?.city || '',
      zip_code: billing?.zip_code || '',
      state: billing?.state || ''
    })
  }

  useEffect(() => {
    handleSetDefault()
  }, [billing])

  return (
    <>
      {loading && <Loading />}

      <h4 className="mt-4 mb-3">Billing Information</h4>
      <form className="row mb-4" onSubmit={handleSubmit}>
        <div className="col-12 col-md-9">
          <div className="row mb-3">
            <div className="col-12">
              <label htmlFor="firstName" className="form-label">
                Full name
              </label>
              <input
                type="text"
                className={
                  enableForm ? 'form-control' : 'form-control-plaintext'
                }
                disabled={!enableForm}
                onChange={(e) => handleChange({ field: 'full_name', value: e.target.value })}
                value={billingState?.full_name || ''}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <label htmlFor="address" className="form-label">
                Address line 1
              </label>
              <input
                type="text"
                className={
                  enableForm ? 'form-control' : 'form-control-plaintext'
                }
                value={billingState?.address_line_1 || ''}
                onChange={(e) => handleChange({ field: 'address_line_1', value: e.target.value })}
                disabled={!enableForm}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <label htmlFor="address" className="form-label">
                Address line 2
              </label>
              <input
                type="text"
                className={
                  enableForm ? 'form-control' : 'form-control-plaintext'
                }
                onChange={(e) => handleChange({ field: 'address_line_2', value: e.target.value })}
                value={billingState?.address_line_2 || ''}
                disabled={!enableForm}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <label htmlFor="city" className="form-label">
                Country
              </label>
              {enableForm
                ? (
                <CountryDropdown
                  classes='form-control'
                  value={billingState?.country}
                  valueType="short"
                  onChange={(e) => handleChange({ field: 'country', value: e })}
                />
                  )
                : (
                <p className="form-control-plaintext">{billingState?.country}</p>
                  )}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="city" className="form-label">
                City
              </label>
              <input
                type="text"
                className={enableForm ? 'form-control' : 'form-control-plaintext'}
                value={billingState?.city || ''}
                onChange={(e) => handleChange({ field: 'city', value: e.target.value })}
                disabled={!enableForm}
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="state" className="form-label">
                State
              </label>
              {enableForm
                ? (
                <RegionDropdown
                  classes='form-control'
                  country={billingState?.country.toUpperCase()}
                  countryValueType="short"
                  valueType="short"
                  value={billingState?.state}
                  onChange={(e) => handleChange({ field: 'state', value: e })}
                />
                  )
                : (
                <p className="form-control-plaintext">{billingState?.state}</p>
                  )}
            </div>
            <div className="col-md-2">
              <label htmlFor="zip" className="form-label">
                ZIP Code
              </label>
              <input
                type="text"
                className={
                  enableForm ? 'form-control' : 'form-control-plaintext'
                }
                value={billingState?.zip_code || ''}
                onChange={(e) => handleChange({ field: 'zip_code', value: e.target.value.replace(/[^A-Za-z0-9]/g, '') })}
                disabled={!enableForm}
              />
            </div>
          </div>

          {!enableForm && (
            <button type="button" className="btn btn-secondary" onClick={() => setEnableForm(true)}>
              Edit
            </button>
          )}
          {enableForm && (
            <div className="d-flex">
              <button type="submit" className="btn btn-primary">
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary ms-4"
                onClick={() => {
                  setEnableForm(false)
                  handleSetDefault()
                }}>
                Cancel
              </button>
            </div>
          )}
        </div>
      </form>

      {message.open && (
        <ModalSuccess
          visible={true}
          setVisible={() => setMessage({ open: false })}
          title={message.message}
          identifier="modalMessage"
          error={message.error}
        />
      )}
    </>
  )
}
export default BillingInformation
