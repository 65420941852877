import ModalConfirmOrCancel from 'Organisms/ModalConfirmOrCancel'
import { STATUS_SUBMIT_HOURS } from 'Pages/TimeTracker/utils/const'
import { useState } from 'react'

export const TYPE_SUBMIT_ACTION = {
  WEEK: {
    id: 'WEEK',
    btnContent: 'Submit week for approval'
  },
  DAY: {
    id: 'DAY',
    btnContent: 'Submit day for approval'
  }
}
const BtnSubmitTracker = ({ approval, onSubmit, listTimeEntries }) => {
  const [submitAction, setSubmitAction] = useState(TYPE_SUBMIT_ACTION.WEEK)
  const [modalConfirm, setModalConfirm] = useState(false)

  const disabled =
    ![STATUS_SUBMIT_HOURS.UNSUBMITTED, STATUS_SUBMIT_HOURS.REJECTED].includes(approval) || listTimeEntries.length === 0

  return (
    <>
      <div className="mt-3 d-flex submitHoursTrackerBtn">
        <div className="btn-group submitHoursTrackerBtn_content">
          <button type="button" className="btn btn-secondary" disabled={disabled} onClick={() => setModalConfirm(true)}>
            {submitAction.btnContent}
          </button>
          <button
            type="button"
            id="submitHoursTrackerBtn_dropdown"
            className="btn btn-secondary dropdown-toggle dropdown-toggle-split submitHoursTrackerBtn_dropdown"
            data-bs-toggle="dropdown"
            disabled={disabled}
            aria-expanded="false">
            <span className="visually-hidden">Toggle Dropdown</span>
          </button>
          <ul className="dropdown-menu dropdown-menu-end">
            {Object.values(TYPE_SUBMIT_ACTION).map((item) => (
              <li className="dropdown-item pointer" key={item.id} onClick={() => setSubmitAction(item)}>
                {item.btnContent}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {modalConfirm && (
        <ModalConfirmOrCancel
          header={`You are about to submit your ${
            submitAction.id === TYPE_SUBMIT_ACTION.WEEK.id ? 'weekly' : 'daily'
          } hours`}
          title="Are you sure you want to continue?"
          setVisible={setModalConfirm}
          btnConfirm="Submit"
          btnCancel="Go back"
          onConfirm={() => onSubmit({ typeSubmit: submitAction.id })}
        />
      )}
    </>
  )
}

export default BtnSubmitTracker
