import axios from 'axios'

const BASE_URL_PAYMENT = '/payments'

// GET
export const getPaymentConnectService = async ({ period }) =>
  axios.get(`${BASE_URL_PAYMENT}/stripe?period=${period}`).then(({ data }) => data)

export const getPaymentMethodsService = async () =>
  axios.get(`${BASE_URL_PAYMENT}/company/payment_methods`).then(({ data }) => data)

export const getInvoicesByCompanyService = async () =>
  axios.get(`${BASE_URL_PAYMENT}/company/invoices`).then(({ data }) => data)

// POST
export const addPaymentMethodService = async ({ payment_method_id }) =>
  axios.post(`${BASE_URL_PAYMENT}/company/payment_methods`, { payment_method_id }).then(({ data }) => data)

// PATCH
export const cancelPlanService = async () => axios.patch(`${BASE_URL_PAYMENT}/company/cancel`).then(({ data }) => data)

export const reactivePlanService = async () =>
  axios.patch(`${BASE_URL_PAYMENT}/company/reactive`).then(({ data }) => data)

export const updatePaymentSeatsService = async ({ seats }) => axios.patch(`${BASE_URL_PAYMENT}/company/seats/${seats}`)

export const changeDefaultPaymentMethodService = async ({ payment_method_id }) =>
  axios.patch(`${BASE_URL_PAYMENT}/company/payment_methods/default`, { payment_method_id }).then(({ data }) => data)

export const changeBillingCompanyService = async ({ body }) =>
  axios.patch(`${BASE_URL_PAYMENT}/billing-information`, body).then(({ data }) => data)
