import axios from 'axios'

const createClientService = async ({ nameClient }) => axios.post('/clients', { name: nameClient }).then(({ data }) => data)

const updateClient = async (body, id) => axios.put('/clients/' + id, { ...body }).then(({ data }) => data)

const getClient = async (id) => axios.get('/clients/' + id).then(({ data }) => data)

const getClients = async (queryParams) =>
  axios.get(`/clients${queryParams ? `?${queryParams}` : ''}`).then(({ data }) => data)

const deleteClient = async (id) => axios.delete('/clients/' + id).then(({ data }) => data)

export { createClientService, updateClient, getClient, getClients, deleteClient }
