import { WarrantyTag } from 'Organisms/WarrantyTag'
import Timer from './Timer'

const TimeTrackerItem = ({ timeEntry, onOpenEdit, onRunTime }) => {
  const disabledBtn = !timeEntry.is_running && (timeEntry.is_submitted || timeEntry.is_approved)

  return (
    <div className="py-4 px-4 timeTrackerItem">
      <div className="timeTrackerItem_ticket settings_fullname">
        <p className="m-0">
          <span className="fw-bold">{timeEntry?.project_task?.project?.name || ''}</span> (
          {timeEntry?.project_task?.project?.client?.name || ''}) {timeEntry.is_warranty && <WarrantyTag />}
        </p>

        <p className="m-0">{timeEntry?.project_task?.task?.name || ''}</p>

        <p className="m-0 fs-7">{timeEntry?.title || ''}</p>
      </div>
      <div className="timeTrackerItem_actions">
        <Timer timeEntry={timeEntry} disabled={disabledBtn} onRunTime={onRunTime}>
        <button
          className="btn btn-secondary btn-sm"
          onClick={() => onOpenEdit({ tracker: timeEntry })}
          disabled={disabledBtn || timeEntry.is_running}>
          Edit
        </button>
        </Timer>
      </div>
    </div>
  )
}
export default TimeTrackerItem
