import {
  addPaymentMethodService,
  cancelPlanService,
  changeBillingCompanyService,
  changeDefaultPaymentMethodService,
  getInvoicesByCompanyService,
  getPaymentConnectService,
  getPaymentMethodsService,
  reactivePlanService,
  updatePaymentSeatsService
} from 'Services'
import { CAN_NOT_BE_CANCELED_THE_PLAN_BY_USER_ACTIVE } from 'Utils/const/messages'
import { useState } from 'react'

export default function useDevops () {
  const [loadingPaymentHook, setLoadingPaymentHook] = useState(false)
  const [listPaymentMethodsHook, setListPaymentMethodsHook] = useState([])
  const [defaultPaymentHook, setDefaultPaymentHook] = useState({})
  const [listInvoicesHook, setListInvoicesHook] = useState([])

  const getPaymentConnectHook = async ({ period }) => {
    try {
      setLoadingPaymentHook(true)
      const res = await getPaymentConnectService({ period })

      return {
        success: true,
        data: res.data
      }
    } catch (e) {
      return {
        success: false
      }
    } finally {
      setLoadingPaymentHook(false)
    }
  }

  const cancelPaymentHook = async () => {
    try {
      await cancelPlanService()

      return {
        success: true
      }
    } catch (err) {
      let message = 'An error occurred while canceling the plan'

      if (err?.response?.data?.error?.options?.description === CAN_NOT_BE_CANCELED_THE_PLAN_BY_USER_ACTIVE) { message = CAN_NOT_BE_CANCELED_THE_PLAN_BY_USER_ACTIVE }

      return {
        message,
        success: false
      }
    }
  }

  const reactivePaymentHook = async () => {
    try {
      await reactivePlanService()

      return {
        success: true
      }
    } catch {
      return {
        success: false
      }
    }
  }

  const updatePaymentSeatsHook = async ({ seats }) => {
    try {
      if (seats < 1) throw new Error()

      await updatePaymentSeatsService({ seats })
      return {
        success: true
      }
    } catch {
      return {
        success: false
      }
    }
  }

  const getListPaymentMethodsHook = async () => {
    try {
      const res = await getPaymentMethodsService()
      setListPaymentMethodsHook(res.data.paymentd_methods)
      setDefaultPaymentHook(
        res.data.default_payment_method !== null
          ? res.data.paymentd_methods.find((item) => item.id === res.data.default_payment_method)
          : res.data.paymentd_methods[0]
      )

      return res
    } catch {
      return {
        success: false
      }
    }
  }

  const addPaymentMethodHook = async ({ payment_method_id }) => {
    try {
      await addPaymentMethodService({ payment_method_id })
      return { success: true }
    } catch {
      return { success: false }
    }
  }

  const changeDefaultPaymentMethodHook = async ({ payment_method_id }) => {
    try {
      await changeDefaultPaymentMethodService({ payment_method_id })
      return { success: true }
    } catch {
      return { success: false }
    }
  }

  const changeBillingCompanyHook = async ({ body }) => {
    try {
      await changeBillingCompanyService({ body })
      return {
        success: true
      }
    } catch {
      return {
        success: false
      }
    }
  }

  const getInvoicesByCompanyHook = async () => {
    try {
      const res = await getInvoicesByCompanyService()
      setListInvoicesHook(res?.data)
      return res
    } catch {
      return {
        success: false
      }
    }
  }

  return {
    loadingPaymentHook,
    listPaymentMethodsHook,
    defaultPaymentHook,
    listInvoicesHook,
    getPaymentConnectHook,
    cancelPaymentHook,
    reactivePaymentHook,
    updatePaymentSeatsHook,
    getListPaymentMethodsHook,
    addPaymentMethodHook,
    changeDefaultPaymentMethodHook,
    changeBillingCompanyHook,
    getInvoicesByCompanyHook
  }
}
