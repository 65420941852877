const Logo = ({ style }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.901 45.571" className={style}>
    <g transform="translate(-1.5 -2.8)">
      <path
        className="a"
        d="M79.828,37.475a10.119,10.119,0,0,1-3.671-13.807,9.35,9.35,0,0,1,3.671-3.591,11.477,11.477,0,0,1,9.258-.559A8.41,8.41,0,0,1,92.2,21.673L90.921,23.03a7.82,7.82,0,0,0-5.746-2.314,8.519,8.519,0,0,0-4.23,1.038,7.858,7.858,0,0,0-2.953,2.873,8.019,8.019,0,0,0-1.038,4.15,8.221,8.221,0,0,0,1.038,4.15A7.31,7.31,0,0,0,80.945,35.8a8.313,8.313,0,0,0,4.23,1.038,7.694,7.694,0,0,0,5.746-2.314L92.2,35.8a8.41,8.41,0,0,1-3.113,2.155,11.184,11.184,0,0,1-4.07.718A9.423,9.423,0,0,1,79.828,37.475Z"
        transform="translate(-14.8 -3.231)"
      />
      <path
        className="a"
        d="M109.954,26.455a5.5,5.5,0,0,1,1.516,4.23v9.1h-1.915V37.468a4.286,4.286,0,0,1-2,1.756,7.459,7.459,0,0,1-3.113.638,5.873,5.873,0,0,1-3.911-1.2,4.079,4.079,0,0,1-.08-6.145c.878-.8,2.314-1.117,4.31-1.117H109.4v-.8a3.628,3.628,0,0,0-1.038-2.873,4.54,4.54,0,0,0-3.113-.958,7.771,7.771,0,0,0-2.713.479A6.816,6.816,0,0,0,100.3,28.53l-.878-1.516a7.356,7.356,0,0,1,2.713-1.437A11.2,11.2,0,0,1,105.4,25.1,6.109,6.109,0,0,1,109.954,26.455Zm-2.235,11.173a4.128,4.128,0,0,0,1.756-2.235V33h-4.629c-2.554,0-3.751.878-3.751,2.634a2.321,2.321,0,0,0,.958,2,4.56,4.56,0,0,0,2.713.718A5.992,5.992,0,0,0,107.719,37.628Z"
        transform="translate(-19.707 -4.5)"
      />
      <path
        className="a"
        d="M128.891,26.694c1.117,1.038,1.6,2.634,1.6,4.709v8.54h-2v-8.38a5.144,5.144,0,0,0-1.117-3.512,4.221,4.221,0,0,0-3.272-1.2,4.871,4.871,0,0,0-3.751,1.437A5.2,5.2,0,0,0,119,32.2v7.741h-2V25.178h1.915v2.713a5.6,5.6,0,0,1,2.235-2.075A7.405,7.405,0,0,1,124.5,25.1,5.45,5.45,0,0,1,128.891,26.694Z"
        transform="translate(-23.321 -4.501)"
      />
      <path
        className="a"
        d="M148.292,26.694c1.117,1.038,1.6,2.634,1.6,4.709v8.54h-2v-8.38a5.144,5.144,0,0,0-1.117-3.512,4.221,4.221,0,0,0-3.272-1.2,4.871,4.871,0,0,0-3.751,1.437A5.2,5.2,0,0,0,138.4,32.2v7.741h-2V25.178h1.915v2.713a5.6,5.6,0,0,1,2.235-2.075A7.405,7.405,0,0,1,143.9,25.1,5.554,5.554,0,0,1,148.292,26.694Z"
        transform="translate(-27.238 -4.501)"
      />
      <path
        className="a"
        d="M170.106,32.981H157.815a5.054,5.054,0,0,0,1.756,3.671,5.7,5.7,0,0,0,3.99,1.437,6.913,6.913,0,0,0,2.554-.479,5.125,5.125,0,0,0,2-1.437l1.117,1.277a5.616,5.616,0,0,1-2.474,1.756,8.437,8.437,0,0,1-3.192.638,7.982,7.982,0,0,1-3.99-.958,6.641,6.641,0,0,1-2.713-2.634,8.141,8.141,0,0,1,0-7.662,7.353,7.353,0,0,1,2.554-2.634A7,7,0,0,1,163.083,25a7.2,7.2,0,0,1,3.671.958,6.752,6.752,0,0,1,2.554,2.634,7.583,7.583,0,0,1,.958,3.831Zm-10.694-5.028a5,5,0,0,0-1.6,3.512H168.27a5.352,5.352,0,0,0-1.6-3.512,5.143,5.143,0,0,0-3.591-1.357A5.2,5.2,0,0,0,159.412,27.953Z"
        transform="translate(-31.175 -4.482)"
      />
      <path
        className="a"
        d="M191.024,17.6V38.35h-1.915V35.477a5.41,5.41,0,0,1-2.394,2.235,6.417,6.417,0,0,1-3.272.8,7.5,7.5,0,0,1-3.751-.958,6.956,6.956,0,0,1-2.634-2.634,7.677,7.677,0,0,1-.958-3.911,8.105,8.105,0,0,1,.958-3.911,6.955,6.955,0,0,1,2.634-2.634,7.5,7.5,0,0,1,3.751-.958,6.959,6.959,0,0,1,3.192.718,6.178,6.178,0,0,1,2.394,2.155V17.6ZM186.4,36.036a5.32,5.32,0,0,0,2-2,6.429,6.429,0,0,0,0-5.906,4.809,4.809,0,0,0-2-2,5.6,5.6,0,0,0-2.793-.718,5.392,5.392,0,0,0-2.793.718,5.32,5.32,0,0,0-2,2,6.429,6.429,0,0,0,0,5.906,4.809,4.809,0,0,0,2,2,5.791,5.791,0,0,0,5.587,0Z"
        transform="translate(-35.254 -2.988)"
      />
      <path
        className="a"
        d="M215.678,19.3V38.853h-3.671v-8.38H201.871v8.38H198.2V19.3h3.671v8.061h10.136V19.3Z"
        transform="translate(-39.716 -3.332)"
      />
      <path
        className="a"
        d="M237.643,33.419H225.912a4.128,4.128,0,0,0,1.6,2.634,5.225,5.225,0,0,0,3.192.958,5.663,5.663,0,0,0,4.07-1.6l1.836,2.155a6.022,6.022,0,0,1-2.554,1.836,9.384,9.384,0,0,1-3.432.638,9.044,9.044,0,0,1-4.31-.958,7.251,7.251,0,0,1-2.873-2.713,7.449,7.449,0,0,1-1.038-3.99,7.887,7.887,0,0,1,.958-3.911,6.828,6.828,0,0,1,2.714-2.713,8.2,8.2,0,0,1,3.99-.958,8.105,8.105,0,0,1,3.911.958,6.828,6.828,0,0,1,2.713,2.713,8.294,8.294,0,0,1,.958,4.07A1.574,1.574,0,0,1,237.643,33.419Zm-10.3-4.948a3.944,3.944,0,0,0-1.437,2.634h8.46a4.214,4.214,0,0,0-1.357-2.634,4.358,4.358,0,0,0-2.873-1.038A4.989,4.989,0,0,0,227.348,28.471Z"
        transform="translate(-44.603 -4.442)"
      />
      <path
        className="a"
        d="M255.912,26.43c1.2,1.038,1.756,2.634,1.756,4.789V40H254.4V38.162a4.379,4.379,0,0,1-1.836,1.516,7.732,7.732,0,0,1-2.873.479,6.967,6.967,0,0,1-2.953-.559,4.229,4.229,0,0,1-1.915-1.6,3.974,3.974,0,0,1-.718-2.314,3.758,3.758,0,0,1,1.516-3.192c.958-.8,2.554-1.2,4.709-1.2h3.831v-.239a3.011,3.011,0,0,0-.958-2.394,4.029,4.029,0,0,0-2.793-.8,8.9,8.9,0,0,0-2.474.4,5.764,5.764,0,0,0-2.075,1.117L244.5,26.829a10.1,10.1,0,0,1,2.793-1.357,12.448,12.448,0,0,1,3.512-.479A6.761,6.761,0,0,1,255.912,26.43Zm-3.192,10.535a2.869,2.869,0,0,0,1.437-1.756V33.453h-3.591c-2,0-3.033.638-3.033,2a1.854,1.854,0,0,0,.718,1.516,3.21,3.21,0,0,0,2.075.559A4.1,4.1,0,0,0,252.719,36.964Z"
        transform="translate(-48.984 -4.476)"
      />
      <path
        className="a"
        d="M279.3,17.6V38.35H275.95V36.435a4.789,4.789,0,0,1-2.155,1.6A7.915,7.915,0,0,1,271,38.59a7.583,7.583,0,0,1-3.831-.958,6.828,6.828,0,0,1-2.714-2.713,8.2,8.2,0,0,1-.958-3.99,7.982,7.982,0,0,1,.958-3.99,6.828,6.828,0,0,1,2.714-2.713A7.8,7.8,0,0,1,271,23.266a7.456,7.456,0,0,1,2.714.479,4.837,4.837,0,0,1,2.075,1.516V17.6H279.3Zm-5.587,17.318a5.08,5.08,0,0,0,1.6-1.6,4.926,4.926,0,0,0,.559-2.474,4.926,4.926,0,0,0-.559-2.474,3.842,3.842,0,0,0-1.6-1.6,4.749,4.749,0,0,0-4.469,0,5.08,5.08,0,0,0-1.6,1.6,4.926,4.926,0,0,0-.559,2.474,4.926,4.926,0,0,0,.559,2.474,3.842,3.842,0,0,0,1.6,1.6,4.232,4.232,0,0,0,2.235.559A3.608,3.608,0,0,0,273.716,34.918Z"
        transform="translate(-52.901 -2.988)"
      />
      <g transform="translate(1.5 2.8)">
        <path
          className="a"
          d="M31.722,32.1H28.849c-2.554,0-4.549,1.117-4.549,3.671a4.19,4.19,0,0,0,4.549,4.549h2.793c3.192,0,4.549-2,4.549-4.549C36.271,33.217,34.276,32.1,31.722,32.1Z"
          transform="translate(-6.104 -8.716)"
        />
        <ellipse className="a" cx="3.272" cy="3.033" rx="3.272" ry="3.033" transform="translate(20.91 13.727)" />
        <path
          className="a"
          d="M25.044,2.8C12.035,2.8,1.5,13.016,1.5,25.545c0,12.61,10.535,22.825,23.544,22.825S48.587,38.155,48.587,25.545C48.587,13.016,38.052,2.8,25.044,2.8Zm6.145,31.684a8.953,8.953,0,0,1-5.347,1.6h-.319a9.217,9.217,0,0,1-5.347-1.6A6.136,6.136,0,0,1,17.382,28.9s.479-3.751.8-5.108c.239-1.6,1.277-6.3,1.277-6.3a3.8,3.8,0,0,1,1.756-2.314,8.229,8.229,0,0,1,4.31-1.117h.319a9.076,9.076,0,0,1,4.31,1.117,3.608,3.608,0,0,1,1.756,2.314s1.038,4.629,1.277,6.3c.239,1.437.8,5.108.8,5.108A6.136,6.136,0,0,1,31.189,34.484Z"
          transform="translate(-1.5 -2.8)"
        />
      </g>
    </g>
  </svg>
)

export default Logo
