import { DateTime } from 'luxon'
import { PARAMS_URL_TRACKER, STATUS_SUBMIT_HOURS } from './const'
import { MAX_QUANTITY_DAYS_OF_PERIOD, PERIOD_TIME_SHEET } from 'Pages/Timesheet/utils/const'

export const getStatusTracker = ({ listTimeEntries }) => {
  if (!listTimeEntries.length) return STATUS_SUBMIT_HOURS.UNSUBMITTED

  if (listTimeEntries.some((item) => item.is_rejected)) return STATUS_SUBMIT_HOURS.REJECTED

  if (listTimeEntries.some((item) => item.is_submitted && !item.is_approved)) return STATUS_SUBMIT_HOURS.PENDING

  if (listTimeEntries.every((item) => item.is_submitted && item.is_approved)) return STATUS_SUBMIT_HOURS.APPROVED

  return STATUS_SUBMIT_HOURS.UNSUBMITTED
}

export const getDiferentHours = ({ start_date, end_date, hours }) => {
  // diferencia de hora
  const start = DateTime.fromISO(start_date)
  const end = end_date

  const differenceInHours = end.diff(start, 'hours')

  return hours + differenceInHours?.values?.hours || 0
}

export const getPeriodByDiffDay = ({ diffDays }) => {
  if (diffDays <= MAX_QUANTITY_DAYS_OF_PERIOD.WEEK) {
    return PERIOD_TIME_SHEET.WEEK
  } else if (diffDays <= MAX_QUANTITY_DAYS_OF_PERIOD.SEMIMONTH) {
    return PERIOD_TIME_SHEET.SEMIMONTH
  } else {
    return PERIOD_TIME_SHEET.MONTH
  }
}

export const getQueryParamsTrakcer = ({ location }) => {
  const queryParams = new URLSearchParams(location.search)
  const dateRangeQuery = queryParams.get(PARAMS_URL_TRACKER.DATE)
  const startDateParamsQuery = dateRangeQuery ? dateRangeQuery.split(':') : null
  const currentDateQuery = queryParams.get(PARAMS_URL_TRACKER.CURRENT_DATE)

  return { queryParams, startDateParamsQuery: startDateParamsQuery?.[0], currentDateQuery }
}
