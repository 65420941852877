export const COLOR_RED = '#FFB4AB'
export const COLOR_WHITE = '#F3F5F7'
export const COLOR_BLACK = '#060A11'
export const COLOR_CONTRACT_BLACK = '#262422'
export const COLOR_LIGHT_BLUE = '#00DDD8'
export const COLOR_BLUE = '#0d6efd'
export const COLOR_BLUE_MATE = '#6D7F9B'
export const COLOR_YELLOW = '#e9c46a'
export const COLOR_LIGHT_GREEN = '#A2EEB3'
export const COLOR_PURPLE_LIGHT = '#B1C8E8'
export const COLOR_EMERALD = '#288373'
export const COLOR_GREY = '#889392'
export const COLOR_EMERALD2 = '#016A67'

export const GET_COLOR_BY_THEME = ({ theme }) => ({
  AVATAR: theme ? COLOR_PURPLE_LIGHT : COLOR_EMERALD,
  AVATAR_TEXT: theme ? COLOR_BLACK : COLOR_WHITE,
  MENU_NAVBAR: theme ? COLOR_WHITE : COLOR_BLACK,
  SECONDARY: theme ? COLOR_WHITE : COLOR_GREY,
  PRIMARY: theme ? COLOR_LIGHT_BLUE : COLOR_EMERALD,
  TERTIARY: theme ? COLOR_WHITE : COLOR_BLACK
})
