// Landing
export const LANDING_PAGE_ROUTER = '/'
export const FAQ_ROUTER = '/#faq'
export const CONTACTUS_ROUTER = '/contact-us'
export const BECOME_PARTNER_ROUTER = '/become-a-partner'
export const SUPPORT_ROUTER = '/support'

// Cookie Policy
export const COOKIE_POLICY_ROUTER = '/cookie-policy'

// Dashboard
export const DASHBAORD_ROUTER = '/dashboard'

// Users
export const USER_ROUTER = '/users'

// Project
export const PROJECT_ROUTER = '/projects'
export const PROJECT_DETAILS_ROUTER = ({ projectId = ':id' } = {}) => `${PROJECT_ROUTER}/${projectId}`

// Payroll
export const PAYROLL_ROUTER = '/payroll'

// Time
export const TIME_ROUTER = '/time'

// Login
export const LOGIN_ROUTER = '/login'

// Sign up
export const SIGNUP_ROUTER = '/signup'

// Profile
export const PROFILE_ROUTER = '/profile'
export const PROFILE_DETAILS_ROUTER = ({ profileId = ':id' } = {}) => `${PROFILE_ROUTER}/${profileId}`

// LearnCenter
export const LEARN_CENTER_ROUTER = '/learn-center'

// Checkout
export const CHECKOUT_ROUTER = '/checkout'

// Welcome
export const WELCOME_ROUTER = '/welcome'

// Reset
export const RESET_PASSWORD_ROUTER = '/reset-password'

// Confirm sign Up
export const CONFIRM_SIGNUP_ROUTER = '/confirm-signup'

// Forgot
export const FORGOT_PASSWORD_ROUTER = '/forgot-password'

// Design
export const DESIGN_ROUTER = '/design-system'

// Pricing
export const PRICING_ROUTER = '/pricing'

// Settings
export const SETTINGS_ROUTER = '/settings'

export const BILLING_POLICY_ROUTER = '/billing-policy'
