import { formatSendPagination } from 'adapters/pagination'
import { useState } from 'react'
import {
  assignProjectToUserService,
  createUser,
  deleteResourceUserService,
  deleteUserService,
  downloadCsvService,
  getUserService,
  getUsers,
  updateProfileUserService,
  updateUserService,
  uploadUserAvatarService
} from 'Services'
import { createQueryString } from 'Utils/helperFunctions'

export default function useUser () {
  const [loadingUserHook, setLoadingUserHook] = useState(false)
  const [listUsers, setListUsers] = useState([])
  const [countListUsersHook, setCountListUsersHook] = useState(0)
  const [userInfoHook, setUserInfoHook] = useState({})

  const getUsersHook = async ({ search, userActive = true, page = 1, limit = 10, roles = [], projectsId }) => {
    try {
      setLoadingUserHook(true)

      const query = createQueryString({
        search: encodeURIComponent(search || ''),
        is_active: userActive,
        pagination: formatSendPagination({ page, limit }),
        roles: roles.join(','),
        project_ids: projectsId
      })

      const data = await getUsers(query)

      setCountListUsersHook(data?.count || 0)
      setListUsers(data?.data || [])
      return data?.data || []
    } catch (e) {
      console.error(e)
      return false
    } finally {
      setLoadingUserHook(false)
    }
  }

  const getUserByIdHook = async ({ idUser }) => {
    try {
      setLoadingUserHook(true)
      const user = await getUserService({ idUser })
      setUserInfoHook(user.data)
      return {
        success: true,
        data: user.data
      }
    } catch (e) {
      return {
        success: false,
        error: e
      }
    } finally {
      setLoadingUserHook(false)
    }
  }

  const clearUserHook = () => {
    setUserInfoHook({})
  }

  const assignProjectToUserHook = async ({ userId, listProjectId }) => {
    try {
      setLoadingUserHook(true)
      await assignProjectToUserService({ userId, listProjectId })
      return {
        success: true
      }
    } catch (e) {
      let errorMessage = ''

      if (
        e?.response?.data?.error?.options?.description ===
        'You cannot assign more that one Product Owner in same project.'
      ) {
        errorMessage = e?.response?.data?.error?.options?.description
      }

      return {
        success: false,
        message: errorMessage || e?.response?.data?.message
      }
    } finally {
      setLoadingUserHook(false)
    }
  }

  const createUserHook = async ({ body }) => {
    try {
      setLoadingUserHook(true)
      const res = await createUser(body)
      return {
        success: true,
        data: res.data
      }
    } catch (e) {
      const message = e?.response?.data?.error?.options?.description ? e.response.data.error.options.description : e?.response?.data?.message

      return {
        success: false,
        message: message || ''
      }
    } finally {
      setLoadingUserHook(false)
    }
  }

  const updateUserHook = async ({ userId, body }) => {
    try {
      setLoadingUserHook(true)
      await updateUserService({ body, userId })
      return {
        success: true
      }
    } catch (e) {
      return {
        success: false,
        message: e?.response?.data?.error?.options?.description || 'An error has occurred'
      }
    } finally {
      setLoadingUserHook(false)
    }
  }

  const downloadCsvHook = async () => {
    try {
      setLoadingUserHook(true)
      await downloadCsvService()
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingUserHook(false)
    }
  }

  const deleteUserHook = async ({ id }) => {
    try {
      setLoadingUserHook(true)
      await deleteUserService({ id })
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingUserHook(false)
    }
  }

  const deleteResourceUser = async ({ idUser, idResource }) => {
    try {
      setLoadingUserHook(true)
      await deleteResourceUserService({ idUser, idResource })
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingUserHook(false)
    }
  }

  const updateProfileUserHook = async ({ body }) => {
    try {
      setLoadingUserHook(true)

      const res = await updateProfileUserService({ body })

      return {
        success: true,
        data: res.data
      }
    } catch (e) {
      return {
        success: false
      }
    } finally {
      setLoadingUserHook(false)
    }
  }

  const uploadUserAvatarHook = async ({ file, name, user }) => {
    try {
      setLoadingUserHook(true)
      const formData = new FormData()
      formData.append('file', file)

      const res = await uploadUserAvatarService({ formData, keyImage: name })

      if (!res.success) throw new Error()

      const resUpdate = await updateProfileUserHook({
        avatar: name,
        firstname: user.firstname,
        lastname: user.lastname,
        timezone: user.timezone,
        full_name: `${user?.firstname || ''} ${user?.lastname}`
      })

      return resUpdate
    } catch (e) {
      return {
        success: false
      }
    } finally {
      setLoadingUserHook(false)
    }
  }

  // helpers
  const getAllUsersByFilterHook = async ({ projectsId, hiddenUser } = {}) => {
    try {
      const users = await getUsersHook({ limit: 1000, projectsId })

      return users
        .map((item) => {
          if (hiddenUser?.id && item.id === hiddenUser.id) return null

          return {
            id: item.id,
            value: item.id,
            text: `${item.firstname} ${item.lastname}`,
            checked: false
          }
        })
        .filter((x) => x)
    } catch (e) {
      console.error(e)
      return []
    }
  }

  return {
    loadingUserHook,
    listUsers,
    countListUsersHook,
    userInfoHook,
    getUsersHook,
    getUserByIdHook,
    clearUserHook,
    assignProjectToUserHook,
    getAllUsersByFilterHook,
    updateUserHook,
    createUserHook,
    downloadCsvHook,
    deleteUserHook,
    deleteResourceUser,
    uploadUserAvatarHook,
    updateProfileUserHook
  }
}
