import { BREAKPOINT } from 'Utils/const/breakPoints'
import { countDecimalsAndAdd0 } from 'Utils/helperFunctions'
import { COLOR_BLUE, COLOR_BLUE_MATE } from 'constant/colors'
import { useMediaQuery } from 'react-responsive'

const DistributionOfHours = ({ totalHoursWork, totalWarrantyWork, children }) => {
  // Media query
  const isMobile = useMediaQuery({
    query: `(max-width: ${BREAKPOINT.MOBILE})`
  })

  return (
    <div className="row justify-content-between align-items-center mb-4 gy-3">
      <div className="col-md-8 col-12">
        <div className="row gy-3">
          <div className="col-sm-3 col-12 d-flex d-sm-grid align-items-center justify-content-between">
            <label className="th">Total hours</label>
            <h3 className="m-0">{countDecimalsAndAdd0({ number: totalHoursWork })}h</h3>
          </div>
          <div className="col-sm-auto col-12">
            <table className="table mb-0">
              <tr>
                <td>
                  <svg
                    className={isMobile ? 'm-0' : ''}
                    width={isMobile ? 50 : 30}
                    height={isMobile ? 50 : 30}
                    xmlns="http://www.w3.org/2000/svg"
                    role="img"
                    aria-label="Placeholder: Image cap"
                    preserveAspectRatio="xMidYMid slice"
                    focusable="false">
                    <rect
                      width="30px"
                      height="30px"
                      fill={COLOR_BLUE}
                      rx={isMobile ? 5 : 0}
                      ry={isMobile ? 5 : 0}></rect>
                  </svg>
                  Billable
                </td>
                <td className="px-3">{countDecimalsAndAdd0({ number: totalHoursWork - totalWarrantyWork })}h</td>
                <td align='end'>
                  {countDecimalsAndAdd0({ number: ((totalHoursWork - totalWarrantyWork) * 100) / totalHoursWork || 0 })}
                  %
                </td>
              </tr>
              <tr>
                <td>
                  <svg
                    className={isMobile ? 'ms-0' : ''}
                    width={isMobile ? 50 : 30}
                    height={isMobile ? 50 : 30}
                    xmlns="http://www.w3.org/2000/svg"
                    role="img"
                    aria-label="Placeholder: Image cap"
                    preserveAspectRatio="xMidYMid slice"
                    focusable="false">
                    <rect
                      width="30px"
                      height="30px"
                      fill={COLOR_BLUE_MATE}
                      rx={isMobile ? 5 : 0}
                      ry={isMobile ? 5 : 0}></rect>
                  </svg>
                  Warranty
                </td>
                <td className="px-3">{countDecimalsAndAdd0({ number: totalWarrantyWork })}h</td>
                <td align='end'>{countDecimalsAndAdd0({ number: (totalWarrantyWork * 100) / totalHoursWork || 0 })}%</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-4">{children}</div>
    </div>
  )
}

export default DistributionOfHours
