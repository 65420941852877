import { DownloadSimple, Plus } from '@phosphor-icons/react'
import useSessionStore from 'Context/useSessionStore'
import { BREAKPOINT } from 'Utils/const/breakPoints'
import { PERMISSION_BY_ROLE } from 'Utils/permissionByRol'
import { COLOR_BLACK, COLOR_WHITE } from 'constant/colors'
import { useMediaQuery } from 'react-responsive'

const DownloadAndCreateUser = ({ onOpen, onDownload }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  // Media query
  const isMobile = useMediaQuery({
    query: `(max-width: ${BREAKPOINT.MOBILE})`
  })

  return (
    <div className={`row ${isMobile ? 'justify-content-center' : 'justify-content-end'} gy-4`}>
      {!isMobile && (
        <div className="col-auto">
          <button type="button" className="btn btn-secondary" onClick={onDownload}>
            <DownloadSimple className="icon" size={17} color={COLOR_WHITE} weight="bold" /> Export CSV
          </button>
        </div>
      )}

      {PERMISSION_BY_ROLE.USER.CREATE.includes(user.role) && (
        <div className={`col-auto ps-sm-0 ${isMobile ? 'width-percent-100' : ''}`}>
          <button
            type="button"
            className={`btn btn-primary ${isMobile ? 'width-percent-100' : ''}`}
            onClick={() => onOpen()}>
            <Plus className="icon" size={15} color={COLOR_BLACK} weight="bold" /> <span>Add member</span>
          </button>
        </div>
      )}
    </div>
  )
}

export default DownloadAndCreateUser
