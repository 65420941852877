import { PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer } from 'recharts'

const data = [
  { name: 'Approved', value: 30 },
  { name: 'Pending', value: 20 }
]

const COLORS = ['#004d40', '#0C3736']

const renderColorSquare = (color) => (
  <svg width="15" height="15">
    <rect width="15" height="15" fill={color} />
  </svg>
)

const ApprovedPendingPiechart = () => {
  return (
    <ResponsiveContainer width="100%" height="180px" aspect={1}>
        <PieChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
            <Pie
                data={data}
                cx="50%"
                cy="30%"
                labelLine={false}
                outerRadius="80%"
                fill="#8884d8"
                dataKey="value"
                stroke="none"
            >
                {
                data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))
                }
            </Pie>
            <Tooltip />
            <Legend
              layout="vertical"
              align="right"
              verticalAlign="top"
              formatter={(value, entry) => `${entry.payload.name}: ${entry.payload.value}h`}
              content={(props) => {
                const { payload } = props
                return (
                  <ul className='list-unstyled'>
                    {payload.map((entry, index) => (
                      <li key={`item-${index}`} style={{ color: 'white' }}>
                        {renderColorSquare(COLORS[index % COLORS.length])}
                        <span className=" ms-2 small">{`${entry.payload.name}: ${entry.payload.value}h`}</span>
                      </li>
                    ))}
                  </ul>
                )
              }}
            />
        </PieChart>
    </ResponsiveContainer>
  )
}

export default ApprovedPendingPiechart
