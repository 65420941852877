import axios from 'axios'

const BASE_URL_DEVOPS = '/devops'

export const connectIntegrationService = async ({ header, projectId, fields }) =>
  axios
    .post(`${BASE_URL_DEVOPS}/jira/connect/${projectId}`, fields, {
      headers: {
        'Jira-Credentials': header
      }
    })
    .then(({ data }) => data)

export const updateIntegrationService = async ({ header, projectId, fields }) =>
  axios
    .put(`${BASE_URL_DEVOPS}/jira/connect/${projectId}`, fields, {
      headers: {
        'Jira-Credentials': header
      }
    })
    .then(({ data }) => data)

export const disconnectIntegrationService = async ({ projectId }) =>
  axios.delete(`${BASE_URL_DEVOPS}/jira/connect/${projectId}`).then(({ data }) => data)

export const getProjectIntegrationService = async ({ header, projectId }) =>
  axios
    .get(`${BASE_URL_DEVOPS}/jira/projects`, {
      headers: {
        'Jira-Credentials': header
      }
    })
    .then(({ data }) => data)

// Azure
export const connectAzureService = async ({ projectId }) =>
  axios.get(`${BASE_URL_DEVOPS}/azure/connect/${projectId}`).then(({ data }) => data)

export const getOrganizationAzureService = async ({ projectId }) =>
  axios.get(`${BASE_URL_DEVOPS}/azure/organizations/${projectId}`).then(({ data }) => data)

export const getProjectAzureService = async ({ projectId, organizationName }) =>
  axios.get(`${BASE_URL_DEVOPS}/azure/projects/${projectId}/${organizationName}`).then(({ data }) => data)

export const getFieldAzureService = async ({ projectId, projectName, organization }) =>
  axios.get(`${BASE_URL_DEVOPS}/azure/project/fields/${projectId}/${organization}/${projectName}`).then(({ data }) => data)

export const postConnectAzureService = async ({ projectId, body }) =>
  axios.post(`${BASE_URL_DEVOPS}/azure/connect/${projectId}`, body).then(({ data }) => data)

export const disconnectAzureService = async ({ projectId }) =>
  axios.delete(`${BASE_URL_DEVOPS}/azure/disconnect/${projectId}`).then(({ data }) => data)
