import { useEffect, useState } from 'react'

function CookieBanner () {
  const [showBanner, setShowBanner] = useState(false)

  const acceptCookies = () => {
    setShowBanner(false)
    setCookie({ name: 'acceptCookiesPulse', value: true })
    setCookie({ name: 'hidenCookiesPulse', value: true })
  }

  const declineCookies = () => {
    setShowBanner(false)
    setCookie({ name: 'acceptCookiesPulse', value: false })
    setCookie({ name: 'hidenCookiesPulse', value: true })
  }

  const setCookie = ({ name, value }) => {
    const date = new Date()
    date.setTime(date.getTime() + (10 * 365 * 24 * 60 * 60 * 1000))
    const expires = '; expires=' + date.toUTCString()
    document.cookie = name + '=' + (value || '') + expires + '; path=/'
  }

  const getCookie = ({ name }) => {
    const nameEQ = name + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  }

  useEffect(() => {
    setShowBanner(!getCookie({ name: 'hidenCookiesPulse' }))
  })

  return (
    <>
      { showBanner &&
        <div className="col-12 col-md-9 cookieBanner rounded">
          <div className="row align-items-center">
            <div className="col-12 col-md mb-3 mb-md-0">
              <p className="mb-0 small">We use cookies to enhance your experience and analyze website traffic. By clicking &apos;Accept&apos;, you agree to our use of cookies. For more details, read our <a href="/cookie-policy">Cookie Policy</a>.</p>
            </div>
            <div className="col-auto">
              <button className="btn btn-secondary" type="button" onClick={declineCookies}>Decline</button>
            </div>
            <div className="col-auto">
              <button className="btn btn-primary" type="button" onClick={acceptCookies}>Accept</button>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default CookieBanner
