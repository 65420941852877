export const OPTIONS_WELCOME_STEP = [
  { value: 'Accounting', label: 'Accounting' },
  { value: 'Airlines/Aviation', label: 'Airlines/Aviation' },
  { value: 'Alternative Dispute Resolution', label: 'Alternative Dispute Resolution' },
  { value: 'Alternative Medicine', label: 'Alternative Medicine' },
  { value: 'Animation', label: 'Animation' },
  { value: 'Apparel & Fashion', label: 'Apparel & Fashion' },
  { value: 'Architecture & Planning', label: 'Architecture & Planning' },
  { value: 'Arts & Crafts', label: 'Arts & Crafts' },
  { value: 'Automotive', label: 'Automotive' },
  { value: 'Aviation & Aerospace', label: 'Aviation & Aerospace' },
  { value: 'Banking', label: 'Banking' },
  { value: 'Biotechnology', label: 'Biotechnology' },
  { value: 'Broadcast Media', label: 'Broadcast Media' },
  { value: 'Building Materials', label: 'Building Materials' },
  { value: 'Business Supplies & Equipment', label: 'Business Supplies & Equipment' },
  { value: 'Capital Markets', label: 'Capital Markets' },
  { value: 'Chemicals', label: 'Chemicals' },
  { value: 'Civic & Social Organization', label: 'Civic & Social Organization' },
  { value: 'Civil Engineering', label: 'Civil Engineering' },
  { value: 'Commercial Real Estate', label: 'Commercial Real Estate' },
  { value: 'Computer & Network Security', label: 'Computer & Network Security' },
  { value: 'Computer Games', label: 'Computer Games' },
  { value: 'Computer Hardware', label: 'Computer Hardware' },
  { value: 'Computer Networking', label: 'Computer Networking' },
  { value: 'Computer Software', label: 'Computer Software' },
  { value: 'Construction', label: 'Construction' },
  { value: 'Consumer Electronics', label: 'Consumer Electronics' },
  { value: 'Consumer Goods', label: 'Consumer Goods' },
  { value: 'Consumer Services', label: 'Consumer Services' },
  { value: 'Cosmetics', label: 'Cosmetics' },
  { value: 'Dairy', label: 'Dairy' },
  { value: 'Defense & Space', label: 'Defense & Space' },
  { value: 'Design', label: 'Design' },
  { value: 'Education Management', label: 'Education Management' },
  { value: 'E-learning', label: 'E-learning' },
  { value: 'Electrical & Electronic Manufacturing', label: 'Electrical & Electronic Manufacturing' },
  { value: 'Entertainment', label: 'Entertainment' },
  { value: 'Environmental Services', label: 'Environmental Services' },
  { value: 'Events Services', label: 'Events Services' },
  { value: 'Executive Office', label: 'Executive Office' },
  { value: 'Facilities Services', label: 'Facilities Services' },
  { value: 'Farming', label: 'Farming' },
  { value: 'Financial Services', label: 'Financial Services' },
  { value: 'Fine Art', label: 'Fine Art' },
  { value: 'Fishery', label: 'Fishery' },
  { value: 'Food & Beverages', label: 'Food & Beverages' },
  { value: 'Food Production', label: 'Food Production' },
  { value: 'Fundraising', label: 'Fundraising' },
  { value: 'Furniture', label: 'Furniture' },
  { value: 'Gambling & Casinos', label: 'Gambling & Casinos' },
  { value: 'Glass, Ceramics & Concrete', label: 'Glass, Ceramics & Concrete' },
  { value: 'Government Administration', label: 'Government Administration' },
  { value: 'Government Relations', label: 'Government Relations' },
  { value: 'Graphic Design', label: 'Graphic Design' },
  { value: 'Health, Wellness & Fitness', label: 'Health, Wellness & Fitness' },
  { value: 'Higher Education', label: 'Higher Education' },
  { value: 'Hospital & Health Care', label: 'Hospital & Health Care' },
  { value: 'Hospitality', label: 'Hospitality' },
  { value: 'Human Resources', label: 'Human Resources' },
  { value: 'Import & Export', label: 'Import & Export' },
  { value: 'Individual & Family Services', label: 'Individual & Family Services' },
  { value: 'Industrial Automation', label: 'Industrial Automation' },
  { value: 'Information Services', label: 'Information Services' },
  { value: 'Information Technology & Services', label: 'Information Technology & Services' },
  { value: 'Insurance', label: 'Insurance' },
  { value: 'International Affairs', label: 'International Affairs' },
  { value: 'International Trade & Development', label: 'International Trade & Development' },
  { value: 'Internet', label: 'Internet' },
  { value: 'Investment Banking/Venture', label: 'Investment Banking/Venture' },
  { value: 'Investment Management', label: 'Investment Management' },
  { value: 'Judiciary', label: 'Judiciary' },
  { value: 'Law Enforcement', label: 'Law Enforcement' },
  { value: 'Law Practice', label: 'Law Practice' },
  { value: 'Legal Services', label: 'Legal Services' },
  { value: 'Legislative Office', label: 'Legislative Office' },
  { value: 'Leisure & Travel', label: 'Leisure & Travel' },
  { value: 'Libraries', label: 'Libraries' },
  { value: 'Logistics & Supply Chain', label: 'Logistics & Supply Chain' },
  { value: 'Luxury Goods & Jewelry', label: 'Luxury Goods & Jewelry' },
  { value: 'Machinery', label: 'Machinery' },
  { value: 'Management Consulting', label: 'Management Consulting' },
  { value: 'Maritime', label: 'Maritime' },
  { value: 'Marketing & Advertising', label: 'Marketing & Advertising' },
  { value: 'Market Research', label: 'Market Research' },
  { value: 'Mechanical or Industrial Engineering', label: 'Mechanical or Industrial Engineering' },
  { value: 'Media Production', label: 'Media Production' },
  { value: 'Medical Device', label: 'Medical Device' },
  { value: 'Medical Practice', label: 'Medical Practice' },
  { value: 'Mental Health Care', label: 'Mental Health Care' },
  { value: 'Military', label: 'Military' },
  { value: 'Mining & Metals', label: 'Mining & Metals' },
  { value: 'Motion Pictures & Film', label: 'Motion Pictures & Film' },
  { value: 'Museums & Institutions', label: 'Museums & Institutions' },
  { value: 'Music', label: 'Music' },
  { value: 'Nanotechnology', label: 'Nanotechnology' },
  { value: 'Newspapers', label: 'Newspapers' },
  { value: 'Nonprofit Organization Management', label: 'Nonprofit Organization Management' },
  { value: 'Oil & Energy', label: 'Oil & Energy' },
  { value: 'Online Publishing', label: 'Online Publishing' },
  { value: 'Outsourcing/Offshoring', label: 'Outsourcing/Offshoring' },
  { value: 'Package/Freight Delivery', label: 'Package/Freight Delivery' },
  { value: 'Packaging & Containers', label: 'Packaging & Containers' },
  { value: 'Paper & Forest Products', label: 'Paper & Forest Products' },
  { value: 'Performing Arts', label: 'Performing Arts' },
  { value: 'Pharmaceuticals', label: 'Pharmaceuticals' },
  { value: 'Philanthropy', label: 'Philanthropy' },
  { value: 'Photography', label: 'Photography' },
  { value: 'Plastics', label: 'Plastics' },
  { value: 'Political Organization', label: 'Political Organization' },
  { value: 'Primary/Secondary', label: 'Primary/Secondary' },
  { value: 'Printing', label: 'Printing' },
  { value: 'Professional Training', label: 'Professional Training' },
  { label: 'Program Development', value: 'Program Development' },
  { label: 'Public Policy', value: 'Public Policy' },
  { label: 'Public Relations', value: 'Public Relations' },
  { label: 'Public Safety', value: 'Public Safety' },
  { label: 'Publishing', value: 'Publishing' },
  { label: 'Railroad Manufacture', value: 'Railroad Manufacture' },
  { label: 'Ranching', value: 'Ranching' },
  { label: 'Real Estate', value: 'Real Estate' },
  { label: 'Recreational', value: 'Recreational' },
  { label: 'Facilities & Services', value: 'Facilities & Services' },
  { label: 'Religious Institutions', value: 'Religious Institutions' },
  { label: 'Renewables & Environment', value: 'Renewables & Environment' },
  { label: 'Research', value: 'Research' },
  { label: 'Restaurants', value: 'Restaurants' },
  { label: 'Retail', value: 'Retail' },
  { label: 'Security & Investigations', value: 'Security & Investigations' },
  { label: 'Semiconductors', value: 'Semiconductors' },
  { label: 'Shipbuilding', value: 'Shipbuilding' },
  { label: 'Sporting Goods', value: 'Sporting Goods' },
  { label: 'Sports', value: 'Sports' },
  { label: 'Staffing & Recruiting', value: 'Staffing & Recruiting' },
  { label: 'Supermarkets', value: 'Supermarkets' },
  { label: 'Telecommunications', value: 'Telecommunications' },
  { label: 'Textiles', value: 'Textiles' },
  { label: 'Think Tanks', value: 'Think Tanks' },
  { label: 'Tobacco', value: 'Tobacco' },
  { label: 'Translation & Localization', value: 'Translation & Localization' },
  { label: 'Transportation/Trucking/Railroad', value: 'Transportation/Trucking/Railroad' },
  { label: 'Utilities', value: 'Utilities' },
  { label: 'Venture Capital', value: 'Venture Capital' },
  { label: 'Veterinary', value: 'Veterinary' },
  { label: 'Warehousing', value: 'Warehousing' },
  { label: 'Wholesale', value: 'Wholesale' },
  { label: 'Wine & Spirits', value: 'Wine & Spirits' },
  { label: 'Wireless', value: 'Wireless' },
  { label: 'Writing & Editing', value: 'Writing & Editing' }
]
