import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useModal, usePayment } from 'Hooks'
import { CreditCard } from '@phosphor-icons/react'
import { COLOR_BLACK, COLOR_WHITE, GET_COLOR_BY_THEME } from 'constant/colors'
import useSessionStore from 'Context/useSessionStore'
import { useState } from 'react'
import { ModalSuccess } from 'Organisms/ModalSuccess'
import { Loading } from 'Organisms/Loading'

const ModalNewPaymentCard = ({ onClose, visible, onSuccess, onGoBack }) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({ open: false })

  const { user } = useSessionStore((state) => ({ user: state.user }))
  const stripe = useStripe()
  const elements = useElements()

  const { Modal, hide } = useModal({ identifier: 'modalFilter', visible, setVisible: onClose })

  const { addPaymentMethodHook } = usePayment()

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '17px',
        color: user.theme ? COLOR_WHITE : COLOR_BLACK
      }
    }
  }

  const onSubmit = async (data) => {
    try {
      data.preventDefault()
      if (!stripe) return console.error('stripe')

      setLoading(true)

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement)
      })

      if (error) {
        return setMessage({
          open: true,
          error: true,
          message: 'An error occurred while saving your card.'
        })
      }

      const res = await addPaymentMethodHook({ payment_method_id: paymentMethod.id })

      if (!res.success) {
        return setMessage({
          open: true,
          error: true,
          message: 'An error occurred while saving your card.'
        })
      }

      hide()
      onSuccess()
    } catch (err) {
      console.error(err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal>
      <div className="modal-content modalSuccess p-5" style={{ maxWidth: 400 }}>
        {loading && <Loading />}
        <div className="d-flex justify-content-center mb-3">
          <CreditCard size={80} color={GET_COLOR_BY_THEME({ theme: user.theme }).PRIMARY} />
        </div>
        <form onSubmit={onSubmit} className="row gy-4">
          <div className="col-12">
            <label htmlFor="cardNumber">Card Number</label>
            <CardNumberElement className="form-control p-3" options={cardElementOptions} />
          </div>

          <div className="col-6">
            <label htmlFor="cardExp">Expiration Date</label>
            <CardExpiryElement className="form-control p-3" options={cardElementOptions} />
          </div>

          <div className="col-6">
            <label htmlFor="cardCvc">Security code</label>
            <CardCvcElement className="form-control p-3" options={cardElementOptions} />
          </div>

          <div className="col-12 mt-4">
            <div className="row justify-content-center">
              <div className="col-auto">
                <button
                  type="button"
                  className="btn btn-secondary mr-2"
                  onClick={() => {
                    hide()
                    onGoBack()
                  }}>
                  Cancel
                </button>
              </div>
              <div className="col-auto">
                <button type="submit" disabled={!stripe} className="btn btn-primary">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>

        {message.open && (
          <ModalSuccess
            visible={true}
            setVisible={() => setMessage({ open: false })}
            title={message.message}
            identifier="modalMessage"
            error={message.error}
          />
        )}
      </div>
    </Modal>
  )
}

export default ModalNewPaymentCard
