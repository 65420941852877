export const DAYS_OF_WEEK = {
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday'
}

export const DAYS_INFO = [
  {
    name: 'Sat',
    weekNumber: 0,
    day: DAYS_OF_WEEK.SATURDAY
  },
  {
    name: 'Sun',
    weekNumber: 1,
    day: DAYS_OF_WEEK.SUNDAY
  },
  {
    name: 'Mon',
    weekNumber: 2,
    day: DAYS_OF_WEEK.MONDAY
  },
  {
    name: 'Tue',
    weekNumber: 3,
    day: DAYS_OF_WEEK.TUESDAY
  },
  {
    name: 'Wed',
    weekNumber: 4,
    day: DAYS_OF_WEEK.WEDNESDAY
  },
  {
    name: 'Thu',
    weekNumber: 5,
    day: DAYS_OF_WEEK.THURSDAY
  },
  {
    name: 'Fri',
    weekNumber: 6,
    day: DAYS_OF_WEEK.FRIDAY
  }
]

export const STATUS_SUBMIT_HOURS = {
  PENDING: 'pending approval',
  REJECTED: 'rejected',
  APPROVED: 'approved',
  UNSUBMITTED: 'unsubmitted'
}

export const MINUTE_IN_DECIMAL = 0.0166667

export const ONBOARDING_STEPS_TRACKER = [
  {
    target: 'date_picker_tracker',
    title: 'Choose a date',
    text: 'Pick the date to log your time.',
    imgUrl: 'https://d3m37vrpuudhl6.cloudfront.net/onboarding/choose_a_date.gif',
    offsetX: 430,
    offsetY: -100
  },
  {
    target: 'add_tracker',
    title: 'Create a Time Entry',
    text: 'Start a new time entry by clicking the "Start Timer" button.',
    imgUrl: 'https://d3m37vrpuudhl6.cloudfront.net/onboarding/start_time.gif',
    offsetX: 250,
    offsetY: 50
  },
  {
    target: 'submitHoursTrackerBtn_dropdown',
    title: 'Submit your day or week for approval',
    text: 'Toggle between each option using the small arrow on the right.',
    imgUrl: 'https://d3m37vrpuudhl6.cloudfront.net/onboarding/submit_time.gif',
    offsetX: 430,
    offsetY: 100
  }
]

export const PARAMS_URL_TRACKER = {
  CURRENT_DATE: 'cdt',
  DATE: 'date',
  IS_TRACKER: 'is_tracker'
}
