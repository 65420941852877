import { useModal } from 'Hooks'
import { CheckCircle } from '@phosphor-icons/react'
import useSessionStore from 'Context/useSessionStore'
import { GET_COLOR_BY_THEME } from 'constant/colors'
import { useNavigate } from 'react-router-dom'
import { SETTINGS_ROUTER } from 'constant/router'
import { TABS_OPTIONS_SETTINGS } from 'Pages/SettingPage/Components/TabsSettings/TabsSettings'
import { RocketIcon } from 'Organisms/Svg'

const SuggestionPopupToUpgradePlan = ({ visible, setVisible }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))
  const navigate = useNavigate()

  const { Modal, hide } = useModal({ identifier: 'suggestionPopup', visible, setVisible })

  return (
    <Modal>
      <div className="modal-content modalSuccess suggestionPopupUpgradePlan rounded-20 p-5">
        <div className="row gx-5 gy-5">
          <div className="col-lg-3 col-12 d-flex align-items-center justify-content-center">
            <RocketIcon color={GET_COLOR_BY_THEME({ theme: user.theme }).PRIMARY} />
          </div>
          <div className="col-lg-9 col-12">
            <h2 className="fs-3">Switch to the professional plan!</h2>
            <p className="text-normal fs-5">
              Enhance your experience by using Pulse Professional, unlock extra features:
            </p>

            <div className="row gy-3 mt-4">
              <div className="col-12 d-flex align-items-center">
                <span>
                  <CheckCircle size={32} weight="bold" color={GET_COLOR_BY_THEME({ theme: user.theme }).PRIMARY} />
                </span>
                <p className="mb-0 ms-3 fs-5 text-normal">Limit user&apos;s access to projects</p>
              </div>
              <div className="col-12 d-flex align-items-center">
                <span>
                  <CheckCircle size={32} weight="bold" color={GET_COLOR_BY_THEME({ theme: user.theme }).PRIMARY} />
                </span>
                <p className="mb-0 ms-3 fs-5 text-normal">Jira / Azure DevOps Integrations</p>
              </div>
              <div className="col-12 d-flex align-items-center">
                <span>
                  <CheckCircle size={32} weight="bold" color={GET_COLOR_BY_THEME({ theme: user.theme }).PRIMARY} />
                </span>
                <p className="mb-0 ms-3 fs-5 text-normal">Project budgets</p>
              </div>
              <div className="col-12 d-flex align-items-center">
                <span>
                  <CheckCircle size={32} weight="bold" color={GET_COLOR_BY_THEME({ theme: user.theme }).PRIMARY} />
                </span>
                <p className="mb-0 ms-3 fs-5 text-normal">Forecast</p>
              </div>
              <div className="col-12 d-flex align-items-center">
                <span>
                  <CheckCircle size={32} weight="bold" color={GET_COLOR_BY_THEME({ theme: user.theme }).PRIMARY} />
                </span>
                <p className="mb-0 ms-3 fs-5 text-normal">Proforma invoice</p>
              </div>
            </div>

            <div className="d-sm-flex d-grid justify-content-sm-between justify-content-center mt-5">
              <button type="button" className="btn btn-secondary" onClick={hide}>
                Maybe Later
              </button>
              <button
                type="button"
                className="mt-sm-0 mt-3 btn btn-primary"
                onClick={() => {
                  hide()
                  navigate(`${SETTINGS_ROUTER}?section=${TABS_OPTIONS_SETTINGS.BILLING.key}`)
                }}>
                Try it now
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SuggestionPopupToUpgradePlan
