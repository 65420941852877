import { formatSendPagination } from 'adapters/pagination'
import { useState } from 'react'
import {
  addTaskToProjectService,
  addUserToProjectService,
  createProject,
  deleteTaskToProjectService,
  deleteUserToProjectService,
  getMetricsGeneralByProjectService,
  getMetricsTaskHoursByProjectService,
  getMetricsUserHoursByProjectService,
  getProject,
  getProjectsServices,
  updateProjectService,
  updateTaskToProjectService
} from 'Services'
import { createQueryString } from 'Utils/helperFunctions'

export default function useProject () {
  const [loadingProjectHook, setLoadingProjectHook] = useState(false)
  const [projectInfoHook, setProjectInfoHook] = useState({})
  const [projectsHook, setProjectsHook] = useState([])
  const [countProjectHook, setCountProjectHook] = useState(0)
  const [metricsUserHoursByProject, setMetricsUserHoursByProject] = useState([])
  const [metricsTaskHoursByProject, setMetricsTaskHoursByProject] = useState([])
  const [metricsGeneralByProject, setMetricsGeneralByProject] = useState(null)

  const getProjectByIdHook = async ({ idProject }) => {
    try {
      setLoadingProjectHook(true)
      const data = await getProject(idProject)
      const returnData = {
        ...(data?.data && {
          ...data?.data,
          user_projects: data?.data?.user_projects.map((item) => item.user)
        })
      }
      setProjectInfoHook(returnData)
      return returnData
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingProjectHook(false)
    }
  }

  const getProjectsHook = async ({ search = '', page = 1, limit = 10, is_active = true, userId } = {}) => {
    try {
      setLoadingProjectHook(true)
      const query = createQueryString({
        search,
        is_active,
        user_id: userId,
        pagination: formatSendPagination({ page, limit })
      })
      const data = await getProjectsServices(query)
      setProjectsHook(data?.data)
      setCountProjectHook(Number(data.count) || 0)
      return data?.data
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingProjectHook(false)
    }
  }

  const createProjectHook = async ({ body }) => {
    try {
      setLoadingProjectHook(true)
      await createProject(body)
      return {
        success: true
      }
    } catch (e) {
      const message = e?.response?.data?.error?.options?.description || ''

      return {
        success: false,
        error: true,
        message
      }
    } finally {
      setLoadingProjectHook(false)
    }
  }
  const addUserToProjectHook = async ({ listUser, idProject }) => {
    try {
      setLoadingProjectHook(true)
      const add = await addUserToProjectService({ listUser, idProject })
      return {
        success: true,
        data: add
      }
    } catch (e) {
      let errorMessage = ''

      if (
        e?.response?.data?.error?.options?.description ===
        'You cannot assign more that one Product Owner in same project'
      ) {
        errorMessage = e?.response?.data?.error?.options?.description
      }

      return {
        success: false,
        message: errorMessage || e?.response?.data?.message
      }
    } finally {
      setLoadingProjectHook(false)
    }
  }

  const addTaskToProjectHook = async ({ idProject, listIdTask }) => {
    try {
      setLoadingProjectHook(true)
      await addTaskToProjectService({ listIdTask, idProject })
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingProjectHook(false)
    }
  }

  const addBudgetToTaskPerProjectHook = async ({ idProject, idTask, newBudget }) => {
    try {
      setLoadingProjectHook(true)
      await updateTaskToProjectService({ idProject, idTask, newBudget })
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingProjectHook(false)
    }
  }

  const updateProjectHook = async ({ body, projectId }) => {
    try {
      setLoadingProjectHook(true)
      await updateProjectService(body, projectId)
      return {
        success: true
      }
    } catch (e) {
      const message = e?.response?.data?.error?.options?.description || ''

      return {
        success: false,
        error: true,
        message
      }
    } finally {
      setLoadingProjectHook(false)
    }
  }

  const updateTaskToProjectHook = async ({ idTask, newBillableRate, idProject, newBudget }) => {
    try {
      setLoadingProjectHook(true)
      await updateTaskToProjectService({ idTask, newBillableRate, idProject, newBudget })
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingProjectHook(false)
    }
  }

  const deleteUserToProjectHook = async ({ userId, idProject }) => {
    try {
      setLoadingProjectHook(true)
      await deleteUserToProjectService({ userId, idProject })
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingProjectHook(false)
    }
  }

  const deleteTaskToProjectHook = async ({ idTask, idProject }) => {
    try {
      setLoadingProjectHook(true)
      await deleteTaskToProjectService({ idTask, idProject })
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingProjectHook(false)
    }
  }

  const getMetricsUserHoursByProjectHook = async ({ idProject, startDate, endDate }) => {
    try {
      setLoadingProjectHook(true)
      const query = createQueryString({
        date: startDate && endDate ? `${startDate}:${endDate}` : ''
      })
      const result = await getMetricsUserHoursByProjectService({ queryParams: query, idProject })
      setMetricsUserHoursByProject(result?.data || [])
      return result?.data || []
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingProjectHook(false)
    }
  }

  const getMetricsTaskHoursByProjectHook = async ({ idProject, startDate, endDate }) => {
    try {
      setLoadingProjectHook(true)
      const query = createQueryString({
        date: startDate && endDate ? `${startDate}:${endDate}` : ''
      })
      const result = await getMetricsTaskHoursByProjectService({ queryParams: query, idProject })
      setMetricsTaskHoursByProject(result?.data || [])
      return result?.data || []
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingProjectHook(false)
    }
  }

  const getMetricsGeneralByProjectHook = async ({ idProject, startDate, endDate }) => {
    try {
      setLoadingProjectHook(true)
      const query = createQueryString({
        date: startDate && endDate ? `${startDate}:${endDate}` : ''
      })
      const result = await getMetricsGeneralByProjectService({ queryParams: query, idProject })
      setMetricsGeneralByProject(result.data)
      return result?.data || []
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingProjectHook(false)
    }
  }

  // helpers
  const getAllProjectsByFilterHook = async () => {
    try {
      const projects = await getProjectsHook({ limit: 1000 })
      return projects.map((item) => ({
        id: item.id,
        value: item.code,
        valueId: item.id,
        text: item.name,
        checked: false
      }))
    } catch (e) {
      console.error(e)
      return []
    }
  }

  return {
    loadingProjectHook,
    projectInfoHook,
    projectsHook,
    countProjectHook,
    metricsUserHoursByProject,
    metricsTaskHoursByProject,
    metricsGeneralByProject,
    getProjectByIdHook,
    getProjectsHook,
    addUserToProjectHook,
    addTaskToProjectHook,
    addBudgetToTaskPerProjectHook,
    updateTaskToProjectHook,
    updateProjectHook,
    deleteUserToProjectHook,
    deleteTaskToProjectHook,
    getAllProjectsByFilterHook,
    createProjectHook,
    getMetricsUserHoursByProjectHook,
    getMetricsTaskHoursByProjectHook,
    getMetricsGeneralByProjectHook
  }
}
