import { useModal } from 'Hooks'
import { XCircle } from '@phosphor-icons/react'
import { COLOR_RED } from 'constant/colors'
import { DateTime } from 'luxon'

const CancelSubscriptionModal = ({ onClose, onConfirm, expire_plan }) => {
  const { Modal, hide } = useModal({ identifier: 'cancelSubscriptionModal', visible: true, setVisible: onClose })

  const handleSubmit = async () => {
    await onConfirm()
    hide()
  }

  return (
    <Modal center>
      <div className="modal-content modalSuccess p-5" style={{ maxWidth: 500 }}>
        <button type="button" className="btn-close btnCloseModal" onClick={hide} aria-label="Close" />
        <h3 className="fs-3 text-center mb-4">
          You&apos;re about to cancel <br />
          your subscription
        </h3>
        <p className="mb-3">
          Once your subscription expires on{' '}
          <span className="text-active">{DateTime.fromISO(expire_plan).setZone('local').toFormat('LLL dd, yyyy')}</span>, you&apos;ll
          lose access to all your Professional features.
        </p>
        <hr />
        <ul className="list-unstyled mt-3">
          <li className="mb-2">
            <XCircle size={28} weight="bold" color={COLOR_RED} className="me-1" /> Unlimited projects
          </li>
          <li className="mb-2">
            <XCircle size={28} weight="bold" color={COLOR_RED} className="me-1" /> Limit user&apos;s access to projects
          </li>
          <li>
            <XCircle size={28} weight="bold" color={COLOR_RED} className="me-1" /> Jira / Azure DevOps Integrations
          </li>
        </ul>
        <hr />
        <div className="row justify-content-center mt-5">
          <div className="col-auto">
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={() => {
                hide()
              }}>
              Stay subscribed
            </button>
          </div>
          <div className="col-auto">
            <button type="submit" onClick={handleSubmit} className="btn btn-danger">
              Cancel my subscription
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CancelSubscriptionModal
