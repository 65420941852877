import Logo from './Logo'
import DribbleSvg from '../Assets/images/landing/Dribble.svg'
import FacebookSvg from '../Assets/images/landing/Facebook.svg'
import InstagramSvg from '../Assets/images/landing/Instagram.svg'
import LinkedinSvg from '../Assets/images/landing/Linkedin.svg'
import MediumSvg from '../Assets/images/landing/Medium.svg'

const Footer = () => {
  return (
        <footer className="row landing_footer_bg">
          <div className="containers">
            <div className="landing_footer">

              <div className="landing_footer_options">
                <div className="landing_footer_option">
                  <Logo style="icon_logo landing_logo" />
                  <p className='mt-3 small'>Empowering productivity <br/>across remote teams</p>
                </div>

                <div className="landing_footer_option landing_footer_links">
                  <h3>Product</h3>
                  <a href="/pricing">Pricing</a>
                  <a href="https://cannedhead.notion.site/Pulse-Roadmap-5f5613f4e1374210a48d3a28db3cb01a" target="_blank" rel="noreferrer">Public Roadmap</a>
                </div>

                <div className="landing_footer_option landing_footer_links">
                  <h3>Resources</h3>
                  <a href="https://docs.cannedhead.com/" target='_blank' rel="noreferrer">Documentation</a>
                  <a href="/design-system">Design System</a>
                </div>

                <div className="landing_footer_option landing_footer_links">
                  <h3>Company</h3>
                  <a href="/contact-us">Contact Us</a>
                  <a href="/become-a-partner">Become a partner</a>
                </div>
              </div>

              <div className="landing_social">
                <img src={FacebookSvg} />
                <img src={InstagramSvg} />
                <img src={LinkedinSvg} />
                <img src={DribbleSvg} />
                <img src={MediumSvg} />
              </div>

              <div className="landing_divider" />

              <div className="landing_copy">
                <p>&copy; 2024 Canned Head Inc. All rights reserved</p>

                <div className="landing_copy_links">
                  <a href="/privacy-policy">Privacy Policy</a>
                  <a href="/terms-of-service">Terms of Service</a>
                  <a href="/cookie-policy">Cookie Policy</a>
                </div>
              </div>
            </div>
          </div>
        </footer>
  )
}

export default Footer
