import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useLocation, NavLink } from 'react-router-dom'
import Avatar from 'react-avatar'
import { CaretDown, Moon, GearSix, SignOut, List, Sun, Question, ArrowSquareUpRight } from '@phosphor-icons/react'

// Components
import { Logo } from './'
import { Loading } from './Loading'

// Hooks
import { signOut } from '../Hooks/useAuth'
import { useUser } from 'Hooks'

// Context
import useSessionStore from '../Context/useSessionStore'

// Helpers and const
import { getRole } from 'Utils/helperFunctions'
import { SIDEBAR_LIST } from 'constant/sidebar'
import { PUBLIC_SIDEBAR_LIST } from 'constant/public_sidebar'
import { Mixpanel } from 'Utils/Mixpanel'
import { LANDING_PAGE_ROUTER, LOGIN_ROUTER, SETTINGS_ROUTER, SIGNUP_ROUTER } from 'constant/router'
import { COLOR_CONTRACT_BLACK, GET_COLOR_BY_THEME } from 'constant/colors'
import { CURRENT_USER_STORAGE } from 'Utils/const/localstorage'
import { ROLES_DISPLAY } from 'constant/roles'
import ContextTutorial from 'Context/tutorialContext'
import { BREAKPOINT } from 'Utils/const/breakPoints'

const Navbar = ({ theme, hideNavbar }) => {
  const { user, updateUser } = useSessionStore((state) => ({ user: state.user, updateUser: state.updateUser }))
  const btnMenu = useRef(null)
  const { openTutorialContext, handleOpenTutorialContext } = useContext(ContextTutorial)

  const [loading, setLoading] = useState(false)

  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
    if (openTutorialContext) handleOpenTutorialContext()
  }, [location.pathname])

  const { loadingUserHook, updateUserHook } = useUser()

  const signOutCurrentUser = async () => {
    try {
      setLoading(true)
      await signOut()
      Mixpanel.reset()
      localStorage.removeItem(CURRENT_USER_STORAGE)
      updateUser(null)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const handleChageDarkMode = async ({ darkMode }) => {
    await updateUserHook({ userId: user.id, body: { theme: darkMode } })
    updateUser({ ...user, theme: darkMode })
  }

  const handleToggleMenu = () => {
    document.documentElement.clientWidth < BREAKPOINT.LAPTOP.substring(0, BREAKPOINT.LAPTOP.length - 2) && btnMenu.current.click()
  }

  return (
    !hideNavbar.includes(location.pathname) && (
      <div className={theme}>
        <nav className={`navbar navbar-expand-lg background_primary ${!user ? 'condense' : null}`}>
          {(loadingUserHook || loading) && <Loading />}

          <div className="containers wAll">
            <div className="navbarCustom">
              <Link to={LANDING_PAGE_ROUTER} className="navbar-brand">
                <Logo style="icon_logo" />
              </Link>

              {!user && (
                <>
                  <Link className="btn btn-ghost btn-sm d-md-none" to={LOGIN_ROUTER}>
                    Login
                  </Link>
                  <Link className="btn btn-primary btn-sm d-md-none" to={SIGNUP_ROUTER}>
                    Sign Up
                  </Link>
                </>
              )}

              <button
                ref={btnMenu}
                className="btn btn-ghost p-0 d-md-none"
                data-bs-toggle="collapse"
                aria-expanded="false"
                type="button"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-label="Toggle navigation">
                <List size={30} color={GET_COLOR_BY_THEME({ theme }).MENU_NAVBAR} />
              </button>

              {user && (
                <>
                  <div className="collapse navbar-collapse background_primary" id="navbarSupportedContent">
                    <div className="d-grid d-lg-none card p-3 navbar_user_info_mobile">
                      <Avatar
                        name={`${user.firstname} ${user.lastname}`}
                        src={user?.logo || null}
                        className="avatarImage"
                        color={GET_COLOR_BY_THEME({ theme }).AVATAR}
                        fgColor={GET_COLOR_BY_THEME({ theme }).AVATAR_TEXT}
                        round={true}
                        size="45px"
                        textSizeRatio={3}
                      />
                      <div className="d-grid navbar_user_info_mobile_texts">
                        <h6 className="m-0">
                          {user?.firstname || ''} {user?.lastname || ''}
                        </h6>
                        <p className="m-0">{ROLES_DISPLAY[user?.role] || ''}</p>
                        <span className="m-0">{user?.email || ''}</span>
                      </div>
                    </div>

                    <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                      {SIDEBAR_LIST.map((sidebar) => {
                        if (!sidebar.scope.includes(user.role)) return <React.Fragment key={sidebar.name} />

                        return (
                          <li key={sidebar.name} className="nav-item mx-md-3" onClick={handleToggleMenu}>
                            <NavLink
                              to={sidebar.to}
                              className={({ isActive }) =>
                                `nav-link ${isActive ? 'text-primary active' : 'text-normal'}`
                              }>
                              {sidebar.name}
                            </NavLink>
                          </li>
                        )
                      })}

                      <hr />

                      <li className="d-grid d-lg-none nav-item mx-md-3" onClick={handleToggleMenu}>
                        <NavLink
                          className={({ isActive }) => `nav-link ${isActive ? 'text-primary active' : 'text-normal'}`}
                          to={SETTINGS_ROUTER}>
                          <GearSix size={18} className="me-2 icon_logo" weight="bold" />
                          Settings
                        </NavLink>
                      </li>

                      <li className="d-grid d-lg-none nav-item dropdown">
                        <a
                          className="nav-link text-normal"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <Question size={20} weight="bold" color={GET_COLOR_BY_THEME({ theme }).MENU_NAVBAR} />{' '}
                          <span className="ms-1 me-2">Help</span>
                          <CaretDown
                            style={{ position: 'relative', top: '-1px' }}
                            size={16}
                            weight="bold"
                            color={GET_COLOR_BY_THEME({ theme }).MENU_NAVBAR}
                          />
                        </a>
                        <ul className="dropdown-menu">
                          <li className="py-2">
                            <a
                              className="dropdown-item"
                              href="https://docs.cannedhead.com"
                              target="_blank"
                              rel="noreferrer">
                              Documentation <ArrowSquareUpRight size={20} color="#fff" />
                            </a>
                          </li>
                          <li className="py-2">
                            <a className="dropdown-item" href="/support">
                              Support & Feedback
                            </a>
                          </li>
                        </ul>
                      </li>

                      <li className="d-grid d-lg-none d-flex justify-content-between nav-item mx-md-3 nav-link">
                        <span
                          className="d-flex align-items-center text-normal"
                          onClick={() => handleChageDarkMode({ darkMode: !user.theme })}>
                          {user.theme
                            ? (
                            <Sun size={18} className="me-2 icon_logo" weight="bold" />
                              )
                            : (
                            <Moon size={18} className="me-2 icon_logo" weight="bold" />
                              )}
                          Switch to {user.theme ? 'light' : 'dark'} mode
                        </span>
                      </li>

                      <li className="d-grid d-lg-none nav-item mx-md-3">
                        <p className="nav-link nav-logout text-normal" onClick={signOutCurrentUser}>
                          <SignOut className="me-2 icon_logo" size={18} weight="bold" />
                          Log out
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="dropdown me-3 d-none d-md-flex">
                    <button className="btn btn-ghost" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <Question size={24} color={GET_COLOR_BY_THEME({ theme }).MENU_NAVBAR} />
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <button type="button" onClick={handleOpenTutorialContext} className="dropdown-item">
                          How does this page work?
                        </button>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="https://docs.cannedhead.com"
                          target="_blank"
                          rel="noreferrer">
                          Documentation <ArrowSquareUpRight size={20} color="#fff" />
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/support">
                          Support & Feedback
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="d-none d-lg-grid nav-item dropdown dropdown-center">
                    <a className="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <Avatar
                        name={`${user.firstname} ${user.lastname}`}
                        src={user?.logo || null}
                        className="avatarImage"
                        color={GET_COLOR_BY_THEME({ theme }).AVATAR}
                        fgColor={GET_COLOR_BY_THEME({ theme }).AVATAR_TEXT}
                        round={true}
                        size="38px"
                        textSizeRatio={3}
                      />
                    </a>
                    <ul className="dropdown-menu">
                      {/* Basic info */}
                      <li className="px-2 py-2 mb-3">
                        <span className="row justify-content-center">
                          <span className="col-auto p-0">
                            <Avatar
                              name={`${user.firstname} ${user.lastname}`}
                              className="avatarImage"
                              src={user?.logo || null}
                              color={GET_COLOR_BY_THEME({ theme }).AVATAR}
                              fgColor={GET_COLOR_BY_THEME({ theme }).AVATAR_TEXT}
                              round={true}
                              size="70px"
                              textSizeRatio={3}
                            />
                          </span>
                        </span>
                        <span className="row justify-content-center my-1">
                          <span className="col-auto">
                            <span className="badge text-bg-secondary">{getRole(user.role)}</span>
                          </span>
                        </span>
                        <span className="row justify-content-center">
                          <span className="col-auto">
                            {user.firstname} {user.lastname}
                          </span>
                        </span>
                        <span className="row justify-content-center">
                          <span className="col-auto form-text text-terciary">{user.email}</span>
                        </span>
                      </li>

                      {/* Settings link */}
                      <li>
                        <Link className="dropdown-item d-flex align-items-center pt-2 pb-2" to={SETTINGS_ROUTER}>
                          <GearSix size={18} className="me-2" color={COLOR_CONTRACT_BLACK} weight="bold" />
                          Settings
                        </Link>
                      </li>

                      {/* Dark mode switch */}
                      <li className="d-flex justify-content-between dropdown-item pt-2 pb-2">
                        <span className="d-flex align-items-center">
                          <Moon size={18} className="me-2" color={COLOR_CONTRACT_BLACK} weight="bold" />
                          Dark mode
                        </span>
                        <div className="form-check form-switch ml-3">
                          <input
                            className="form-check-input switch_both"
                            type="checkbox"
                            role="switch"
                            defaultChecked={user.theme}
                            onChange={(e) => {
                              handleChageDarkMode({ darkMode: e.target.checked })
                            }}
                          />
                        </div>
                      </li>

                      {/* Log out link */}
                      <li>
                        <Link
                          className="dropdown-item d-flex align-items-center pt-2 pb-2"
                          onClick={signOutCurrentUser}>
                          <SignOut className="me-2" size={18} color={COLOR_CONTRACT_BLACK} weight="bold" />
                          Log out
                        </Link>
                      </li>
                    </ul>
                  </div>
                </>
              )}

              {!user && (
                <div className="collapse navbar-collapse background_primary" id="navbarSupportedContent">
                  <ul className="navbar-nav mx-auto mb-2 mb-lg-0 ps-md-5">
                    {PUBLIC_SIDEBAR_LIST.map((sidebar) => {
                      return (
                        <li key={sidebar.name} className="nav-item mx-md-3">
                          <NavLink
                            to={sidebar.to}
                            className={({ isActive }) =>
                              `nav-link ${isActive ? 'text-primary active' : 'text-normal'}`
                            }>
                            {sidebar.name}
                          </NavLink>
                        </li>
                      )
                    })}
                  </ul>
                  <div className="nav-item d-none d-md-flex">
                    <Link className="btn btn-ghost me-4" to={LOGIN_ROUTER}>
                      Log in
                    </Link>
                    <Link className="btn btn-primary" to={SIGNUP_ROUTER}>
                      Free 30-day trial
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </nav>
      </div>
    )
  )
}

export default Navbar
