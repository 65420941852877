const StepNewsLetter = ({ data, setData }) => {
  return (
    <div>
      <h4>Would you like to receive our newsletter?</h4>
      <p>You will be able to unsubscribe at any time from your account settings.</p>

      <div className="form-check form-switch">
        <input
          checked={data?.news_letter}
          onChange={(e) => setData({ ...data, news_letter: e.target.checked })}
          className="form-check-input"
          type="checkbox"
          role="switch switch_both"
        />
      </div>
    </div>
  )
}

export default StepNewsLetter
