import { useState } from 'react'
import { DateTime } from 'luxon'
import { LIST_PERIOD_TIME_SHEET, PERIOD_TIME_SHEET } from 'Pages/Timesheet/utils/const'
import { COLOR_BLUE_MATE } from 'constant/colors'
import { changeDateTimeSheet, changePeriodTimeSheet } from 'Pages/Timesheet/utils/helpersTimeSheet'
import { getEndWeek, getStartWeek } from 'Utils/helperFunctions'
import { DatePickerCustomFromTo } from './Components/DatePickerCustomFromTo'

const CustomDatePicker = ({
  onSearchData,
  disabledAll,
  defaultStartDay,
  defaultEndDay,
  titleMobile,
  colorPeriod = 'quaternary',
  defaultPeriod
}) => {
  const [startDay, setStartDay] = useState(defaultStartDay ?? getStartWeek())
  const [endDay, setEndDay] = useState(defaultEndDay ?? getEndWeek())
  const [currentStartDate, setCurrentStartDate] = useState(getStartWeek())
  const [currentEndDate, setCurrentEndDate] = useState(getEndWeek())

  const [period, setPeriod] = useState(defaultPeriod || PERIOD_TIME_SHEET.WEEK)

  const disabledArrow = [PERIOD_TIME_SHEET.ALL_TIME, PERIOD_TIME_SHEET.CUSTOM].includes(period) || disabledAll
  const hiddenReturnThisDate =
    disabledArrow ||
    (startDay.toFormat('yyyy-MM-dd') === currentStartDate.toFormat('yyyy-MM-dd') &&
      endDay.toFormat('yyyy-MM-dd') === currentEndDate.toFormat('yyyy-MM-dd'))

  const handleChangeDay = async ({ quantity, now }) => {
    const updateDate = changeDateTimeSheet({ now: now ? DateTime.now() : false, quantity, period, startDay, endDay })
    setStartDay(updateDate.startDay)
    setEndDay(updateDate.endDay)
    onSearchData && onSearchData({ startDay: updateDate.startDay, endDay: updateDate.endDay, period })
  }

  const handleSearchByCustom = async ({ start, end }) => {
    const sendStart = DateTime.fromJSDate(start)
    const sendEnd = DateTime.fromJSDate(end).set({ hour: 23, minute: 59, second: 59, millisecond: 999 })

    onSearchData &&
      onSearchData({
        period: PERIOD_TIME_SHEET.CUSTOM,
        startDay: sendStart,
        endDay: sendEnd
      })
  }

  const handleChangePeriod = async ({ changePeriod }) => {
    if (period === changePeriod) return
    const updateDay = changePeriodTimeSheet({ period: changePeriod, startDay: DateTime.now() })

    setPeriod(changePeriod)

    if (updateDay) {
      setStartDay(updateDay.startDay)
      setEndDay(updateDay.endDay)

      setCurrentStartDate(updateDay.startDay)
      setCurrentEndDate(updateDay.endDay)

      onSearchData && onSearchData({ startDay: updateDay.startDay, endDay: updateDay.endDay, period: changePeriod })
    }

    if (changePeriod === PERIOD_TIME_SHEET.CUSTOM) {
      setStartDay(getStartWeek())
      setEndDay(getEndWeek())
    }
  }

  const handleChangeDateByCustom = ({ startDate, endDate }) => {
    setStartDay(startDate)
    setEndDay(endDate)
  }

  return (
    <div className="bannerDatePicker">
      <div>
        {!disabledArrow && (
          <div className="btn-group" role="group">
            <button
              type="button"
              className="btn btn-secondary"
              id="btnGoBackDate"
              onClick={() => handleChangeDay({ quantity: -1 })}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill={COLOR_BLUE_MATE}
                viewBox="0 0 256 256">
                <path d="M168.49,199.51a12,12,0,0,1-17,17l-80-80a12,12,0,0,1,0-17l80-80a12,12,0,0,1,17,17L97,128Z"></path>
              </svg>
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              id="btnNextToDate"
              onClick={() => handleChangeDay({ quantity: 1 })}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill={COLOR_BLUE_MATE}
                viewBox="0 0 256 256">
                <path d="M184.49,136.49l-80,80a12,12,0,0,1-17-17L159,128,87.51,56.49a12,12,0,1,1,17-17l80,80A12,12,0,0,1,184.49,136.49Z"></path>
              </svg>
            </button>
          </div>
        )}
      </div>
      <div className={`bannerDatePicker_dateRange ${disabledArrow ? 'bannerDatePicker_dateRange_up' : ''}`}>
        {titleMobile && <h1>{titleMobile}</h1>}

        {period === PERIOD_TIME_SHEET.ALL_TIME && <h3 className="mb-0">All time</h3>}

        {![PERIOD_TIME_SHEET.ALL_TIME, PERIOD_TIME_SHEET.CUSTOM].includes(period) && (
          <h3 className="mb-0 fs-sm-4 fs-3">
            {startDay.toFormat('cccc')}, {startDay.toFormat('dd')} {startDay.toFormat('LLL')} -{' '}
            {endDay.toFormat('cccc')}, {endDay.toFormat('dd')} {endDay.toFormat('LLL')}
          </h3>
        )}

        {period === PERIOD_TIME_SHEET.CUSTOM && (
          <div className="bannerDatePicker_period_custom">
            <h3 className="mb-0">Custom:</h3>
            <DatePickerCustomFromTo startDate={startDay} endDate={endDay} onChangeDate={handleChangeDateByCustom} />
            <button
              type="button"
              className="btn btn-ghost text-primary"
              onClick={() => handleSearchByCustom({ start: startDay, end: endDay })}>
              Apply
            </button>
          </div>
        )}

        {!hiddenReturnThisDate && (
          <div className="bannerDatePicker_return">
            <button type="button" className="btn btn-ghost text-primary" onClick={() => handleChangeDay({ now: true })}>
              Return to this <span className="text-lowercase">{period}</span>
            </button>
          </div>
        )}
      </div>

      {!disabledAll && (
        <div className={`bannerDatePicker_period ${disabledArrow ? 'bannerDatePicker_period_up' : ''}`}>
          <div className="dropdown">
            <button
              className={`btn btn-${colorPeriod} dropdown-toggle`}
              type="button"
              data-bs-toggle="dropdown"
              id="filterByPeriod"
              aria-expanded="false">
              {LIST_PERIOD_TIME_SHEET.find((item) => item.value === period).name}
            </button>
            <ul className="dropdown-menu">
              {LIST_PERIOD_TIME_SHEET.map((item) => (
                <li key={item.value}>
                  <button
                    type="button"
                    onClick={() => handleChangePeriod({ changePeriod: item.value })}
                    className="dropdown-item">
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}
export default CustomDatePicker
