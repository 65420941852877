import { useState, useEffect, useCallback } from 'react'
import { Pagination } from '../../Organisms'
import ProjectModal from './ProjectModal'
import DeleteProjectModal from './DeleteProjectModal'
import DeactivateProjectModal from './DeactivateProjectModal'
import { useDebounce, useProject } from 'Hooks'
import { Loading } from 'Organisms/Loading'
import { HeaderProject, TableListProject } from 'Pages/Projects/Components'
import { TabsListTable } from 'Organisms/TabsListTable'
import { Helmet } from 'react-helmet'

export const TABS_PROJECT = {
  ACTIVE: {
    name: 'active projects'
  },
  ARCHIVED: { name: 'archived projects' }
}

const Projects = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedProject, setSelectedProject] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)

  const [visible, setVisible] = useState(false)
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const [visibleDeactivateModal, setVisibleDeactivateModal] = useState(false)

  const [activeTab, setActiveTab] = useState(TABS_PROJECT.ACTIVE.name)

  // Hooks
  const { loadingProjectHook, projectsHook, countProjectHook, getProjectsHook, getProjectByIdHook } = useProject()

  const fetchProject = async ({
    search = searchQuery,
    limit = rowPerPage,
    page = currentPage,
    isActive = activeTab
  } = {}) => {
    try {
      await getProjectsHook({
        search,
        is_active: isActive === TABS_PROJECT.ACTIVE.name,
        page,
        limit
      })
    } catch (e) {
      console.error(e)
    }
  }

  const openProjectModal = async (projectId) => {
    if (projectId) {
      const data = await getProjectByIdHook({ idProject: projectId })
      setSelectedProject(data)
    } else {
      setSelectedProject({})
    }
    setVisible(true)
  }

  const openDeleteProjectModal = async (userId) => {
    if (userId) {
      const data = await getProjectByIdHook({ idProject: userId })
      setSelectedProject(data)
    } else {
      setSelectedProject({})
    }
    setVisibleDeleteModal(true)
  }

  const openDeactivateProjectModal = async (projectId) => {
    if (projectId) {
      const data = await getProjectByIdHook({ idProject: projectId })
      setSelectedProject(data)
    } else {
      setSelectedProject({})
    }
    setVisibleDeactivateModal(true)
  }

  const openActivateProjectModal = async (userId) => {
    if (userId) {
      const data = await getProjectByIdHook({ idProject: userId })
      setSelectedProject(data)
    } else {
      setSelectedProject({})
    }
    setVisibleDeactivateModal(true)
  }

  useEffect(() => {
    ;(async () => {
      await fetchProject()
    })()
  }, [])

  // SEARCH
  const searchInputValue = ({ search }) => {
    setSearchQuery(search)
    filterDebounce({ search })
  }

  const filterDebounce = useCallback(
    useDebounce(({ search }) => {
      setCurrentPage(1)
      fetchProject({ search, page: 1 })
    }, 1000),
    [rowPerPage]
  )

  // CHANGE ROW PER PAGE
  const handleChangeRowPerPage = ({ rows }) => {
    setRowPerPage(rows)
    setCurrentPage(1)
    fetchProject({ limit: rows, page: 1 })
  }

  // CHANGE  PAGE
  const handleChangePage = (page) => {
    setCurrentPage(page)
    fetchProject({ page })
  }

  const handleChangeTab = ({ tab }) => {
    if (tab === activeTab) return

    setActiveTab(tab)
    setCurrentPage(1)
    fetchProject({ isActive: tab, page: 1 })
  }

  return (
    <>
      <Helmet>
        <title>Projects</title>
      </Helmet>

      {loadingProjectHook && <Loading />}

      <div className="containers projects pt-3">
        <HeaderProject onSearch={searchInputValue} onOpenCreate={openProjectModal} />

        <hr />
        <div className="row align-items-end justify-content-between gy-3  ">
          <div className="col-sm-auto col-12">
            <TabsListTable tabs={TABS_PROJECT} activeTab={activeTab} onChange={handleChangeTab} />
          </div>
          <div className="col-sm-auto col-12 d-flex justify-content-end">
            <p className="mb-2">
              Showing {rowPerPage > countProjectHook ? countProjectHook : rowPerPage} of {countProjectHook} projects
            </p>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-12">
            <TableListProject
              projectList={projectsHook}
              activeTab={activeTab}
              onEdit={openProjectModal}
              onActive={openActivateProjectModal}
              onDeactive={openDeactivateProjectModal}
              onDelete={openDeleteProjectModal}
            />
          </div>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={handleChangePage}
            totalCount={countProjectHook}
            pageSize={rowPerPage}
            changeRowPerPage={handleChangeRowPerPage}
          />
        </div>
      </div>

      {visible && (
        <ProjectModal
          visible={visible}
          setVisible={setVisible}
          selectedProject={selectedProject}
          reloadProjects={fetchProject}
        />
      )}

      {visibleDeleteModal && (
        <DeleteProjectModal
          visible={visibleDeleteModal}
          setVisible={setVisibleDeleteModal}
          selectedProject={selectedProject}
          reloadProjects={fetchProject}
        />
      )}

      {visibleDeactivateModal && (
        <DeactivateProjectModal
          visible={visibleDeactivateModal}
          setVisible={setVisibleDeactivateModal}
          selectedProject={selectedProject}
          reloadProjects={fetchProject}
        />
      )}
    </>
  )
}

export default Projects
