import { getEndWeek, getStartWeek, mapStates } from 'Utils/helperFunctions'
import { PARAMS_URL_TIMESHEET, PERIOD_TIME_SHEET } from './const'
import {
  STATUS_COLOR_TICKET_DANGER,
  STATUS_COLOR_TICKET_INFO,
  STATUS_COLOR_TICKET_SECONDARY,
  STATUS_COLOR_TICKET_SUCCESS,
  STATUS_COLOR_TICKET_WARNING,
  STATUS_TICKET_IN_PROGRESS,
  STATUS_TICKET_TO_DO
} from 'constant/StatusTicket'

export const setBackgroundColorTimeSheet = ({ status, completedWork, estimate, warrantyWork }) => {
  const billableRate = completedWork - warrantyWork > 0 ? completedWork - warrantyWork : 0

  if (!estimate) {
    if (mapStates(status) === STATUS_TICKET_TO_DO) return STATUS_COLOR_TICKET_SECONDARY

    if (mapStates(status) === STATUS_TICKET_IN_PROGRESS) return STATUS_COLOR_TICKET_INFO

    return STATUS_COLOR_TICKET_SUCCESS
  }

  if (estimate) {
    // if (mapStates(status) === STATUS_TICKET_TO_DO) return STATUS_COLOR_TICKET_SECONDARY

    if ([STATUS_TICKET_IN_PROGRESS, STATUS_TICKET_TO_DO].includes(mapStates(status))) {
      if (billableRate <= estimate * 0.7) return STATUS_COLOR_TICKET_INFO

      if (billableRate > estimate * 0.7 && billableRate <= estimate) {
        return STATUS_COLOR_TICKET_WARNING
      }

      return STATUS_COLOR_TICKET_DANGER
    } else {
      if (billableRate <= estimate) return STATUS_COLOR_TICKET_SUCCESS

      return STATUS_COLOR_TICKET_DANGER
    }
  }

  return STATUS_COLOR_TICKET_SECONDARY
}

export const getMyHoursTimeSheet = ({ completedWork, estimate, warranty }) => {
  const realHours = completedWork - warranty
  if (!estimate) return realHours >= 0 && (completedWork > 0 || warranty > 0) ? '100%' : '0%'

  if (realHours < 0) return '0%'

  const result = (realHours * 100) / estimate

  if (Number.isNaN(result)) {
    return '0%'
  }

  return `${result.toFixed(0)}%`
}

export const isFirstHalfOfMonthTimeSheet = ({ date }) => {
  const startOfMonth = date.startOf('month')
  const startWeekNumber = startOfMonth.weekNumber
  const dateWeekNumber = date.weekNumber

  return dateWeekNumber >= startWeekNumber && dateWeekNumber < startWeekNumber + 2
}

export const changeDateTimeSheet = ({ quantity, period, startDay, endDay, now }) => {
  if (now) {
    if (period === PERIOD_TIME_SHEET.WEEK) {
      return {
        startDay: getStartWeek(),
        endDay: getEndWeek()
      }
    }

    if (period === PERIOD_TIME_SHEET.SEMIMONTH) {
      if (!isFirstHalfOfMonthTimeSheet({ date: now })) {
        return {
          startDay: now.startOf('month'),
          endDay: now.startOf('month').plus({ days: 14 })
        }
      } else {
        return {
          startDay: now.startOf('month').plus({ days: 15 }),
          endDay: now.endOf('month')
        }
      }
    }

    if (period !== PERIOD_TIME_SHEET.CUSTOM && period !== PERIOD_TIME_SHEET.ALL_TIME) {
      return {
        startDay: now.startOf(period),
        endDay: now.endOf(period)
      }
    }
  }

  if (period === PERIOD_TIME_SHEET.WEEK) {
    return {
      startDay: startDay.plus({ weeks: quantity }),
      endDay: endDay.plus({ weeks: quantity })
    }
  }

  if (period === PERIOD_TIME_SHEET.SEMIMONTH && quantity > 0) {
    if (isFirstHalfOfMonthTimeSheet({ date: startDay })) {
      return {
        startDay: startDay.startOf('month').plus({ days: 15 }),
        endDay: startDay.endOf('month')
      }
    } else {
      return {
        startDay: startDay.plus({ month: quantity }).startOf('month'),
        endDay: startDay.plus({ month: quantity }).startOf('month').plus({ days: 14 })
      }
    }
  }

  if (period === PERIOD_TIME_SHEET.SEMIMONTH && quantity < 0) {
    if (isFirstHalfOfMonthTimeSheet({ date: startDay })) {
      return {
        startDay: startDay.plus({ month: quantity }).startOf('month').plus({ days: 15 }),
        endDay: startDay.plus({ month: quantity }).endOf('month')
      }
    } else {
      return {
        startDay: startDay.startOf('month'),
        endDay: startDay
          .startOf('month')
          .plus({ days: 14 })
          .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
      }
    }
  }

  if (period === PERIOD_TIME_SHEET.MONTH) {
    return {
      startDay: startDay.plus({ months: quantity }).startOf('month'),
      endDay: endDay.plus({ months: quantity }).endOf('month')
    }
  }

  if (period === PERIOD_TIME_SHEET.QUARTER) {
    return {
      startDay: startDay.plus({ quarters: quantity }).startOf('month'),
      endDay: endDay.plus({ quarters: quantity }).endOf('month')
    }
  }

  if (period === PERIOD_TIME_SHEET.YEAR) {
    return {
      startDay: startDay.plus({ years: quantity }).startOf('month'),
      endDay: endDay.plus({ years: quantity }).endOf('month')
    }
  }
}

export const changePeriodTimeSheet = ({ period, startDay }) => {
  if (period === PERIOD_TIME_SHEET.CUSTOM) {
    return {
      startDay: getStartWeek(),
      endDay: getEndWeek()
    }
  }

  if (period === PERIOD_TIME_SHEET.ALL_TIME) {
    return {
      startDay: '',
      endDay: ''
    }
  }

  if (period === PERIOD_TIME_SHEET.WEEK) {
    return {
      startDay: getStartWeek(),
      endDay: getEndWeek()
    }
  }

  if ([PERIOD_TIME_SHEET.MONTH, PERIOD_TIME_SHEET.YEAR].includes(period)) {
    return {
      startDay: startDay.startOf(period),
      endDay: startDay.endOf(period)
    }
  }

  if (period === PERIOD_TIME_SHEET.SEMIMONTH) {
    if (isFirstHalfOfMonthTimeSheet({ date: startDay })) {
      return {
        startDay: startDay.startOf('month'),
        endDay: startDay
          .startOf('month')
          .plus({ days: 14 })
          .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
      }
    } else {
      return {
        startDay: startDay.startOf('month').plus({ days: 15 }),
        endDay: startDay.endOf('month')
      }
    }
  }

  if (period === PERIOD_TIME_SHEET.QUARTER) {
    const currentQuarter = Math.floor((startDay.month - 1) / 3) + 1
    const startQuarter = startDay.set({ month: (currentQuarter - 1) * 3 + 1, day: 1 }).startOf('day')
    let endQuarter = startQuarter.plus({ months: 3 }).minus({ days: 1 })
    const lastDayOfMount = endQuarter.endOf('month').day

    if (endQuarter.day !== lastDayOfMount) {
      endQuarter = endQuarter.set({ day: lastDayOfMount })
    }

    return {
      startDay: startQuarter,
      endDay: endQuarter
    }
  }
}

export const getQueryParamsTimesheet = ({ location }) => {
  const queryParams = new URLSearchParams(location.search)
  const periodQuery = queryParams.get(PARAMS_URL_TIMESHEET.PERIOD)
  const rangeDateQuery = queryParams.get(PARAMS_URL_TIMESHEET.FRONT_TO_DATE)
  const rangeDateQuerySplit = rangeDateQuery && rangeDateQuery.split(':')
  const isDetailQuery = queryParams.get(PARAMS_URL_TIMESHEET.IS_DETAILS)
  const searchQuery = queryParams.get(PARAMS_URL_TIMESHEET.SEARCH)

  const projectQuery = queryParams.get(PARAMS_URL_TIMESHEET.PROJECTS)
  const tasksQuery = queryParams.get(PARAMS_URL_TIMESHEET.TASKS)
  const statesQuery = queryParams.get(PARAMS_URL_TIMESHEET.STATES)
  const usersQuery = queryParams.get(PARAMS_URL_TIMESHEET.USERS)
  const sprintQuery = queryParams.get(PARAMS_URL_TIMESHEET.SPRINT)

  const pageQuery = queryParams.get(PARAMS_URL_TIMESHEET.PAGE) || 1
  const limitQuery = queryParams.get(PARAMS_URL_TIMESHEET.LIMIT) || 10

  return {
    queryParams,
    periodQuery,
    startDateQuery: rangeDateQuerySplit?.[0],
    endDateQuery: rangeDateQuerySplit?.[1],
    isDetailQuery,
    searchQuery,
    projectQuery,
    tasksQuery,
    statesQuery,
    usersQuery,
    sprintQuery,
    pageQuery,
    limitQuery
  }
}
