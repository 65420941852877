const Navs = () => {
  return (
    <section className="col-12">
      <h2 className="mt-5">Navs</h2>
      <hr />
      <h3 className="mt-5">Underline</h3>
      <ul className="nav nav-underline">
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="#">
            Active
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">
            Link
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">
            Link
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link disabled">Disabled</a>
        </li>
      </ul>
      <h3 className="mt-5">Vertical</h3>
      <div className="row">
        <div className="col-12 col-md-3">
          <ul className="nav nav-pills flex-column">
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="#">
                Link
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Link
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Link
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Link
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Link
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link disabled" href="#">
                Link
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Navs
