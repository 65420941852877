import { COLORS_GRAFICH } from 'Utils/const/profile'
import { countDecimalsAndAdd0 } from 'Utils/helperFunctions'
import { COLOR_CONTRACT_BLACK } from 'constant/colors'

const CustomBarTreeDashboard = ({ x, y, width, height, hours, totalHours, root: { name, index } }) => {
  const showPercent = height > 90 && width > 68
  const showHours = height > 55 && width > 68
  const showName = height > 30 && width > 68

  const countShow = 0 + (showName ? 1 : 0) + (showHours ? 1 : 0) + (showPercent ? 1 : 0)

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={COLORS_GRAFICH[index]}
        rx={4}
        ry={4}
        stroke={COLOR_CONTRACT_BLACK}
        strokeWidth="1"
      />

      {showName && (
        <text
          x={x + width / 4}
          y={countShow === 1 ? y + height / 1.8 : countShow === 2 ? y + height / 2 - 10 : y + height / 2 - 20}
          fontSize="14"
          fill="white"
          textAnchor="start">
          {name}
        </text>
      )}

      {showHours && (
        <>
          <text x={x + width / 4} y={countShow === 1 ? y + height / 1.8 : countShow === 2 ? y + height / 2 : y + height / 2} fontSize="14" fill="white" textAnchor="start">{`${countDecimalsAndAdd0(
            {
              number: Number(hours)
            }
          )}h`}</text>
        </>
      )}

      {showPercent && (
        <text
          x={x + width / 4}
          y={countShow === 1 ? y + height / 1.8 : countShow === 2 ? y + height / 2 + 10 : y + height / 2 + 20}
          fontSize="14"
          fill="white"
          textAnchor="start">{`(${countDecimalsAndAdd0({ number: (hours * 100) / totalHours })}%)`}</text>
      )}
    </g>
  )
}

export default CustomBarTreeDashboard
