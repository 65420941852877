import { Navigate } from 'react-router-dom'
import useSessionStore from '../Context/useSessionStore'
import { DASHBAORD_ROUTER } from 'constant/router'

const RequireAuth = ({ children, scope }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  if (scope && scope.includes(user.role)) {
    return children
  } else {
    return <Navigate to={DASHBAORD_ROUTER} />
  }
}

export default RequireAuth
