import { countDecimalsAndAdd0 } from 'Utils/helperFunctions'

const TableTimesheetProject = ({ projectTask, timesheetList }) => {
  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Item</th>
            {projectTask.map((task) => (
              <th className="text-no-wrap" key={task?.task.id} scope="col">
                {task?.task?.name || ''}
              </th>
            ))}
            <th scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          {timesheetList.map((ticket, index) => (
            <tr key={index}>
              <td>{ticket.title}</td>
              {projectTask.map((task) => (
                <td key={task.id} scope="col">
                  {countDecimalsAndAdd0({
                    number: ticket.tasks.find((item) => item.name === task?.task?.name)?.hours || 0
                  })}
                </td>
              ))}
              <td>{countDecimalsAndAdd0({ number: ticket.hours })}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default TableTimesheetProject
