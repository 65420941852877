import { decimalToHours, sumHoursPerMinutes, validateFormatTime } from 'Utils/helperFunctions'
import { useState } from 'react'

const TimeInput = ({ className, disabled, register, errors, setError, clearErrors, setValue, defaultValue = '' }) => {
  const [inputValue, setInputValue] = useState(decimalToHours({ time: defaultValue }))

  const handleInputChange = (e) => {
    const value = e.target.value

    if (value.split(':').length > 2) return

    const onlyNumber = /^[0-9:]+$/

    const result = onlyNumber.test(e.target.value) ? e.target.value : ''
    setInputValue(result)
    setValue('time', result)

    if (!validateFormatTime({ time: result })) {
      setError('time', { type: 'custom', message: 'Format is not valid' })
    } else {
      clearErrors('time')
    }
  }

  const handleValidMinutes = ({ value }) => {
    const result = sumHoursPerMinutes({ time: value })

    if (!validateFormatTime({ time: result })) setError('time', { type: 'custom', message: 'Format is not valid' })

    setInputValue(result)
    setValue('time', result)
  }

  return (
    <>
      <input
        {...register('time', { required: true })}
        type="text"
        className={`${className} ${errors && errors.time ? 'is-invalid' : ''}`}
        placeholder="00:00"
        id='inputTime'
        value={inputValue}
        onChange={handleInputChange}
        disabled={disabled}
        onBlur={(e) => handleValidMinutes({ value: e.target.value })}
      />
      <div className="invalid-feedback">{errors?.time?.message || ''}</div>
    </>
  )
}

export default TimeInput
