import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

// Hooks
import useModal from '../../Hooks/useModal'
import { useClient, useProject } from 'Hooks'

// Components
import { FormSettingsProject } from 'Organisms/Forms'
import { ButtonsModal } from 'Organisms'
import { Loading } from 'Organisms/Loading'
import { PROJECT_CODE_ALREADY_EXISTS } from 'Utils/const/messages'
import { Mixpanel } from 'Utils/Mixpanel'

export const FIELD_CLIENT_ID_PROJECT = 'client_id'

const ProjectModal = ({ visible, setVisible, selectedProject, reloadProjects }) => {
  // State
  const [showDropdown, setShowDropdown] = useState(true)
  const [clients, setClients] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingInternal, setLoadingInternal] = useState(true)

  // Hooks
  const { Modal, hide } = useModal({ identifier: 'addproject', visible, setVisible })
  const { loadingClientHook, createClientHook, getClientsHook } = useClient()
  const { loadingProjectHook, createProjectHook, updateProjectHook } = useProject()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
    setValue
  } = useForm({
    defaultValues: {
      name: selectedProject?.name || '',
      code: selectedProject?.code || '',
      note: selectedProject?.note || '',
      client_id: selectedProject?.client_id || ''
    }
  })

  const LOADING = loadingClientHook || loadingProjectHook

  // Handlers
  const onSubmit = async (data) => {
    setLoading(true)
    try {
      const body = data

      delete data.created_at
      delete data.updated_at
      delete data.deleted_at
      delete data.client
      delete data.project_tasks
      delete data.user_projects

      if (!showDropdown) {
        const resCreateClient = await createClientHook({ nameClient: data[FIELD_CLIENT_ID_PROJECT] })

        if (!resCreateClient.success) {
          return setError('client_id', { type: 'exist', message: resCreateClient.message })
        }

        body[FIELD_CLIENT_ID_PROJECT] = resCreateClient.data.id
      }

      let res
      if (!selectedProject?.id) {
        Mixpanel.track('Create Project Intent')
        res = await createProjectHook({ body })
        if (res.success) { Mixpanel.track('Project Created') }
      } else {
        Mixpanel.track('Edit Project Intent')
        res = await updateProjectHook({ body, projectId: selectedProject.id })
        if (res.success) { Mixpanel.track('Project Edited') }
      }

      setLoading(false)

      if (!res.success) {
        if (res.message === PROJECT_CODE_ALREADY_EXISTS) setError('code', { type: 'exists', message: res.message })
        return
      }

      hide()
      reloadProjects()
    } catch (e) {
      if ((e?.response?.data?.message || '').includes('Please write a different Code.')) {
        setError('code', { type: 'custom', message: e.response.data.message })
      }
      setLoading(false)
    }
  }

  const fetchClients = async () => {
    try {
      const data = await getClientsHook()
      setClients(data || [])

      if (data.length === 0) setShowDropdown(false)

      if (!selectedProject?.id) {
        setValue(FIELD_CLIENT_ID_PROJECT, data?.[0]?.id || null)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleChangeDropdownClient = () => {
    reset({
      [FIELD_CLIENT_ID_PROJECT]: showDropdown ? '' : clients?.[0]?.id || ''
    })

    setShowDropdown((prevtState) => !prevtState)
  }

  useEffect(() => {
    ;(async () => {
      try {
        await fetchClients()
      } finally {
        setLoadingInternal(false)
      }
    })()
  }, [])

  return (
    <Modal>
      <>
        {LOADING && <Loading />}

        {!loadingInternal && (
          <div className="modal-content" style={{ maxWidth: 800 }}>
            <div className="modal-header">
              <h3 className="modal-title fs-5 text-capitalize" id="exampleModalLabel">
                {!selectedProject?.id ? 'Add project' : selectedProject.name}
              </h3>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col">
                  <div className="row justify-content-center">
                    <div className="col-10">
                      <FormSettingsProject
                        onSubmit={handleSubmit(onSubmit)}
                        showDropdown={showDropdown}
                        register={register}
                        clients={clients}
                        errors={errors}
                        onChangeShowDropdown={handleChangeDropdownClient}
                        formControl="addProject"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <ButtonsModal onClose={hide} loading={loading} formControl={'addProject'} />
            </div>
          </div>
        )}
      </>
    </Modal>
  )
}

export default ProjectModal
