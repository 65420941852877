import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Check, CheckCircle } from '@phosphor-icons/react'
import { GET_COLOR_BY_THEME } from 'constant/colors'
import CancelSubscriptionModal from './CancelSubscriptionModal'
import { OPTIONS_PRINCING, PLAN_PACKAGE_FREE } from 'Utils/const/packages'
import { DateTime } from 'luxon'
import { useCompany, usePayment } from 'Hooks'
import { Loading } from 'Organisms/Loading'
import useSessionStore from 'Context/useSessionStore'
import ModalConfirmOrCancel from 'Organisms/ModalConfirmOrCancel'
import { ModalSuccess } from 'Organisms/ModalSuccess'
import ManageSeatsModal from './ManageSeatsModal'
import { PRICING_ROUTER } from 'constant/router'
import { Mixpanel } from 'Utils/Mixpanel'

const MIN_ACTIVE_USER_BY_FREE_PLAN = 5

const BillingPlan = ({ plan, expirationDate, seats, period, isTrial, expire_trial, onRefreshCompany, isCancel }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const [openCancelSubscriptionModal, setOpensubscriptionModal] = useState(false)
  const [openModalConfirmReactiveSubscription, setOpenModalConfirmReactiveSubscription] = useState(false)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({ open: false })
  const [openManageSeatsModal, setOpenManageSeatsModal] = useState(false)

  const { cancelPaymentHook, reactivePaymentHook, updatePaymentSeatsHook } = usePayment()
  const { userCompanyHook, getUserByCompanyHook } = useCompany()

  const handleCancelPlan = async () => {
    Mixpanel.track('Cancel Subscription Intent')
    try {
      if (userCompanyHook.count > MIN_ACTIVE_USER_BY_FREE_PLAN) {
        Mixpanel.track('Cancel Subscription Failed', {
          event_type: 'Cancel Subscription',
          reason: 'Unavailable free seats'
        })
        return setMessage({
          open: true,
          error: true,
          message: 'The plan cannot be canceled as it exceeds 5 active users.'
        })
      }

      setLoading(true)
      const res = await cancelPaymentHook()

      if (!res.success) {
        Mixpanel.track('Cancel Subscription Intent', {
          event_type: 'Cancel Subscription',
          reason: 'Service failed'
        })
        return setMessage({
          open: true,
          error: true,
          message: res.message
        })
      }

      await onRefreshCompany()
    } finally {
      setLoading(false)
    }
  }

  const handleReactivePlan = async () => {
    try {
      setLoading(true)
      const res = await reactivePaymentHook()
      Mixpanel.track('Reactivate Plan Intent')
      if (!res.success) {
        Mixpanel.track('Reactivate Plan Failed')
        return setMessage({
          open: true,
          error: true,
          message: 'An error occurred while activating the plan'
        })
      }
      Mixpanel.track('Reactivate Plan Success')
      await onRefreshCompany()
    } finally {
      setLoading(false)
    }
  }

  const handleChangeSeats = async ({ seats }) => {
    Mixpanel.track('Update Seats Intent', {
      seats
    })
    try {
      setLoading(true)
      const res = await updatePaymentSeatsHook({ seats })

      if (!res.success) {
        Mixpanel.track('Manage Seats Failed', {
          seats
        })
        return setMessage({
          open: true,
          error: true,
          message: 'Failed to update subscription seats'
        })
      }

      await onRefreshCompany()
      Mixpanel.track('Update Seats Success', {
        seats
      })
      setMessage({
        open: true,
        message: 'The seats have been updated'
      })
    } finally {
      setLoading(false)
    }
  }

  const getCountUserCompany = async () => {
    await getUserByCompanyHook({ companyId: user.company_id })
  }

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        await getCountUserCompany()
      } catch {
        console.error('Error billing plan')
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  return (
    <>
      {loading && <Loading />}
      <div className="row mt-2">
        <div className="col-12">
          <div className="card active card-secondary py-4 px-5 col-12">
            <div className="row justify-content-between">
              <div className="col">
                {isTrial && <span className="badge text-bg-info mb-3">FREE TRIAL until {expire_trial}</span>}

                <h3>{plan.title} Plan</h3>

                {plan.id !== PLAN_PACKAGE_FREE.id
                  ? (
                  <h6 style={{ textTransform: 'capitalize' }}>
                    {period} billed - {isCancel ? 'Expires' : 'Renews'} on{' '}
                    {DateTime.fromISO(expirationDate).setZone('local').toFormat('LLL dd, yyyy')}
                  </h6>
                    )
                  : (
                  <div className="col-auto">
                    <span className="badge text-bg-terciary d-inline-flex mb-3">Forever</span>
                  </div>
                    )}

                <p>Includes:</p>
                <div className="row">
                  {plan.including.map((item) => (
                    <p key={item}>
                      <Check
                        size={12}
                        weight="bold"
                        color={GET_COLOR_BY_THEME({ theme: user.theme }).PRIMARY}
                        className="me-1"
                      />
                      {item}
                    </p>
                  ))}
                </div>
              </div>

              {plan.id !== PLAN_PACKAGE_FREE.id && (
                <div className="col-auto">
                  <div className="row">
                    <h3 className="text-end">
                      ${OPTIONS_PRINCING[period].price}
                      <span className="h6"> /per seat</span>
                    </h3>
                    <h6 className="text-end">{seats} active seats</h6>
                  </div>
                </div>
              )}
            </div>
            <div className="row justify-content-end">
              {plan.id === PLAN_PACKAGE_FREE.id && (
                <div className="col-auto">
                  <Link to={PRICING_ROUTER} className="btn btn-primary">
                    Upgrade subscription
                  </Link>
                </div>
              )}

              {plan.id !== PLAN_PACKAGE_FREE.id && !isCancel && (
                <>
                  <div className="col-auto">
                    <button
                      type="button"
                      className="btn btn-secondary me-2"
                      onClick={() => setOpensubscriptionModal(true)}>
                      Cancel subscription
                    </button>
                  </div>
                  <div className="col-auto">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        Mixpanel.track('Manage Seats Intent')
                        setOpenManageSeatsModal(true)
                      }}>
                      Manage seats
                    </button>
                  </div>
                </>
              )}

              {isCancel && (
                <div className="col-auto">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setOpenModalConfirmReactiveSubscription(true)}>
                    Reactivate subscription
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {openCancelSubscriptionModal && (
        <CancelSubscriptionModal
          visible={true}
          identifier="modalCancelSubscription"
          expire_plan={expirationDate}
          onClose={() => setOpensubscriptionModal(false)}
          onConfirm={handleCancelPlan}
        />
      )}

      {openModalConfirmReactiveSubscription && (
        <ModalConfirmOrCancel
          title="Are you sure you want to reactivate your subscription?"
          setVisible={() => setOpenModalConfirmReactiveSubscription(false)}
          onConfirm={handleReactivePlan}
          icon={<CheckCircle size={56} color={GET_COLOR_BY_THEME({ theme: user.theme }).PRIMARY} />}
        />
      )}

      {message.open && (
        <ModalSuccess
          visible={true}
          setVisible={() => setMessage({ open: false })}
          title={message.message}
          identifier="modalMessageSuccess"
          error={message.error}
        />
      )}

      {openManageSeatsModal && (
        <ManageSeatsModal
          visible={true}
          identifier="modalManageSeats"
          onClose={() => {
            setOpenManageSeatsModal(false)
          }}
          seats={seats}
          period={period}
          onRefreshCompany={onRefreshCompany}
          onSubmit={handleChangeSeats}
          countUserActive={userCompanyHook?.count || 1}
        />
      )}
    </>
  )
}

export default BillingPlan
