import { useForm, useWatch } from 'react-hook-form'
import { HeaderSettings } from '../HeaderSettings'
import { tzStrings } from 'Utils/helperFunctions'
import { LIST_HOURS, LIST_MINUTES } from 'Utils/const/hours'
import { useSchedule, useUser } from 'Hooks'
import { useEffect, useState } from 'react'
import { Loading } from 'Organisms/Loading'
import { ModalSuccess } from 'Organisms/ModalSuccess'
import { DAYS_OF_WEEK } from 'Pages/TimeTracker/utils/const'
import useSessionStore from 'Context/useSessionStore'
import { PERMISSION_BY_ROLE } from 'Utils/permissionByRol'

const SettingsSet = () => {
  const { user, updateUser } = useSessionStore((state) => ({ user: state.user, updateUser: state.updateUser }))
  const isAdmin = PERMISSION_BY_ROLE.SETTINGS.AUTOMATIC_CLOSE_HOURS.includes(user.role)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({ open: false })

  const { updateProfileUserHook } = useUser()
  const { postScheduleHook, getScheduleHook, updateScheduleHook, deteleSchedule } = useSchedule()
  const { control, register, handleSubmit, setValue } = useForm({
    defaultValues: {
      news_letter: user?.news_letter
    }
  })

  const send_hours_auto = useWatch({
    control,
    name: 'send_hours_auto',
    defaultValue: 0
  })

  const there_is_schedule = useWatch({
    control,
    name: 'there_is_schedule',
    defaultValue: 0
  })

  const getSchedule = async () => {
    try {
      setLoading(true)
      const res = await getScheduleHook()

      if (!res?.success) return

      const {
        data: { weekday, timezone, hours, minutes }
      } = res

      setValue('weekday', weekday)
      setValue('timezone', timezone)
      setValue('hours', hours)
      setValue('minutes', minutes)
      setValue('send_hours_auto', 1)
      setValue('there_is_schedule', 1)
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = async (data) => {
    try {
      setLoading(true)

      let message = ''

      // si eres admin puedes programar el auto cierra de las horas
      if (isAdmin) {
        // se quiere desactivar y si hay programado
        if (!send_hours_auto && there_is_schedule) {
          const res = await deteleSchedule()

          if (!res.success) throw new Error()

          message = 'Scheduled automatic closing time was removed.'

          setValue('there_is_schedule', 0)
        }

        // se quiere activar o actualizar y hay programado
        if (send_hours_auto) {
          const body = {
            weekday: data.weekday.toLowerCase(),
            hour: `${data.hours}:${data.minutes}`,
            timezone: data.timezone
          }

          const res = there_is_schedule ? await updateScheduleHook(body) : await postScheduleHook(body)

          if (!res.success) throw new Error()

          message = 'Automatic hour closure scheduled successfully.'

          setValue('there_is_schedule', 1)
        }
      }

      if (user?.news_letter !== data.news_letter) {
        const body = { news_letter: data.news_letter }
        const res = await updateProfileUserHook({ body })

        if (!res.success) return

        await updateUser({ ...user, ...body })

        message = data.news_letter ? 'You have subscribed to our newsletter.' : 'You have unsubscribed from our newsletter.'
      }

      setMessage({
        open: true,
        message
      })
    } catch {
      return setMessage({
        open: true,
        error: true,
        message: 'Failed to schedule automatic hour closure. Please try again later.'
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    isAdmin && getSchedule()
  }, [])

  return (
    <>
      {loading && <Loading />}

      <form className="col-12 d-grid align-content-start" onSubmit={handleSubmit(onSubmit)}>
        <HeaderSettings title="Settings" />

        <div className="row gy-4 align-items-center">
          {isAdmin && (
            <>
              <div className="col-12 col-sm-9">
                <h4 className="mb-3">Time Tracker</h4>
                <p className="text-secondary fs-6">Close Time Tracker automatically</p>
                <span className="text-normal_two fs-7">
                  When this setting is enabled, all the time trackers will be automatically submitted to the selected
                  time and day of the week.
                </span>
              </div>

              <div className="col-12 col-sm-3 form-check form-switch mb-3 d-flex align-items-center justify-content-sm-start justify-content-center">
                <input
                  id="send_hours_auto"
                  className="form-check-input"
                  type="checkbox"
                  role="switch switch_both"
                  {...register('send_hours_auto')}
                />
              </div>

              {send_hours_auto
                ? (
                <>
                  <div className="col-12">
                    <div className="row gy-2">
                      <div className="col-md-2 col-sm-3 col-12">
                        <p className="text-secondary">Weekday</p>
                      </div>

                      <div className="col-sm-5 col-12">
                        <select className="form-select" {...register('weekday', { required: true })}>
                          {Object.values(DAYS_OF_WEEK).map((item) => (
                            <option value={item.toLowerCase()} key={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row gy-2">
                      <div className="col-md-2 col-sm-3 col-12">
                        <p className="text-secondary">Hour</p>
                      </div>

                      <div className="col-sm-5 col-12">
                        <div className="row">
                          <div className="col">
                            <select className="form-select" {...register('hours')}>
                              {LIST_HOURS.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col">
                            <select className="form-select" {...register('minutes')}>
                              {LIST_MINUTES.map((item) => (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row gy-2">
                      <div className="col-md-2 col-sm-3 col-12">
                        <p className="text-secondary">Time zone</p>
                      </div>

                      <div className="col-sm-5 col-12">
                        <select id="inputTimezone" className="form-select" {...register('timezone')}>
                          {tzStrings.map((item, key) => (
                            <option key={key} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </>
                  )
                : (
                <></>
                  )}

              <hr />
            </>
          )}

          <div className="col-12 col-sm-9">
            <h4 className="mb-3">Newsletter</h4>
            <p className="text-secondary fs-6">Newsletter subscription</p>
            <span className="text-normal_two fs-7">
              If you turn off this toggle, you will stop receiving the Pulse monthly newsletter, but don&apos;t
              worry! You can come back whenever you&apos;re ready
            </span>
          </div>

          <div className="col-12 col-sm-3 form-check form-switch mb-3 d-flex align-items-center justify-content-sm-start justify-content-center">
            <input
              id="send_hours_auto"
              className="form-check-input"
              type="checkbox"
              role="switch switch_both"
              {...register('news_letter')}
            />
          </div>
        </div>
      </form>

      {message.open && (
        <ModalSuccess
          visible={true}
          setVisible={() => setMessage({ open: false })}
          title={message.message}
          identifier="messageApproved"
          error={message?.error}
        />
      )}
    </>
  )
}

export default SettingsSet
