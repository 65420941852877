import Avatar from 'react-avatar'
import dayjs from 'dayjs'
import { Trash } from '@phosphor-icons/react'
import { COLOR_BLACK, COLOR_CONTRACT_BLACK, COLOR_YELLOW } from 'constant/colors'
import { PERMISSION_BY_ROLE } from 'Utils/permissionByRol'
import useSessionStore from 'Context/useSessionStore'

const TableUserTeam = ({ users, onDelete }) => {
  const { user: userContext } = useSessionStore((state) => ({ user: state.user }))

  return (
    <div className='table-responsive'>
      <table className="table align-middle">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Name</th>
            <th scope="col">Position</th>
            <th scope="col">Date assigned</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>
                <Avatar
                  name={`${user.firstname} ${user.lastname}`}
                  className='avatarImage'
                  src={user?.logo || null}
                  color={COLOR_YELLOW}
                  fgColor={COLOR_BLACK}
                  round={true}
                  size="38px"
                  textSizeRatio={3}
                />
              </td>
              <td>
                <p className="m-0">
                  {user.firstname} {user.lastname}
                </p>
                <span className="form-text">{user.email}</span>
              </td>
              <td className='text-no-wrap'>{user.position}</td>
              <td className='text-no-wrap'>{dayjs(user.last_activity).format('MMM DD, YYYY')}</td>
              {PERMISSION_BY_ROLE.PROJECT.TEAM_SECTION.DELETE_USER.includes(userContext.role) && (
                <td>
                  <button type="button" className="btn btn-quaternary" onClick={() => onDelete({ userId: user.id })}>
                    <Trash size={18} color={COLOR_CONTRACT_BLACK} weight="bold" />
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default TableUserTeam
