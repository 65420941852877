import { PERMISSION_BY_ROLE } from 'Utils/permissionByRol'
import { ROLE_ADMIN_NAME, ROLE_DEVELOPER_NAME, ROLE_OPERATION_MANAGER_NAME, ROLE_PRODUCT_OWNER_NAME } from './roles'
import {
  DASHBAORD_ROUTER,
  PAYROLL_ROUTER,
  PROJECT_ROUTER,
  TIME_ROUTER,
  USER_ROUTER
} from './router'

export const SIDEBAR_LIST = [
  {
    name: 'Dashboard',
    scope: [ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME, ROLE_PRODUCT_OWNER_NAME, ROLE_DEVELOPER_NAME],
    to: DASHBAORD_ROUTER
  },
  {
    name: 'Time',
    scope: [ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME, ROLE_PRODUCT_OWNER_NAME, ROLE_DEVELOPER_NAME],
    to: TIME_ROUTER
  },
  {
    name: 'Users',
    scope: PERMISSION_BY_ROLE.USER.VIEW_PAGE,
    to: USER_ROUTER
  },
  {
    name: 'Projects',
    scope: [ROLE_ADMIN_NAME, ROLE_OPERATION_MANAGER_NAME, ROLE_PRODUCT_OWNER_NAME],
    to: PROJECT_ROUTER
  },
  {
    name: 'Payroll',
    scope: [ROLE_ADMIN_NAME],
    to: PAYROLL_ROUTER
  },
  {
    name: 'Approvals',
    scope: [ROLE_OPERATION_MANAGER_NAME, ROLE_PRODUCT_OWNER_NAME],
    to: PAYROLL_ROUTER
  },
  {
    name: 'Payments',
    scope: [ROLE_DEVELOPER_NAME],
    to: PAYROLL_ROUTER
  }
]
