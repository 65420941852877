const Buttons = () => {
  return (
    <section className="col-12">
      <h2 className="mt-5">Buttons</h2>
      <hr />
      <div className="row d-none d-md-flex">
        <div className="col">
          <label className="th">Button type</label>
        </div>
        <div className="col">
          <label className="th">Default</label>
        </div>
        <div className="col">
          <label className="th">Hover / Pressed</label>
        </div>
        <div className="col">
          <label className="th">Focus</label>
        </div>
        <div className="col">
          <label className="th">Disabled</label>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 col-md">
          <h4>Primary</h4>
        </div>
        <div className="col-6 col-md">
          <button className="btn btn-primary" type="button">
            .btn-primary
          </button>
        </div>
        <div className="col-6 col-md">
          <button className="btn btn-primary hover" type="button">
            hover / pressed
          </button>
        </div>
        <div className="col-6 col-md">
          <button className="btn btn-primary active" type="button">
            focus / active
          </button>
        </div>
        <div className="col-6 col-md">
          <button className="btn btn-primary disabled" type="button" disabled>
            disabled
          </button>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col">
          <h4>Secondary</h4>
        </div>
        <div className="col">
          <button className="btn btn-secondary" type="button">
            .btn-secondary
          </button>
        </div>
        <div className="col">
          <button className="btn btn-secondary hover" type="button">
            hover / pressed
          </button>
        </div>
        <div className="col">
          <button className="btn btn-secondary active" type="button">
            focus / active
          </button>
        </div>
        <div className="col">
          <button className="btn btn-secondary disabled" type="button" disabled>
            disabled
          </button>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col">
          <h4>Terciary</h4>
        </div>
        <div className="col">
          <button className="btn btn-terciary" type="button">
            .btn-terciary
          </button>
        </div>
        <div className="col">
          <button className="btn btn-terciary hover" type="button">
            hover / pressed
          </button>
        </div>
        <div className="col">
          <button className="btn btn-terciary active" type="button">
            focus / active
          </button>
        </div>
        <div className="col">
          <button className="btn btn-terciary disabled" type="button" disabled>
            disabled
          </button>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col">
          <h4>Ghost</h4>
        </div>
        <div className="col">
          <button className="btn btn-ghost" type="button">
            .btn-ghost
          </button>
        </div>
        <div className="col">
          <button className="btn btn-ghost hover" type="button">
            hover / pressed
          </button>
        </div>
        <div className="col">
          <button className="btn btn-ghost active" type="button">
            focus / active
          </button>
        </div>
        <div className="col">
          <button className="btn btn-ghost disabled" type="button" disabled>
            disabled
          </button>
        </div>
      </div>
      <div className="row mt-4 mb-5">
        <div className="col-12 col-md">
          <h4>Danger</h4>
        </div>
        <div className="col-6 col-md">
          <button className="btn btn-danger" type="button">
            .btn-danger
          </button>
        </div>
        <div className="col-6 col-md">
          <button className="btn btn-danger hover" type="button">
            hover / pressed
          </button>
        </div>
        <div className="col-6 col-md">
          <button className="btn btn-danger active" type="button">
            focus / active
          </button>
        </div>
        <div className="col-6 col-md">
          <button className="btn btn-danger disabled" type="button" disabled>
            disabled
          </button>
        </div>
      </div>
    </section>
  )
}

export default Buttons
