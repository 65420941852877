import { Helmet } from 'react-helmet'

const CookiePolicy = () => {
  return (
        <>
            <Helmet>
                <title>Cookies Policy</title>
                <meta
                name="description"
                content="."
                />
                <meta name="keywords" content="" />
                <meta name="author" content="Pulse by Canned Head" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://example.com/image.jpg" />
                <meta property="og:url" content="https://example.com/time-tracking-software" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className='container py-5'>
                <h1>Cookies Policy</h1>
                <p>Canned Head Inc. uses cookies on the Website, Services and mobile application.</p>
                <p>This Cookie Policy makes an integral part of our Privacy Policy and Terms of Use. You can find out more about cookies and how to control them in the information below. Concepts written in capital letters in this Cookie Policy have the same meaning as defined in the <a href="/privacy-policy">Privacy Policy</a> or in the <a href="/terms-of-use">Terms of Use</a>.</p>
                <h2 className="mt-5">1. What is a cookie?</h2>
                <p>Cookies are text files containing small amounts of information that are downloaded to your computer or mobile device when you visit a website. Cookies are then sent back to the originating website on each subsequent visit, or to another website that recognizes that cookie. Cookies are useful because they allow a website to recognize a user&apos;s device. They serve the purpose of making our Service more user-friendly, efficient, and secure. Cookies also make it possible for our systems to recognize your browser and offer you the best possible configuration.</p>
                <p>We use cookies to collect information about the use of the Website and the Services.</p>
                <p>Each cookie distinguishes you from other Users and Website visitors. Some other techniques, such as web beacons or pixels, have similar purposes to cookies. In this Cookie Policy, the word &apos;cookie&apos; designates the objects delivered by those techniques as well.</p>
                <p>While we do our best to keep this table updated, please note that the number and names of cookies, pixels and other technologies may change from time to time.</p>
                <h2 className="mt-5">2. Types of Cookies</h2>
                <p>In general, there are three different ways to classify cookies: how long they endure, their provenance and what purpose they serve.</p>
                <h3>Duration</h3>
                <p>Session cookies – These cookies are temporary and expire once you close your browser (or once your session ends).</p>
                <p>Persistent cookies — This category encompasses all cookies that remain on your hard drive until you erase them, or your browser does, depending on the cookie&apos;s expiration date. All persistent cookies have an expiration date written into their code, but their duration can vary.</p>
                <h3>Provenance</h3>
                <p>First-party cookies — As the name implies, first-party cookies are put on your device directly by Canned Head Inc..</p>
                <p>Third-party cookies — These are the cookies that are placed on your device, not by Canned Head Inc., but by a third party like an advertiser or an analytic system. Some third-party cookies (for example, Google Analytics) gather information about your browsing activities over time and across different websites, which may result in advertisements or other messages being displayed to you based on your browsing history.</p>
                <p>To get information on whether these are first-party or third-party cookies please check the category &apos;provider&apos; below. Please read the privacy policies of such third parties to understand their processing of personal data.</p>
                <h3>Purpose</h3>
                <p>There are several types of cookies on the Website, which have different purposes:</p>
                <h3>Necessary Cookies</h3>
                <p>Necessary cookies help make a site usable by enabling basic functions like page navigation and access to secure areas of the website, as well as enable core functionality such as security, network management, and accessibility. The Website cannot function properly without these cookies.</p>
                <div className='card p-4 mb-5'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Provider</th>
                                <th>Purpose</th>
                                <th>Expiry</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>__stripe_mid</td>
                                <td>cannedhead.com</td>
                                <td>This cookie is necessary for making credit card transactions on cannedhead.com. The service is provided by Stripe.com which allows online transactions without storing any credit card information.</td>
                                <td>1 year</td>
                                <td>HTTP</td>
                            </tr>
                            <tr>
                                <td>__stripe_sid</td>
                                <td>cannedhead.com</td>
                                <td>This cookie is necessary for making credit card transactions on cannedhead.com. The service is provided by Stripe.com which allows online transactions without storing any credit card information.</td>
                                <td>1 year</td>
                                <td>HTTP</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h3>Preference Cookies</h3>
                <p>Preference cookies enable the Website to remember information that changes the way the Website behaves or looks, like your preferred language or the region you are in.</p>
                <div className='card p-4 mb-5'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Provider</th>
                                <th>Purpose</th>
                                <th>Expiry</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>_ga</td>
                                <td>cannedhead.com</td>
                                <td>This cookie is necessary for making credit card transactions on cannedhead.com. The service is provided by Stripe.com which allows online transactions without storing any credit card information.</td>
                                <td>1 year</td>
                                <td>HTTP</td>
                            </tr>
                            <tr>
                                <td>__stripe_sid</td>
                                <td>cannedhead.com</td>
                                <td>This cookie is necessary for making credit card transactions on cannedhead.com. The service is provided by Stripe.com which allows online transactions without storing any credit card information.</td>
                                <td>1 year</td>
                                <td>HTTP</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h3>Statistics Cookies</h3>
                <p>Statistics cookies, also known as &apos;performance cookies,&apos; and &apos;analytical cookies&apos; help us understand how visitors interact with the Website by collecting and reporting information anonymously. These cookies allow us to count visits and traffic sources so that we can measure and improve the performance of our Website. They help us know which pages are the most and the least popular and see how visitors move around the Website. All information collected by these cookies is collected anonymously and none of this information can be used to identify you. This includes cookies from third-party analytics services as long as the cookies are for the exclusive use of the owner of the website visited. If these cookies are blocked, we will not know when someone visits the Website.</p>
                <div className='card p-4 mb-5'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Provider</th>
                                <th>Purpose</th>
                                <th>Expiry</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>_ga</td>
                                <td>cannedhead.com</td>
                                <td>This cookie is necessary for making credit card transactions on cannedhead.com. The service is provided by Stripe.com which allows online transactions without storing any credit card information.</td>
                                <td>1 year</td>
                                <td>HTTP</td>
                            </tr>
                            <tr>
                                <td>__stripe_sid</td>
                                <td>cannedhead.com</td>
                                <td>This cookie is necessary for making credit card transactions on cannedhead.com. The service is provided by Stripe.com which allows online transactions without storing any credit card information.</td>
                                <td>1 year</td>
                                <td>HTTP</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h3>Marketing Cookies</h3>
                <p>Help us provide you with a good user experience, tailored content, and also allow us to continually improve our Website. Marketing cookies are set up through our location and set by our advertising partners. They can use these companies to create a profile of your interests and show you relevant advertisements in other locations.</p>
                <h3>Functional Cookies</h3>
                <p>Functionality cookies are used to enhance the performance of websites as without them certain functions of the website may not be available. However, they are not vital for a website to run, but they allow to remember important information and user&apos;s preferences.</p>
                <h3>Unclassified Cookies</h3>
                <p>Unclassified cookies are cookies that we are in the process of classifying, together with the providers of individual cookies.</p>
                <h2 className='mt-5'>Use of external tools on our website</h2>
                <h3>3.1. Google Analytics</h3>
                <p>Canned Head Inc. has integrated Google Analytics on the Website (with an anonymization function). Google Analytics is a website analysis service. Website analysis refers to the collection, recording and analysis of data regarding the behavior of Users of Pulse. In other words, Google Analytics uses cookies to remember a User&apos;s behavior and provide Canned Head Inc. with reports on the visits of the Website and User&apos;s activity.</p>
                <p></p>
            </div>
        </>
  )
}

export default CookiePolicy
