import { useState, useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import useSessionStore from 'Context/useSessionStore'

import { useProject } from 'Hooks'

import { Loading } from 'Organisms/Loading'
import { Activity, ProjectHealth, ProjectTimesheet, Settings, TaskAndBudgets, Team } from './Sections'

import { PERMISSION_BY_ROLE } from 'Utils/permissionByRol'

import { HeaderProjectPage } from './Components/HeaderProjectPage'
import { TabsListProject, TabsListProjectLaptop } from './Components/TabsListProject'
import { Integrations } from './Sections/Integrations'
import { useMediaQuery } from 'react-responsive'
import { BREAKPOINT } from 'Utils/const/breakPoints'

const TABS_PROJECT = {
  // ACTIVITY: 1,
  TIMESHEET: 4,
  TASKS: 2,
  TEAM: 3,
  SETTINGS: 5,
  PROJECT_HEALTH: 6,
  INTEGRATIONS: 7
}

const Project = () => {
  // Media query
  const isLaptop = useMediaQuery({
    query: `(max-width: ${BREAKPOINT.LAPTOP})`
  })

  const LIST_TABS_IN_PROJECT = [
    {
      name: "Project's Health",
      tab: TABS_PROJECT.PROJECT_HEALTH
    },
    {
      name: 'Timesheet',
      tab: TABS_PROJECT.TIMESHEET
    },
    {
      name: 'Tasks & Budget',
      tab: TABS_PROJECT.TASKS
    },
    {
      name: 'Team',
      tab: TABS_PROJECT.TEAM
    },
    {
      name: 'Settings',
      tab: TABS_PROJECT.SETTINGS,
      scope: PERMISSION_BY_ROLE.PROJECT.SETTINGS_SECTION.VIEW
    },
    {
      name: 'Integrations',
      tab: TABS_PROJECT.INTEGRATIONS,
      scope: PERMISSION_BY_ROLE.PROJECT.INTEGRATIONS_SECTION.VIEW,
      hidden: isLaptop
    }
  ]

  const { user } = useSessionStore((state) => ({ user: state.user }))
  const { id } = useParams()

  const [searchParams] = useSearchParams()

  const authorizedAzure = searchParams.get('authorized')

  const [loadingPage, setLoadingPage] = useState(true)
  const [tabSection, setTabSection] = useState(authorizedAzure ? TABS_PROJECT.INTEGRATIONS : TABS_PROJECT.PROJECT_HEALTH)

  // hooks
  const { loadingProjectHook, projectInfoHook, getProjectByIdHook } = useProject()

  const handleGetProjectByInfo = async () => {
    await getProjectByIdHook({ idProject: id })
  }
  useEffect(() => {
    ;(async () => {
      try {
        await handleGetProjectByInfo()
      } finally {
        setLoadingPage(false)
      }
    })()
  }, [])

  return (
    <div className="containers project pt-3">
      {loadingProjectHook && <Loading />}

      {!loadingPage && projectInfoHook && (
        <>
          <HeaderProjectPage project={projectInfoHook} />

          <hr className="mb-0" />

          <div className="row">
            <div className="col-lg-3 col-12 p-lg-5 pt-3 pb-3">
              {!isLaptop
                ? (
                <TabsListProject tabsList={LIST_TABS_IN_PROJECT} activeTab={tabSection} onChangeTab={setTabSection} />
                  )
                : (
                <TabsListProjectLaptop
                  tabsList={LIST_TABS_IN_PROJECT}
                  activeTab={tabSection}
                  onChangeTab={setTabSection}
                />
                  )}
            </div>

            <div className="col-lg-9 col-12 project-container pt-lg-5 pt-3">
              {tabSection === TABS_PROJECT.ACTIVITY && <Activity />}

              {tabSection === TABS_PROJECT.TEAM && (
                <Team project={projectInfoHook} refreshProjectInfo={handleGetProjectByInfo} />
              )}

              {tabSection === TABS_PROJECT.TASKS && (
                <TaskAndBudgets
                  tasks={projectInfoHook?.project_tasks || []}
                  idProject={projectInfoHook.id}
                  refreshProjectInfo={handleGetProjectByInfo}
                  totalSpent={projectInfoHook.spent}
                />
              )}

              {tabSection === TABS_PROJECT.TIMESHEET && <ProjectTimesheet project={projectInfoHook} />}

              {tabSection === TABS_PROJECT.SETTINGS &&
                PERMISSION_BY_ROLE.PROJECT.SETTINGS_SECTION.VIEW.includes(user.role) && (
                  <Settings project={projectInfoHook} refreshProjectInfo={handleGetProjectByInfo} />
              )}

              {tabSection === TABS_PROJECT.PROJECT_HEALTH && <ProjectHealth project={projectInfoHook} />}

              {tabSection === TABS_PROJECT.INTEGRATIONS && (
                <Integrations project={projectInfoHook} refreshProjectInfo={handleGetProjectByInfo} />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Project
