import ReactGA from 'react-ga4'

const initializeAnalytics = () => {
  const ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID

  ReactGA.initialize(ID, {
    gtagOptions: {
      send_page_view: false
    }
  }) // GA4 Measurement ID
}

const trackPageView = (page, title) => {
  ReactGA.send({
    hitType: 'pageview',
    page,
    title
  })
}

const trackEvent = ({ category, action, label }) => {
  ReactGA.event({
    category,
    action,
    label
  })
}

export {
  initializeAnalytics,
  trackPageView,
  trackEvent
}
