import { useCallback, useState } from 'react'
import { Plus } from '@phosphor-icons/react'

// Hooks
import { useDebounce, useProject } from 'Hooks'

// Component
import { Loading } from 'Organisms/Loading'
import {
  ModalAddBudgetToTask,
  ModalAddTaskToProject,
  SummaryHoursTaskAndBudget,
  TableBudgets,
  TableTasks
} from './Components'

import { PERMISSION_BY_ROLE } from 'Utils/permissionByRol'
import { countDecimalsAndAdd0 } from 'Utils/helperFunctions'

import useSessionStore from 'Context/useSessionStore'

// Const
import { COLOR_BLACK } from 'constant/colors'
import { TabsListTable } from 'Organisms/TabsListTable'

const TABS = {
  TASKS: {
    name: 'Tasks',
    scope: PERMISSION_BY_ROLE.PROJECT.TASK_SECTION.VIEW
  },
  BUDGET: {
    name: 'Budget per task',
    scope: PERMISSION_BY_ROLE.PROJECT.BUDGET_SECTION.VIEW
  }
}

const TasksBudget = ({ tasks, idProject, refreshProjectInfo, totalSpent }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const totalBudget = countDecimalsAndAdd0({
    number: tasks.reduce((acc, item) => {
      return acc + (item?.budget || 0)
    }, 0)
  })

  const [activeTab, setActiveTab] = useState(
    PERMISSION_BY_ROLE.PROJECT.TASK_SECTION.VIEW.includes(user.role) ? TABS.TASKS.name : TABS.BUDGET.name
  )
  const [addTaksModal, setAddTaskModal] = useState(false)
  const [addBudgetModal, setAddBudgetModal] = useState(false)

  const { loadingProjectHook, updateTaskToProjectHook, deleteTaskToProjectHook } = useProject()

  const handleChangeBillableRateDebounce = useCallback(
    useDebounce(async ({ idTask, newBillableRate }) => {
      try {
        await updateTaskToProjectHook({ idTask, newBillableRate, idProject })
        await refreshProjectInfo()
      } catch (e) {
        console.error(e)
      }
    }, 1000),
    []
  )

  const handleDeleteTaskToProject = async ({ idTask }) => {
    try {
      await deleteTaskToProjectHook({ idTask, idProject })
      await refreshProjectInfo()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      {loadingProjectHook && <Loading />}

      <div className="row justify-content-center tasksbudget">
        <div className="col-lg-11 col-12 d-grid">
          <div className="row">
            <h2 className="mb-3">Tasks and Budget</h2>
            <p className='mb-md-5 mb-0'>You can add tasks and budget in hours to this project and view the modifications.</p>
          </div>

          <SummaryHoursTaskAndBudget totalBudget={totalBudget} totalSpent={totalSpent} />

          <div className="row justify-content-between gy-sm-0 gy-3 d-flex flex-wrap-reverse mb-sm-3">
            <div className="col-sm-auto col-12">
              <TabsListTable tabs={TABS} activeTab={activeTab} onChange={({ tab }) => setActiveTab(tab)} />
            </div>

            <div className="col-sm-auto col-12 d-flex justify-content-end">
              {activeTab === TABS.TASKS.name && (
                <button type="button" className="btn btn-primary" onClick={() => setAddTaskModal(true)} id="createTask">
                  <Plus className="icon" size={17} color={COLOR_BLACK} weight="bold" /> Add Task
                </button>
              )}
              {activeTab === TABS.BUDGET.name && (
                <button type="button" className="btn btn-primary" onClick={() => setAddBudgetModal(true)}>
                  <Plus className="icon" size={15} color={COLOR_BLACK} weight="bold" /> <span>Add budget</span>
                </button>
              )}
            </div>
          </div>

          <div className="card mb-3 p-4">
            {activeTab === TABS.TASKS.name && PERMISSION_BY_ROLE.PROJECT.TASK_SECTION.VIEW.includes(user.role) && (
              <TableTasks
                tasks={tasks}
                onChangeBillableRate={handleChangeBillableRateDebounce}
                onDelete={handleDeleteTaskToProject}
              />
            )}
            {activeTab === TABS.BUDGET.name && PERMISSION_BY_ROLE.PROJECT.BUDGET_SECTION.VIEW.includes(user.role) && (
              <TableBudgets budgets={tasks} />
            )}
          </div>
        </div>
      </div>

      {addTaksModal && (
        <ModalAddTaskToProject
          visible={addTaksModal}
          setVisible={setAddTaskModal}
          oldTask={tasks}
          idProject={idProject}
          refreshProjectInfo={refreshProjectInfo}
        />
      )}

      {addBudgetModal && (
        <ModalAddBudgetToTask
          visible={addBudgetModal}
          setVisible={setAddBudgetModal}
          budgets={tasks}
          idProject={idProject}
          refreshProjectInfo={refreshProjectInfo}
        />
      )}
    </>
  )
}

export default TasksBudget
