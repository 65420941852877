import { useEffect, useState } from 'react'
import { MagnifyingGlass } from '@phosphor-icons/react'
import { COLOR_BLUE_MATE } from 'constant/colors'

const SearchableListButton = ({
  name,
  switches,
  setSwitches,
  onlyOne,
  disabled,
  id,
  style,
  contentWidthAll,
  colorBtn = 'quaternary'
}) => {
  const [searchFilter, setSearchFilter] = useState([])
  const [search, setSearch] = useState('')

  const handleSwitchChange = (itemId) => {
    let update
    if (onlyOne) {
      update = switches.map((item) => {
        if (item.id === itemId) {
          return {
            ...item,
            checked: !item.checked
          }
        }

        return {
          ...item,
          checked: false
        }
      })
    } else {
      update = switches.map((item) => (item.id === itemId ? { ...item, checked: !item.checked } : item))
    }

    setSwitches({ switches: name, values: update })
  }

  const handleSwitchAll = () => {
    let update

    if (switches.some((item) => !item.checked)) {
      update = switches.map((item) => {
        return { ...item, checked: true }
      })
    } else {
      update = switches.map((item) => {
        return { ...item, checked: false }
      })
    }

    setSwitches({ switches: name, values: update })
  }

  useEffect(() => {
    handleSearchFilter({ value: search })
  }, [switches])

  const handleSearchFilter = ({ value }) => {
    setSearch(value)
    setSearchFilter(switches.filter((item) => (item?.text || '').toLowerCase().includes((value || '').toLowerCase())))
  }

  return (
    <div className="dropdown ">
      <button
        className={`btn btn-${colorBtn} dropdown-toggle ${style}`}
        type="button"
        data-bs-toggle="dropdown"
        id={id}
        aria-expanded="false"
        disabled={disabled}
        data-bs-auto-close="outside">
        <span>
          {name}
          <small className="ms-2 badge text-bg-primary me-sm-0 me-auto">
            {switches.filter((item) => item.checked).length > 0 && switches.filter((item) => item.checked).length}
          </small>
        </span>
      </button>

      <ul
        className={`dropdown-menu dropdownCustom pt-0 ${contentWidthAll ? 'width-percent-100' : ''}`}
        id="listFilterUser">
        <li className="dropdown-sticky-search">
          <span className="dropdown-item not-hover">
            <span className="searchInput">
              <input
                type="text"
                value={search}
                className="form-control"
                id="inputSearchFilter"
                onChange={(e) => handleSearchFilter({ value: e.target.value })}
              />
              <MagnifyingGlass size={18} color={COLOR_BLUE_MATE} weight="bold" />
            </span>
          </span>
          <hr className="dropdown-divider" />
        </li>
        {!onlyOne && (
          <>
            <li className="dropdown-item d-flex justify-content-between align-items-center">
              <div className="form-check w-100">
                <input
                  checked={!switches.some((item) => !item.checked)}
                  className="form-check-input"
                  id={`all_${name}`}
                  type="checkbox"
                  onChange={() => handleSwitchAll()}
                />
                <label className="form-check-label w-100" htmlFor={`all_${name}`}>
                  Select all
                </label>
              </div>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
          </>
        )}
        {searchFilter.map((item, index) => (
          <li key={index} className="dropdown-item d-flex justify-content-between align-items-center">
            <div className="form-check w-100">
              <label className="form-check-label w-100" htmlFor={item.id}>
                <input
                  className="form-check-input"
                  id={item.id}
                  checked={item.checked}
                  onChange={() => handleSwitchChange(item.id)}
                  type="checkbox"
                />
                {item.text}
              </label>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default SearchableListButton
