import SearchInput from 'Organisms/SearchInput'
import { PERMISSION_BY_ROLE } from 'Utils/permissionByRol'
import useSessionStore from 'Context/useSessionStore'
import { useMediaQuery } from 'react-responsive'
import { BREAKPOINT } from 'Utils/const/breakPoints'
import ModalFilterUserMobile from './ModalFilterUserMobile'
import PopoverFilterUser from './PopoverFilter'

const HeaderUserPage = ({ onSearch, onFilter }) => {
  const { user: UserContext } = useSessionStore((state) => ({ user: state.user }))

  // Media query
  const isMobile = useMediaQuery({
    query: `(max-width: ${BREAKPOINT.MOBILE})`
  })

  return (
    <div className="row align-items-center gy-4">
      <div className="col-lg-auto">
        <h1 className="mb-0">Users</h1>
      </div>

      <div className="col-lg-auto">
        <SearchInput
          className="form-control form-control-outline"
          placeholder="Search"
          onChange={(e) => onSearch({ search: e.target.value })}
        />
      </div>

      {PERMISSION_BY_ROLE.USER.FILTER_BY_ROLE.ALLOWED.includes(UserContext.role) && (
        <div className="col-xl-2 col-12 col-md-4 d-grid">
          {!isMobile ? <PopoverFilterUser onFilter={onFilter} /> : <ModalFilterUserMobile onFilter={onFilter} />}
        </div>
      )}
    </div>
  )
}

export default HeaderUserPage
