import { DndContext, closestCenter } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import DraggableListItem from './DraggableListItem'
import { Layout } from '@phosphor-icons/react'

const DraggableList = ({ columns, onDragEnd, onChangeActiveSwitch }) => {
  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd}>
      <div className="dropdown">
        <button
          className="btn btn-quaternary dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          data-bs-auto-close="outside">
          <Layout className="icon" size={17} weight="bold" /> Columns
        </button>

        <ul className="dropdown-menu dropdownOrderItems pt-0">
          <SortableContext items={columns} strategy={verticalListSortingStrategy}>
            {columns.map((item) => (
              <DraggableListItem key={item.id} item={item} onChange={onChangeActiveSwitch} />
            ))}
          </SortableContext>
        </ul>
      </div>
    </DndContext>
  )
}

export default DraggableList
