import React from 'react'

const TabsListProjectLaptop = ({ tabsList, activeTab, onChangeTab }) => {
  return (
    <div className="dropdown ">
      <button
        className="btn btn-secondary dropdown-toggle width-percent-100 d-flex align-items-center justify-content-between"
        type="button"
        data-bs-toggle="dropdown"
        id="projectMenuLaptop"
        aria-expanded="false"
        data-bs-auto-close="outside">
        {tabsList.find((item) => item.tab === activeTab).name}
      </button>

      <ul className="dropdown-menu width-percent-100 dropdownCustom pt-0" id="listFilterUser">
        {tabsList.map((item) => {
          if (item?.hidden) return <React.Fragment key={item.id} />

          return (
            <li
              key={item.tab}
              className={`dropdown-item ${activeTab === item.tab ? 'active' : ''}`}
              onClick={() => onChangeTab(item.tab)}>
              {item.name}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default TabsListProjectLaptop
