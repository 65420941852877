import { useEffect, useState } from 'react'
import SearchableListButton from './SearchableListButton'
import { useProject, useTasks, useTicket, useUser } from 'Hooks'
import useSessionStore from 'Context/useSessionStore'
import { ROLE_DEVELOPER_NAME } from 'constant/roles'
import { useMediaQuery } from 'react-responsive'
import { BREAKPOINT } from 'Utils/const/breakPoints'

const SWITCHES_NAME = {
  SPRINT: 'sprints',
  PROJECTS: 'projects',
  STATES: 'states',
  TASK: 'tasks',
  USERS: 'users'
}

const FilterAllDropdown = ({
  onChangeFilter,
  hiddenClear,
  hiddenProject,
  hiddenSprint,
  hiddenStatus,
  hiddenTasks,
  hiddenUsers,
  disabled,
  notMe,
  clearOut,
  colorBtn,
  defaultSelectProject = [],
  defaultSelectStatus = [],
  defaultSelectUsers = [],
  defaultSelectTasks = []
}) => {
  // Media query
  const isMobile = useMediaQuery({
    query: `(max-width: ${BREAKPOINT.MOBILE})`
  })

  const { user } = useSessionStore((state) => ({ user: state.user }))

  const [projectsFilter, setProjectsFilter] = useState([])
  const [usersFilter, setUsersFilter] = useState([])
  const [taskTypesFilter, setTaskTypesFilter] = useState([])
  const [statesFilter, setStatesFilter] = useState([])
  const [sprintFilter, setSprintFilter] = useState([])

  const { getAllProjectsByFilterHook } = useProject()
  const { getAllUsersByFilterHook } = useUser()
  const { getAllTasksByFilterHook } = useTasks()
  const {
    getAllStatusByFilterHook
  } = useTicket()

  // Getters
  const getAllProjects = async ({ clear } = {}) => {
    try {
      const projects = await getAllProjectsByFilterHook()

      const defaultActiveProject = !clear
        ? projects.map((project) => {
          if (defaultSelectProject.includes(project.id)) {
            return {
              ...project,
              checked: true
            }
          }

          return project
        })
        : projects

      setProjectsFilter(defaultActiveProject)
    } catch (e) {
      console.error(e)
    }
  }

  const getAllSprint = async () => {
    // try {
    // const sprints = await getAllSprintByFilterHook()
    // setSprintFilter(sprints)
    // return sprints
    return []
    // } catch (e) {
    // console.error(e)
    // }
  }

  const getAllStatus = async ({ clear } = {}) => {
    try {
      const status = await getAllStatusByFilterHook()

      const defaultActiveStatus = !clear
        ? status.map((status) => {
          if (defaultSelectStatus.includes(status.value)) {
            return {
              ...status,
              checked: true
            }
          }

          return status
        })
        : status

      setStatesFilter(defaultActiveStatus)
      return status
      // return []
    } catch (e) {
      console.error(e)
    }
  }

  const getAllUsers = async ({ projectsId, clear } = {}) => {
    try {
      const users = await getAllUsersByFilterHook({ projectsId, hiddenUser: notMe ? user : null })

      const defaultActiveUser = !clear
        ? users.map((user) => {
          if (defaultSelectUsers.includes(user.value)) {
            return {
              ...user,
              checked: true
            }
          }

          return user
        })
        : users

      setUsersFilter(defaultActiveUser)
      return users
    } catch (e) {
      console.error(e)
    }
  }

  const getAllTasks = async ({ projectsId, clear } = {}) => {
    try {
      const tasks = await getAllTasksByFilterHook({ projectsId })

      const defaultActiveTasks = !clear
        ? tasks.map((task) => {
          if (defaultSelectTasks.includes(task.value)) {
            return {
              ...task,
              checked: true
            }
          }

          return task
        })
        : tasks

      setTaskTypesFilter(defaultActiveTasks)
      return tasks
    } catch (e) {
      console.error(e)
    }
  }

  // Helpers
  const handleBuildObjectFilter = ({ newFilter = '', newValues = [] } = {}) => ({
    projects: projectsFilter,
    states: statesFilter,
    tasks: taskTypesFilter,
    users: usersFilter,
    sprints: sprintFilter,
    ...(newFilter && { [newFilter.toLowerCase()]: newValues })
  })

  const handleCreateListPromise = ({ projectsId, noProject, clear } = {}) => {
    const listPromise = []

    if (!hiddenSprint) listPromise.push(getAllSprint({ clear }))
    if (!hiddenStatus) listPromise.push(getAllStatus({ clear }))
    if (!hiddenTasks) listPromise.push(getAllTasks({ projectsId, clear }))
    if (!hiddenProject && !noProject) listPromise.push(getAllProjects({ clear }))

    if (user.role !== ROLE_DEVELOPER_NAME && !hiddenUsers) listPromise.push(getAllUsers({ projectsId, clear }))

    return listPromise
  }

  const handleChangeFilterProject = async ({ switches, values } = {}) => {
    setProjectsFilter(values)

    const projectsIdActive = values
      .filter((item) => item.checked)
      .map((item) => item.id)
      .join(',')

    const listPromise = handleCreateListPromise({ projectsId: projectsIdActive, noProject: true, clear: true })

    const results = await Promise.allSettled(listPromise)

    const switchesSprint = results[0]?.status === 'fulfilled' ? results[0].values : []
    const switchesStatus = results[1]?.status === 'fulfilled' ? results[1].values : []
    const switchesTasks = results[2]?.status === 'fulfilled' ? results[2].values : []
    const switchesUsers = results[3]?.status === 'fulfilled' ? results[3].values : []

    const filter = handleBuildObjectFilter({ newFilter: switches, newValues: values })
    filter.sprints = switchesSprint
    filter.states = switchesStatus
    filter.users = switchesUsers
    filter.tasks = switchesTasks

    onChangeFilter && onChangeFilter({ filter })
  }

  const handleChangeSwitches = async ({ switches, values } = {}) => {
    if (switches === SWITCHES_NAME.STATES) setStatesFilter(values)

    if (switches === SWITCHES_NAME.TASK) setTaskTypesFilter(values)

    if (switches === SWITCHES_NAME.USERS) setUsersFilter(values)

    if (switches === SWITCHES_NAME.SPRINT) setSprintFilter(values)

    if (switches === SWITCHES_NAME.PROJECTS) setProjectsFilter(values)

    const filter = handleBuildObjectFilter({ newFilter: switches, newValues: values })

    onChangeFilter && onChangeFilter({ filter })
  }

  const handleClearFilter = async () => {
    const listPromise = handleCreateListPromise({ clear: true })

    await Promise.allSettled(listPromise)

    onChangeFilter && onChangeFilter()
  }

  useEffect(() => {
    const querys = defaultSelectProject.length > 0 ? { projectsId: defaultSelectProject.join(',') } : {}
    const listPromise = handleCreateListPromise(querys)

    Promise.allSettled(listPromise)
  }, [clearOut])

  return (
    <div className="col-auto">
      <div className="row gy-3">
        {!hiddenProject && (
          <div className="col-sm-auto col-12">
            {isMobile && <p className="mb-1 fs-6">Projects</p>}
            <SearchableListButton
              colorBtn={colorBtn}
              contentWidthAll={isMobile}
              style={isMobile ? 'width-percent-100 d-flex justify-content-between align-items-center' : ''}
              id="filterProject"
              name={SWITCHES_NAME.PROJECTS}
              switches={projectsFilter || []}
              setSwitches={handleChangeFilterProject}
              disabled={disabled}
            />
          </div>
        )}
        {!hiddenSprint && (
          <div className="col-sm-auto col-12">
            {isMobile && <p className="mb-1 fs-6">Sprints</p>}
            <SearchableListButton
              colorBtn={colorBtn}
              contentWidthAll={isMobile}
              style={isMobile ? 'width-percent-100 d-flex justify-content-between align-items-center' : ''}
              id="filterSprint"
              name={SWITCHES_NAME.SPRINT}
              switches={sprintFilter || []}
              setSwitches={handleChangeSwitches}
              disabled={disabled}
            />
          </div>
        )}

        {!hiddenStatus && (
          <div className="col-sm-auto col-12">
            {isMobile && <p className="mb-1 fs-6">States</p>}
            <SearchableListButton
              colorBtn={colorBtn}
              contentWidthAll={isMobile}
              style={isMobile ? 'width-percent-100 d-flex justify-content-between align-items-center' : ''}
              id="filterStates"
              name={SWITCHES_NAME.STATES}
              switches={statesFilter || []}
              setSwitches={handleChangeSwitches}
              disabled={disabled}
            />
          </div>
        )}

        {!hiddenTasks && (
          <div className="col-sm-auto col-12">
            {isMobile && <p className="mb-1 fs-6">Tasks</p>}
            <SearchableListButton
              colorBtn={colorBtn}
              contentWidthAll={isMobile}
              style={isMobile ? 'width-percent-100 d-flex justify-content-between align-items-center' : ''}
              id="filterTask"
              name={SWITCHES_NAME.TASK}
              switches={taskTypesFilter || []}
              setSwitches={handleChangeSwitches}
              disabled={disabled}
            />
          </div>
        )}

        {!hiddenUsers && (
          <div className="col-sm-auto col-12">
            {isMobile && <p className="mb-1 fs-6">Users</p>}
            <SearchableListButton
              colorBtn={colorBtn}
              contentWidthAll={isMobile}
              style={isMobile ? 'width-percent-100 d-flex justify-content-between align-items-center' : ''}
              id="filterUser"
              name={SWITCHES_NAME.USERS}
              switches={usersFilter || []}
              setSwitches={handleChangeSwitches}
              disabled={disabled}
            />
          </div>
        )}

        {!hiddenClear && (
          <div className="col-sm-auto col-12">
            <button
              type="button"
              className={`btn btn-ghost text-quaternary ${
                isMobile ? 'width-percent-100 d-flex justify-content-between align-items-center' : ''
              }`}
              id="clearFilter"
              onClick={handleClearFilter}
              disabled={disabled}>
              Clear filters
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default FilterAllDropdown
