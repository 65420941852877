import { useEffect, useState } from 'react'
import { Check } from '@phosphor-icons/react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
// import CheckoutForm from './CheckoutForm'
import { Logo } from 'Organisms'

const stripePromise = loadStripe('pk_test_hDGHQfVO4clQw1zeEXTpZopW00EpCpju6Y')

const Checkout = () => {
  // const [clientSecret, setClientSecret] = useState('')
  const [selectedPeriod, setSelectedPeriod] = useState('annually')
  const [quantity, setQuantity] = useState(1)
  const [
    discount
    // setDiscount
  ] = useState(10)
  const [couponVisible, isCouponVisible] = useState(false)

  const handleSwitchChange = (e) => {
    setSelectedPeriod(e.target.value)
  }

  const increment = () => {
    setQuantity(quantity + 1)
  }

  const decrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1)
    }
  }

  useEffect(() => {}, [])

  // const options = {
  //   clientSecret
  // }

  return (
    <div className="container checkout">
      <div className="row justify-content-center checkout_header">
        <div className="col-6 col-md-2 logo">
          <Logo />
        </div>
      </div>
      <div className="row justify-content-center mb-4">
        <h1 className="col-12 text-center text-md-start">Checkout</h1>
      </div>
      <Elements stripe={stripePromise}>
        <form className="row justify-content-between">
          <div className="col-12 col-md-6">
            <div className="card p-3 p-md-5 professional-plan">
              <div className="row justify-content-between align-items-center">
                <div className="col-auto">
                  <h4 className="mb-2">Professional Plan</h4>
                </div>
                <div className="col-auto">
                  <h5 className="mb-2">${selectedPeriod === 'annually' ? '12' : '15'} per seat/month</h5>
                </div>
              </div>
              <div className="row">
                <p className="mb-0">Billed {selectedPeriod}</p>
              </div>
            </div>
            <div className="row mt-3">
              <p>
                <Check size={12} weight="bold" color={'#00DDD8'} className="me-1" />
                Unlimited Projects
              </p>
            </div>
            <div className="row">
              <p>
                <Check size={12} weight="bold" color={'#00DDD8'} className="me-1" />
                Limit user&apos;s access to projects
              </p>
            </div>
            <div className="row">
              <p>
                <Check size={12} weight="bold" color={'#00DDD8'} className="me-1" />
                250 GB of Storage
              </p>
            </div>

            <h3 className="mt-4">Billing Information</h3>
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="firstName" className="form-label">
                  First Name
                </label>
                <input type="text" className="form-control" id="firstName" name="firstName" required />
              </div>
              <div className="col-md-6">
                <label htmlFor="lastName" className="form-label">
                  Last Name
                </label>
                <input type="text" className="form-control" id="lastName" name="lastName" required />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="address" className="form-label">
                Address
              </label>
              <input type="text" className="form-control" id="address" name="address" required />
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="city" className="form-label">
                  City
                </label>
                <input type="text" className="form-control" id="city" name="city" required />
              </div>
              <div className="col-md-4">
                <label htmlFor="state" className="form-label">
                  State
                </label>
                <input type="text" className="form-control" id="state" name="state" required />
              </div>
              <div className="col-md-2">
                <label htmlFor="zip" className="form-label">
                  ZIP Code
                </label>
                <input type="text" className="form-control" id="zip" name="zip" required />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="companyName" className="form-label">
                Company Name
              </label>
              <input type="text" className="form-control" id="companyName" name="companyName" />
            </div>
            <h3 className="mt-5">Payment Method</h3>
            <div className="mb-3">
              <label htmlFor="cardNumber" className="form-label">
                Card Number
              </label>
              <input type="text" className="form-control" id="cardNumber" name="cardNumber" required />
            </div>
            <div className="row mb-5">
              <div className="col-md-6">
                <label htmlFor="expirationDate" className="form-label">
                  Expiration Date
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="expirationDate"
                  name="expirationDate"
                  placeholder="MM/YYYY"
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="cvv" className="form-label">
                  CVV
                </label>
                <input type="text" className="form-control" id="cvv" name="cvv" required />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5">
            <div className="card p-4 p-md-5 summary">
              <div className="row">
                <h4 className="mb-4">Subscription</h4>
              </div>
              <div className="row">
                <div className="col-auto">
                  <h5 className="mt-2 mb-3">Select a period</h5>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-auto">
                  <div className="form-check form-check-inline">
                    <input
                      name="period"
                      className="form-check-input"
                      type="radio"
                      id="inlineCheckbox1"
                      value="annually"
                      checked={selectedPeriod === 'annually'}
                      onChange={handleSwitchChange}
                    />
                    <label className="form-check-label" htmlFor="inlineCheckbox1">
                      Pay annually
                    </label>
                  </div>
                </div>
                <div className="col-auto">
                  <div name="period" className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="inlineCheckbox2"
                      value="monthly"
                      checked={selectedPeriod === 'monthly'}
                      onChange={handleSwitchChange}
                    />
                    <label className="form-check-label" htmlFor="inlineCheckbox2">
                      Pay monthly
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mb-3 justify-content-between align-items-center">
                <div className="col-auto">
                  <h5>Add Seats</h5>
                  <p className="small">Each additional seat is ${selectedPeriod === 'annually' ? '12' : '15'}/mo</p>
                </div>
                <div className="col-auto">
                  <div className="input-group number-input">
                    <button type="button" className="btn btn-outline-secondary" onClick={decrement}>
                      -
                    </button>
                    <input type="number" className="form-control seats-input" value={quantity} readOnly />
                    <button type="button" className="btn btn-outline-secondary" onClick={increment}>
                      +
                    </button>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                {!couponVisible && (
                  <div className="col-auto">
                    <button type="button" className="btn btn-ghost-primary" onClick={() => isCouponVisible(true)}>
                      + Add a coupon
                    </button>
                  </div>
                )}
                {couponVisible && (
                  <div className="row row-cols-lg-auto align-items-center">
                    <div className="col-12">
                      <input type="text" className="form-control" placeholder="Coupon" />
                    </div>
                    <div className="col-12">
                      <button type="button" className="btn btn-secondary">
                        Apply
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="card summary-card p-3 p-md-5">
                  <div className="row">
                    <div className="col-auto">
                      <h5>Summary</h5>
                    </div>
                  </div>
                  <div className="row justify-content-between mt-4">
                    <div className="col-auto">
                      <p className="mb-1">
                        {quantity} {quantity > 1 ? 'users' : 'user'} x ${selectedPeriod === 'annually' ? '12' : '15'}{' '}
                        USD
                      </p>
                      <p className="small">Paid annualy</p>
                    </div>
                    <div className="col-auto">
                      <p className="mb-5">${selectedPeriod === 'annually' ? 12 * quantity : 15 * quantity} USD</p>
                    </div>
                  </div>
                  <div className="row justify-content-between">
                    <div className="col-auto">
                      <p>Discount</p>
                    </div>
                    <div className="col-auto">
                      <p className="text-success">-${discount} USD (10%)</p>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12">
                      <button type="button" className="btn btn-lg btn-primary col-12">
                        Pay ${selectedPeriod === 'annually' ? 12 * quantity - discount : 15 * quantity - discount} USD
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Elements>
    </div>
  )
}

export default Checkout
