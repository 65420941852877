import mixpanel from 'mixpanel-browser'

const MIX_PANEL_TOKEN = process.env.REACT_APP_MIX_PANEL

mixpanel.init(MIX_PANEL_TOKEN, { debug: true, persistence: 'localStorage' })

const actions = {
  identify: (id) => {
    mixpanel.identify(id)
  },
  alias: (id) => {
    mixpanel.alias(id)
  },
  track: (name, props) => {
    mixpanel.track(name, props)
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props)
    }
  },
  reset: () => {
    mixpanel.reset()
  }
}

export const Mixpanel = actions
