import { Bell, BracketsCurly, CaretRight, ChartBar, Check, Coins, Gear, Users } from '@phosphor-icons/react'
import { COLOR_EMERALD2, GET_COLOR_BY_THEME } from 'constant/colors'
import { SIGNUP_ROUTER } from 'constant/router'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Performance from '../../Assets/images/landing/Performance.svg'
import DashboardSvg from '../../Assets/images/landing/Dashboard.svg'
import AzureSvg from '../../Assets/images/landing/Azure.svg'
import JiraSvg from '../../Assets/images/landing/Jira.svg'
import GithubSvg from '../../Assets/images/landing/Github.svg'
import IntegrationSvg from '../../Assets/images/landing/Integrations.svg'
import PayrollSvg from '../../Assets/images/landing/Payroll-Projects.svg'
import TimeTrackerSvg from '../../Assets/images/landing/TimeTracker.svg'
import TicketsSvg from '../../Assets/images/landing/Tickets.svg'
import CurrentUsers from '../../Assets/images/landing/Photos.png'
import Stars from '../../Assets/images/landing/Stars3.svg'
import HackerNews from '../../Assets/images/landing/HackerNews.svg'
import ProductHunt from '../../Assets/images/landing/ProductHunt.svg'
import X from '../../Assets/images/landing/X.svg'
import Reddit from '../../Assets/images/landing/Reddit.svg'
import { CollapseItem } from 'Organisms/Collapse'
import { trackEvent } from 'Utils/GoogleAnalytics'
import Footer from 'Organisms/Footer'
import CookieBanner from 'Organisms/CookieBanner'

const LandingPage = () => {
  const navigate = useNavigate()
  const trackCTA = async (label) => {
    await trackEvent({
      category: 'User',
      action: 'Go to Sign up',
      label
    })
  }

  return (
    <>
      <Helmet>
        <title>Time Tracking Software For Remote Employees</title>
        <meta
          name="description"
          content="Discover the best time tracking software designed for remote employees. Boost productivity, manage time efficiently, and gain insights into your remote team's performance."
        />
        <meta name="keywords" content="time tracker, time tracking, remote employees, productivity, software, remote work" />
        <meta name="author" content="Pulse by Canned Head" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Time Tracking Software For Remote Employees" />
        <meta property="og:description" content="Discover the best time tracking software designed for remote employees. Boost productivity, manage time efficiently, and gain insights into your remote team's performance." />
        <meta property="og:image" content="https://example.com/image.jpg" />
        <meta property="og:url" content="https://example.com/time-tracking-software" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className='container-fluid'>
        <section className='container'>
            <div className="row">
              <div className='landing_hero'>
                <span className="badge text-bg-secondary">Currently in Beta</span>
                <h1 className="m-0 landing_title text-gradient">Discover the best time tracking software <br/>designed for remote employees</h1>
                <p className="m-0 fs-5">We use time tracking to boost productivity, manage time efficiently, gain insights<br className='d-none d-md-flex'/> into your remote team&apos;s performance and conquer business profitability.</p>
                <div className='row'>
                  <div className='col-auto'>
                    <button type="button" className="btn btn-primary btn-lg" onClick={() => { trackCTA('Start Tracking 1'); navigate(SIGNUP_ROUTER) }}>
                      Start Tracking
                      <CaretRight weight="bold" className="ms-1" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-5 align-items-center">
              <div className='col-6 col-md-3 text-end'>
                <img className='img-fluid mb-2' src={CurrentUsers} />
              </div>
              <div className='col-6 col-md-3'>
                <img className='img-fluid' src={Stars} />
                <p className='mt-2'>+1357 Remoters boosting efficiency</p>
              </div>
            </div>

            <section className='featured-on-section row justify-content-center align-items-center'>
              <div className='col-12 col-md-auto'>
                <p className='mb-0 featured-on text-center mb-4 mb-md-0'>Featured on</p>
              </div>
              <div className='col-6 col-md-auto text-center mb-4 mb-md-0'>
                <a href="https://news.ycombinator.com/" target="_blank" rel="noopener noreferrer">
                  <img className="featured-brand img-fluid" src={HackerNews}/>
                </a>
              </div>
              <div className='col-6 col-md-auto text-center mb-4 mb-md-0'>
                <a href="https://www.producthunt.com/" target="_blank" rel="noopener noreferrer">
                  <img className="featured-brand img-fluid" src={ProductHunt}/>
                </a>
              </div>
              <div className='col-6 col-md-auto text-center mb-4 mb-md-0'>
                <a href="https://www.reddit.com/" target="_blank" rel="noopener noreferrer">
                  <img className="featured-brand img-fluid" src={Reddit}/>
                </a>
              </div>
              <div className='col-6 col-md-auto text-center mb-4 mb-md-0'>
                <a href="https://twitter.com" target='_blank' rel="noopener noreferrer">
                  <img className="featured-brand img-fluid" src={X}/>
                </a>
              </div>
        </section>

            <div className="row justify-content-center mt-5">
              <div className='col-12 col-md-10'>
                <img className='img-fluid' src={DashboardSvg} />
              </div>
            </div>
        </section>

        <div className="row landing">

          <div className="landing_img_text_bg">
            <div className="containers">
              <div className="landing_feature">
                <div className="landing_feature_content">
                  <Gear color={COLOR_EMERALD2} size={30} />
                  <p className="m-0">
                    Manage your workforce. <span className='text-terciary'>Boost efficiency in employee onboarding and resource management.</span>
                  </p>
                </div>
                <div className="landing_feature_content">
                  <Users color={COLOR_EMERALD2} size={30} />
                  <p className="m-0">
                    Get a complete overview of your team&apos;s time. <span className='text-terciary'>Track time and get data-driven insights of your
                    projects and team performance.</span>
                  </p>
                </div>
                <div className="landing_feature_content">
                  <Coins color={COLOR_EMERALD2} size={30} />
                  <p className="m-0">
                    Take control your company finances. <span className='text-terciary'>Avoid a high burn rate, monitoring your budget and payroll.</span>
                  </p>
                </div>
                <div className="landing_feature_content">
                  <ChartBar color={COLOR_EMERALD2} size={30} />
                  <p className="m-0">
                    Forecasting. <span className='text-terciary'>Get budget projections to the financial aspects of projects, such us estimated costs and
                    revenue.</span>
                  </p>
                </div>
                <div className="landing_feature_content">
                  <BracketsCurly color={COLOR_EMERALD2} size={30} />
                  <p className="m-0">
                    Integrate your issue and project tracking software. <span className='text-terciary'>Connect data across your existing issue and project
                    tracking software.</span>
                  </p>
                </div>
                <div className="landing_feature_content">
                  <Bell color={COLOR_EMERALD2} size={30} />
                  <p className="m-0">
                    Integrate alerts into your tool ecosystem. <span className='text-terciary'>Understand the true status of your projects,
                    receive preventive alerts to stay on track.</span>
                  </p>
                </div>
              </div>

              <div className="landing_connect">
                <h2 className="m-0 landing_title landing_short">
                  Bring all your <br />
                  data together
                </h2>

                <p className="m-0">
                  We integrate your work your project <br /> tracking software with your financial
                </p>

                <div className="landing_connect_icons mt-2">
                  <img src={AzureSvg} />
                  <img src={JiraSvg} />
                  <img src={GithubSvg} />
                  <img src={IntegrationSvg} />
                </div>
              </div>

              <div className="lading_img_text">

                <div className="landing_section">
                  <img className='pe-md-5' src={TimeTrackerSvg} />
                  <div className="landing_section_text">
                    <h3>Time management</h3>
                    <h2>Time Tracking</h2>
                    <p className='mb-1'><Check size={12} weight="bold" color={GET_COLOR_BY_THEME({ theme: true }).PRIMARY} className="me-2" />Identify time-wasting activities, leading to better efficiency.</p>
                    <p className='mb-1'><Check size={12} weight="bold" color={GET_COLOR_BY_THEME({ theme: true }).PRIMARY} className="me-2" />Track employee work hours precisely for reliable payroll and billing.</p>
                    <p className='mb-1'><Check size={12} weight="bold" color={GET_COLOR_BY_THEME({ theme: true }).PRIMARY} className="me-2" />Provide records for labor laws and auditing requirements.</p>
                    <button type="button" className="btn btn-secondary d-none" onClick={() => navigate(SIGNUP_ROUTER)}>
                      Learn More
                    </button>
                  </div>
                </div>

                <div className="landing_section">
                  <div className="landing_section_text">
                    <h3>Dashboards & Reporting</h3>
                    <h2>Analize profitability and productivity</h2>
                    <p className='mb-1'><Check size={12} weight="bold" color={GET_COLOR_BY_THEME({ theme: true }).PRIMARY} className="me-2" />Evaluating the profitability of individual projects provide insights into
                      which types of projects generate the highest returns and which may be less profitable.</p>
                      <p className='mb-1'><Check size={12} weight="bold" color={GET_COLOR_BY_THEME({ theme: true }).PRIMARY} className="me-2" />Make strategic decisions about project scope, employee workload, and operational efficiencies.</p>
                    <button type="button" className="btn btn-secondary d-none" onClick={() => navigate(SIGNUP_ROUTER)}>
                      Learn More
                    </button>
                  </div>
                  <img className='ps-md-5' src={Performance} />
                </div>

                <div className="landing_section">
                  <img className='pe-md-5' src={PayrollSvg} />

                  <div className="landing_section_text ps-md-5">
                    <p>Payroll</p>
                    <h2>Paying contractors or employees</h2>
                    <p className='mb-1'><Check size={12} weight="bold" color={GET_COLOR_BY_THEME({ theme: true }).PRIMARY} className="me-2" />Keeping accurate records of payments, invoices, and financial transactions is essential for
                      compliance and financial reporting purposes.</p>
                    <p className='mb-1'><Check size={12} weight="bold" color={GET_COLOR_BY_THEME({ theme: true }).PRIMARY} className="me-2" />Avoid inadequate record-keeping practices can lead to errors,
                      audits and financial discrepancies
                    </p>
                    <button type="button" className="btn btn-secondary d-none" onClick={() => navigate(SIGNUP_ROUTER)}>
                      Learn More
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="containers">
            <div className="lading_billing">
              <h2 className="m-0 landing_title landing_short">Billing clients & Budget management</h2>
              <p className="my-3 mx-0 landing_description">
                Generate proforma invoices, as a useful tool for initiating sales transactions, clarifying terms and
                conditions, estimating costs, facilitating customs clearance, securing financing and avoiding disputes.
              </p>
              <button type="button" className="btn btn-primary btn-lg" onClick={() => { trackCTA('Start now'); navigate(SIGNUP_ROUTER) }}>
                Start now
                <CaretRight weight="bold" className="ms-1" />
              </button>
            </div>
          </div>
        </div>

        <section className='row justify-content-center'>
          <div className='col-12 col-md-9'>
            <img className="img-fluid mt-5" src={TicketsSvg} />
          </div>
        </section>

        <div id="faq" className='container faq-wrapper'>
          <section className="row align-items-center">
            <div className='col-12 col-md-6'>
              <h2>Frequently Asked Questions</h2>
            </div>
            <div className='col-12 col-md-6'>
              <CollapseItem collapseId="faq1" buttonText="What is a time tracking app, and why do I need it?" >
                <p>A time tracking app helps you record the hours worked on various tasks or projects. It&apos;s useful for businesses to track employee hours, freelancers to bill clients accurately, and project managers to monitor productivity and project timelines.</p>
              </CollapseItem>
              <CollapseItem collapseId="faq2" buttonText="Is the time tracking app compatible with different devices?" >
                <p>Yes, our time tracking app is web-based, making it compatible with any device that has a web browser and internet connection. This means you can use it on desktops, laptops, tablets, and smartphones without any additional installation. It&apos;s a flexible solution that works across different platforms, providing you with seamless access wherever you are.</p>
              </CollapseItem>
              <CollapseItem collapseId="faq3" buttonText="How secure is my data in the time tracking app?" >
                <p>Time tracking apps typically use encryption and other security measures to protect your data. Check the app&apos;s <a href="/privacy-policy" target="_blank">privacy policy</a> and security features to ensure your information is safeguarded.</p>
              </CollapseItem>
              <CollapseItem collapseId="faq4" buttonText="Can I generate reports from the time tracking app?" >
                <p>Yes, We offer reporting features that allow you to generate various types of reports, such as timesheets, project summaries, and billing statements(coming soon). These reports can usually be exported in CSV or Excel.</p>
              </CollapseItem>
              <CollapseItem collapseId="faq5" buttonText="Can I track time for different clients and projects at the same time?" >
                <p>Yes, our app allows you to track time for multiple clients and projects simultaneously. You can easily switch between projects or clients while tracking your work. This flexibility helps you manage your time effectively across different tasks and ensures accurate reporting for each client and project. The app provides a clear breakdown, making it simple to generate timesheets and invoices tailored to each client or project.</p>
              </CollapseItem>
              <CollapseItem collapseId="faq6" buttonText="Does the app integrate with other software tools?" >
                <p>Yes, our app offers an API that allows integration with various software tools and platforms. This flexibility lets you connect with other applications for data sharing, automation, and custom workflows. However, if you require technical assistance or custom integration work, our support team is ready to help. Just <a className='/contact'>reach out</a>, and we&apos;ll guide you through the process to ensure smooth and effective integration.</p>
              </CollapseItem>
            </div>
          </section>
        </div>

        <div className='landing'>
          <div className="containers">
            <div className="landing_end">
              <h2 className="m-0 landing_title landing_short">Stop wasting time with complex spreadsheets</h2>
              <p className="m-0">Make sure your business operates efficently</p>

              <div className="landing_end_btns">
                <button type="button" className="btn btn-secondary d-none" onClick={() => navigate(SIGNUP_ROUTER)}>
                  Learn More about us
                </button>
                <button type="button" className="btn btn-primary" onClick={() => { trackCTA('Free 30-day trial - Footer'); navigate(SIGNUP_ROUTER) }}>
                   Free 30-day trial
                </button>
              </div>
            </div>
          </div>
        </div>

        <Footer/>
      </div>
      <CookieBanner/>
    </>
  )
}

export default LandingPage
