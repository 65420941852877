export const ROLE_ADMIN_NAME = 'admin'
export const ROLE_ADMIN_DISPLAY = 'Administrator'

export const ROLE_OPERATION_MANAGER_NAME = 'opsmanager'
export const ROLE_OPERATION_MANAGER_DISPLAY = 'Operations Manager'

export const ROLE_PRODUCT_OWNER_NAME = 'po'
export const ROLE_PRODUCT_OWNER_DISPLAY = 'Product Owner'

export const ROLE_DEVELOPER_NAME = 'dev'
export const ROLE_DEVELOPER_DISPLAY = 'Developer'

export const ROLES_DISPLAY = {
  [ROLE_ADMIN_NAME]: ROLE_ADMIN_DISPLAY,
  [ROLE_OPERATION_MANAGER_NAME]: ROLE_OPERATION_MANAGER_DISPLAY,
  [ROLE_PRODUCT_OWNER_NAME]: ROLE_PRODUCT_OWNER_DISPLAY,
  [ROLE_DEVELOPER_NAME]: ROLE_DEVELOPER_DISPLAY
}

export const ROLES_BY_DISPLAY = {
  [ROLE_ADMIN_DISPLAY]: ROLE_ADMIN_NAME,
  [ROLE_OPERATION_MANAGER_DISPLAY]: ROLE_OPERATION_MANAGER_NAME,
  [ROLE_PRODUCT_OWNER_DISPLAY]: ROLE_PRODUCT_OWNER_NAME,
  [ROLE_DEVELOPER_DISPLAY]: ROLE_DEVELOPER_NAME
}
