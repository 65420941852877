import axios from 'axios'

const BASE_URL_USER = '/users'

const getMe = async () =>
  axios
    .get('/me')
    .then(({ data }) => data)
    .catch(() => null)

// GET
const getUserService = async ({ idUser }) => axios.get(`${BASE_URL_USER}/${idUser}`).then(({ data }) => data)
const getUsers = async (queryParams) => axios.get(`/users?${queryParams}`).then(({ data }) => data)
const downloadCsvService = async () => axios.get('/users/downloadCsv').then(({ data }) => data)

// POST
const createUser = async (body) => axios.post('/users', { ...body }).then(({ data }) => data)
const assignProjectToUserService = async ({ userId, listProjectId }) =>
  axios.post(`/users/${userId}/projects`, { projects: listProjectId }).then(({ data }) => data)

export const uploadUserAvatarService = async ({ formData, keyImage }) =>
  axios
    .post('/files/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Bucket: 'BUCKET',
        Key: keyImage
      }
    })
    .then(({ data }) => data)

// PUT/PATCH
const updateMe = async (body) => axios.put('/me', { ...body }).then(({ data }) => data)
const updateUser = async (body, id) => axios.put('/users/' + id, { ...body }).then(({ data }) => data)
const updateUserService = async ({ userId, body }) =>
  axios.put(`${BASE_URL_USER}/${userId}`, body).then(({ data }) => data)

export const updateProfileUserService = async ({ body }) => axios.patch(BASE_URL_USER, body).then(({ data }) => data)

// DELETE
const deleteUserService = async ({ id }) => axios.delete('/users/' + id).then(({ data }) => data)
const deleteResourceUserService = async ({ idUser, idResource }) =>
  axios.delete(`/users/${idUser}/resource/${idResource}`).then(({ data }) => data)

export {
  createUser,
  updateUser,
  getUserService,
  getUsers,
  deleteUserService,
  getMe,
  updateMe,
  updateUserService,
  assignProjectToUserService,
  downloadCsvService,
  deleteResourceUserService
}
