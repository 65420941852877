import { COLOR_GREY } from 'constant/colors'
import { CaretRight } from '@phosphor-icons/react'

const CollapseItem = ({
  buttonText, // Customizable button text
  collapseId, // Unique identifier for the collapse functionality
  children // Customizable content for the card-body
}) => {
  return (
        <>
            <p className="row collapseItemButton mb-0">
              <button
                  className="btn text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#${collapseId}`}
                  aria-expanded="false"
                  aria-controls={collapseId} >
                  <CaretRight size={24} weight="bold" color={COLOR_GREY} />{buttonText}
              </button>
            </p>
            <div className="collapse collapseItemContent" id={collapseId}>
              <div className="card card-body">
                {children}
              </div>
            </div>
        </>
  )
}

export default CollapseItem
