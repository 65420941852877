import useSessionStore from 'Context/useSessionStore'
import { FIELD_CLIENT_ID_PROJECT } from 'Pages/Projects/ProjectModal'
import { PERMISSION_BY_ROLE } from 'Utils/permissionByRol'

const FormSettingsProject = ({
  onSubmit,
  showDropdown,
  register,
  clients,
  errors,
  onChangeShowDropdown,
  formControl
}) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const THERE_ARE_CLIENTS = clients.length > 0

  return (
    <form id={formControl} className="d-grid" onSubmit={onSubmit}>
      <div className="row align-items-center mb-3">
        <label className="col-sm-2 col-form-label" htmlFor="inputClient">
          Client
        </label>
        <div className="col-sm-10">
          <div className="row align-items-center gy-3">
            <div className={`${THERE_ARE_CLIENTS ? 'col-md-7' : ''} col-12`}>
              {showDropdown
                ? (
                <select
                  {...register(FIELD_CLIENT_ID_PROJECT, { required: true })}
                  className={`form-control form-select ${errors && errors.client ? 'is-invalid' : ''}`}
                  id="inputClient">
                  {clients.map((client) => (
                    <option value={client.id} key={client.id}>
                      {client.name}
                    </option>
                  ))}
                </select>
                  )
                : (
                <input
                  {...register(FIELD_CLIENT_ID_PROJECT, { required: true })}
                  type="text"
                  className={`form-control ${errors && errors.client_id ? 'is-invalid' : ''}`}
                  id="inputClient"
                  placeholder="Enter your client's name"
                />
                  )}
              <div className="invalid-feedback">
                {showDropdown
                  ? 'Please select a client.'
                  : errors?.client_id?.type === 'exist'
                    ? 'Client name is already exist.'
                    : 'Please write a new client.'}
              </div>
            </div>

            {THERE_ARE_CLIENTS && (
              <div className="col-md-5 col-12">
                {PERMISSION_BY_ROLE.CLIENT.CREATE.ALLOWED.includes(user.role) && (
                  <div className="col-auto d-flex align-items-center">
                    <p className="mb-0 me-4">or</p>
                    <button type="button" onClick={onChangeShowDropdown} className="btn btn-secondary btn-sm">
                      {showDropdown && 'New client'}
                      {!showDropdown && 'Choose a client'}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <label className="col-sm-2 col-form-label" htmlFor="inputName">
          Name
        </label>
        <div className="col-sm-10">
          <input
            type="text"
            {...register('name', { required: true })}
            className={`form-control ${errors && errors.name ? 'is-invalid' : ''}`}
            id="inputName"
            placeholder="Project name"
          />
          <div className="invalid-feedback">Please provide a project name.</div>
        </div>
      </div>
      <div className="row mb-3">
        <label className="col-sm-2 col-form-label" htmlFor="inputCode">
          Code
        </label>
        <div className="col-sm-10">
          <input
            type="text"
            {...register('code', { required: true })}
            className={`form-control ${errors && errors.code ? 'is-invalid' : ''}`}
            id="inputCode"
            placeholder="Project code"
          />
          <div className="invalid-feedback">
            {errors?.code && errors.code.type === 'required'
              ? 'Please provide a project code.'
              : errors?.code?.message || ''}
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <label className="col-sm-2 col-form-label" htmlFor="inputNote">
          Note
        </label>
        <div className="col-sm-10">
          <textarea
            {...register('note', { required: true })}
            className={`form-control ${errors && errors.note ? 'is-invalid' : ''}`}
            id="inputNote"
            rows="4"
            placeholder="Describe the project"
          />
          <div className="invalid-feedback">Please provide a project description.</div>
        </div>
      </div>
    </form>
  )
}

export default FormSettingsProject
