import { Auth } from 'aws-amplify'

Auth.configure({
  region: process.env.REACT_APP_AWS_COGNITO_REGION,
  userPoolId: process.env.REACT_APP_AWS_COGNITO_POOLID,
  userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_CLIENTID,
  oauth: {
    domain: process.env.REACT_APP_COGNITO_DOMAIN,
    scope: ['openid', 'email', 'profile', 'phone'],
    redirectSignIn: process.env.REACT_APP_REDIRECT,
    responseType: 'code'
  }
})

const checkTokenExpirationAndRefresh = async () => {
  try {
    const session = await Auth.currentSession()
    const expirationTime = session.getAccessToken().payload.exp
    const currentTimestamp = Math.floor(Date.now() / 1000)

    if (expirationTime < currentTimestamp) {
      const refreshToken = session.getRefreshToken()

      if (refreshToken) {
        await Auth.currentSession(refreshToken)
        return refreshToken
        // Use refreshedSession.getAccessToken() for new access token
        // Update your app's state or context with the new token
      } else {
        await Auth.signOut()
        return null
      }
    }
  } catch (error) {
    console.error('Error checking token expiration:', error)
    await Auth.signOut()
    return null
  }
}

const signUp = async (data) => {
  try {
    const { user } = await Auth.signUp({
      username: data.email,
      password: data.password,
      attributes: {
        email: data.email
      }
    })
    return user
  } catch (error) {
    console.error('error signing up:', error)
  }
}

const forgotPassword = (email) => Auth.forgotPassword(email)

const completeNewPassword = async (user, password, requiredAttributes) => {
  return Auth.completeNewPassword(user, password, requiredAttributes)
}

const forgotPasswordSubmit = async (username, code, new_password) => {
  try {
    return await Auth.forgotPasswordSubmit(username, code, new_password)
  } catch (error) {
    console.error('error submitting password:', error)
  }
}

const currentSession = () => Auth.currentSession()

const resendConfirmationCode = async (email) => {
  try {
    await Auth.resendSignUp(email)
  } catch (err) {
    console.error('error resending code: ', err)
  }
}

const confirmSignUp = async (email, code) => {
  try {
    await Auth.confirmSignUp(email, code, { forceAliasCreation: false })
  } catch (error) {
    console.error('error confirming sign up', error)
  }
}

const confirmInvite = async (username, password, newPassword, attributes = {}) => {
  try {
    const loggedin = await signIn({ email: username, password })
    completeNewPassword(loggedin.result.user, newPassword)
    return {
      type: 'Success'
    }
  } catch (e) {
    console.error('Error getting current authenticated user', e)
  }
}

const currentAuthenticatedUser = async () => {
  try {
    const { user } = await Auth.currentAuthenticatedUser()
    return user
  } catch (error) {
    console.error('Error getting current authenticated user', error)
  }
}

const signIn = async (data) => {
  return Auth.signIn(data.email, data.password)
    .then((user) => {
      if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        return {
          type: 'MFA'
        }
      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        const { requiredAttributes } = user.challengeParam
        return {
          type: 'NewPasswordRequired',
          result: {
            required_attributes: requiredAttributes,
            user
          }
        }
      } else if (user.challengeName === 'MFA_SETUP') {
        return {
          type: 'TOTP'
        }
      }
      return { type: 'Success', user }
    })
    .catch((e) => {
      if (e.code === 'UserNotConfirmedException') {
        return { type: 'UserNotConfirmed' }
      }
      throw e
    })
}

const signUpUsingProvider = async (provider) => {
  Auth.federatedSignIn({ provider })
}

const signOut = async () => {
  try {
    await Auth.signOut({ global: true })
  } catch (error) {
    console.error('error signing out: ', error)
  }
}

export {
  signUp,
  currentSession,
  forgotPassword,
  forgotPasswordSubmit,
  resendConfirmationCode,
  confirmSignUp,
  confirmInvite,
  completeNewPassword,
  currentAuthenticatedUser,
  signIn,
  signUpUsingProvider,
  signOut,
  checkTokenExpirationAndRefresh
}
