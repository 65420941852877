import { Check, GearSix, PlugsConnected } from '@phosphor-icons/react'
import useSessionStore from 'Context/useSessionStore'
import { DropdownMenuActions } from 'Organisms/DropdownMenuActions'
import { COLOR_BLACK, COLOR_WHITE } from 'constant/colors'

const IntegrationCard = ({ logo, title, description, integrationInfo, onConnect, onEdit, onDelete }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const OPTIONS_MENU = [
    {
      id: 1,
      actions: [
        {
          id: 1,
          onClick: () => onEdit({ edit: integrationInfo }),
          name: 'Edit'
        }
      ]
    },
    {
      id: 2,
      actions: [
        {
          id: 1,
          name: 'Disconnect',
          onClick: () => onDelete(),
          styles: 'text-danger'
        }
      ]
    }
  ]

  return (
    <div className="integration_card">
      <img className="integration_azure" src={logo} />
      <div className="integration_card_title">
        <h6>{title}</h6>
        <p className="m-0">{description}</p>
      </div>
      <div className="integration_card_btns ms-sm-auto m-0">
        {integrationInfo
          ? (
          <>
            <button type="button" className="btn btn-primary text-no-wrap">
              <span>Connected</span> <Check className="icon" size={15} color={COLOR_BLACK} weight="bold" />
            </button>
            <DropdownMenuActions
              icon={<GearSix size={25} color={user.theme ? COLOR_WHITE : COLOR_BLACK} />}
              type="ghost"
              options={OPTIONS_MENU}
            />
          </>
            )
          : (
          <button
            type="button"
            className="btn btn-secondary text-no-wrap"
            onClick={onConnect}>
            <PlugsConnected className="icon" size={15} color={COLOR_BLACK} weight="bold" />
            <span className="ms-1">Connect</span>
          </button>
            )}
      </div>
    </div>
  )
}

export default IntegrationCard
