import useSessionStore from 'Context/useSessionStore'
import { TabsListTable } from 'Organisms/TabsListTable'
import { PERMISSION_BY_ROLE } from 'Utils/permissionByRol'

const TabsListUserTable = ({ tabs, activeTab, onChange }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  return (
    <>
      {PERMISSION_BY_ROLE.USER.TABS_ACTIVE_AND_ARCHIVED.includes(user.role) && (
        <div className="row">
          <div className="col-12">
            <TabsListTable tabs={tabs} activeTab={activeTab} onChange={onChange} />
          </div>
        </div>
      )}
    </>
  )
}

export default TabsListUserTable
