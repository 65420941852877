import { Trash } from '@phosphor-icons/react'
import { useModal, useTimeEntries } from 'Hooks'
import { COLOR_RED } from 'constant/colors'
import { useForm } from 'react-hook-form'

const ModalDeleteTracker = ({ visible, setVisible, tracker, onCancel, refreshTracker }) => {
  const { Modal, hide } = useModal({ identifier: 'deleteTracker', visible, setVisible })

  const { handleSubmit } = useForm()

  const { deleteTimeEntryHook } = useTimeEntries()

  const onSubmit = async () => {
    try {
      await deleteTimeEntryHook({ id: tracker.id })
      await refreshTracker()
      hide()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Modal>
      <div className="modal-content" style={{ maxWidth: 500 }}>
        <form onSubmit={handleSubmit(onSubmit)} className="modal-body p-5">
          <div className="row justify-content-center">
            <Trash size={56} color={COLOR_RED} weight="bold" />
          </div>
          <div className="row justify-content-center py-4">
            <p className="text-center">Are you sure you want to delete this time entry ?</p>
          </div>
          <div className="row justify-content-center">
            <div className="col-auto">
              <button
                type="button"
                className="btn btn-secondary mr-2"
                onClick={() => {
                  hide()
                  onCancel()
                }}>
                Cancel
              </button>
            </div>
            <div className="col-auto">
              <button type="submit" onSubmit={handleSubmit(onSubmit)} className="btn btn-danger">
                Delete
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default ModalDeleteTracker
