import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import useModal from '../../Hooks/useModal'
import { Plugs, PlugsConnected } from '@phosphor-icons/react'
import { updateProjectService } from 'Services'
import { COLOR_RED, GET_COLOR_BY_THEME } from 'constant/colors'
import useSessionStore from 'Context/useSessionStore'

const DeactivateProjectModal = ({ visible, setVisible, selectedProject, reloadProjects = () => {} }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const { Modal, hide } = useModal({ identifier: 'deactivateproject', visible, setVisible })

  const { handleSubmit, reset } = useForm()

  useEffect(() => {
    reset(selectedProject)
  }, [selectedProject, reset])

  const onSubmit = async () => {
    try {
      await updateProjectService(
        {
          is_active: !selectedProject.is_active
        },
        selectedProject.id
      )
      hide()
      reloadProjects()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Modal>
      <div className="modal-content" style={{ maxWidth: 500 }}>
        <form onSubmit={handleSubmit(onSubmit)} className="modal-body p-5">
          <div className="row justify-content-center">
            {selectedProject.is_active && <Plugs size={56} color={COLOR_RED} weight="bold" />}
            {!selectedProject.is_active && (
              <PlugsConnected
                style={{ transform: 'scaleX(-1)' }}
                size={56}
                color={GET_COLOR_BY_THEME({ theme: user.theme }).PRIMARY}
                weight="bold"
              />
            )}
          </div>
          <div className="row justify-content-center py-4">
            <p className="text-center">
              Are you sure you want to {!selectedProject.is_active ? 'activate' : 'deactivate'} [{selectedProject.code}]{' '}
              {selectedProject.name}?{' '}
            </p>
            {selectedProject.is_active && <p>We&apos;ll still keep this project information and related data</p>}
            {!selectedProject.is_active && (
              <p>We&apos;ll restore this project information and related data to the system</p>
            )}
          </div>
          <div className="row justify-content-center">
            <div className="col-auto">
              <button type="button" className="btn btn-secondary mr-2" onClick={hide} id="cancelModal">
                Cancel
              </button>
            </div>
            <div className="col-auto">
              <button
                type="submit"
                onSubmit={handleSubmit(onSubmit)}
                className={`btn ${!selectedProject.is_active ? 'btn-primary' : 'btn-danger'}`}>
                {!selectedProject.is_active ? 'Activate' : 'Deactivate'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default DeactivateProjectModal
