import { PopoverPortal } from 'Organisms/Popover'
import { ChatTeardropDots } from '@phosphor-icons/react'
import Avatar from 'react-avatar'
import { GET_COLOR_BY_THEME } from 'constant/colors'
import useSessionStore from 'Context/useSessionStore'
import { DateTime } from 'luxon'

const PopoverNotesTimeEntry = ({ user, note, spent_date }) => {
  const { user: userContext } = useSessionStore((state) => ({ user: state.user }))

  return (
    <PopoverPortal
      buttonStyle="btn-ghost"
      dark={user.theme}
      buttonShow={
        <>
          <ChatTeardropDots size={25} />
        </>
      }>
        <div className={userContext.theme ? 'dark-theme' : ''}>
          <div className="p-4 d-flex flex-column rounded gap-2 background_secondary popoverNotesTimeEntry">
            <div className="gap-2 d-flex align-items-center">
              <Avatar
                name={`${user.firstname} ${user.lastname}`}
                className="avatarImage"
                src={user?.logo || null}
                color={GET_COLOR_BY_THEME({ theme: userContext.theme }).AVATAR}
                fgColor={GET_COLOR_BY_THEME({ theme: userContext.theme }).AVATAR_TEXT}
                round={true}
                size={40}
                textSizeRatio={3}
              />

              <div>
                <h6 className='m-0'>
                  {user?.firstname || ''} {user?.lastname || ''}
                </h6>
                <p className='m-0 popoverNotesTimeEntry_date'>{DateTime.fromISO(spent_date).setZone('local').toFormat('h:mm a / MMM d, yyyy')}</p>
              </div>
            </div>

            <p className='m-0'>{note}</p>
          </div>
        </div>
    </PopoverPortal>
  )
}

export default PopoverNotesTimeEntry
