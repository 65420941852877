import { FIELD_CONNECT_JIRA } from '../../const/connectJiraFields'

const BasicInfoIntegrationModal = ({ register, errors }) => {
  return (
    <>
      <div className="row">
        <label className="col-12 col-form-label" htmlFor="inputDomain">
          Domain
        </label>
        <div className="col-12">
          <input
            type="text"
            {...register(FIELD_CONNECT_JIRA.DOMAIN, { required: true })}
            className={`form-control ${errors && errors[FIELD_CONNECT_JIRA.DOMAIN] ? 'is-invalid' : ''}`}
            id="inputDomain"
            placeholder="Ex: https://example.atlassian.net"
          />
          <div className="invalid-feedback">
            {errors?.domain
              ? errors?.domain?.type === 'required'
                ? 'Please provide a domain.'
                : errors?.domain?.message || ''
              : ''}
          </div>
        </div>
      </div>

      <div className="row">
        <label className="col-12 col-form-label" htmlFor="inputEmail">
          Email
        </label>
        <div className="col-12">
          <input
            type="text"
            {...register(FIELD_CONNECT_JIRA.EMAIL, { required: true })}
            className={`form-control ${errors && errors[FIELD_CONNECT_JIRA.EMAIL] ? 'is-invalid' : ''}`}
            id="inputEmail"
            placeholder="Enter your linked email"
          />
          <div className="invalid-feedback">Please provide an email.</div>
        </div>
      </div>

      <div className="row">
        <label className="col-12 col-form-label" htmlFor="inputToken">
          Token
        </label>
        <div className="col-12">
          <input
            type="text"
            {...register(FIELD_CONNECT_JIRA.TOKEN, { required: true })}
            className={`form-control ${errors && errors[FIELD_CONNECT_JIRA.TOKEN] ? 'is-invalid' : ''}`}
            id="inputToken"
            placeholder="Enter token"
          />
          <div className="invalid-feedback">Please provide a token.</div>
        </div>
      </div>
    </>
  )
}

export default BasicInfoIntegrationModal
