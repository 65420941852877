import React from 'react'
import { DateTime } from 'luxon'
import { getMyHoursTimeSheet, setBackgroundColorTimeSheet } from '../utils/helpersTimeSheet'
import { countDecimalsAndAdd0, mapStates } from 'Utils/helperFunctions'
import { STATUS_COLOR_TICKET_DANGER } from 'constant/StatusTicket'
import { ProgressBarTimeSheet } from 'Organisms/ProgressBarTimeSheet'
import {
  TABLE_ITEM_COMPLETED_WORK_TIME_ENTRY,
  TABLE_ITEM_DATE_TIME_ENTRY,
  TABLE_ITEM_ESTIMATE_TIME_ENTRY,
  TABLE_ITEM_HOURS_TIME_ENTRY,
  TABLE_ITEM_NOTES_TIME_ENTRY,
  TABLE_ITEM_STATE_TIME_ENTRY,
  TABLE_ITEM_STORY_POINT_TIME_ENTRY,
  TABLE_ITEM_TAKS_TIMEENTRY,
  TABLE_ITEM_TITLE_TIME_ENTRY,
  TABLE_ITEM_USER_TIME_ENTRY
} from 'constant/tableColumns'
import { WarrantyTag } from 'Organisms/WarrantyTag'
import { PopoverNotesTimeEntry } from '../Components'
import { TextTruncate } from 'Organisms/TextTruncate'
import useSessionStore from 'Context/useSessionStore'

const TimesheetTableItem = ({ item, listGroupTimeEntries, colunms }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const estimate = item?.ticket?.estimate || 0
  const hours = item?.hours || 0
  const is_warranty = item.is_warranty

  const title = item.title.trim()

  const { warrantyWork, completedWork } = listGroupTimeEntries[title]
  const billableRateGroup = completedWork - warrantyWork > 0 ? completedWork - warrantyWork : 0

  const COLOR_STATUS = setBackgroundColorTimeSheet({
    status: item?.ticket?.status,
    completedWork,
    estimate,
    warrantyWork
  })

  return (
    <tr>
      {colunms.map((column) => {
        const { id, checked, name } = column

        if (!checked) return <React.Fragment key={id} />

        return (
          <React.Fragment key={id}>
            {name === TABLE_ITEM_DATE_TIME_ENTRY && (
              <td className="text-no-wrap">{DateTime.fromISO(item.spent_date).setZone('local').toFormat('DD')}</td>
            )}

            {name === TABLE_ITEM_TITLE_TIME_ENTRY && (
              <td style={{ maxWidth: 900, width: '100%' }}>
                <TextTruncate
                  text={item.title.trim()}
                  classTooltip={user.theme ? 'custom-tooltip-dark' : 'custom-tooltip-ligth'}
                  maxCharacter={84}
                  classText={COLOR_STATUS === STATUS_COLOR_TICKET_DANGER ? 'text-danger' : ''}>
                  {title} {is_warranty && <WarrantyTag />}
                </TextTruncate>
              </td>
            )}

            {name === TABLE_ITEM_USER_TIME_ENTRY && (
              <td className="text-no-wrap">
                {item?.user?.firstname || ''} {item?.user?.lastname || ''}
              </td>
            )}

            {name === TABLE_ITEM_NOTES_TIME_ENTRY && (
              <td>
                {item?.notes
                  ? (
                  <PopoverNotesTimeEntry note={item?.notes} spent_date={item?.spent_date || ''} user={item?.user} />
                    )
                  : (
                      ''
                    )}
              </td>
            )}

            {name === TABLE_ITEM_TAKS_TIMEENTRY && (
              <td className="text-no-wrap">{item?.project_task?.task?.name || '-'}</td>
            )}

            {name === TABLE_ITEM_STATE_TIME_ENTRY && (
              <td>
                <span className={`badge ${estimate ? `text-bg-${COLOR_STATUS}` : ''} `}>
                  {item?.ticket?.status ? mapStates(item?.ticket?.status || '') : '-'}
                </span>
              </td>
            )}

            {name === TABLE_ITEM_STORY_POINT_TIME_ENTRY && <td>{item?.ticket?.story_points ?? '-'}</td>}
            {name === TABLE_ITEM_ESTIMATE_TIME_ENTRY && <td>{estimate || '-'}</td>}
            {name === TABLE_ITEM_HOURS_TIME_ENTRY && (
              <td className={`${COLOR_STATUS === STATUS_COLOR_TICKET_DANGER ? 'text-danger' : ''}`}>
                {countDecimalsAndAdd0({ number: hours })}
              </td>
            )}

            {name === TABLE_ITEM_COMPLETED_WORK_TIME_ENTRY && (
              <td className={`${COLOR_STATUS === STATUS_COLOR_TICKET_DANGER ? 'text-danger' : ''}`}>
                {countDecimalsAndAdd0({ number: completedWork || 0 })}
              </td>
            )}
          </React.Fragment>
        )
      })}

      <td>
        <ProgressBarTimeSheet
          warrantyWork={warrantyWork}
          estimate={estimate}
          billableRateGroup={billableRateGroup}
          status={item?.ticket?.status || ''}
          completedWork={completedWork}
          item={item}
        />
      </td>
      <td>
        <span className="col-auto">
          {getMyHoursTimeSheet({
            completedWork: completedWork + warrantyWork,
            estimate,
            warranty: is_warranty ? hours : 0
          })}
        </span>
      </td>
    </tr>
  )
}

export default TimesheetTableItem
