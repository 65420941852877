import { CheckCircle, Info } from '@phosphor-icons/react'
import { Popover } from 'Organisms/Popover'
import { COLOR_LIGHT_BLUE } from 'constant/colors'

const PasswordRequirement = () => (
  <Popover buttonStyle="btn-ghost ms-2 p-0" buttonShow={<Info size={15} />} hover>
    <div className="login-required-password p-2" style={{ width: 200 }}>
      <p className="m-0 text-white">Your password must contain:</p>
      <p className="m-0 text-white d-flex align-items-center">
        <CheckCircle className="me-1" size={15} color={COLOR_LIGHT_BLUE} /> Uppercase
      </p>
      <p className="m-0 text-white d-flex align-items-center">
        <CheckCircle className="me-1" size={15} color={COLOR_LIGHT_BLUE} /> Lowercase
      </p>
      <p className="m-0 text-white d-flex align-items-center">
        <CheckCircle className="me-1" size={15} color={COLOR_LIGHT_BLUE} /> Number
      </p>
      <p className="m-0 text-white d-flex align-items-center">
        <CheckCircle className="me-1" size={15} color={COLOR_LIGHT_BLUE} /> Special Character
      </p>
      <p className="m-0 text-white d-flex align-items-center">
        <CheckCircle className="me-1" size={15} color={COLOR_LIGHT_BLUE} /> Min. 8 characters
      </p>
    </div>
  </Popover>
)

export default PasswordRequirement
