import { WarningCircle } from '@phosphor-icons/react'
import useSessionStore from 'Context/useSessionStore'
import { useUser } from 'Hooks'
import { Loading } from 'Organisms/Loading'
import { ModalSuccess } from 'Organisms/ModalSuccess'
import { tzStrings } from 'Utils/helperFunctions'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { HeaderSettings } from '../HeaderSettings'

const INIT_STATE_MODAL_MESSAGE = {
  open: false,
  message: '',
  error: false
}

const BasicInfoSettings = () => {
  const { user, updateUser } = useSessionStore((state) => ({ user: state.user, updateUser: state.updateUser }))

  const { loadingUserHook, updateProfileUserHook } = useUser()

  const [modalMessage, setModalMessage] = useState(INIT_STATE_MODAL_MESSAGE)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      firstname: user?.firstname ?? '',
      lastname: user?.lastname ?? '',
      email: user?.email ?? '',
      timezone: user?.timezone ?? ''
    }
  })

  const onSubmit = async (data) => {
    const body = {
      firstname: data.firstname,
      lastname: data.lastname,
      full_name: `${data?.firstname || ''} ${data?.lastname || ''}`,
      timezone: data.timezone
    }

    const res = await updateProfileUserHook({ body })

    if (!res.success) {
      return setModalMessage({
        open: true,
        message: 'An error occurred while updating your profile',
        error: true
      })
    }

    updateUser({
      ...user,
      ...res.data
    })
    setModalMessage({
      open: true,
      message: 'Your profile is updated successfully',
      error: false
    })
  }

  return (
    <>
      {loadingUserHook && <Loading />}
      <form id="addMember" className="col-12 d-grid align-content-start" onSubmit={handleSubmit(onSubmit)}>
        <HeaderSettings title='Basic Information' />

        <div className="row mb-3">
          <label className="col-lg-2 col-md-3 col-12 col-form-label" htmlFor="inputFirstName">
            First Name *
          </label>
          <div className="col-lg-5 col-md-9 col-12">
            <input
              {...register('firstname', { required: true })}
              type="text"
              className={`form-control ${errors && errors.firstname ? 'is-invalid' : ''}`}
              id="inputFirstName"
              placeholder="First name"
            />
            <div className="invalid-feedback">Please provide a First name.</div>
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-lg-2 col-md-3 col-12 col-form-label" htmlFor="inputLastName">
            Last Name *
          </label>
          <div className="col-lg-5 col-md-9 col-12">
            <input
              name="lastname"
              type="text"
              {...register('lastname', { required: true })}
              className={`form-control ${errors && errors.lastname ? 'is-invalid' : ''}`}
              id="inputLastName"
              placeholder="Last name"
            />
            <div className="invalid-feedback">Please provide a Last name.</div>
          </div>
        </div>

        <div className="row align-items-center mb-3">
          <label className="col-lg-2 col-md-3 col-12 col-form-label" htmlFor="inputTimezone">
            Timezone
          </label>
          <div className="col-lg-5 col-md-9 col-12">
            <select id="inputTimezone" className="form-select" {...register('timezone')}>
              {tzStrings.map((item, key) => (
                <option key={key} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-lg-2 col-md-3 col-12 col-form-label" htmlFor="inputEmail">
            Email
          </label>
          <div className="col-lg-5 col-md-9 col-12">
            <input
              name="email"
              autoComplete="false"
              type="email"
              {...register('email', { required: true })}
              className={`form-control ${errors && errors.email ? 'is-invalid' : ''}`}
              id="inputEmail"
              placeholder="Email"
              disabled
            />
            <div className="invalid-feedback">
              {errors?.email?.type === 'exists' ? errors.email.message : 'Please provide an Email.'}
            </div>
          </div>
        </div>
      </form>

      {modalMessage.open && (
        <ModalSuccess
          visible={true}
          setVisible={() => setModalMessage(INIT_STATE_MODAL_MESSAGE)}
          title={modalMessage.message}
          identifier="modalMessageSuccess"
          error={modalMessage.error}
          icon={modalMessage.error && WarningCircle}
        />
      )}
    </>
  )
}

export default BasicInfoSettings
