import {
  deleteScheduleService,
  getScheduleService,
  postScheduleService,
  updateScheduleService
} from 'Services/Schedule.service'
import { useState } from 'react'

export default function useSchedule () {
  const [scheduleHook, setScheduleHook] = useState(null)

  const getScheduleHook = async () => {
    try {
      const res = await getScheduleService()
      const splitHours = res.data.hour.split(':')

      const data = {
        success: true,
        data: {
          ...res.data,
          hours: splitHours[0],
          minutes: splitHours[1]
        }
      }

      setScheduleHook(data)
      return data
    } catch {
      return {
        success: false
      }
    }
  }

  const postScheduleHook = async ({ weekday, hour, timezone }) => {
    try {
      await postScheduleService({ weekday, hour, timezone })

      return {
        success: true
      }
    } catch {
      return {
        success: false
      }
    }
  }

  const updateScheduleHook = async ({ weekday, hour, timezone }) => {
    try {
      await updateScheduleService({ weekday, hour, timezone })

      return {
        success: true
      }
    } catch {
      return {
        success: false
      }
    }
  }

  const deteleSchedule = async () => {
    try {
      await deleteScheduleService()

      return {
        success: true
      }
    } catch {
      return {
        success: false
      }
    }
  }

  return {
    scheduleHook,
    postScheduleHook,
    getScheduleHook,
    updateScheduleHook,
    deteleSchedule
  }
}
