import React, { useContext } from 'react'
import { TimesheetTableItem } from './'
import ContextColumnTimeEntry from 'Context/ColumnsTimeEntryContext'

const TableTimeSheetAll = ({ dataList, listGroupTimeEntries }) => {
  const { timeEntryColunm } = useContext(ContextColumnTimeEntry)

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            {timeEntryColunm.map((item) => {
              const { checked, id, text } = item
              if (!checked) return <React.Fragment key={id} />

              return (
                <th key={id} scope="col">
                  {text}
                </th>
              )
            })}

            <th scope="col" colSpan={2} />
          </tr>
        </thead>
        <tbody>
          {dataList.map((timeEntry) => (
            <TimesheetTableItem key={timeEntry.id} item={timeEntry} listGroupTimeEntries={listGroupTimeEntries} colunms={timeEntryColunm} />
          ))}
        </tbody>
      </table>
    </div>
  )
}
export default TableTimeSheetAll
