import SelectWithHint from 'Organisms/SelectWithHint'

const OPTIONS_ABOUT_PULSE_STEP = [
  {
    value: 'Search engines (Google, Bing, etc.)',
    label: 'Search engines (Google, Bing, etc.)'
  },
  {
    value: 'Recommendation (frind, colleague) ',
    label: 'Recommendation (frind, colleague) '
  },
  {
    value: 'Another app (e.g. Hubspot Marketplace)',
    label: 'Another app (e.g. Hubspot Marketplace)'
  },
  {
    value: 'Ads',
    label: 'Ads'
  },
  {
    value: 'Youtube',
    label: 'Youtube'
  },
  {
    value: 'Instagram',
    label: 'Instagram'
  },
  {
    value: 'Linkedin',
    label: 'Linkedin'
  },
  {
    value: 'Online community',
    label: 'Online community'
  },
  {
    value: 'Other',
    label: 'Other'
  }
]

const StepAboutUs = ({ data, setData }) => {
  return (
    <div>
      <h4>How did you hear about us?</h4>
      <p>Knowing how you hear about Pulse helps us understand where to find more customers.</p>

      <SelectWithHint
        value={data?.about_pulse ? OPTIONS_ABOUT_PULSE_STEP.find((item) => item.value === data.about_pulse) : null}
        id="selectAboutUs"
        placeholder="Select..."
        formatSelect={OPTIONS_ABOUT_PULSE_STEP}
        handleChange={({ value }) => setData({ ...data, about_pulse: value })}
      />
    </div>
  )
}

export default StepAboutUs
