import {
  FIELD_CONNECT_INTEGRATION_ESTIMATE,
  FIELD_CONNECT_INTEGRATION_SPRINT,
  FIELD_CONNECT_INTEGRATION_STORY_POINTS
} from '../../const/connectJiraFields'

const FIELDS_LIST_CONENCT = [
  FIELD_CONNECT_INTEGRATION_STORY_POINTS,
  FIELD_CONNECT_INTEGRATION_ESTIMATE,
  FIELD_CONNECT_INTEGRATION_SPRINT
]

const ConnectFieldsIntegrationModal = ({ register, errors, options }) => {
  return (
    <>
      {FIELDS_LIST_CONENCT.map((field) => (
        <div className="row" key={field.field}>
          <label className="col-12 col-form-label" htmlFor={`input${field.field}`}>
            {field.name}
          </label>
          <div className="col-12">
            <select
              {...register(field.field)}
              className={`form-control form-select ${errors && errors[field.field] ? 'is-invalid' : ''}`}
              id={`input${field.field}`}>
              <option value="">Select an option</option>
              {options.map((option) => (
                <optgroup key={option.category} label={option.category}>
                  {option.fields.map((field) => (
                    <option key={field.key} value={field.key}>
                      {field.name}
                    </option>
                  ))}
                </optgroup>
              ))}
            </select>
            <div className="invalid-feedback">Please connect a {field.name}.</div>
          </div>
        </div>
      ))}
    </>
  )
}

export default ConnectFieldsIntegrationModal
