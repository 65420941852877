import { DateTime } from 'luxon'

import { COLOR_BLUE_MATE } from 'constant/colors'
import SearchableListButton from 'Organisms/SearchableListButton'
import useSessionStore from 'Context/useSessionStore'
import { ROLE_DEVELOPER_NAME } from 'constant/roles'
import { BtnAddTracker } from '../BtnAddTracker'
import { useMediaQuery } from 'react-responsive'
import { BREAKPOINT } from 'Utils/const/breakPoints'
import { decimalToHours } from 'Utils/helperFunctions'

const FilterTrackerByDate = ({
  onChangeDay,
  currentDayName,
  activeDate,
  usersFilter,
  onChangeUserFilters,
  approval,
  onOpenAlert,
  selectedTracker,
  onChangeSelectedTracker,
  onOpen,
  hoursActiveDay
}) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const isMobile = useMediaQuery({
    query: `(max-width: ${BREAKPOINT.MOBILE})`
  })

  const isDeveloper = user.role === ROLE_DEVELOPER_NAME

  return (
    <div className="filterTrackerByDate">
      <div className="btn-group" role="group">
        <button
          id="timeTrackerGoBackDay"
          type="button"
          className="btn btn-secondary"
          onClick={() => onChangeDay({ days: -1 })}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={COLOR_BLUE_MATE} viewBox="0 0 256 256">
            <path d="M168.49,199.51a12,12,0,0,1-17,17l-80-80a12,12,0,0,1,0-17l80-80a12,12,0,0,1,17,17L97,128Z"></path>
          </svg>
        </button>
        <button
          id="timeTrackerNextDay"
          type="button"
          className="btn btn-secondary"
          onClick={() => onChangeDay({ days: 1 })}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={COLOR_BLUE_MATE} viewBox="0 0 256 256">
            <path d="M184.49,136.49l-80,80a12,12,0,0,1-17-17L159,128,87.51,56.49a12,12,0,1,1,17-17l80,80A12,12,0,0,1,184.49,136.49Z"></path>
          </svg>
        </button>
      </div>

      <h2 className="mb-0 filterTrackerByDate_day" id="timeTrackerDay">
        {currentDayName}, {activeDate.toFormat('dd')} {activeDate.toFormat('LLL')}
        {activeDate.ordinal !== DateTime.now().ordinal && (
          <button
            type="button"
            id="trackerReturnToToday"
            className="btn btn-ghost text-primary"
            onClick={() => onChangeDay({ now: true })}>
            Return to today
          </button>
        )}
      </h2>

      {isMobile && (
        <h2 className='filterTrackerByDate_hours_mobile'>{decimalToHours({ time: hoursActiveDay || 0 })}</h2>
      )}

      <div className="filterTrackerByDate_filterUser">
        {!isDeveloper && (
          <SearchableListButton
            id="filterByUser"
            name="Users"
            switches={usersFilter || []}
            setSwitches={onChangeUserFilters}
            onlyOne
            colorBtn='secondary'
          />
        )}

        <BtnAddTracker
          approval={approval}
          onOpenAlert={onOpenAlert}
          selectedTracker={selectedTracker}
          onChangeSelectedTracker={onChangeSelectedTracker}
          onOpen={onOpen}
        />
      </div>
    </div>
  )
}

export default FilterTrackerByDate
