import { X } from '@phosphor-icons/react'
import useSessionStore from 'Context/useSessionStore'
import SearchableListButton from 'Organisms/SearchableListButton'
import { GET_COLOR_BY_THEME } from 'constant/colors'
import { useEffect, useState } from 'react'
import { CardDetailsTimeEntry } from './Components'
import { useTimeEntries } from 'Hooks'

const DetailsTimeEntry = ({ onClose, title, idEntry }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const { ticketHistory, getTimeEntryDetailByIdHook } = useTimeEntries()

  const [listHistoryByFilter, setListHistoryByFilter] = useState([])
  const [selectFilter, setSelectFilter] = useState([
    {
      id: 'status_entry',
      value: 'status',
      text: 'Status change',
      checked: false,
      filterBy: ['STATUS_CHANGE']
    },
    {
      id: 'notes_entry',
      value: 'notes',
      text: 'Notes',
      checked: false,
      filterBy: ['NOTE']
    },
    {
      id: 'time_entry_entry',
      value: 'time_entry',
      text: 'Time entry',
      checked: false,
      filterBy: ['STOP', 'START']
    },
    {
      id: 'payroll',
      value: 'payroll',
      text: 'Payroll',
      checked: false,
      filterBy: ['SUBMITED', 'PAYROLL']
    }
  ])
  const [loading, setLoading] = useState(true)

  const handleChangeSwitches = ({ values, defaultList }) => {
    const list = defaultList || ticketHistory

    const listFilterBy = []

    values.forEach(item => {
      if (item.checked) listFilterBy.push(...item.filterBy)
    })

    setListHistoryByFilter(listFilterBy.length > 0 ? list.filter(item => listFilterBy.includes(item.event.type)) : list)
    setSelectFilter(values)
  }

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const result = await getTimeEntryDetailByIdHook({ entryId: idEntry })
        handleChangeSwitches({ values: selectFilter, defaultList: result?.data || [] })
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    })()
  }, [idEntry])

  return (
    <div className="timesheet-card card p-4">
      {loading
        ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <div className="spinner" />
        </div>
          )
        : (
        <div>
          <div className="row gx-3 align-items-center justify-content-between mb-4">
            <div className="col-3">
              <h5 className="m-0">History</h5>
            </div>
            <div className="col-8">
              <SearchableListButton
                colorBtn="secondary"
                style="width-percent-100 d-flex justify-content-between align-items-center"
                id="selectTypeEntry"
                name="Type of entry"
                switches={selectFilter}
                contentWidthAll
                setSwitches={handleChangeSwitches}
              />
            </div>
            <div className="col-1 m-0" onClick={onClose}>
              <X size={20} className="pointer" color={GET_COLOR_BY_THEME({ theme: user.theme }).MENU_NAVBAR} />
            </div>
          </div>

          <div className="row">
            <div className="col-12 mb-4">
              <h6>{title}</h6>
            </div>
          </div>

          {listHistoryByFilter.map((detail) => (
            <CardDetailsTimeEntry key={detail.id} date={detail.created_at} event={detail.event} userDetail={detail.user} />
          ))}
        </div>
          )}
    </div>
  )
}

export default DetailsTimeEntry
