import { CaretLeft } from '@phosphor-icons/react'
import { CustomDatePicker } from './'
import { COLOR_BLUE_MATE } from 'constant/colors'

const CustomDatePickerGoBack = ({ onSearchData, disabledAll, goBack, startDay, endDay, titleMobile, defaultPeriod }) => {
  if (goBack) {
    return (
      <>
        {titleMobile && <h1 className='mb-3'>{titleMobile}</h1>}
        <div className="d-flex aling-items-center">
          <CaretLeft id="goBackBreadcrumbs" className="pointer" size={32} color={COLOR_BLUE_MATE} onClick={goBack} />
          <h3 className="mb-0">
            {startDay.toFormat('cccc')}, {startDay.toFormat('dd')} {startDay.toFormat('LLL')} -{' '}
            {endDay.toFormat('cccc')}, {endDay.toFormat('dd')} {endDay.toFormat('LLL')}
          </h3>
        </div>
      </>
    )
  }

  return (
    <CustomDatePicker
      titleMobile={titleMobile}
      onSearchData={onSearchData}
      disabledAll={disabledAll}
      defaultStartDay={startDay}
      defaultEndDay={endDay}
      defaultPeriod={defaultPeriod}
    />
  )
}

export default CustomDatePickerGoBack
