import Avatar from 'react-avatar'

function ActivityLogItem ({ item }) {
  const setText = (item) => {
    if (item.type === 'note without ticket number') {
      return 'Missing ticket number: 0-Onboarding: MSA meeting'
    } else if (item.type === 'Overtime ticket vs Estimate') {
      return 'Overtime ticket vs Estimate: 0-Onboarding: MSA meeting'
    } else if (item.type === 'No activity') {
      return `${item.user} did not reported any time`
    } else {
      ;('')
    }
  }

  return (
    <div className="row">
      <div className="col-auto activity-log-user">
        <Avatar
          className="activity-log-user-image avatarImage"
          name={`${item.firstname} ${item.lastname}`}
          src={item?.logo || null}
          color="#e9c46a"
          fgColor="#000000"
          round={true}
          size="30px"
          textSizeRatio={3}
        />
      </div>
      <div className="col-10 text-secondary pb-3">
        <p>{setText(item)}</p>
        {item.ticket && (
          <a href="#" className="ticket card shadow rounded px-4 py-3 mt-3">
            <span className="row">
              <span className="col-auto">
                {item.ticket.type === 'jira' && (
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                    <path
                      fill="#0075D1"
                      d="M152.2 236.4c-7.7-8.2-19.7-7.7-24.8 2.8L1.6 490.2c-5 10 2.4 21.7 13.4 21.7h175c5.8.1 11-3.2 13.4-8.4 37.9-77.8 15.1-196.3-51.2-267.1zM244.4 8.1c-122.3 193.4-8.5 348.6 65 495.5 2.5 5.1 7.7 8.4 13.4 8.4H497c11.2 0 18.4-11.8 13.4-21.7 0 0-234.5-470.6-240.4-482.3-5.3-10.6-18.8-10.8-25.6.1z"
                    />
                  </svg>
                )}
                {item.ticket.type === 'azure' && (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20px" height="20px">
                    <polygon
                      fill="#0075D1"
                      points="2.966,8.331 18.021,5.403 10.652,0 10.652,2.476 2.247,5.899 0,8.871 0,15.714 2.966,16.57 2.966,8.331"
                    />
                    <polygon
                      fill="#0075D1"
                      points="8.944,20.939 18.247,24 24,19.092 24,4.412 18.023,5.403 18.023,18.372 2.966,16.57 8.944,24 8.944,20.939"
                    />
                    <polygon
                      fill="#0066B6"
                      points="10.652,0 10.652,2.476 2.247,5.899 0,8.871 0,15.714 2.966,16.57 2.966,8.331 12,6.574 12,0.988 10.652,0"
                    />
                    <polygon
                      fill="#0066B6"
                      points="2.966,16.57 8.944,24 8.944,20.939 12,21.944 12,17.651 2.966,16.57"
                    />
                  </svg>
                )}
              </span>
              <span className="col">
                <span className="mb-0">{item.ticket.description}</span>
              </span>
            </span>
          </a>
        )}
      </div>
    </div>
  )
}

export default ActivityLogItem
