import useSessionStore from 'Context/useSessionStore'
import { ROLE_ADMIN_NAME } from 'constant/roles'
import { Fragment } from 'react'

export const TABS_OPTIONS_SETTINGS = {
  BASIC_INFO: {
    id: 1,
    name: 'Basic Info',
    key: 'BASIC_INFO'
  },
  SETTINGS: {
    id: 2,
    name: 'Settings',
    key: 'SETTINGS'
  },
  BILLING: {
    id: 3,
    name: 'Billing',
    scope: [ROLE_ADMIN_NAME],
    key: 'BILLING'
  }
}

const TabsSettings = ({ onChangeTab, activeTab }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  return (
    <ul className="nav nav-pills flex-column mt-4">
      {Object.values(TABS_OPTIONS_SETTINGS).map((option) => {
        if (option?.scope && !option.scope.includes(user.role)) return <Fragment key={option.id} />

        return (
          <li key={option.id} className="nav-item">
            <p
              className={`nav-link pointer m-0 ${activeTab === option.id ? 'active' : ''}`}
              onClick={() => onChangeTab({ tab: option.id })}>
              {option.name}
            </p>
          </li>
        )
      })}
    </ul>
  )
}

export default TabsSettings
